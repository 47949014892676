import {Component, OnDestroy, OnInit, ChangeDetectorRef} from '@angular/core';
import { ChatService } from './chat.service';
import { UsersService } from '../users/users.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {last, Subscription} from "rxjs";
import { Membership, User } from '@pubnub/chat';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  standalone: true,
  styleUrls: ['./chat.component.css'],
  imports: [FormsModule, CommonModule]
})
export class ChatComponent implements OnInit, OnDestroy {
  isTyping: boolean = false; // To track typing status of the other user
  private typingSubscription: (() => void) | null = null;
  errorMessage: string = '';
  messageError: string = '';
  isMessageFieldError: boolean = false;
  isSearchFieldError: boolean = false;
  showSearchResults: boolean = false;
  isModalOpen: boolean = false;
  isDirectConversationOpen: boolean = false;
  currentMessage: string = '';
  directConversationSearchTerm: string = '';
  private messageSubscriptions: Subscription[] = [];  // Store all message subscriptions
  channel: string = '';
  users: any[] = [];
  directFilteredUsers: any[] = [];
  filteredUsers: any[] = [];
  selectedUser: any | null = null;
  last_selected_user: any | null = null;  // To store the last selected user
  reactions: string[] = ['👍', '❤️', '😂', '😢', '❗'];
  searchTerm: string = '';
  is_loading = false;
  from_user_id: string | null = localStorage.getItem('email');
  from_user_name: string | null = localStorage.getItem('email');
  to_user_id: string | null = '';
  to_user_name: string | null = '';
  to_user_email: string | null = '';
  lastMessageWithReactionPanel: any | null = null;
  private conversationSubscription!: Subscription;
  messages: any[] = [];


  constructor(
    private chatService: ChatService,
    private usersService: UsersService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // Ensure chat is initialized before joining channels
    this.chatService.waitForChatInitialization().then(() => {
      console.log('Chat initialized. Proceeding to join channels.');
      this.getUsers();
      this.fetchUnreadMessagesAndListenForChannels();
      this.conversationSubscription = this.chatService.newConversation$.subscribe(({ channel, membership }) => {
        this.listenForMessagesInChannel(channel, membership);
      });
    }).catch((error) => {
      console.error('Error initializing chat service:', error);
    });
  }


  ngOnDestroy(): void {
    if (this.conversationSubscription) {
      this.conversationSubscription.unsubscribe();
    }
    if (this.messageSubscriptions) {
      this.messageSubscriptions.forEach(subscription => {
        subscription.unsubscribe();
        console.log(`Successfully unsubscribed`);
      });
    }
    const disconnectFunctions = this.chatService.getDisconnectFunctions();
    console.log(`The number of disconnect functions are ${disconnectFunctions.length}`);
    disconnectFunctions.forEach(disconnect => {
      disconnect();
      console.log('Successfully disconnected from a channel.');
    });
    if (this.last_selected_user) {
      this.chatService.updateUserStatus(this.last_selected_user.user_id, 'inactive');
    }
    // Unsubscribe from typing events
    if (this.typingSubscription) {
      this.typingSubscription();
    }
    // this.chatService.leaveAllUserChannels();
    // this.chatService.deleteAllChannels(false);
    this.chatService.destroyVariables();
  }


  getUsers(): void {
    this.is_loading = true;
    const fromUserId = this.from_user_id ?? '';
    const slug = environment.slug;
    this.usersService.getUsers(`clients/${slug}`, false).subscribe((response: any) => {
      this.users = response.message;
    }, (error) => {
      console.error('Error fetching users:', error);
      this.is_loading = false;
    });
  }



  async fetchUnreadMessagesAndListenForChannels(): Promise<void> {
    try {
      // Get the list of all memberships
      const memberships = await this.chatService.getUserMemberships();
      // Loop through each membership and process
      for (const membership of memberships) {
        // Get the unread message count for this membership
        const unreadCount = await this.chatService.getUnreadMessages(membership);
        console.log(`The user id for the membership is ${membership.user.id}`);
        console.log(`Unread messages for channel ${membership.channel.id}: ${unreadCount}`);
        // Listen for real-time messages in this channel
        this.listenForMessagesInChannel(membership.channel, membership);
      }
      this.filterUsers();
      this.cdr.detectChanges();
    } catch (error) {
      console.error('Error fetching unread messages and listening to channels:', error);
    }
  }

  // Add these new properties

  filterUsers(): void {
    const recentChats = this.chatService.getRecentChats();

    if (this.isDirectConversationOpen) {
      if (this.directConversationSearchTerm.trim()) {
        this.directFilteredUsers = this.users.filter(user =>
          user.name?.toLowerCase().includes(this.directConversationSearchTerm.toLowerCase())
        );
        this.showSearchResults = this.directFilteredUsers.length > 0;

        // Reset error state when user starts typing
        this.errorMessage = '';
        this.isSearchFieldError = false;
      } else {
        this.directFilteredUsers = [];
        this.showSearchResults = false;
      }
    } else {
      if (this.searchTerm.trim()) {
        this.filteredUsers = recentChats.filter(user =>
          user.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      } else {
        this.filteredUsers = recentChats;
        this.filteredUsers.forEach(user => {
          this.chatService.updateUserStatus(user.user_id, 'inactive');
        });
      }
    }
  }

  selectUser(user: any): void {
    if (this.last_selected_user) {
      this.chatService.updateUserStatus(this.last_selected_user.user_id, 'inactive');
    }
    if (this.isDirectConversationOpen) {
      // Check if the user is already in historyUsers
      const userExists = this.filteredUsers.some(existingUser => String(existingUser.user_id) === String(user.user_id));
      if (userExists) {
        // If user already exists, show an error and highlight the search field
        this.errorMessage = "Chat with this user already exists, cannot create new chat";
        this.isSearchFieldError = true;
        console.log(this.errorMessage);
        this.showSearchResults = false;
        return;
      }

      // Reset error state if no issues
      this.errorMessage = '';
      this.isSearchFieldError = false;

      // Proceed with selecting the user for a new conversation
      this.selectedUser = user;
      this.to_user_id = String(this.selectedUser.user_id);
      this.to_user_name = String(this.selectedUser.name);
      this.to_user_email = String(this.selectedUser.email);
      this.from_user_id = this.from_user_id ?? '';

      const selectedUserChannel = `${uuidv4()}`;
      this.channel = selectedUserChannel;

      // Fill the search field with the selected user's name
      this.directConversationSearchTerm = this.selectedUser.name;

      // Hide the search results
      this.showSearchResults = false;

      console.log("Selected user in Direct Conversation box:", this.selectedUser);
    }
    else {
      this.selectedUser = user;
      console.log(`This is the type of `, this.selectedUser);
      this.to_user_id = String(this.selectedUser.user_id);
      this.to_user_name = String(this.selectedUser.name);
      this.to_user_email = String(this.selectedUser.email);
      this.from_user_id = this.from_user_id ?? '';
      const selectedUserChannel = this.chatService.getChannelForUser(this.to_user_id);
      console.log("Current channel:", selectedUserChannel);
      this.channel = selectedUserChannel; //Keep it here for now, remove it from here after adding status tags for channel presence
      this.chatService.updateUserStatus(this.selectedUser.user_id, 'active');
      console.log(`User ${this.selectedUser.name} with userID ${this.selectedUser.user_id} is now active.`);
      // Update the last_selected_user
      this.last_selected_user = this.selectedUser;

      console.log(`This is the type of `, this.selectedUser);
      // Marking Messages as read in the chat
      this.chatService.markMessagesAsRead(this.channel).then(() => {
        this.chatService.updateUserStatus(this.selectedUser.user_id, undefined, 0);
        this.cdr.detectChanges;
        console.log(`Unread count for user ${this.selectedUser.name} has been reset to 0.`);
      }).catch((error) => {
        console.error('Error marking messages as read:', error);
      });

      this.chatService.getChannelHistory(this.channel, undefined, undefined, 25).then((history) => {
        if (history) {
          this.messages = history.messages;
          // Set message.reaction for each message
          this.messages.forEach((message) => {
            if (message.reactions) {
              const userReactions = message.reactions;
              const reactions = Object.keys(userReactions);
              if (reactions.length > 0) {
                // Assuming each message can have only one reaction from the user
                message.reaction = reactions[0];
              }
            }
          });
          this.cdr.detectChanges();
          this.scrollToBottomDiv();
        } else {
          console.error('No message history available.');
        }
      }).catch((error) => {
        console.error('Error fetching history or channel not available:', error);
      });
      // Typing Indicator functionality
        this.chatService.listenForTyping(this.channel, (typingUserIds: string[]) => {
          console.log()
          this.isTyping = typingUserIds.includes(this.to_user_id || '');
          this.cdr.detectChanges();
          this.scrollToBottomDiv();
        }).then((unsubscribe) => {
          this.typingSubscription = unsubscribe;  // Save the unsubscribe function
        }).catch((error) => {
          console.error('Error subscribing to typing events:', error);
        });
    }
  }

  scrollToBottomDiv(): void {
    try {
      console.log('Scrolling to bottom');
      const chatMessages = document.querySelector('.chat-messages');
      if (chatMessages) {
      chatMessages.scrollTop = chatMessages.scrollHeight;
      }
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }

  scrollToBottom(): void {
    try {
      console.log('Scrolling to bottom');
      const lastMessage = document.querySelector('.chat-messages div:last-child');
      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: 'smooth' });  // Scrolls the last message into view
      }
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }


  sendMessage(): void {
    if (!this.selectedUser) {
      // If no user is selected, show an error message and highlight the text box
      this.messageError = "Select user before sending message";
      this.isMessageFieldError = true;
      console.log(this.messageError);
      return;
    }

    // Check if the current message is empty
    if (!this.currentMessage.trim()) {
      this.messageError = "Cannot send empty message";
      this.isMessageFieldError = true;
      console.log("Message is empty, not sending");
      return;
    }

    this.chatService.sendTextMessage(this.channel, this.currentMessage,
      this.to_user_id || '', this.to_user_name || '', this.from_user_name || '')
      .then((message) => {
        this.currentMessage = '';  // Clear the input field after the message is sent
        this.messageError = "";
        this.isMessageFieldError = false;
        if(this.isDirectConversationOpen) {
          this.closeDirectConversation();
        }
        this.chatService.stopTyping(this.channel); // Stop typing after sending message
      })
      .catch((error) => {
        console.error('Error sending message:', error);
    });
  }


  listenForMessagesInChannel(channel: any, membership: Membership): void {
    // const memberships = this.chatService.getUserMemberships();
    // Subscribe to the channel using the channel object directly
    const subscription = this.chatService.subscribeToChannel(channel, membership).subscribe(
      async (message) => {
        if (this.selectedUser && channel.id === this.channel) {
          console.log(`New message received in channel ${channel.id}:`, message);
          this.messages.push(message);  // Add the new message to the list
          this.cdr.detectChanges();
          this.scrollToBottomDiv();
        } else {
          console.log(`Message received for another channel: ${channel.id}. Ignoring.`);
        }
      },
      (error) => {
        console.error(`Error receiving message in channel ${channel.id}:`, error);
      }
    );
    // Push the subscription to the array right after subscribing
    this.messageSubscriptions.push(subscription);
  }

  onInputChange(): void {
    if (this.currentMessage.trim()) {
      this.chatService.startTyping(this.channel); // Start typing when the user types

    }
  }
  // // Method to open the reaction panel
  // openReactionPanel(event: MouseEvent, message: any): void {
  //   event.preventDefault(); // Prevent the default context menu
  //   event.stopPropagation();
  //   // Close any other open reaction panels
  //   if (this.lastMessageWithReactionPanel && this.lastMessageWithReactionPanel !== message) {
  //     this.lastMessageWithReactionPanel.showReactionPanel = false;
  //   }
  //   console.log('We are inside openReactionPanel');
  //   // Toggle the reaction panel for this message
  //   message.showReactionPanel = !message.showReactionPanel;
  //   this.lastMessageWithReactionPanel = message.showReactionPanel ? message : null;
  //   this.cdr.detectChanges();
  // }

  // // Method to select a reaction
  // selectReaction(message: any, reaction: string): void {
  //   console.log('We are inside selectPanel');
  //   // Step 1: Update the reaction state immediately
  //   if (message.reaction === reaction) {
  //     // If the same reaction is clicked, remove it
  //     message.reaction = null;
  //   } else {
  //     // Set the new reaction
  //     message.reaction = reaction;
  //   }

  //   // Step 2: Close the reaction panel immediately
  //   message.showReactionPanel = false;
  //   this.cdr.detectChanges(); // Trigger change detection for instant UI update
  //   this.lastMessageWithReactionPanel = null;
  //   // Step 3: Call the service to update the reaction asynchronously
  //   this.chatService.toggleReaction(message, reaction).then((updatedMessage) => {
  //     if (updatedMessage) {
  //       message = updatedMessage;
  //       message.reaction = reaction;
  //       this.cdr.detectChanges();
  //     }
  //   }).catch((error) => {
  //     console.error('Error toggling reaction:', error);
  //   });
  // }


  // addThumbsUpReaction(message: any): void {
  //   if (message.reaction === '👍') {
  //     // Remove the thumbs up reaction
  //     this.chatService.toggleReaction(message, '👍').then((updatedMessage) => {
  //       if (updatedMessage) {
  //         // Update only the reactions property
  //         message = updatedMessage;
  //         message.reaction = null;
  //         this.cdr.detectChanges();
  //       }
  //     });
  //   } else {
  //     // Remove existing reaction if any
  //     if (message.reaction) {
  //       this.chatService.toggleReaction(message, message.reaction);
  //     }
  //     // Add the thumbs up reaction
  //     this.chatService.toggleReaction(message, '👍').then((updatedMessage) => {
  //       if (updatedMessage) {
  //         // Update only the reactions property
  //         message = updatedMessage;
  //         message.reaction = '👍';
  //         this.cdr.detectChanges();
  //       }
  //     });
  //   }
  // }
  // handleGlobalClick(event: MouseEvent): void {
  //   const target = event.target as HTMLElement;

  //   // Check if the click is outside of the reaction panel and input field
  //   const isClickOutsideReactionPanel = !target.closest('.reaction-panel');
  //   if (this.lastMessageWithReactionPanel && isClickOutsideReactionPanel) {
  //     // Close the currently open reaction panel
  //     this.lastMessageWithReactionPanel.showReactionPanel = false;
  //     this.lastMessageWithReactionPanel = null; // Reset the reference
  //     this.cdr.detectChanges();
  //   }
  // }

  openModal(): void {
    this.isModalOpen = true;
  }

  closeModal(): void {
    this.isModalOpen = false;
  }
  createDirectConversation(): void {
    console.log('Creating Direct Conversation');
    this.selectedUser = null;
    this.isDirectConversationOpen = true;
    this.closeModal();
  }

  createBroadcast(): void {
    console.log('Creating Broadcast');
    // Add your logic here
    this.closeModal();
  }

  createGroupConversation(): void {
    console.log('Creating Group Conversation');
    // Add your logic here
    this.closeModal();
  }

  closeDirectConversation(): void {
    this.isDirectConversationOpen = false;
    this.directConversationSearchTerm = ''; // Clear the search input
    this.directFilteredUsers = []; // Clear filtered users
    this.showSearchResults = false; // Hide search results
    this.errorMessage = ''; // Clear error message
    this.isSearchFieldError = false; // Reset error flag
    this.selectedUser = null; // Clear the selected user
    this.to_user_id = '';
    this.to_user_name = '';
    this.to_user_email = '';
    this.channel = '';
    this.currentMessage = ''; // Clear the current message input
    this.messageError = "";
    this.isMessageFieldError = false;
    console.log('Direct Conversation closed and reset.');
  }
}
