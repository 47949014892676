
<div style="margin: 0px;">
  <form [formGroup]="myForm" (ngSubmit)="onSubmit()"  novalidate>

    <!--  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">  DEPCRICATED-->
    <div class="flex-container">


      <mat-card style="width: 450px; height: 100%;">
        <mat-toolbar color="primary" class="mat-elevation-z16">
          <mat-toolbar-row>
            <span>{{title_text}}</span>
          </mat-toolbar-row>
        </mat-toolbar>


        <mat-card-content >
          <mat-form-field style="margin-top: 15px; width: 100%">
            <mat-label>Adgroup Name:</mat-label>
            <input matInput type="text" placeholder="" (focus)="onFocus()" formControlName="name">
            <mat-error style="font-size: 12px">Valid name required</mat-error>
          </mat-form-field>
          <mat-form-field style="margin-top: 15px; width: 100%">
            <mat-label>Description:</mat-label>
            <input matInput type="text" placeholder="" (focus)="onFocus()" formControlName="description">
            <mat-error style="font-size: 12px">Alpha, no spaces, and lowercase only</mat-error>
          </mat-form-field>

          <mat-form-field style="margin-top: 15px; width: 100%">
            <mat-label>Status:</mat-label>
            <input matInput type="text" placeholder="e.g. active" (focus)="onFocus()" formControlName="status">
            <mat-error style="font-size: 12px">Alpha, no spaces, and lowercase only</mat-error>
          </mat-form-field>

          <mat-form-field style="margin-top: 15px; width: 400px">
            <mat-label>Channel Type:</mat-label>
            <mat-select formControlName="channel_type">
              <mat-option *ngFor="let channel_type of channel_type_list" [value]="channel_type.value">
                {{ channel_type.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error>
              <strong>Channel Type</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field style="margin-top: 15px; width: 400px">
            <mat-label>Response Type:</mat-label>
            <mat-select formControlName="response">
              <mat-option *ngFor="let response_type of response_type_list" [value]="response_type.value">
                {{ response_type.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error>
              <strong>Response Type</strong>
            </mat-error>
          </mat-form-field>


        </mat-card-content>
        <div *ngIf="is_form_error">
          <mat-error style="margin-bottom: 5px">
            <strong>{{form_error_message}}</strong>
          </mat-error>
        </div>


        <div class="flex-container" style="margin-top: 10px;">
          <button mat-raised-button color="primary" [disabled]="!myForm.valid || is_loading" type="submit" style="margin: 15px">{{submit_text}}</button>
          <button mat-raised-button color="accent" [disabled]="is_loading" type="button" (click)="onCancel()" style="margin: 15px">Cancel</button>
        </div>

        <mat-progress-bar *ngIf="is_loading" mode="indeterminate" style="margin-top: 10px"></mat-progress-bar>
      </mat-card>

    </div>

  </form>
</div>
