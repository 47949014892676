<div class="edit-appointment-container">
  <div class="header" *ngIf="!isCompletedCard">
    <div class="header-left">
      <button mat-icon-button (click)="back()">
        <i [ngClass]="'healent-back'" style="font-size: 20px; color: var(--secondary_shade_50);"></i>
      </button>
      <span>Time spent this month: <span class="accent-text">{{ accumulatedTime }} mins</span></span>
    </div>
    <div class="header-right">
      <span *ngIf="!isCompletedCard">Now: <span class="accent-text">{{ elapsedTime }}</span></span>
      <i [ngClass]="'healent-document'" style="font-size: 20px; color: var(--primary);"></i>
    </div>
  </div>

  <!-- Edit Form -->
  <div class="content" *ngIf="!isCompletedCard">
    <div class="type-section">
      <mat-radio-group [(ngModel)]="appointment.type">
        <mat-radio-button value="patient_contact" color="primary">Patient contact</mat-radio-button>
        <mat-radio-button value="chart_note" color="primary">Chart note</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="details-row">
      <!-- Start Date (Date Picker) -->
      <div class="form-field">
        <mat-form-field appearance="outline" class="field">
          <mat-label>Start date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="startDate"
            (dateChange)="onDateChange($event.value)"
            placeholder="DD/MM/YYYY">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- Start Time (Time Picker) -->
      <div class="form-field">
        <app-time-picker [initialTime]="selectedTime" (timeChange)="onTimeChange($event)"></app-time-picker>
      </div>

      <!-- Duration -->
      <div class="form-field">
        <mat-form-field appearance="outline" class="field duration-field">
          <mat-label>Duration</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="appointment.duration"
            (ngModelChange)="onDurationChange($event)"
            (keypress)="numberOnly($event)"
            placeholder="Duration in minutes"
            min="0">
          <div class="number-controls" matSuffix>
            <i [ngClass]="'healent-up'" class="duration-icon" (click)="incrementDuration()"></i>
            <i [ngClass]="'healent-down'" class="duration-icon" (click)="decrementDuration()"></i>
          </div>
        </mat-form-field>
      </div>

      <!-- Time Spent -->
      <mat-form-field appearance="outline" class="field">
        <mat-label>Time spent</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="appointment.timeSpent"
          (ngModelChange)="onTimeSpentChange($event)"
          (keypress)="numberOnly($event)"
          placeholder="Time spent in minutes"
          min="0">
        <div class="number-controls" matSuffix>
          <i [ngClass]="'healent-up'" class="duration-icon" (click)="incrementTimeSpent()"></i>
          <i [ngClass]="'healent-down'" class="duration-icon" (click)="decrementTimeSpent()"></i>
        </div>
      </mat-form-field>
    </div>

    <div class="program-type-section">
      <span>Program Type</span>
      <mat-radio-group [(ngModel)]="appointment.programType">
        <mat-radio-button value="RPM">RPM</mat-radio-button>
        <mat-radio-button value="RTM">RTM</mat-radio-button>
        <mat-radio-button value="CCM">CCM</mat-radio-button>
        <mat-radio-button value="BHI">BHI</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="notes-section">
      <span>Appointment Note</span>
      <textarea class="appointment-note" [(ngModel)]="appointment.note"></textarea>
    </div>
  </div>

  <!-- Mark as Complete Card -->
  <div class="completed-card" *ngIf="isCompletedCard">
    <div class="card-header">
      <h3>Patient contact</h3>
      <i [ngClass]="'healent-document'" style="font-size: 20px; color: var(--primary);"></i>
    </div>
    <div class="card-content">
      <div class="details-row">
        <!-- Start Date (Date Picker) -->
        <div class="form-field">
          <mat-form-field appearance="outline" class="field">
            <mat-label>Start date</mat-label>
            <input
              matInput
              [matDatepicker]="pickerCompleted"
              [(ngModel)]="startDate"
              (dateChange)="onDateChange($event.value)"
              placeholder="DD/MM/YYYY"
              disabled>
            <mat-datepicker-toggle matSuffix [for]="pickerCompleted" [disabled]="true"></mat-datepicker-toggle>
            <mat-datepicker #pickerCompleted disabled></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- Start Time (Time Picker) -->
        <div class="form-field">
          <app-time-picker [initialTime]="selectedTime" (timeChange)="onTimeChange($event)" [disabled]="true"></app-time-picker>
        </div>

        <!-- Duration -->
        <div class="form-field">
          <mat-form-field appearance="outline" class="field duration-field">
            <mat-label>Duration</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="appointment.duration"
              (ngModelChange)="onDurationChange($event)"
              (keypress)="numberOnly($event)"
              placeholder="Duration in minutes"
              min="0"
              disabled>
            <div class="number-controls" matSuffix>
              <i [ngClass]="'healent-up'" class="duration-icon disabled"></i>
              <i [ngClass]="'healent-down'" class="duration-icon disabled"></i>
            </div>
          </mat-form-field>
        </div>

        <!-- Time Spent -->
        <mat-form-field appearance="outline" class="field">
          <mat-label>Time spent</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="appointment.timeSpent"
            (ngModelChange)="onTimeSpentChange($event)"
            (keypress)="numberOnly($event)"
            placeholder="Time spent in minutes"
            min="0">
          <div class="number-controls" matSuffix>
            <i [ngClass]="'healent-up'" class="duration-icon" (click)="incrementTimeSpent()"></i>
            <i [ngClass]="'healent-down'" class="duration-icon" (click)="decrementTimeSpent()"></i>
          </div>
        </mat-form-field>
      </div>

      <div class="program-type-section">
        <span>Program Type</span>
        <mat-radio-group [(ngModel)]="appointment.programType">
          <mat-radio-button value="RPM">RPM</mat-radio-button>
          <mat-radio-button value="RTM">RTM</mat-radio-button>
          <mat-radio-button value="CCM">CCM</mat-radio-button>
          <mat-radio-button value="BHI">BHI</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="card-actions">
      <div class="action-buttons">
        <div class="group-1">
          <button
            *ngFor="let button of completedButtonConfig"
            class="action-button"
            [ngClass]="button.customClass"
            (click)="handleButtonAction(button.action)"
          >
            {{ button.label }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit Form Actions -->
  <div class="action-buttons" *ngIf="!isCompletedCard">
    <div class="group-1">
      <button
        *ngFor="let button of buttonConfig"
        class="action-button"
        [ngClass]="button.customClass"
        (click)="handleButtonAction(button.action)"
      >
        {{ button.label }}
      </button>
    </div>
  </div>
</div>