import { Component, EventEmitter, Input, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import moment from 'moment';
import { TableActionsDrawerService } from './../../table-actions-drawer/table-actions-drawer.service';
import { Appointment } from '../../table-actions-drawer/components/patient-info/patient-info.component';

@Component({
  selector: 'app-appointment-list',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.css']
})
export class AppointmentListComponent implements OnChanges {
  @Input({ required: true }) patientId!: string | number;
  @Output() openDetails = new EventEmitter<Appointment>(); // For editing existing appointments
  @Output() createNew = new EventEmitter<void>(); // For creating new appointments
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = ['type', 'date', 'time', 'timeSpent', 'completed'];
  dataSource = new MatTableDataSource<Appointment>([]);
  pageSize = 5;
  pageSizeOptions = [10, 25, 50, 100, 250];
  isLoading = false;
  accumulatedTime = 0;

  constructor(private tableActionsService: TableActionsDrawerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['patientId'] && this.patientId) {
      this.loadAppointments();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  loadAppointments() {
    this.isLoading = true;
    this.tableActionsService.getAppointmentInfo({ client_id: this.patientId }).subscribe({
      next: (res: any) => {
        if (res && res.appointments) {
          const transformedAppointments = res.appointments.map((appointment: any) => ({
            id: appointment.meeting_id,
            type: appointment.appointment_type,
            startDate: this.formatDate(appointment.start_datetime_local),
            startTime: this.formatTime(appointment.start_datetime_local),
            duration: appointment.planned_duration,
            timeSpent: appointment.duration_minutes || 0,
            programType: 'RPM', // Mocked for now; update based on API response
            note: '', // Mocked for now; update based on API response
            completed: appointment.duration_minutes > 0
          }));
          this.dataSource.data = transformedAppointments;
          this.accumulatedTime = res.appointments.reduce((sum: number, appt: any) => sum + (appt.duration_minutes || 0), 0);
        }
        this.isLoading = false;
      },
      error: () => {
        console.error('Failed to load appointments');
        this.isLoading = false;
      }
    });
  }

  formatDate(dateTime: string): string {
    const momentDate = moment(dateTime, 'YYYY-MM-DD hh:mm A');
    return momentDate.format('DD/MM/YYYY');
  }

  formatTime(dateTime: string): string {
    const momentDate = moment(dateTime, 'YYYY-MM-DD hh:mm A');
    return momentDate.format('hh:mm A');
  }

  getAppointmentTypeIcon(type: string): string {
    switch (type) {
      case 'onsite': return 'healent-path-4041 color-green';
      case 'tech_call': return 'healent-form-input-icon-28';
      case 'chart_check': return 'healent-tasks-solid';
      default: return 'healent-path-4053';
    }
  }

  openDrawerDetails(appointment: Appointment) {
    this.openDetails.emit(appointment); // Trigger edit for non-onsite rows
  }

  refreshAppointments() {
    this.loadAppointments();
  }

  viewAllNotes() {
    console.log('View all notes for patient:', this.patientId);
  }

  createNewAppointment() {
    this.createNew.emit(); // Trigger new appointment creation
  }
}