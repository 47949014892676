<form [formGroup]="myForm" (ngSubmit)="onSubmit()" novalidate>

  <div class="flex-container">

    <mat-card style="width: 350px; height: 100%;">

      <mat-toolbar color="accent" class="mat-elevation-z16">
        <mat-toolbar-row>
          <span>New Task</span>
        </mat-toolbar-row>
      </mat-toolbar>
      <mat-progress-bar *ngIf="is_loading" color="accent" mode="indeterminate" style="margin-top: 0"></mat-progress-bar>


      <mat-card-content>
        <mat-form-field style="margin-top: 15px; width: 100%">
          <mat-label>Title</mat-label>
          <input matInput type="text" placeholder="" (focus)="onFocus()" formControlName="title">
          <mat-error style="font-size: 12px">
            Title is required
          </mat-error>
        </mat-form-field>


        <mat-form-field style="margin-top: 15px; width: 100%">
          <mat-label>Choose a date</mat-label>
          <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>


        <mat-slide-toggle #slide color="accent" (change)="onRecurringChange($event)">recurring</mat-slide-toggle>



        <mat-form-field style="margin-top: 15px; width: 100%" *ngIf="is_recurring">
          <mat-label>Repeat each day</mat-label>
          <input matInput type="text" placeholder="" (focus)="onFocus()" formControlName="repeat_each_day">
          <mat-error style="font-size: 12px">
            Repeat each day is required
          </mat-error>
        </mat-form-field>





        <!--        <app-input-email placeholder="Enter your username:" value="" formControlName="email" type="text" ></app-input-email>-->
        <!--        <app-input-password placeholder="Enter your password"  formControlName="password"  ></app-input-password>-->
      </mat-card-content>
      <div *ngIf="is_form_error">
        <mat-error style="margin-left: 10px; margin-bottom: 5px">
          <strong>{{form_error_message}}</strong>
        </mat-error>
      </div>

      <button mat-raised-button color="accent" [disabled]="!myForm.valid || is_loading" type="submit" style="margin: 25px">Create</button>



    </mat-card>

  </div>



</form>