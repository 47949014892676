<div class="filter-bar">
  <!-- Filters Label -->
  <div class="filter-left">
    <span class="filter-label">{{ currentFilterLabel }}</span>
  </div>
  <!-- Filter Categories -->
  <div class="filter-right">
    <div class="filter-action-categories">
      <div class="filter-categories">
        <div *ngFor="let category of filterCategories | keyvalue" class="filter-category">
          <button [matMenuTriggerFor]="menu" (click)="toggleDropdown(category.key)" class="filter-button"
            (mouseenter)="onMouseEnter(category.key)" (mouseleave)="onMouseLeave()">
            <div style="display: flex;flex-direction: row; align-items: center; justify-content: space-between; gap: 5px; height: 20px;">
              <i [ngClass]="category.value.icon" style="margin-top: 3px;"></i>
              <div style="height: 19px;">{{ category.value.label }}</div>
            </div>

            <div class="count-badge" [class.icon-visible]="hoveredCategory === category.key"
              [class.visible]="category.value.count > 0">
              <ng-container *ngIf="hoveredCategory !== category.key; else iconTemplate">
                <span *ngIf="category.value.count > 0">{{ category.value.count }}</span>
              </ng-container>
              <ng-template #iconTemplate>
                <i [ngClass]="'healent-close-circle'" class="icon-style"
                  (click)="clearCategoryFilters(category.key); $event.stopPropagation();"></i>
              </ng-template>
            </div>
          </button>

          <mat-menu #menu="matMenu" class="filter-menu">
            <div class="menu-content" (click)="$event.stopPropagation()">
              <div class="search-container">
                <mat-checkbox [checked]="isAllSelected(category.key)"
                            [indeterminate]="isSomeSelected(category.key)"
                            (change)="toggleAllOptions(category.key, $event.checked)"
                            class="select-all-checkbox">
                </mat-checkbox>
                <div class="search-input-container">
                  <i [ngClass]="'healent-search'" class="search-icon"></i>
                  <input type="text" [(ngModel)]="searchTexts[category.key]"
                        (ngModelChange)="filterOptions(category.value.options, category.key)"
                        placeholder="Search"
                        class="search-input-filter-dropdown" />
                </div>
              </div>

              <div class="options-list">
                <div *ngFor="let option of filterOptions(category.value.options, category.key)"
                     class="option-item">
                  <mat-checkbox [checked]="option.selected"
                              (change)="toggleOption(category.key, option.name)"
                              class="option-checkbox">
                    {{ option.name }}
                  </mat-checkbox>
                </div>
              </div>

              <div class="selection-summary">
                <span>{{ category.value.count }} items selected</span>
                <button class="clear-selection" (click)="clearSelection(category.key)" *ngIf="category.value.count > 0">
                  Clear selection
                </button>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>

      <!-- Action Buttons -->
      <div class="action-buttons" *ngIf="hasActiveFilters()">
        <button mat-flat-button (click)="openSaveDialog()"
          class="save-button"
          *ngIf="shouldShowSaveButton()">
          Save Filters
        </button>
        <button mat-flat-button (click)="clearAll()" class="clear-button">
          Clear All
        </button>
      </div>
    </div>


  </div>
  <!-- Saved Filters Dropdown -->
  <div class="saved-filters">
    <button [matMenuTriggerFor]="savedMenu" class="filter-button">
      <div style="display: flex; align-items: center; justify-content: space-between; gap: 5px;">
        <i [ngClass]="'healent-custom-filer'"></i>
        <div>Saved filters</div>
        <i [ngClass]="'healent-down-1'" style="margin-top: 5px;"></i>
      </div>
    </button>
    <mat-menu #savedMenu="matMenu" class="filter-menu saved-filter-menu">
      <div *ngIf="savedFilters.length === 0" class="no-results">
        No saved filters found
      </div>
      <div class="options-list">
        <div *ngFor="let filter of savedFilters" class="option-item"
          style="display: flex; flex-direction: row; justify-content: space-between;align-items: center;">
          <div (click)="applyFilter(filter); $event.stopPropagation();" style="width: 90%;">
            {{ filter }}
          </div>
          <i [ngClass]="'healent-delete'" style="margin-top: 5px; cursor: pointer; color: var(--dangerous)"
            (click)="removeFilter(filter, $event)"></i>
        </div>
      </div>
    </mat-menu>
  </div>
</div>
