import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-link-preview',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div *ngIf="url" class="link-preview">
      <a [href]="url" target="_blank" rel="noopener noreferrer">
        <div class="preview-content">
          <div class="favicon" *ngIf="favicon">
            <img [src]="favicon" alt="favicon" />
          </div>
          <div class="url-info">
            <div class="domain">{{domain}}</div>
            <div class="full-url">{{url}}</div>
          </div>
        </div>
      </a>
    </div>
  `,
  styles: [`
    .link-preview {
      border: 1px solid #e1e4e8;
      border-radius: 6px;
      padding: 12px;
      margin: 8px 0;
      background: #f6f8fa;
    }
    .preview-content {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .favicon img {
      width: 16px;
      height: 16px;
    }
    .url-info {
      overflow: hidden;
    }
    .domain {
      font-weight: 500;
      color: #24292e;
    }
    .full-url {
      color: #586069;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    a {
      text-decoration: none;
    }
  `]
})
export class LinkPreviewComponent implements OnInit {
  @Input() url: string = '';
  domain: string = '';
  favicon: string = '';

  ngOnInit() {
    if (this.url) {
      try {
        const urlObj = new URL(this.url);
        this.domain = urlObj.hostname;
        this.favicon = `https://www.google.com/s2/favicons?domain=${this.domain}`;
      } catch (e) {
        console.error('Invalid URL:', e);
      }
    }
  }
}
