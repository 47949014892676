<div style="margin: 0;">
  <form [formGroup]="myForm" (ngSubmit)="onSubmit()" novalidate>

    <!--  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">  DEPCRICATED-->
    <div class="flex-container">

      <div>
        <mat-card style="width: 250px; height: 100%;">
          <mat-toolbar color="accent" class="mat-elevation-z16">
            <mat-toolbar-row>
              <span>{{title_text}}</span>
            </mat-toolbar-row>
          </mat-toolbar>


          <mat-card-content>
            <mat-form-field style="margin-top: 15px; width: 100%">
              <mat-label>Username:</mat-label>
              <input matInput type="text" placeholder="e.g. foo" (focus)="onFocus()" formControlName="username">
              <mat-error style="font-size: 12px">Valid name required</mat-error>
            </mat-form-field>
            <mat-form-field style="margin-top: 15px; width: 100%">
              <mat-label>User Email:</mat-label>
              <input matInput type="text" placeholder=" e.g. myslug" (focus)="onFocus()" formControlName="email">
              <mat-error style="font-size: 12px">Alpha, no spaces, and lowercase only</mat-error>
            </mat-form-field>

          </mat-card-content>
          <div *ngIf="is_form_error">
            <mat-error style="margin-bottom: 5px">
              <strong>{{form_error_message}}</strong>
            </mat-error>
          </div>


          <div class="flex-container" style="margin-top: 10px;">
            <button mat-raised-button color="accent" [disabled]="!myForm.valid || is_loading" type="submit" style="margin: 15px">{{submit_text}}</button>
            <button mat-raised-button color="warn" [disabled]="is_loading" type="button" (click)="onCancel()" style="margin: 15px">Cancel</button>
          </div>

          <mat-progress-bar *ngIf="is_loading" mode="indeterminate" style="margin-top: 10px"></mat-progress-bar>
        </mat-card>
      </div>
    </div>

  </form>
</div>