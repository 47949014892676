import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ProcessAppointmentComponent } from "./components/process-appointment/process-appointment.component";
import { PushFormToAppComponent } from "./components/push-form-to-app/push-form-to-app.component";
import { ContactInfoComponent } from "./components/contact-info/contact-info.component";
import { PaymentInfoComponent } from "./components/payment-info/payment-info.component";
import { PatientNotesComponent } from "./components/patient-notes/patient-notes.component";
import { PatientNotesInterface, VisitNotesInterface } from '../../registration/registration.interface';

export type ManageDrawerIntrface = {
  name: string;
  chartNumber: string;
  provider: string;
  apptType: string;
  apptTime: string;
  contactDetails: {
    email: string;
    phone: string;
  },
  meetingId: number;
  patientId: number;
  athenaNotes: string[];
  patientNotes: PatientNotesInterface;
  visitNotes: VisitNotesInterface;
}

@Component({
  selector: 'app-table-actions-drawer',
  standalone: true,
  imports: [CommonModule, MatSidenavModule, MatSlideToggleModule, MatIconModule, DragDropModule, MatTableModule, ProcessAppointmentComponent, PushFormToAppComponent, ContactInfoComponent, PaymentInfoComponent, PatientNotesComponent],
  templateUrl: './table-actions-drawer.component.html',
  styleUrl: './table-actions-drawer.component.css'
})
export class TableActionsDrawerComponent implements OnChanges {
  @Input({ required: true }) rowData!: ManageDrawerIntrface // Input to accept row data
  @Input({ required: true }) action!: string // Input to accept action
  @Input({ required: true }) tableType!: string // Input to tabletype
  @Input({ required: true }) rawRow!: any // Input to tabletype
  @Input({ required: true }) isLoadingCancel!: boolean // Input to tabletype
  @Input({ required: true }) missedSections!: { form_name: string; form_id: number }[] // Input to accept missed sections
  @ViewChild('drawer') drawer!: MatDrawer;
  @Output() cancelAppt = new EventEmitter<number>()
  @Output() triggerRefresh = new EventEmitter<boolean>()


  dataSource = new MatTableDataSource<any>();  // Data source for the table

  displayedColumns: string[] = ['name', 'chartNumber', 'provider', 'apptType', 'apptTime', 'delete'];
  name!: string;
  largewidthDrawerList: string[] = ['healent-action-icon', 'healent-action-icon-1', 'healent-path-3627']


  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rowData']) {
      // When rowData changes, update the table's data source
      this.dataSource = new MatTableDataSource([this.rowData]);
      this.name = this.rowData?.name
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside && this.drawer.opened) {
      this.drawer.close();
    }
  }

  closeDrawer() {
    if (this.drawer) {
      this.drawer.close();
    }
  }

  openDrawer() {
    if (this.drawer) {
      this.drawer.open();
    }
  }

  deleteRow () {
    console.log('row deleted')
  }

  onCancelAppointment (meetingId: number, event: MouseEvent) {
    event.stopPropagation();
    this.cancelAppt.emit(meetingId)
  }

  triggerDataRefresh (trigger: any) {
    console.log(trigger, '===tiggger-----')
    this.triggerRefresh.emit(trigger)
  }

}
