import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { TableActionsDrawerService } from '../../table-actions-drawer.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ManageDrawerIntrface } from '../../table-actions-drawer.component';

type NotesInterface = {
  note: string;
  date: string;
  id: string;
  providerName: string;
  isExpanded: boolean;
  flagged: boolean
}

@Component({
  selector: 'app-patient-notes',
  standalone: true,
  imports: [CommonModule, FormsModule, MatTooltipModule],
  templateUrl: './patient-notes.component.html',
  styleUrls: ['./patient-notes.component.css']
})
export class PatientNotesComponent implements OnChanges {
  @Output() closeDrawerEvent = new EventEmitter<void>();
  @Output() markRowEvent = new EventEmitter<boolean>();
  @Input({ required: true }) patientName!: string;
  @Input({ required: true }) meetingId!: number;
  @Input({ required: true }) patientId!: number;
  @Input({ required: true }) rowData!: ManageDrawerIntrface;

  templateButtons = [
    { buttonName: 'PHQ-9', tooltipText: 'PHQ-9', templateText: 'PHQ-9 completed on' },  
    { buttonName: 'Veteran', tooltipText: 'Veteran', templateText: 'Veteran; ensure there is an active VA Auth on file and that the case policy is listed as primary insurance on the appt.' },
    { buttonName: 'Work Comp', tooltipText: 'Work Comp', templateText: 'Work Comp: make sure the case policy is listed as primary insurance on the appt'},
    { buttonName: 'Template1', tooltipText: 'Example template text 4', templateText: 'Example template text 4' },
    { buttonName: 'Template2', tooltipText: 'Example template text 5', templateText: 'Example template text 5' },
    { buttonName: 'Template3', tooltipText: 'Example template text 6', templateText: 'Example template text 6' }
  ];

  vistNotesTemplates = [
    { buttonName: 'Deductible', tooltipText: 'BALANCE: Deductible', templateText: 'BALANCE: Deductible' },
    { buttonName: 'Coinsurance', tooltipText: 'BALANCE: Coinsurance', templateText: 'BALANCE: Coinsurance' },
    { buttonName: 'Collections', tooltipText: 'Balance in Collections $____. Must be paid in full at visit.', templateText: 'Balance in Collections $____. Must be paid in full at visit.' },
    { buttonName: 'Credit', tooltipText: 'CREDIT ON ACCOUNT: $__', templateText: 'CREDIT ON ACCOUNT: $__' },
    { buttonName: 'Inactive', tooltipText: '**INSURANCE IS INACTIVE**', templateText: '**INSURANCE IS INACTIVE**' },
    { buttonName: 'Signatures', tooltipText: 'The patients signature on file and insured signature on file are expired.', templateText: 'The patients signature on file and insured signature on file are expired.' },
    { buttonName: 'Ins. Card', tooltipText: 'Need Insurance Card', templateText: 'Need Insurance Card' },
    { buttonName: 'BALANCE: Previous Copay', tooltipText: 'BALANCE: Copay was not collected at the previous visit.', templateText: 'BALANCE: Copay was not collected at the previous visit.' },
    { buttonName: 'PCP', tooltipText: 'Ask patient who their Primary Care Physician is and add it to Primary Insurance on Quickview.', templateText: 'Ask patient who their Primary Care Physician is and add it to Primary Insurance on Quickview.' },
    { buttonName: 'Undeliverable Email', tooltipText: 'Confirm Email Address', templateText: 'Confirm Email Address' },
    { buttonName: 'PHQ', tooltipText: 'Push PHQ-9 for patient to fill out. ', templateText: 'Push PHQ-9 for patient to fill out. ' },
    { buttonName: 'SSN', tooltipText: 'Ask patient their SSN', templateText: 'Ask patient their SSN' },
    { buttonName: 'ID', tooltipText: 'Need Photo ID/Insurance Card', templateText: 'Need Photo ID/Insurance Card' },
    { buttonName: 'New Patient', tooltipText: '***New Patient: Need ID, Ins Card, Signatures & PHQ-9', templateText: '***New Patient: Need ID, Ins Card, Signatures & PHQ-9' },
    { buttonName: 'Approved Procedure', tooltipText: 'Patient has a tickler to schedule an approved procedure. Please schedule.', templateText: 'Patient has a tickler to schedule an approved procedure. Please schedule.' },
    { buttonName: 'Anesthesia Balance', tooltipText: 'BALANCE: Anesthesia $_______', templateText: 'BALANCE: Anesthesia $_______' },
    { buttonName: 'Pharmacy', tooltipText: 'Ask patient their preferred pharmacy.', templateText: 'Ask patient their preferred pharmacy.' }
  ]
  newNote = '';
  isAddingNote = false;
  enableButton: boolean = true;
  noteType: 'patient' | 'visit' = 'patient';
  editMode = false;
  noteToEditIndex: number | null = null;

  // Use BehaviorSubject to store and manage notes
  patientNotes$: BehaviorSubject<NotesInterface[]> = new BehaviorSubject<NotesInterface[]>([]);
  visitNotes$: BehaviorSubject<NotesInterface[]> = new BehaviorSubject<NotesInterface[]>([]);
  is_loading_notes!: boolean;
  isSavingNote: boolean = false;
  emailId!: string;

  constructor(
    private datePipe: DatePipe,
    private tableActionsDrawerService: TableActionsDrawerService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['meetingId']) {
      // Clear notes when a new meeting is selected (new row selected)
      this.emailId = localStorage.getItem('email') || ''
      this.patientNotes$.next([]);
      this.visitNotes$.next([]);
      this.loadNotes();
      this.vistNotesTemplates = this.vistNotesTemplates.sort((a, b) => a.buttonName.localeCompare(b.buttonName))
    }
  }

  toggleText(index: number, type: string) {
    const notes$ = type === 'visit' ? this.visitNotes$ : this.patientNotes$;
    const notes = notes$.getValue();
    notes[index] = { ...notes[index], isExpanded: !notes[index].isExpanded };
    notes$.next([...notes]);
  }

  loadNotes() {
    const patientNotes = (this.rowData.patientNotes?.patient_notes || []).map((note: Partial<NotesInterface>) => ({
      note: note.note || '',
      date: note.date || '',
      id: note.id || this.generateUUID(),
      providerName: note.providerName || '',
      isExpanded: false,
      flagged: note.flagged || false
    }));

    const visitNotes = (this.rowData.visitNotes?.meeting_notes || []).map((note: Partial<NotesInterface>) => ({
      note: note.note || '',
      date: note.date || '',
      id: note.id || this.generateUUID(),
      providerName: note.providerName || '',
      isExpanded: false,
      flagged: note.flagged || false
    }));
    this.patientNotes$.next(patientNotes);
    this.visitNotes$.next(visitNotes);
    // removed the service as patient and visit notes is coming from rowData.
    // forkJoin({
    //   patientNotesResponse: this.tableActionsDrawerService.getNotesData(`patient_notes/${this.patientId}`, {}),
    //   visitNotesResponse: this.tableActionsDrawerService.getNotesData(`meeting_notes/${this.meetingId}`, {})
    // }).subscribe({
    //   next: ({ patientNotesResponse, visitNotesResponse }) => {
    //     const patientNotes = patientNotesResponse?.message?.patient_notes.map((note: {}) => {
    //       return {...note, isExpanded: false}
    //     }) || [];
    //     const visitNotes = visitNotesResponse?.message?.meeting_notes.map((note: {}) => {
    //       return {...note, isExpanded: false}
    //     }) || [];

    //     // Update the observables with new data
    //     this.patientNotes$.next([...patientNotes]);
    //     this.visitNotes$.next([...visitNotes]);

    //     this.is_loading_notes = false
    //   },
    //   error: (error) => {
    //     console.error('Error loading notes:', error);
    //     // Handle the error
    //   }
    // });
  }

  generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  private updateExistingNote(formattedDate: string) {
    if (this.noteType === 'patient') {
      const patientNotes = this.patientNotes$.getValue();
      patientNotes[this.noteToEditIndex!] = {
        ...patientNotes[this.noteToEditIndex!],
        note: this.newNote,
        date: formattedDate,
        providerName: this.emailId
      };
      this.patientNotes$.next([...patientNotes]);
    } else {
      const visitNotes = this.visitNotes$.getValue();
      visitNotes[this.noteToEditIndex!] = {
        ...visitNotes[this.noteToEditIndex!],
        note: this.newNote,
        date: formattedDate,
        providerName: this.emailId
      };
      this.visitNotes$.next([...visitNotes]);
    }
  }

  private addNewNote(formattedDate: string, noteId: string) {
    const newNote = {
      id: noteId,
      note: this.newNote,
      date: formattedDate,
      providerName: this.emailId,
      isExpanded: false,
      flagged: false
    };
    if (this.noteType === 'patient') {
      const patientNotes = this.patientNotes$.getValue();
      patientNotes.unshift(newNote);
      this.patientNotes$.next([...patientNotes]);
    } else {
      const visitNotes = this.visitNotes$.getValue();
      visitNotes.unshift(newNote);
      this.visitNotes$.next([...visitNotes]);
    }
  }

  private resetNoteFields() {
    this.newNote = '';
    this.isAddingNote = false;
    this.editMode = false;
    this.noteToEditIndex = null;
    this.enableButton = true;
  }

  saveNote(event: MouseEvent) {
    event.stopPropagation();
    // to prevent multiple save attempts while already saving
    const trimmedNote = this.newNote?.trim();
    if (this.isSavingNote || trimmedNote === '') {
      this.newNote = ''
      return;
    }
    this.isSavingNote = true;
    let noteId = ''
    const formattedDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd') || ''; // use 'MMM dd, yyyy to get Oct23 Mon, 2024'
    const endPointName = this.noteType === 'patient' ? 'patient' : 'meeting';
    const id = this.noteType === 'patient' ? this.patientId : this.meetingId;
    const notes = this.noteType === 'patient' ? this.patientNotes$ : this.visitNotes$
    if (this.editMode && this.noteToEditIndex !== null) {
      noteId = notes.getValue()[this.noteToEditIndex!].id
    } else {
      noteId = this.generateUUID()
    }
    if (this.newNote) {
      this.tableActionsDrawerService.putStatus(`${endPointName}_notes/${id}`, { note: this.newNote, id: noteId, providerName: this.emailId }).subscribe((res) => {
        if (res.status === 200) {
          if (this.editMode && this.noteToEditIndex !== null) {
            this.updateExistingNote(formattedDate);
          } else {
            this.addNewNote(formattedDate, noteId);
          }
          // Check flagged state after saving the note
          const anyPatientNotesFlagged = this.patientNotes$.getValue().some((n) => n.flagged);
          const anyVisitNotesFlagged = this.visitNotes$.getValue().some((n) => n.flagged);

          const isRowFlagged = anyPatientNotesFlagged || anyVisitNotesFlagged;

          // Emit the correct flagged state to update row background
          this.markRowEvent.emit(isRowFlagged);
          this.resetNoteFields();
          this.isSavingNote = false;
        } else {
          this.isSavingNote = false
        }
      }, (error) => {
        console.error(error)
        this.isSavingNote = false;
      }
    );
    }
  }

  startAddingNote(type: 'patient' | 'visit', event: MouseEvent) {
    event.stopPropagation();
    this.noteType = type;
    this.isAddingNote = true;
    this.editMode = false;
    this.enableButton = false;
    this.newNote = '';
  }

  editNote(note: { note: string, date: string }, index: number, type: 'patient' | 'visit', event: MouseEvent) {
    event.stopPropagation();
    this.noteType = type;
    this.isAddingNote = true;
    this.editMode = true;
    this.newNote = note.note;
    this.noteToEditIndex = index;
    this.enableButton = false;
  }

  deleteNote(index: number, type: 'patient' | 'visit', event: MouseEvent) {
    event.stopPropagation();
    const endPointName = type === 'patient' ? 'patient' : 'meeting';
    const id = type === 'patient' ? this.patientId : this.meetingId;
    const notes = type === 'patient' ? this.patientNotes$.getValue()[index] : this.visitNotes$.getValue()[index];
    this.tableActionsDrawerService.deleteNotes(`${endPointName}_notes/${id}`, {note_id: notes.id}).subscribe((res) => {
      if (res?.status === 200) {
        if (type === 'patient') {
          const patientNotes = this.patientNotes$.getValue();
          patientNotes.splice(index, 1);
          this.patientNotes$.next([...patientNotes]);
        } else {
          const visitNotes = this.visitNotes$.getValue();
          visitNotes.splice(index, 1);
          this.visitNotes$.next([...visitNotes]);
        }
        // Check if any notes are still flagged after deletion
        const anyPatientNotesFlagged = this.patientNotes$.getValue().some((n) => n.flagged);
        const anyVisitNotesFlagged = this.visitNotes$.getValue().some((n) => n.flagged);

        const isRowFlagged = anyPatientNotesFlagged || anyVisitNotesFlagged;

        // Emit the correct flagged state to update row background
        this.markRowEvent.emit(isRowFlagged);
        this.resetNoteFields();
      }
    })
  }

  cancelNoteAddition(event: MouseEvent) {
    event.stopPropagation();
    this.resetNoteFields();
  }

  triggerCloseDrawer() {
    this.closeDrawerEvent.emit();
  }

  addTemplate(templateText: string) {
    this.newNote = templateText;
  }

  markRow (note: NotesInterface, noteType: string) {
    const data = {
      patient_id: this.patientId,
      meeting_id: this.meetingId,
      note_id: note.id,
      note_type: noteType,
      flagged: !note.flagged
    }
    const updatedFlaggedState = !note.flagged;

    const notes$ = noteType === 'meeting' ? this.visitNotes$ : this.patientNotes$;
    const notes = notes$.getValue();


    this.tableActionsDrawerService.postFlagNotes(data).subscribe((res) => {
      if (res.status === 200) {
        const updatedNotes = notes.map((n) =>
          n.id === note.id ? { ...n, flagged: updatedFlaggedState } : n
        );

        notes$.next([...updatedNotes]);
        const anyPatientNotesFlagged = this.patientNotes$
        .getValue()
        .some((n) => n.flagged);
      const anyVisitNotesFlagged = this.visitNotes$
        .getValue()
        .some((n) => n.flagged);

      const isRowFlagged = anyPatientNotesFlagged || anyVisitNotesFlagged;

      // Emit the correct flagged state
      this.markRowEvent.emit(isRowFlagged);
      }
    }, (error) => {
      const revertedNotes = notes.map((n) =>
        n.id === note.id ? { ...n, flagged: note.flagged } : n
      );
      notes$.next([...revertedNotes]);
    })
  }
}
