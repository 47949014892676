import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { TableActionsDrawerService } from '../../table-actions-drawer/table-actions-drawer.service';
import { interval, Subscription } from 'rxjs';
import moment from 'moment';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { TimePickerComponent } from '../../time-picker/time-picker.component';
import { Appointment, AppointmentResponse, Client, Note } from '../../table-actions-drawer/components/patient-info/patient-info.component';

interface TimeValue {
  hour: string;
  minute: string;
  period: 'AM' | 'PM';
}

interface ButtonConfig {
  key: string;
  label: string;
  action: string;
  customClass?: string;
}

@Component({
  selector: 'app-edit-appointment',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatDatepickerModule,
    TimePickerComponent
  ],
  templateUrl: './edit-appointment.component.html',
  styleUrls: ['./edit-appointment.component.css'],
  providers: [provideNativeDateAdapter()]
})
export class EditAppointmentComponent implements OnInit, OnDestroy {
  @Input() appointment!: Appointment;
  @Output() backToList = new EventEmitter<void>();
  @Output() deleteConfirmed = new EventEmitter<Appointment>();

  accumulatedTime = 0; // Mocked for now
  currentTime: string = '';
  elapsedTime: string = '00:00'; // Formatted timer display
  isCompletedCard = false;
  timerSubscription!: Subscription;
  timerSeconds = 0;

  // Date picker
  startDate: Date | null = null;

  // Time picker
  selectedTime: TimeValue = { hour: '07', minute: '26', period: 'PM' }; // Default from appointment.startTime

  // Button configurations for edit form
  buttonConfig: ButtonConfig[] = [
    { key: 'save', label: 'Save and Close', action: 'save', customClass: 'save-button' },
    { key: 'complete', label: 'Mark as completed', action: 'complete', customClass: 'complete-button' },
    { key: 'delete', label: 'Delete', action: 'delete', customClass: 'delete-button' }
  ];

  // Button configurations for completed card
  completedButtonConfig: ButtonConfig[] = [
    { key: 'update', label: 'Update time spent', action: 'update', customClass: 'update-time-button' },
    { key: 'reopen', label: 'Reopen', action: 'reopen', customClass: 'reopen-button' },
    { key: 'delete', label: 'Delete', action: 'delete', customClass: 'delete-button' },
    { key: 'close', label: 'Close', action: 'close', customClass: 'close-button' }
  ];

  constructor(private tableActionsService: TableActionsDrawerService) {}

  ngOnInit() {
    // Fetch detailed appointment data
    this.fetchAppointmentDetails();

    // Update current time, elapsed time, and timeSpent every second
    this.timerSubscription = interval(1000).subscribe(() => {
      // Update current time using moment
      this.currentTime = moment().format('HH:mm');

      // Update elapsed time using moment.duration
      const duration = moment.duration(this.timerSeconds, 'seconds');
      this.elapsedTime = `${Math.floor(duration.asMinutes()).toString().padStart(2, '0')}:${duration.seconds().toString().padStart(2, '0')}`;

      // Update timeSpent based on the timer (in minutes, rounded down)
      this.appointment.timeSpent = Math.floor(this.timerSeconds / 60);
    });

    // Conditionally show the "Mark as completed" button based on appointment.completed
    this.updateButtonConfig();
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  parseAppointmentDateTime() {
    // Parse startDate (DD/MM/YYYY)
    const [day, month, year] = this.appointment.startDate.split('/').map(Number);
    this.startDate = new Date(year, month - 1, day);

    // Parse startTime (HH:MM AM/PM)
    const [time, period] = this.appointment.startTime.split(' ');
    const [hour, minute] = time.split(':').map(Number);
    const hour12 = hour % 12 || 12;
    this.selectedTime = {
      hour: String(hour12).padStart(2, '0'),
      minute: String(minute).padStart(2, '0'),
      period: period as 'AM' | 'PM'
    };
  }

  updateButtonConfig() {
    // Show "Mark as completed" button only if the appointment is not completed
    this.buttonConfig = this.buttonConfig.filter(button => {
      if (button.action === 'complete') {
        return !this.appointment.completed;
      }
      return true;
    });
  }

  onDateChange(date: Date) {
    this.startDate = date;
    this.appointment.startDate = moment(date).format('DD/MM/YYYY');
  }

  onTimeChange(time: TimeValue | null) {
    if (time) {
      this.selectedTime = time;
      this.appointment.startTime = `${time.hour}:${time.minute} ${time.period}`;
    }
  }

  onDurationChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = parseInt(inputElement.value, 10);
    // Only update if the value is a valid number and non-negative
    if (!isNaN(value) && value >= 0) {
      this.appointment.duration = value;
    } else {
      this.appointment.duration = 0; // Default to 0 if invalid
    }
  }

  onTimeSpentChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = parseInt(inputElement.value, 10);
    // Only update if the value is a valid number and non-negative
    if (!isNaN(value) && value >= 0) {
      this.appointment.timeSpent = value;
      // Update timerSeconds to match the new timeSpent value (convert minutes to seconds)
      this.timerSeconds = this.appointment.timeSpent * 60;
    } else {
      this.appointment.timeSpent = 0;
      this.timerSeconds = 0;
    }
  }

  incrementDuration() {
    if (this.appointment.duration < 999) {
      this.appointment.duration++;
    }
  }

  decrementDuration() {
    if (this.appointment.duration > 0) {
      this.appointment.duration--;
    }
  }

  incrementTimeSpent() {
    if (this.appointment.timeSpent < 999) {
      this.appointment.timeSpent++;
      // Update timerSeconds to match the new timeSpent value
      this.timerSeconds = this.appointment.timeSpent * 60;
    }
  }

  decrementTimeSpent() {
    if (this.appointment.timeSpent > 0) {
      this.appointment.timeSpent--;
      // Update timerSeconds to match the new timeSpent value
      this.timerSeconds = this.appointment.timeSpent * 60;
    }
  }

  numberOnly(event: KeyboardEvent): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  fetchAppointmentDetails() {
    this.tableActionsService.fetchAppointmentDetails(this.appointment.id).subscribe({
      next: (details: AppointmentResponse) => {
        // Map the response to the Appointment interface with default values for safety
        const startDateTime = moment(details.start_date_time_utc, 'YYYY-MM-DD HH:mm:ss');
        const hour = startDateTime.hour();
        const hour12 = hour % 12 || 12;
        const period = hour < 12 ? 'AM' : 'PM';

        this.appointment = {
          id: details.id,
          client: details.client ?? { id: 0, name: 'Unknown', email: '' }, // Default client if null
          type: details.appointment_type,
          startDate: startDateTime.format('DD/MM/YYYY'),
          startTime: `${hour12}:${startDateTime.format('mm')} ${period}`,
          duration: details.duration_minutes ?? 0, // Default to 0 if null
          timeSpent: 0, // Will be set based on timerSeconds
          programType: this.mapMonitoringType(details.monitoring_type),
          note: details.note?.text ?? '', // Default to empty string if null
          completed: details.is_completed ?? false // Default to false if null
        };

        //total time spent
        this.accumulatedTime = details.duration_minutes;
        // Initialize timer with total seconds (duration_minutes * 60 + duration_seconds)
        this.timerSeconds = ((details.duration_minutes ?? 0) * 60) + (details.duration_seconds ?? 0);
        // Set initial timeSpent based on timerSeconds
        this.appointment.timeSpent = Math.floor(this.timerSeconds / 60);
        this.startTimer();

        // Parse the date and time for the UI
        this.parseAppointmentDateTime();

        // Update the completed card state
        this.isCompletedCard = details.is_completed ?? false;

        // Update button config
        this.updateButtonConfig();
      },
      error: () => {
        console.error('Failed to fetch appointment details');
      }
    });
  }

  startTimer() {
    // Start the timer from the initial timerSeconds value
    this.timerSubscription = interval(1000).subscribe(() => {
      this.timerSeconds++;
      console.log(`Timer: ${this.timerSeconds} seconds`);
    });
  }

  mapMonitoringType(monitoringType: string | undefined): string {
    // Map the monitoring_type to programType
    const typeMap: { [key: string]: string } = {
      'Wellness': 'Wellness',
      'RPM': 'RPM',
      'RTM': 'RTM',
      'CCM': 'CCM',
      'BHI': 'BHI'
    };
    return typeMap[monitoringType ?? ''] || 'RPM'; // Default to RPM if undefined
  }

  handleButtonAction(action: string) {
    switch (action) {
      case 'save':
        this.saveAndClose();
        break;
      case 'complete':
        this.markAsComplete();
        break;
      case 'delete':
        if (this.isCompletedCard) {
          this.deleteFromCompletedCard();
        } else {
          this.deleteAppointment();
        }
        break;
      case 'update':
        this.updateTimeSpent();
        break;
      case 'reopen':
        this.reopenAppointment();
        break;
      case 'close':
        this.closeCompletedCard();
        break;
      default:
        console.log(`Unhandled action: ${action}`);
    }
  }

  saveAndClose() {
    this.tableActionsService.updateAppointmentDetails(this.appointment).subscribe({
      next: () => {
        console.log('Appointment updated successfully');
        this.back();
      },
      error: () => {
        console.error('Failed to update appointment');
      }
    });
  }

  markAsComplete() {
    this.tableActionsService.setAppointmentCompleted(this.appointment.id).subscribe({
      next: () => {
        console.log('Appointment marked as completed');
        this.appointment.completed = true;
        this.isCompletedCard = true;
        this.updateButtonConfig();
      },
      error: () => {
        console.error('Failed to mark appointment as completed');
      }
    });
  }

  updateTimeSpent() {
    this.tableActionsService.updateAppointmentTimeSpent(this.appointment.id, this.appointment.timeSpent).subscribe({
      next: () => {
        console.log('Time spent updated successfully');
        this.back();
      },
      error: () => {
        console.error('Failed to update time spent');
      }
    });
  }

  reopenAppointment() {
    this.tableActionsService.reopenCompletedAppointment(this.appointment.id).subscribe({
      next: () => {
        console.log('Appointment reopened successfully');
        this.appointment.completed = false;
        this.isCompletedCard = false;
        this.updateButtonConfig();
      },
      error: () => {
        console.error('Failed to reopen appointment');
      }
    });
  }

  deleteFromCompletedCard() {
    this.deleteConfirmed.emit(this.appointment);
  }

  deleteAppointment() {
    this.deleteConfirmed.emit(this.appointment);
  }

  closeCompletedCard() {
    this.back();
  }

  back() {
    this.backToList.emit();
  }
}