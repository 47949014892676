<div style="margin-top: 30px;">
  <form [formGroup]="myForm" (ngSubmit)="onSubmit()" novalidate>

    <!--  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">  DEPCRICATED-->
    <div class="flex-container">

      <div>
        <mat-card style="width: 300px; height: 100%;">
          <mat-toolbar color="accent" class="mat-elevation-z16">
            <mat-toolbar-row>
              <span>MFA</span>
            </mat-toolbar-row>
          </mat-toolbar>
          <mat-progress-bar *ngIf="is_loading" color="accent" mode="indeterminate" style="margin-top: 0px"></mat-progress-bar>


          <mat-card-content> <!-- fxLayout="column" -->
            <app-input-verification-code placeholder="Enter your MFA code" formControlName="mfa_code"></app-input-verification-code>
            <!--            <mat-form-field style="margin-top: 15px; width: 100%">-->
            <!--              <mat-label>MFA Code:</mat-label>-->
            <!--              <input matInput type="text" placeholder="" (focus)="onFocus()" formControlName="mfa_code">-->
            <!--              <mat-error style="font-size: 12px">No spaces and length of 6</mat-error>-->
            <!--            </mat-form-field>-->
          </mat-card-content>
          <div *ngIf="is_form_error">
            <mat-error style="margin-left: 10px; margin-bottom: 5px">
              <strong>{{form_error_message}}</strong>
            </mat-error>
          </div>



          <div class="flex-container">
            <button mat-raised-button color="accent" [disabled]="is_loading" type="button" (click)="goBack()" style="margin: 10px">Back</button>
            <button mat-raised-button color="accent" [disabled]="!myForm.valid || is_loading" type="submit" style="margin: 10px">Submit</button>
          </div>


        </mat-card>
      </div>
    </div>

  </form>
</div>