import { Component, Input, OnInit, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {NG_VALUE_ACCESSOR, Validators, ValidatorFn } from '@angular/forms';
import {MatButtonModule} from "@angular/material/button";
import {ModelValidators} from "../../validators/validators";

@Component({
  selector: 'app-input-password',
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatFormFieldModule,
    MatInputModule, MatIconModule, MatButtonModule],
  templateUrl: './input-password.component.html',
  styleUrl: './input-password.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPasswordComponent),
      multi: true,
    },
  ],
})
export class InputPasswordComponent implements OnInit {

  /*
  <app-input-password placeholder="Enter your password"  formControlName="password"></app-input-password>
  */
  hide = true;

  @Input('placeholder') placeholder: string = '';
  @Input() required: boolean = false;

  formControl = new FormControl('',);
  onTouched: any;
  onChange: any;

  ngOnInit() {
    const validators: ValidatorFn[] = [];
    validators.push(Validators.required);
    validators.push(Validators.minLength(8));
    validators.push(Validators.maxLength(100));
    validators.push(ModelValidators.cannotContainSpaceValidator());
    validators.push(ModelValidators.passwordCognitoControlsValidator());
    this.formControl.addValidators(validators)
  } // end ngOnInit

  writeValue(value: any): void {
    this.formControl.setValue(value);
  } // end writeValue

  registerOnChange(fn: any): void {
    console.log('registerOnChange')
    this.onChange = fn;
    this.formControl.valueChanges.subscribe(fn);
  } // end registerOnChange

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  } // end registerOnTouched

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  } // end setDisabledState

  // onFocus(){
  //   console.log('onFocus')
  //   this.is_form_error = false;
  //   this.form_error_message = "";
  // }


} // end InputPasswordComponent
