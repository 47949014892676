import {Injectable} from '@angular/core';
import {Channel, Chat, Membership, Message, User} from "@pubnub/chat";
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  chat!: Chat;
  current_user_id: string | null = localStorage.getItem('email');
  from_user_name: string | null = this.current_user_id;
  private initialized: Promise<void>;
  private disconnectFunctions: (() => void)[] = [];
  private userChannelMap: { [userId: string]: string } = {};
  private historyUsers: {
    user_id: string,
    name: string,
    timeToken: string,
    unreadCount: number,
    status: string
  }[] = [];
  private newConversationSubject = new Subject<{ channel: Channel, membership: Membership }>();
  newConversation$ = this.newConversationSubject.asObservable(); // Observable to expose to components

  constructor() {
    this.initialized = this.initializeChat().then(() => {
      console.log('Chat SDK initialization complete.');
    }).catch((error) => {
      console.error('Chat SDK initialization incomplete.');
    });
    this.historyUsers = [];
    this.userChannelMap = {};
    this.disconnectFunctions = [];
  }

  async initializeChat() {
    try {
      if (!this.current_user_id) {
        throw new Error('Current user ID is missing. Chat initialization cannot proceed.');
      }
      this.chat = await Chat.init({
        publishKey: 'pub-c-cbc67cf1-d69a-41c4-8a11-cf9fb00552a1',
        subscribeKey: 'sub-c-40212731-7398-4154-84ae-75c5a037e674',
        userId: this.current_user_id,
        typingTimeout: 3000
      });
      console.log('Chat SDK initialized:', this.chat);
      // this.triggerReinitializeEvent();
    } catch (error) {
      console.error('Error initializing Chat SDK:', error);
    }
  }

  waitForChatInitialization(): Promise<void> {
    return this.initialized;
  }
  
  async getUnreadMessages(membership: Membership): Promise<number | false> {
    try {
      // Step 1: Get unread message count
      const unreadCount = await membership.getUnreadMessagesCount();
      console.log(`The membership object is`, membership);
      console.log(`unread count for ${membership.channel.id} is ${unreadCount}`);

      const channelId = membership.channel.id;
      const history = await this.getChannelHistory(channelId, undefined, undefined, 1);
  
      if (history && history.messages.length > 0) {
        const lastMessage = history.messages[0];
        console.log(`message is ${lastMessage.content.text}`);
        this.updateHistoryUsers(lastMessage, unreadCount || 0, 'inactive');
      } else {
        console.warn(`No message history found for channel: ${channelId}`);
      }
      return unreadCount ?? 0;
    } catch (error) {
      console.error('Error getting unread messages count:', error);
      return 0;
    }
  }
  
  destroyVariables(){
    this.disconnectFunctions = [];
    this.historyUsers = [];
    this.userChannelMap = {};
  }
  // Method to create a user
  async createUser(selectedUser: any) {
    try {
      const user: User = await this.chat.createUser(String(selectedUser.user_id), {
        name: selectedUser.name,
        custom: {
          email: selectedUser.email
        }
      });
      console.log('User created successfully:', user);
      return user;
    } catch (error) {
      console.error('Error creating user:', error);
      return null;
    }
  }
  // Create a direct conversation with another user
  async createDirectConversation(channelId: string, selectedUser: any, topic: string = 'Conversation Topic', purpose: string = 'chat') {
    try {
        console.log(selectedUser);
        
        // Check if the user exists; if not, create a new one
        let userToChatWith = await this.chat.getUser(String(selectedUser.user_id));
        
        if (!userToChatWith) {
            console.log(`User with ID ${selectedUser.user_id} not found. Creating user...`);
            userToChatWith = await this.createUser(selectedUser);

            if (!userToChatWith) {
                console.error('Error creating user. Cannot proceed with conversation creation.');
                return null;
            }
        } else {
            console.log(`User with ID ${selectedUser.user_id} found:`, userToChatWith);
        }

        // Add the user to historyUsers with 'active' status
        this.addHistoryUser(selectedUser.user_id, selectedUser.name, 'active');
        
        // Create the direct conversation
        const { channel, hostMembership, inviteeMembership } = await this.chat.createDirectConversation({
            user: userToChatWith,
            channelId: channelId,
            channelData: {
                name: `Chat between ${this.current_user_id} (${this.from_user_name}) and ${selectedUser.user_id} (${selectedUser.name})`,                
                custom: {
                    topic: topic
                }
            },
            membershipData: {
                custom: {
                    purpose: purpose
                }
            }
        });
        this.userChannelMap[selectedUser.user_id] = channelId;

        console.log(`Created direct conversation with channel: ${channel.id}`);
        // Subscribe to the channel
        // this.subscribeToChannel(channel, hostMembership);
        this.newConversationSubject.next({ channel, membership: hostMembership });
        return { channel, hostMembership, inviteeMembership };
    } catch (error) {
        console.error('Error creating direct conversation:', error);
        return null;
    }
  }


  // Method to send a text message to a channel
  async sendTextMessage(channelId: string, text: string, to_user_id: string, to_user_name: string, from_user_name: string) {
    try {
      // Reference the channel where you want to send the text message
      let channel: Channel | null = await this.chat.getChannel(channelId);
      if (!channel) {
        console.log(`Channel with name ${channelId} not found. Creating direct conversation...`);
        
        const selectedUser = { user_id: to_user_id, name: to_user_name };
        const result = await this.createDirectConversation(channelId, selectedUser);
        
        if (!result || !result.channel) {
            console.error('Failed to create channel. Cannot send message.');
            return null;
        }

        // Assign the newly created channel to the variable
        channel = result.channel;
    }
  
      // Send the text message using the channel's `sendText` method
      console.log(`Before returning the sentMessage`);
      const sentMessage = await channel.sendText(text, {
        storeInHistory: true,
        meta: {
          to_user_id: to_user_id,
          to_user_name: to_user_name,
          from_user_name: from_user_name
        },
      });
      // Call updateHistoryUsers with the sent message to update the recent chats
      return sentMessage;
    } catch (error) {
      console.error('Error sending message:', error);
      return null;
    }
  }
  
  // Fetch message history for a channel
  async getChannelHistory(channelId: string, startTimetoken?: string, endTimetoken?: string, count?: number) {
    try {
      // Reference the channel
      const channel: Channel | null = await this.chat.getChannel(channelId);

      // Check if the channel exists
      if (!channel) {
        console.log(`Channel with name ${channelId} not found.`);
        return;
      } else {
        console.log(`Channel with name ${channelId} found. ${channel}`);
      }
      const historyOptions: any = {};

      if (startTimetoken) {
        historyOptions.startTimetoken = startTimetoken;
      }
      if (endTimetoken) {
        historyOptions.endTimetoken = endTimetoken;
      }

      if (count) {
        historyOptions.count = count;
      }
      console.log(`History Options are ${historyOptions}`);
      // Get message history from the channel
      const history = await channel.getHistory(historyOptions);
      console.log('Message history:', history);
      return history;
    } catch (error) {
      console.error('Error fetching history:', error);
      return null;
    }
  }

  subscribeToChannel(channel: Channel, membership: Membership): Observable<any> {
    return new Observable(observer => {
      const disconnect = channel.connect(async (message: Message) => {
        // Record start time
        // const startTime = performance.now(); 
        // console.log('Started processing at:', startTime);
  
        const to_user_id = message.meta?.['to_user_id'] ?? null;
        const from_user_id = message.userId;
        const current_user_id = this.current_user_id;
        const otherUserId = current_user_id === from_user_id ? to_user_id : from_user_id;
        const messageIdentifier = `${message.timetoken}-${channel.id}-${message.userId}`;
        console.log(`This is the message Identifier: ${messageIdentifier}`);
  
        const existingUser = this.historyUsers.find(historyUser => historyUser.user_id === otherUserId);
        if (existingUser) {
          if (existingUser.status === 'active') {
            observer.next(message);
            this.updateHistoryUsers(message, 0, 'active');
            await this.markMessagesAsRead(channel.id, message.timetoken);
          } else {
            observer.next(message);
            const unreadCount = await this.getUnreadMessages(membership);
            // this.updateHistoryUsers(message, unreadCount, 'inactive');
          }
        } else {
          console.error(`User with ID ${otherUserId} not found in historyUsers.`);
        }
      });
      this.disconnectFunctions.push(disconnect);
    });
  }

  getDisconnectFunctions(): (() => void)[] {
    return this.disconnectFunctions;
  }
  
  getRecentChats() {
    return this.historyUsers;
  }

  private updateHistoryUsers(msg: any, unreadCount: number, status?:string): void{
    let to_user_id = msg.meta.to_user_id;
    console.log(`Message is ${msg}`);
    console.log(`Message meta data is ${msg.meta}`);
    if(to_user_id != this.current_user_id) {
      let to_user_name = msg.meta.to_user_name;
      let timeToken = msg.timetoken;
      const existingUser = this.historyUsers.find(user => user.user_id === to_user_id);
      if (existingUser) {
        existingUser.timeToken = timeToken;
        existingUser.unreadCount = unreadCount;
      } else {
        this.historyUsers.push({user_id: to_user_id, name: to_user_name, timeToken: timeToken, unreadCount: unreadCount, status:status || 'inactive'});
      }
    } else {
      let from_user_id = msg.userId;
      let from_user_name = msg.meta.from_user_name;
      let timeToken = msg.timetoken;
      const existingUser = this.historyUsers.find(user => user.user_id === from_user_id);
      if (existingUser) {
        existingUser.timeToken = timeToken;
        existingUser.unreadCount = unreadCount;
      } else {
        this.historyUsers.push({user_id: from_user_id, name: from_user_name, timeToken: timeToken, unreadCount:unreadCount, status:status || 'inactive'});
      }
    }
    this.historyUsers.sort((a, b) => parseInt(b.timeToken) - parseInt(a.timeToken));
  }

  updateUserStatus(userId: string, status?: string, unreadCounter?:number) {
    const foundUser = this.historyUsers.find(user => user.user_id === userId);
    if (foundUser) {
      if (unreadCounter != undefined) {
        foundUser.unreadCount = unreadCounter;
        console.log(`Unread Count inside UserStatus is ${foundUser.unreadCount}`);
      }
    // User found, you can now access the user's details
      console.log('User found:', foundUser);
      foundUser.status = status || foundUser.status;
      console.log('User after changing status:', foundUser);
    } else {
    // User not found
      console.log('User not found');
    }
  }

  addHistoryUser(user_id: string, name: string, status: string): void {
    const newUser = {
        user_id: user_id,
        name: name,
        timeToken: '', // Default or empty time token if not provided
        unreadCount: 0, // Default unread count set to 0
        status: status
    };
    
    // Push the new user entry into the historyUsers array
    this.historyUsers.push(newUser);
    this.historyUsers.sort((a, b) => parseInt(b.timeToken) - parseInt(a.timeToken));
    console.log(`User with ID ${user_id} added to historyUsers with status: ${status}`);
    // Update local storage to save the latest historyUsers
  }

  // Method to start typing indicator
  async startTyping(channelId: string) {
    try {
      const channel = await this.chat.getChannel(channelId);
      if (channel) {
        await channel.startTyping();
        console.log('Typing started on channel:', channelId);
      }
    } catch (error) {
      console.error('Error starting typing indicator:', error);
    }
  }
  // Method to stop typing indicator
  async stopTyping(channelId: string) {
    try {
      const channel = await this.chat.getChannel(channelId);
      if (channel) {
        await channel.stopTyping();
        console.log('Typing stopped on channel:', channelId);
      }
    } catch (error) {
      console.error('Error stopping typing indicator:', error);
    }
  }
  async markMessagesAsRead(channelId: string, endTimetoken?: string): Promise<void> {
    try {
      // Ensure that current_user_id is not null
      if (!this.current_user_id) {
        console.error('Error: current_user_id is null. Cannot mark messages as read.');
        return;
      }
      
      // Reference the current user
      const user = await this.chat.getUser(this.current_user_id);
      
      // Check if user is null
      if (!user) {
        console.error(`Error: User with ID ${this.current_user_id} not found.`);
        return;
      }
      
      // Get the user's memberships and filter for the correct channel
      const memberships = await user.getMemberships({
        filter: `channel.id == '${channelId}'`
      });
      
      // Reference the channel by its ID
      const channel = await this.chat.getChannel(channelId);
      if (!channel) {
        console.error(`Error: Channel with ID ${channelId} not found.`);
        return;
      }
      // Get the last message in the channel's history using getChannelHistory
      const history = await this.getChannelHistory(channelId, undefined, endTimetoken, 1);  // Fetch the most recent message
      
      if (history && history.messages.length > 0) {
        const message = history.messages[0];  // The latest message
        const lastMessageTimetoken = message.timetoken;
        console.log(`message timetoken is ${lastMessageTimetoken}`);
        
        // Set the last read message and timetoken for the channel membership
        const membership = memberships.memberships[0];  // Assuming the correct channel membership is found
        const customFields = membership.custom;
  
        if (!customFields || !customFields['lastReadMessageTimetoken']) {
          console.log('No previous read message found. Marking the current message as read.');
        } else {
          const lastReadTimetoken = customFields['lastReadMessageTimetoken'];
          console.log(`last read time token is ${lastReadTimetoken}`);
          if (lastReadTimetoken === lastMessageTimetoken) {
            console.log(`Last message had already been read at timetoken: ${lastReadTimetoken}`);
            return;  // Do not mark it as read again
          }
        }
        await membership.setLastReadMessage(message);
        await membership.setLastReadMessageTimetoken(message.timetoken);
        // console.log('Membership after setting timetoken:', membership);
        // console.log(`Successfully marked message with timetoken ${message.timetoken} as read for channel ${channelId}`);
      } else {
        console.error('No messages found in the channel');
      }
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  }

  async getUserMemberships(): Promise<any[]> {
    try {
      if (!this.current_user_id) {
        console.error('Error: current_user_id is null. Cannot get memberships.');
        return [];
      }

      const user = await this.chat.getUser(this.current_user_id);
      if (!user) {
        console.error(`Error: User with ID ${this.current_user_id} not found.`);
        return [];
      }

      const memberships = await user.getMemberships();
      console.log(`Total memberships found: ${memberships.total}`);

      // Step 1: Populate the class-level hashmap with userId as key and channelId as value
      for (const membership of memberships.memberships) {
        const channel = membership.channel;

        const membersResponse = await channel.getMembers();
      const members = membersResponse.members;

      // Filter out the current user and add the other user to the map
      members.forEach(member => {
        const otherUser = member.user;
        if (otherUser.id !== this.current_user_id) {
          this.userChannelMap[otherUser.id] = channel.id;
        }
      });
    }

      console.log('User-Channel Map:', this.userChannelMap);
      return memberships.memberships;
    } catch (error) {
      console.error('Error retrieving user memberships:', error);
      return [];
    }
  }

  // Method to get the channel ID for a specific user
  getChannelForUser(userId: string): string {
    return this.userChannelMap[userId];
  }

  async listenForTyping(channelId: string, callback: (typingUserIds: string[]) => void): Promise <()=> void> {
    try {
      const channel = await this.chat.getChannel(channelId);
      if (channel) {
        return channel?.getTyping(callback) ?? (() => {});
      }
      return () => {};
    } catch (error) {
      console.error('Error listening for typing events:', error);
      return () => {};
    }
  }

  async leaveChannel(channelId: string): Promise<boolean> {
    try {
      // Get the channel by its ID
      const channel = await this.chat.getChannel(channelId);
    
      // Check if the channel exists before attempting to leave
      if (channel) {
        await channel.leave();
        console.log(`Successfully left the channel with ID: ${channelId}`);
        return true;
      } else {
        console.error(`Channel with ID ${channelId} not found.`);
        return false;
      }
    } catch (error) {
      console.error('Error leaving the channel:', error);
      return false;
    }
  }
  async leaveAllUserChannels(): Promise<void> {
    try {
      // Retrieve all memberships for the current user
      const memberships = await this.getUserMemberships();
  
      // Iterate through each membership and leave the associated channel
      for (const membership of memberships) {
        const channelId = membership.channel.id;  // Get the channel ID from the membership object
  
        // Leave the channel using the leaveChannel method
        const leftChannel = await this.leaveChannel(channelId);
        
        if (leftChannel) {
          console.log(`Successfully left channel with ID: ${channelId}`);
        } else {
          console.error(`Failed to leave channel with ID: ${channelId}`);
        }
      }
    } catch (error) {
      console.error('Error leaving all user channels:', error);
    }
  }  
  async toggleReaction(message: Message, reaction: string): Promise<Message | null> {
    try {
      const updatedMessage = await message.toggleReaction(reaction);
      console.log('Does it succeed on updating the message?');
      console.log(`Updated message is reaction ${updatedMessage.reactions}`);
      return updatedMessage;
    } catch (error) {
      console.error('Error toggling reaction: blah blah', error);
      return null;
    }
  }

  async deleteAllChannels(softDelete: boolean = false): Promise<void> {
    try {
      // Fetch all channels
      let page = { next: '' };
      let hasNextPage = true;
  
      while (hasNextPage) {
        const channelsResponse = await this.chat.getChannels({ page });
        const channels = channelsResponse.channels;
  
        if (channels.length === 0) {
          console.log("No channels found to delete.");
          break;
        }
        
        // Loop through each channel and delete it
        for (const channel of channels) {
          try {
            await this.chat.deleteChannel(channel.id, { soft: softDelete });
            console.log(`Deleted channel: ${channel.id}`);
          } catch (error) {
            console.error(`Failed to delete channel ${channel.id}:`, error);
          }
        }
        // Check if there's a next page
        page.next = channelsResponse.page.next ?? '';
        hasNextPage = !!channelsResponse.page.next;
      }
      console.log("All channels deleted successfully.");
    } catch (error) {
      console.error("Error deleting channels:", error);
    }
  }
}

