import {Injectable, NgZone} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Observable, retry, tap} from "rxjs";
import {CurrentLoggedInBehaviorService, CurrentRefreshTokenService, LoadingService, SuperAdminViewBehaviorService} from "../objectPassingService";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {HospitalLocationInterface, HospitalLocationPostInterfaceData, StatusPutInterfaceData} from "./registration.interface";



@Injectable({providedIn: 'root'})
export class RegistrationService {

  url:string = `${environment.API_URL}`

  constructor(
    private http: HttpClient,
    public router: Router,
    private currentLoggedInBehaviorService: CurrentLoggedInBehaviorService,
    private currentRefreshTokenService: CurrentRefreshTokenService,
    private superAdminViewBehaviorService: SuperAdminViewBehaviorService,
    public ngZone: NgZone,
    private loadingService: LoadingService
  ) {}


  getLocations(endpoint: string, is_mocked: boolean = false ): Observable<any> {
    const url = `${this.url}/${endpoint}`;
    return this.http.get<any>(url);
  } //end

  postLocations(endpoint: string, data: HospitalLocationPostInterfaceData, is_mocked: boolean = false ): Observable<any> {
    return this.http.post(`${this.url}/${endpoint}`, data);
  } //end

  putStatus(endpoint: string, data: StatusPutInterfaceData[]): Observable<any> {
    console.log('OMG!!!!!!!!!!!!',`${this.url}/${endpoint}`, data)
    return this.http.put(`${this.url}/${endpoint}`, data);
  } //end

  patchStatus(endpoint: string, data: { send_back_forms: { form_name: string; form_id: number }[] }): Observable<any> {
    const request$ = this.http.patch(`${this.url}/${endpoint}`, data)
    return this.loadingService.showLoadingUntilCompleted(request$)
  }

  cancelApptPatch(endpoint: string, data: {}): Observable<any> {
    const request$ = this.http.patch(`${this.url}/${endpoint}`, data)
    return this.loadingService.showLoadingUntilCompleted(request$)
  }

  actionIconPatch(endpoint: string, data: {}): Observable<any> {
    return this.http.patch(`${this.url}/${endpoint}`, data)
  }

  // post(endpoint: string, data: CampaignInterface){
  //   return this.http.post(`${this.url}/${endpoint}`, data,{observe: 'response'});
  // }
  //
  // put(endpoint: string, data: CampaignInterface){
  //   return this.http.put(`${this.url}/${endpoint}`, data,{observe: 'response'});
  // }
  //
  // delete(endpoint: string): Observable<any> {
  //   const url = `${this.url}/${endpoint}`;
  //   return this.http.delete<any>(url);
  // } //end getAlerts


}
