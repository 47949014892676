
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject} from 'rxjs';
import {Alert} from "./dashboard/dashboard.service";




@Injectable({providedIn: 'root'})
export class TasksAllBehaviorService {

  constructor() {
  }

  public tasksAllAnnouncedBehaviorSource = new BehaviorSubject<Alert[]>([]);
  // Observable string streams
  tasksAllBehaviorAnnounced$ = this.tasksAllAnnouncedBehaviorSource.asObservable();

  // Service message commands
  announceTasksAllBehavior(current: Alert[]) {
    this.tasksAllAnnouncedBehaviorSource.next(current);
  }
}

@Injectable({providedIn: 'root'})
export class AlertsBehaviorService {

  constructor() {
  }

  public currentAlertsAnnouncedBehaviorSource = new BehaviorSubject<Alert[]>([]);
  // Observable string streams
  currentAlertsBehaviorAnnounced$ = this.currentAlertsAnnouncedBehaviorSource.asObservable();

  // Service message commands
  announceAlertsBehavior(current: Alert[]) {
    this.currentAlertsAnnouncedBehaviorSource.next(current);
  }
}


@Injectable({providedIn: 'root'})
export class MakeEngagingCallsBehaviorService {


  public currentMakeEngagingCallsAnnouncedBehaviorSource = new BehaviorSubject<Alert[]>([]);
  // Observable string streams
  currentMakeEngagingCallsBehaviorAnnounced$ = this.currentMakeEngagingCallsAnnouncedBehaviorSource.asObservable();

  // Service message commands
  announceMakeEngagingCallsBehavior(current: Alert[]) {
    this.currentMakeEngagingCallsAnnouncedBehaviorSource.next(current);
  }
}


@Injectable({providedIn: 'root'})
export class GroupTasksService {


  public GroupTasksAnnounceBehaviorSource = new BehaviorSubject<boolean>(true);
  // Observable string streams
  GroupTasksBehaviorAnnounced$ = this.GroupTasksAnnounceBehaviorSource.asObservable();

  // Service message commands
  announceGroupTasksBehavior(current: boolean) {
    this.GroupTasksAnnounceBehaviorSource.next(current);
  }
}

@Injectable({providedIn: 'root'})
export class ClientIdService {

  // Used to pass the client_id from the dashboard to the patient view

  public ClientIdAnnounceBehaviorSource = new BehaviorSubject<number>(-1);
  // Observable string streams
  ClientIdBehaviorAnnounced$ = this.ClientIdAnnounceBehaviorSource.asObservable();

  // Service message commands
  announceClientIdBehavior(current: number) {
    this.ClientIdAnnounceBehaviorSource.next(current);
  }
}
