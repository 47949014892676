import {Routes} from '@angular/router';
import {LoginComponent} from "./auth/login/login.component";
import {MfaComponent} from "./auth/mfa/mfa.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {AuthGuard, NotAuthGuard, SuperAdminGuard, FrontdeskAuthGuard} from './auth.guard';
import {QrCodeComponent} from "./auth/qr-code/qr-code.component";
import {NewPasswordRequiredComponent} from "./auth/new-password-required/new-password-required.component";
import {ForgotPasswordComponent} from "./auth/forgot-password/forgot-password.component";
import {
  ForgotPasswordConfirmationComponent
} from "./auth/forgot-password-confirmation/forgot-password-confirmation.component";
import {UsersComponent} from "./users/users.component";
import {CelTestComponent} from "./cel-test/cel-test.component";
import {CelDetailsTestComponent} from "./cel-details-test/cel-details-test.component";
import {AdserverNavComponent} from "./adserver/adserver-nav/adserver-nav.component";
import {CampaignGroupsComponent} from "./adserver/campaign-groups/campaign-groups.component";

import {CampaignGroupSequenceComponent} from "./adserver/campaign-group-sequence/campaign-group-sequence.component";
import {CampaignsComponent} from "./adserver/campaigns/campaigns.component";
import {AdgroupsComponent} from "./adserver/adgroups/adgroups.component";
import {AdsComponent} from "./adserver/ads/ads.component";
import {ClientSelectComponent} from "./adserver/client-select/client-select.component";
import {RegistrationNavComponent} from "./registration/registration-nav/registration-nav.component";
import {RegistrationDashboardComponent} from "./registration/registration-dashboard/registration-dashboard.component";

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: 'login',  component: LoginComponent, canActivate: [NotAuthGuard]},
  { path: 'mfa',  component: MfaComponent, canActivate: [NotAuthGuard]},
  { path: 'qrcode',  component: QrCodeComponent, canActivate: [NotAuthGuard]},
  { path: 'new-password-required',  component: NewPasswordRequiredComponent, canActivate: [NotAuthGuard]},
  { path: 'forgot-password',  component: ForgotPasswordComponent, canActivate: [NotAuthGuard]},
  { path: 'forgot-password-confirmation',  component: ForgotPasswordConfirmationComponent, canActivate: [NotAuthGuard]},
  { path: 'users',  component: UsersComponent,  canActivate: [SuperAdminGuard]},
  { path: 'cal',  component: CelTestComponent, canActivate: [SuperAdminGuard]},
  { path: 'cal-details',  component: CelDetailsTestComponent, canActivate: [SuperAdminGuard]},
  { path: 'dashboard/:user_id',  component: DashboardComponent,  canActivate: [AuthGuard]},
  { path: 'adserver-nav',  component: AdserverNavComponent, canActivate: [SuperAdminGuard], children: [
      { path: 'client-select',  component: ClientSelectComponent, canActivate: [SuperAdminGuard],  outlet: 'admeta'},
      { path: 'campaign-group-sequence',  component: CampaignGroupSequenceComponent, canActivate: [SuperAdminGuard],  outlet: 'admeta'},
      { path: 'campaign-group',  component: CampaignGroupsComponent, canActivate: [SuperAdminGuard],  outlet: 'admeta'},
      { path: 'campaigns',  component: CampaignsComponent, canActivate: [SuperAdminGuard],  outlet: 'admeta'},
      { path: 'adgroups',  component: AdgroupsComponent, canActivate: [SuperAdminGuard],  outlet: 'admeta'},
      { path: 'ads',  component: AdsComponent, canActivate: [SuperAdminGuard],  outlet: 'admeta'},
    ]},
  { path: 'registration-nav',  component: RegistrationNavComponent, canActivate: [FrontdeskAuthGuard], children: [
      { path: 'registration-dashboard',  component: RegistrationDashboardComponent, canActivate: [FrontdeskAuthGuard],  outlet: 'registrationmeta'},
    ]},


];


