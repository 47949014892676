import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {LoadingService} from "../../objectPassingService";

@Component({
  selector: 'app-loading',
  standalone: true,
    imports: [CommonModule, MatProgressBarModule],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.css'
})
export class LoadingComponent {

  constructor(
    public loadingService: LoadingService
  ) {

  }

}
