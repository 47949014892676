import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Router} from "@angular/router";
import {IntakeAssessmentsService} from "./intake-assessments.service";

@Component({
  selector: 'app-intake-assessments',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './intake-assessments.component.html',
  styleUrl: './intake-assessments.component.css'
})
export class IntakeAssessmentsComponent implements OnInit {


  constructor(
    public router: Router,
    private intakeAssessmentsService: IntakeAssessmentsService,
  ) { }

  ngOnInit() {

    const patient_id: string = '1949'; // dev p2423

    const endpoint: string = `patient/${patient_id}/intake-assessments`;

    this.intakeAssessmentsService.getIntakeAssessments(endpoint).subscribe(
      (response) => {
        console.log('response', response);

        //response.message.rpm_rtm_assessment.survey_answers.osordSection.forEach()



      },
      (error) => {
        console.log('error', error);
      }
    );


  } // end constructor


} // end class
