import { Component } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';

import { SuperAdminViewBehaviorService } from '../objectPassingService';
import { LoginService } from "../auth/login/login.service";
import { MatMenuModule } from "@angular/material/menu";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    AsyncPipe,
    MatMenuModule,
    MatTooltipModule
  ]
})
export class MainNavComponent {
  superadmin_view = false;
  is_production = environment.production;
  slug = environment.slug;
  constructor(
    private loginService: LoginService,
    private superAdminViewBehaviorService: SuperAdminViewBehaviorService,
    private router: Router
  ) {
    this.superAdminViewBehaviorService.currentSuperAdminViewBehaviorAnnounced$
      .subscribe(superadmin_view => {
        this.superadmin_view = superadmin_view;
      });
    console.log("superadmin_viewmmmmmmmmmmmmmm", this.superadmin_view)
  }

  logout() {
    this.loginService.logout();
  }

  calendarRedirect() {
    this.router.navigate(['cal']);
  }

  adserverRedirect() {
    this.router.navigate(['adserver-nav']);
  }
  usersRedirect() {
    this.router.navigate(['users']);
  }
  registrationRedirect() {
    this.router.navigate(['registration-nav', { outlets: { registrationmeta: ['registration-dashboard'] } }]).then(r => console.log('registration-nav'));
  }

  calendarDetailsRedirect() {
    this.router.navigate(['cal-details']);
  }

  chatRedirect() {
    console.log(`The value of is_production is ${this.is_production}`);
    this.router.navigate(['chat']);
  }

  clinicalDasboardRedirect() {
    this.router.navigate(['clinical-dashboard'])
  }

  isRouteActive(route: string): boolean {
    const currentUrl = this.router.url;
    if (currentUrl === route) {
      return true;
    }
    if (route === '/registration-nav') {
      return currentUrl.startsWith('/registration-nav');
    }

    return false;
  }


}
