import {Component, Input, OnInit, forwardRef, ChangeDetectionStrategy} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {
  NG_VALUE_ACCESSOR,
  Validators,
  ValidatorFn,
} from '@angular/forms';

@Component({
  selector: 'app-input-email',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
  templateUrl: './input-email.component.html',
  styleUrl: './input-email.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputEmailComponent),
      multi: true,
    },
  ],
})
export class InputEmailComponent  implements OnInit {

  /*

<app-input-email placeholder="foo@bar.com" type="email" required="true" email="true" formControlName="wtf" is_disabled="true"></app-input-email>

 */

  @Input('placeholder') placeholder: string = '';
  @Input('type') type: string = 'text';
  @Input() required: boolean = false;
  @Input() email: boolean = false;
  @Input('is_disabled') is_disabled: boolean = false;

  formControl = new FormControl('',);
  onTouched: any;
  onChange: any;


  ngOnInit() {
    const validators: ValidatorFn[] = [Validators.email];
    validators.push(Validators.required);
    // if (this.required) {
    //   validators.push(Validators.required);
    // }
    //
    // if (this.email) {
    //   validators.push(Validators.email);
    // }

    this.formControl.addValidators(validators)
  } // end ngOnInit

  writeValue(value: any): void {
    this.formControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    console.log('registerOnChange')
    this.onChange = fn;
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }

  // onFocus(){
  //   console.log('onFocus')
  //   this.is_form_error = false;
  //   this.form_error_message = "";
  // }

}
