<ng-container *ngIf="is_logged_in">
  <app-main-nav>
    <router-outlet></router-outlet>
  </app-main-nav>
</ng-container>

<ng-container *ngIf="loadingService.loading$ | async">
  <div class="loading-overlay">
    <div class="spinner-content">
      <mat-spinner class="custom-spinner"></mat-spinner>
      <span class="loading-text">Loading...</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!is_logged_in">
  <mat-toolbar color="accent"><span class="ref"></span></mat-toolbar>
  <router-outlet></router-outlet>
</ng-container>
