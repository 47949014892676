<div class="appointment-table-info">
  <div class="appointment-header">
    <h4 style="font-size: 24px; font-weight: 600; color: var(--primary);">Appointments (Total time this month: {{ accumulatedTime }} mins)</h4>
    <div class="appointment-icons">
      <i [ngClass]="'healent-ref'" (click)="refreshAppointments()" title="Refresh"></i>
      <i [ngClass]="'healent-document'" (click)="viewAllNotes()" title="View All Notes"></i>
      <i [ngClass]="'healent-form-input-icon-8'" (click)="createNewAppointment()" title="New Appointment"></i>
    </div>
  </div>
  <div class="appointment-table">
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" class="appointments-table" [class.loading]="isLoading">
        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef class="w15">Type</th>
          <td mat-cell *matCellDef="let row" class="w5 center-cell-type">
            <i [ngClass]="[getAppointmentTypeIcon(row.type), 'icon-style']" style="padding: 10px;"></i>
          </td>
        </ng-container>
        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef class="w25">Date</th>
          <td mat-cell *matCellDef="let row" class="w25" [ngClass]="row.type !== 'onsite' ? 'hover-class' : ''" (click)="row.type !== 'onsite' && openDrawerDetails(row)">
            {{ row.startDate }}
          </td>
        </ng-container>
        <!-- Time Column -->
        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef class="w20">Time</th>
          <td mat-cell *matCellDef="let row" class="w20" [ngClass]="row.type !== 'onsite' ? 'hover-class' : ''" (click)="row.type !== 'onsite' && openDrawerDetails(row)">
            {{ row.startTime }}
          </td>
        </ng-container>
        <!-- Time Spent Column -->
        <ng-container matColumnDef="timeSpent">
          <th mat-header-cell *matHeaderCellDef class="w25">Time Spent</th>
          <td mat-cell *matCellDef="let row" class="w25" [ngClass]="row.type !== 'onsite' ? 'hover-class' : ''" (click)="row.type !== 'onsite' && openDrawerDetails(row)">
            {{ row.timeSpent }} mins
          </td>
        </ng-container>
        <!-- Completed Column -->
        <ng-container matColumnDef="completed">
          <th mat-header-cell *matHeaderCellDef class="w25">Completed</th>
          <td mat-cell *matCellDef="let row" class="w25">
            <i [ngClass]="['healent-path-3624', 'icon-style']" style="padding: 30px; color: var(--secondary_shade_50);"></i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-spinner *ngIf="isLoading" class="table-spinner"></mat-spinner>
    </div>
    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons class="fixed-paginator"></mat-paginator>
  </div>
</div>