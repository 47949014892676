<ng-container *ngIf="is_loading">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</ng-container>


<div>
  <mat-list>
    <mat-list-item>Ads</mat-list-item>
    <mat-divider></mat-divider>
  </mat-list>
</div>

<ng-container *ngIf="adgroup_id==''">
  <span>Must select Adgroup Group</span>
</ng-container>



<ng-container *ngIf="client_id && adgroup_id">


  <div [hidden]="is_loading">

    <div style="margin-top: 20px; margin-bottom: 20px;">
      <button mat-fab aria-label="Add Client" (click)="addAd(false, {})" matTooltip="Add Ad"
              matTooltipPosition="right">
        <mat-icon class="add-button">add</mat-icon>
      </button>
    </div>


    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>

    <div ngClass="table-width" ngClass.xs="table-width-xs" ngClass.sm="table-width-sm">

      <mat-card class="mat-elevation-z24">

        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="ad_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ad ID</th>
            <td mat-cell *matCellDef="let row">{{row.ad_id}}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let row">{{row.status}}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let row">{{row.name}}</td>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
            <td mat-cell *matCellDef="let row">{{row.description}}</td>
          </ng-container>


          <!-- View Column -->
<!--          <ng-container matColumnDef="View">-->
<!--            <th mat-header-cell *matHeaderCellDef mat-sort-header>View</th>-->
<!--            <td mat-cell *matCellDef="let row">-->
<!--              <button mat-icon-button class="any-icon-primary table-icon" (click)="viewData(row)">-->
<!--                <mat-icon color="accent">visibility</mat-icon>-->
<!--              </button>-->
<!--            </td>-->
<!--          </ng-container>-->


          <!-- Edit Column (click)="addAccount(true,row.Id)" -->
          <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Edit</th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button class="any-icon-primary table-icon" (click)="addAd(true,  row)">
                <mat-icon color="accent" style="cursor: pointer;">edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Delete Column -->
          <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Delete</th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button class="warn-icon-primary table-icon" (click)="deleteRow(row)">
                <mat-icon color="warn" style="cursor: pointer;">delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"
              (click)="getTableRecord(row)"></tr>
        </table>


        <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100, 250, 500]"
                       [pageSize]="100"
                       showFirstLastButtons
                       aria-label="Select page of periodic elements">
        </mat-paginator>

      </mat-card>
    </div>

  </div>
</ng-container>
