<div class="time-input-container">
  <mat-form-field appearance="outline">
    <input
      type="time"
      matInput
      #timeInputDecorator
      [(ngModel)]="timeInput"
      (keydown)="onTimeKeydown($event)"
      (input)="onManualTimeInput($event)"
      placeholder="HH:MM AM/PM"
      [disabled]="disabled">
    <mat-icon
      matSuffix
      class="time-icon"
      [ngClass]="{'disabled': disabled}"
      (click)="toggleTimePicker($event)"
      style="cursor: pointer;">schedule</mat-icon>
    <mat-error *ngIf="timeError">{{timeError}}</mat-error>
  </mat-form-field>

  <div #timePicker class="time-picker-dropdown" *ngIf="showTimePicker">
    <div class="time-columns">
      <div class="time-column">
        <div *ngFor="let h of hours" [class.selected]="selectedTime.hour === h" (click)="selectHour(h)">
          {{h}}
        </div>
      </div>
      <div class="time-column">
        <div *ngFor="let m of minutes" [class.selected]="selectedTime.minute === m" (click)="selectMinute(m)">
          {{m}}
        </div>
      </div>
      <div class="time-column period">
        <div [class.selected]="selectedTime.period === 'AM'" (click)="selectPeriod('AM')">
          AM
        </div>
        <div [class.selected]="selectedTime.period === 'PM'" (click)="selectPeriod('PM')">
          PM
        </div>
      </div>
    </div>
  </div>
</div>