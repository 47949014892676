import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTooltip } from '@angular/material/tooltip';
import { TableActionsDrawerService } from '../table-actions-drawer/table-actions-drawer.service';

interface Patient {
  client_name: string;
  surgeon_name: string;
  checkin_status: string;
  appointment_time: string;
  appointment_type: string;
  meeting_id: number;
  client_id: number
}

@Component({
  selector: 'app-broadcast',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    MatTooltip
  ],
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.css']
})
export class BroadcastComponent implements OnChanges {
  @Output() closeDrawerEvent = new EventEmitter();
  @ViewChild(MatSort) sort!: MatSort;
  @Input() selectedRecords: any[] = [];
  @Input() title!: { id: string; name: string }; // title of broadcast

  displayedColumns: string[] = ['select', 'client_name', 'surgeon_name', 'checkin_status', 'appointment_time', 'appointment_type'];
  dataSource: MatTableDataSource<Patient>;
  selection = new SelectionModel<Patient>(true, []);

  messageTypes = [
    { id: 'sms', label: 'SMS', checked: true },
    { id: 'email', label: 'Email', checked: false },
    { id: 'notification', label: 'Send as Notification', checked: false },
  ];

  templateButtons = [
    {
      buttonName: 'Upcoming Appt Outreach',
      templateText: `This is Commonwealth Pain & Spine. We have been trying to reach you regarding your upcoming appointment. Please call us at ${localStorage.getItem('locationPhoneNumber') || ''}. Thank you!`
    },
    { buttonName: 'Inclement Weather',
      templateText: 'This is Commonwealth Pain & Spine. Unfortunately, due to inclement weather, our office has closed today. Staff should be in touch with you soon regarding your appointment/medications. We apologize for the inconvenience.'
    },
    { buttonName: 'New Year Insurance Cards',
      templateText: '“Friendly reminder from Commonwealth Pain & Spine. We ask that you bring your MOST CURRENT insurance card with you to your upcoming appointment. Thank you for your cooperation'
    },
    {
      buttonName: 'Mobile App Invitation',
      templateText: `\u{1F44B} Welcome to Commonwealth Pain & Spine! We're thrilled to introduce our new custom app designed to enhance your healthcare experience. \u{1F4F1} Download the app now to access appointment scheduling, treatment plans, medical records, health tips, and more! Your health, organized.\n\nTap the link below to download the app and get started on your health journey.\nhttps://qr2.mobi/1qoo0`
    }
  ];
  newNote: string = '';

  constructor(
    private tableActionsDrawerService: TableActionsDrawerService
  ) {
    this.dataSource = new MatTableDataSource<Patient>([]);
  }

  ngOnChanges() {
    if (this.selectedRecords?.length) {
      // Update the data source with the new selected records
      this.dataSource.data = this.selectedRecords;
      // Update the selection model
      this.selection.clear();
      this.selectedRecords.forEach(record => {
        this.selection.select(record);
      });
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  toggleMessageType(selectedType: string) {
    this.messageTypes = this.messageTypes.map(type => ({
      ...type,
      checked: type.id === selectedType ? !type.checked : type.checked
    }));
  }

  triggerCloseDrawer() {
    this.closeDrawerEvent.emit();
  }

  addTemplate(templateText: string) {
    this.newNote = templateText;
  }

  sendBroadcast() {
    const message = this.newNote;
    const data = {
      message: message,
      client_ids: this.selection.selected.map((record) => record?.client_id),
      medium: this.messageTypes.filter((type) => type.checked).map((type) => type.id)
    }

    // broadcast sending logic
    this.tableActionsDrawerService.sendBroadCast('broadcast', data).subscribe({
      next: (response) => {
      },
      error: (err) => {
      }
    });
  }
}
