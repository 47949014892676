<div class="main-container">
  <div class="main-header">
    <div class="header-left">
      <div class="left-header-progress-container">
        <div>
          <h1>Patient Navigator</h1>
        </div>
        @if (enableProgressBar) {
        <div class="progress-container">
          <div
            class="progress-bar"
            (mouseover)="showTooltip('collections')"
            (mouseleave)="hideTooltip()"
          >
            <div class="progress-label">Collections</div>

            <div class="multi-progress-bar">
              <div
                class="progress-segment collected"
                [style.width]="collectedPercentage + '%'"
              ></div>
              <div
                class="progress-segment balance-due"
                [style.width]="balanceDuePercentage + '%'"
              ></div>
            </div>

            <div
              class="custom-tooltip"
              *ngIf="tooltipVisible === 'collections'"
            >
              <div class="tooltip-content">
                <div>
                  <span class="dot green"></span> Collected:
                  {{ collectedAmount | currency }}
                </div>
                <div>
                  <span class="dot red"></span> Balances due:
                  {{ balancesDue | currency }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="progress-bar"
            (mouseover)="showTooltip('payments')"
            (mouseleave)="hideTooltip()"
          >
            <div class="progress-label">Payment Methods</div>
            <div class="multi-progress-bar">
              <div
                class="progress-segment card"
                [style.width]="cardPercentage + '%'"
              ></div>
              <div
                class="progress-segment cash"
                [style.width]="cashPercentage + '%'"
              ></div>
              <div
                class="progress-segment check"
                [style.width]="checkPercentage + '%'"
              ></div>
            </div>
            <div
              class="custom-tooltip custom-tooltip-position"
              *ngIf="tooltipVisible === 'payments'"
            >
              <div class="tooltip-content">
                <div>
                  <span class="dot green"></span> Card: {{ cash | currency }}
                </div>
                <div>
                  <span class="dot orange"></span> Cash: {{ card | currency }}
                </div>
                <div>
                  <span class="dot red"></span> Check: {{ check | currency }}
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </div>

    <div class="header-right">
      <mat-form-field class="search-input">
        <i class="healent-search" matPrefix></i>
        <input
          matInput
          placeholder="Search patients"
          (keyup)="applyGlobalFilter($event)"
          #input
          [(ngModel)]="searchText"
        />
        <span class="btn-hint" matSuffix *ngIf="!searchText">
          (Shift + Space)
        </span>
        <i
          class="btn-close healent-group-4555"
          matSuffix
          *ngIf="searchText"
          (click)="clearSearch(input)"
        >
        </i>
      </mat-form-field>
      <!-- <button class="broadcast-button" (click)="onBroadCastClick($event, 'header-broadcast')">
        <i [ngClass]="'healent-path-3637'" style="margin-right: 10px;"></i>
        <span>Broadcast</span>
      </button> -->
      <app-custom-location-select
        [locations]="locations"
        [selectedLocation]="selectedLocation"
        (locationChange)="onHospitalLocation($event)"
      >
      </app-custom-location-select>
      <app-custom-datepicker
        [(ngModel)]="currentDate"
        (dateChange)="onDateChange($event, currentDate)"
        style="height: 30px"
      ></app-custom-datepicker>
    </div>
  </div>
  <div class="filter-bar" style="height: 60px" *ngIf="userData$ | async as userData">
    <app-filter-bar
      (filterChanged)="applyFilters($event)"
      (savedFilterApplied)="applySavedFilter($event)"
      [userData]="userData"
      [resetFilters]="resetFilters"
      [dateType]="registrationPhase"
    ></app-filter-bar>
  </div>
  <div class="main-body" [hidden]="is_loading_table" *ngIf="!is_loading_table">
    <mat-accordion [hidden]="is_loading_table" class="tables-container" multi>
      <!--############################################################################################################ -->
      <!--############################################################################################################ -->
      <!--############################################################################################################ -->
      <mat-expansion-panel
        *ngIf="isPreRegisteredEnabled"
        id="pre-registered"
        #panelPreRegistered="matExpansionPanel"
        class="pre-registered panel"
        [expanded]="panelExpanded['pre-registered']"
        (opened)="onPanelToggled('pre-registered')"
        (closed)="onPanelToggled('pre-registered')"
        [ngClass]="{ 'expanded-panel-active': classState.preRegistered }"
      >
        <mat-expansion-panel-header style="pointer-events: none">
          <mat-panel-title> Pre-registered </mat-panel-title>
          <mat-panel-description class="panel-description">
            <span class="bg-primary">
              {{ getDisplayedCount('pre-registered') }}</span
            >
            <ng-container *ngIf="selection_pre_registered.hasValue()">
              <span
                (click)="
                  selectMoveEvent('pre-registered', 'in-progress');
                  $event.stopPropagation()
                "
                matTooltip="Move to in-progress"
                style="pointer-events: auto"
                class="bg-warning"
                [matTooltipClass]="'dashboard-tooltip'"
                *ngIf="showActionButtons"
              >
                Move to "in-progress"
              </span>
              <span
                (click)="
                  selectMoveEvent('pre-registered', 'checked-in');
                  $event.stopPropagation()
                "
                matTooltip="Move to checked-in"
                style="pointer-events: auto"
                class="bg-accent"
                [matTooltipClass]="'dashboard-tooltip'"
                *ngIf="showActionButtons"
              >
                Move to "checked-in"
              </span>
              <span
                *ngIf="
                  selection_pre_registered.hasValue() ||
                  selection_in_progress.hasValue() ||
                  selection_checked_in.hasValue()
                "
                (click)="
                  onBroadCastClick($event, 'pre-registered', 'panel-action')
                "
                style="pointer-events: auto"
                class="bg-black"
              >
                Send a bulk message
              </span>
            </ng-container>
          </mat-panel-description>

          <span
            (click)="toggleDrawer($event, 'pre-registered')"
            matRippleDisabled
            *ngIf="panelPreRegistered.expanded"
            style="pointer-events: auto"
            class="table-btn-icon"
          >
            <i class="healent-gear-fill healent-gear-fill-gray" [matTooltip]="'Manage Columns'" [matTooltipClass]="'dashboard-tooltip'"></i>
          </span>
          <span
            (click)="expandPanel('pre-registered'); $event.stopPropagation()"
            matRippleDisabled
            style="pointer-events: auto"
            class="table-btn-icon"
            [matTooltip]="panelTooltips['pre-registered']"
            [matTooltipClass]="'dashboard-tooltip'"
          >
            <i class="healent-q-1"></i>
          </span>
        </mat-expansion-panel-header>
        <div class="table-content">
          <mat-card class="mat-elevation-z24">
            <div class="table-wrapper">
              <table mat-table [dataSource]="dataSourcePreRegistered" matSort>
                <ng-container matColumnDef="select">
                  <th class="w2" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                      (change)="$event ? toggleAllRows('pre-registered') : null"
                      [checked]="
                        selection_pre_registered.hasValue() &&
                        isAllSelected('pre-registered')
                      "
                      [indeterminate]="
                        selection_pre_registered.hasValue() &&
                        !isAllSelected('pre-registered')
                      "
                      [aria-label]="checkboxLabel('pre-registered')"
                    >
                    </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                      (change)="
                        $event ? selection_pre_registered.toggle(row) : null
                      "
                      [checked]="selection_pre_registered.isSelected(row)"
                      [aria-label]="checkboxLabel('pre-registered', row)"
                    >
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="client_active">
                  <th
                    class="w2"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  ></th>
                  <td class="reg_status-icon" mat-cell *matCellDef="let row">
                    @if(row.client_activated_time_local) {
                    <i
                      [ngClass]="['healent-form-input-icon-26', row.randomColorClass]"
                      [matTooltip]="getClentActivatedTimeToolTip(row)"
                      [matTooltipClass]="'dashboard-tooltip'"
                    ></i>
                    }
                  </td>
                </ng-container>

                <ng-container matColumnDef="client_name">
                  <th
                    class="w20"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Patient Name
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <a
                      class="client-name-link"
                      (click)="openLink(row.old_patient_profile, row, $event, 'patientInfo')"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ row.client_name || '-' }}
                    </a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="alerts">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Alerts
                  </th>

                  <td class="reg_status-icon" mat-cell *matCellDef="let row">
                    @if(row.alertIcons.length > 0) {
                    <i
                      *ngFor="let icon of row.alertIcons"
                      [matTooltip]="getRegStatusTooltip(icon)"
                      [ngClass]="icon"
                      [matTooltipClass]="'dashboard-tooltip'"
                    ></i>
                    } @else {
                    {{ '-' }}
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="date_of_birth">
                  <th
                    class="w10"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    DOB
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ (row.date_of_birth | date: 'MMM dd, yyyy') || '-' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="chart_number">
                  <th
                    class="w3"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Chart #
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <a
                      class="client-name-link"
                      (click)="openLink(row.athena_profile_link, row, $event, 'patientInfo')"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ row.chart_number || '-' }}
                    </a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="surgeon_name">
                  <th
                    class="w30"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Provider
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row?.surgeon_name || '-' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="appointment_type">
                  <th
                    class="w10"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Appt Type
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.appointment_type || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="appointment_unixts">
                  <th
                    class="w10"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Appt Time
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.appointment_time || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="insurance_name">
                  <th
                    class="w15"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Insurance
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.insurance_name || '-' }}
                  </td>
                </ng-container>
                <!-- <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Pre</th>
                  <td class="reg_status-icon" mat-cell *matCellDef="let row">
                    <i
                      [matTooltip]="
                        getRegStatusTooltip(row?.alerts?.form_status || '')
                      "
                      [ngClass]="
                        getRegStatusIcon(row?.alerts?.form_status || '')
                      "
                      [matTooltipClass]="'dashboard-tooltip'"
                    ></i>
                  </td>
                </ng-container> -->

                <ng-container matColumnDef="Delete">
                  <th
                    class="w5"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Actions
                  </th>
                  <td class="reg_status-icon" mat-cell *matCellDef="let row">
                    <i
                      [matTooltip]="
                        getRegStatusTooltip(row?.alerts?.form_status || '')
                      "
                      [ngClass]="
                        getRegStatusIcon(row?.alerts?.form_status || '')
                      "
                      [matTooltipClass]="'dashboard-tooltip'"
                      style="padding: 3px"
                      (click)="handleStatusClick(row, $event, 'pre-registered')"

                    ></i>
                    <i
                      [matTooltip]="getTooltipState(row)"
                      [ngClass]="getIconState(row)"
                      (click)="toggleIconState(row)"
                      style="padding: 3px"
                      [matTooltipClass]="'dashboard-tooltip'"
                    >
                    </i>
                    <i
                      *ngFor="let action of actionsList.slice(1)"
                      [matTooltip]="getRegStatusTooltip(action)"
                      [matTooltipClass]="'dashboard-tooltip'"
                      [ngClass]="[
                        action,
                        action === 'healent-path-3627' &&
                        row.alerts.patient_notes.patient_notes_count > 0
                          ? 'icon-patient-count'
                          : '',
                        action === 'healent-path-3627' &&
                        row.alerts.meeting_notes.meeting_notes_count > 0
                          ? 'icon-visit-count'
                          : ''
                      ]"
                      style="padding: 3px"
                      (click)="
                        onActionsClick($event, action, row, 'pre-registered')
                      "
                    >
                    </i>
                    <!-- <mat-form-field>
                      <mat-select
                        placeholder="Move to"
                        (selectionChange)="
                          onMoveChange('pre-registered', row, $event)
                        "
                      >
                        <mat-option
                          *ngFor="let client of move_select_PreRegistered_list"
                          [value]="client.value"
                        >
                          {{ client.viewValue }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field> -->
                  </td>
                </ng-container>
                <ng-container matColumnDef="copay">
                  <th
                    class="w5"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    CoPay
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row?.payment_details?.copay || '-' }}
                  </td>
                </ng-container>

                <!--                <ng-container matColumnDef="paid">-->
                <!--                  <th-->
                <!--                    class="w5"-->
                <!--                    mat-header-cell-->
                <!--                    *matHeaderCellDef-->
                <!--                    mat-sort-header-->
                <!--                  >-->
                <!--                    Paid-->
                <!--                  </th>-->
                <!--                  <td mat-cell *matCellDef="let row">-->
                <!--                    {{row?.paid || '-'}}-->
                <!--                  </td>-->
                <!--                </ng-container>-->

                <ng-container matColumnDef="balance">
                  <th
                    class="w5"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Balance
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row?.balance || '-' }}
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsPreRegistered"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsPreRegistered"
                  class="element-row"
                  [style.display]="row.mask === 0 ? 'none' : ''"
                  (click)="getTableRecord(row)"
                  [ngClass]="{ 'marked-row': row.marked }"
                ></tr>
              </table>
            </div>
            <div
              *ngIf="dataSourcePreRegistered?.data?.length === 0"
              class="mat-row no-entries"
              style="text-align: center"
            >
              <span class="mat-cell">No entries</span>
            </div>
          </mat-card>
        </div>
      </mat-expansion-panel>

      <!--############################################################################################################ -->
      <!--############################################################################################################ -->
      <!--############################################################################################################ -->

      <mat-expansion-panel
        id="in-progress"
        *ngIf="isInProgressEnabled"
        #panelInprogress="matExpansionPanel"
        class="in-progress panel"
        [expanded]="panelExpanded['in-progress']"
        (opened)="onPanelToggled('in-progress')"
        (closed)="onPanelToggled('in-progress')"
        [ngClass]="{ 'expanded-panel-active': classState.inProgress }"
      >
        <mat-expansion-panel-header style="pointer-events: none">
          <mat-panel-title> In-progress </mat-panel-title>
          <mat-panel-description class="panel-description">
            <span class="bg-warning">
              {{ getDisplayedCount('in-progress') }}</span
            >
            <ng-container *ngIf="selection_in_progress.hasValue()">
              <span
                (click)="
                  selectMoveEvent('in-progress', 'pre-registered');
                  $event.stopPropagation()
                "
                matTooltip="Move to pre-registered"
                [matTooltipClass]="'dashboard-tooltip'"
                style="pointer-events: auto"
                class="bg-primary"
              >
                Move to "pre-registered"
              </span>
              <span
                (click)="
                  selectMoveEvent('in-progress', 'checked-in');
                  $event.stopPropagation()
                "
                matTooltip="Move to checked-in"
                style="pointer-events: auto"
                class="bg-accent"
                [matTooltipClass]="'dashboard-tooltip'"
              >
                Move to "checked-in"
              </span>
              <span
                *ngIf="
                  selection_pre_registered.hasValue() ||
                  selection_in_progress.hasValue() ||
                  selection_checked_in.hasValue()
                "
                (click)="
                  onBroadCastClick($event, 'in-progress', 'panel-action')
                "
                style="pointer-events: auto"
                class="bg-black"
              >
                Send a bulk message
              </span>
            </ng-container>
          </mat-panel-description>
          <span
            (click)="toggleDrawer($event, 'in-progress')"
            matRippleDisabled
            *ngIf="panelInprogress.expanded"
            style="pointer-events: auto"
            class="table-btn-icon"
          >
            <i class="healent-gear-fill healent-gear-fill-gray" [matTooltip]="'Manage Columns'" [matTooltipClass]="'dashboard-tooltip'"></i>
          </span>
          <span
            (click)="expandPanel('in-progress'); $event.stopPropagation()"
            matRippleDisabled
            style="pointer-events: auto"
            class="table-btn-icon"
            [matTooltip]="panelTooltips['in-progress']"
            [matTooltipClass]="'dashboard-tooltip'"
          >
            <i class="healent-q-1"></i>
          </span>
        </mat-expansion-panel-header>

        <div class="table-content">
          <mat-card class="mat-elevation-z24">
            <div class="table-wrapper">
              <table mat-table [dataSource]="dataSourceInProgress" matSort>
                <ng-container matColumnDef="select">
                  <th class="w2" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                      (change)="$event ? toggleAllRows('in-progress') : null"
                      [checked]="
                        selection_in_progress.hasValue() &&
                        isAllSelected('in-progress')
                      "
                      [indeterminate]="
                        selection_in_progress.hasValue() &&
                        !isAllSelected('in-progress')
                      "
                      [aria-label]="checkboxLabel('in-progress')"
                    >
                    </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                      (change)="
                        $event ? selection_in_progress.toggle(row) : null
                      "
                      [checked]="selection_in_progress.isSelected(row)"
                      [aria-label]="checkboxLabel(row)"
                    >
                    </mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="client_active">
                  <th
                    class="w2"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  ></th>
                  <td class="reg_status-icon" mat-cell *matCellDef="let row">
                    @if(row.client_activated_time_local) {
                    <i
                      [ngClass]="['healent-form-input-icon-26', row.randomColorClass]"
                      [matTooltip]="getClentActivatedTimeToolTip(row)"
                      [matTooltipClass]="'dashboard-tooltip'"
                    ></i>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="client_name">
                  <th
                    class="w20"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Patient Name
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <a
                      class="client-name-link"
                      (click)="openLink(row.old_patient_profile, row, $event, 'patientInfo')"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ row.client_name || '-' }}
                    </a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="alerts">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Alerts
                  </th>

                  <td class="reg_status-icon" mat-cell *matCellDef="let row">
                    @if(row.alertIcons.length > 0) {
                    <i
                      *ngFor="let icon of row.alertIcons"
                      [matTooltip]="getRegStatusTooltip(icon)"
                      [ngClass]="icon"
                      [matTooltipClass]="'dashboard-tooltip'"
                    ></i>
                    } @else {
                    {{ '-' }}
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="date_of_birth">
                  <th
                    class="w10"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    DOB
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ (row.date_of_birth | date: 'MMM dd, yyyy') || '-' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="chart_number">
                  <th
                    class="w3"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Chart #
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <a
                      class="client-name-link"
                      (click)="openLink(row.athena_profile_link, row, $event, 'patientInfo')"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ row.chart_number || '-' }}
                    </a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="surgeon_name">
                  <th
                    class="w30"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Provider
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row?.surgeon_name || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="appointment_type">
                  <th
                    class="w10"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Appt Type
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.appointment_type || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="appointment_unixts">
                  <th
                    class="w10"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Appt Time
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.appointment_time || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="insurance_name">
                  <th
                    class="w15"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Insurance
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.insurance_name || '-' }}
                  </td>
                </ng-container>

                <!-- <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Pre</th>
                  <td class="reg_status-icon" mat-cell *matCellDef="let row">
                    <i
                      [ngClass]="getRegStatusIcon(row?.alerts?.form_status)"
                      [matTooltip]="
                        getRegStatusTooltip(row?.alerts?.form_status)
                      "
                      [matTooltipClass]="'dashboard-tooltip'"
                    ></i>
                  </td>
                </ng-container> -->
                <ng-container matColumnDef="Delete">
                  <th
                    class="w5"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Actions
                  </th>
                  <td class="reg_status-icon" mat-cell *matCellDef="let row">
                    <i
                      [matTooltip]="
                        getRegStatusTooltip(row?.alerts?.form_status || '')
                      "
                      [ngClass]="
                        getRegStatusIcon(row?.alerts?.form_status || '')
                      "
                      [matTooltipClass]="'dashboard-tooltip'"
                      style="padding: 3px"
                      (click)="handleStatusClick(row, $event, 'in-progress')"
                    ></i>
                    <i
                      [matTooltip]="getTooltipState(row)"
                      [ngClass]="getIconState(row)"
                      (click)="toggleIconState(row)"
                      style="padding: 3px"
                      [matTooltipClass]="'dashboard-tooltip'"
                    >
                    </i>
                    <i
                      *ngFor="let action of actionsList.slice(1)"
                      [matTooltip]="getRegStatusTooltip(action)"
                      [ngClass]="[
                        action,
                        action === 'healent-path-3627' &&
                        row.alerts.patient_notes.patient_notes_count > 0
                          ? 'icon-patient-count'
                          : '',
                        action === 'healent-path-3627' &&
                        row.alerts.meeting_notes.meeting_notes_count > 0
                          ? 'icon-visit-count'
                          : ''
                      ]"
                      style="padding: 3px"
                      (click)="
                        onActionsClick($event, action, row, 'in-progress')
                      "
                    ></i>
                    <!-- <mat-form-field>
                      <mat-select
                        placeholder="Move to"
                        (selectionChange)="
                          onMoveChange('in-progress', row, $event)
                        "
                      >
                        <mat-option
                          *ngFor="let client of move_select_InProgress_list"
                          [value]="client.value"
                        >
                          {{ client.viewValue }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field> -->
                  </td>
                </ng-container>
                <ng-container matColumnDef="copay">
                  <th
                    class="w5"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    CoPay
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row?.payment_details?.copay || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="paid">
                  <th
                    class="w5"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Paid
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row?.paid || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="balance">
                  <th
                    class="w5"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Balance
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row?.balance || '-' }}
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsInProgress"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsInProgress"
                  class="element-row"
                  [style.display]="row.mask === 0 ? 'none' : ''"
                  (click)="getTableRecord(row)"
                  [ngClass]="{ 'marked-row': row.marked }"
                ></tr>
              </table>
            </div>
            <div
              *ngIf="dataSourceInProgress?.data?.length === 0"
              class="mat-row no-entries"
              style="text-align: center"
            >
              <span class="mat-cell">No entries</span>
            </div>
          </mat-card>
        </div>
      </mat-expansion-panel>

      <!--############################################################################################################ -->
      <!--############################################################################################################ -->
      <!--############################################################################################################ -->

      <mat-expansion-panel
        id="checked-in"
        *ngIf="isCheckedInEnabled"
        #panelcheckedIn="matExpansionPanel"
        class="checked-in panel"
        [expanded]="panelExpanded['checked-in']"
        (opened)="onPanelToggled('checked-in')"
        (closed)="onPanelToggled('checked-in')"
        [ngClass]="{ 'expanded-panel-active': classState.checkedIn }"
      >
        <mat-expansion-panel-header style="pointer-events: none">
          <mat-panel-title> Checked-in </mat-panel-title>
          <mat-panel-description class="panel-description">
            <span class="bg-accent">
              {{ getDisplayedCount('checked-in') }}</span
            >
            <ng-container *ngIf="selection_checked_in.hasValue()">
              <span
                (click)="
                  selectMoveEvent('checked-in', 'pre-registered');
                  $event.stopPropagation()
                "
                matTooltip="Move to pre-registered"
                style="pointer-events: auto"
                class="bg-primary"
                [matTooltipClass]="'dashboard-tooltip'"
                *ngIf="showActionButtons"
              >
                Move to "pre-registered"
              </span>
              <span
                (click)="
                  selectMoveEvent('checked-in', 'in-progress');
                  $event.stopPropagation()
                "
                matTooltip="Move to in-progress"
                style="pointer-events: auto"
                class="bg-warning"
                [matTooltipClass]="'dashboard-tooltip'"
                *ngIf="showActionButtons"
              >
                Move to "in-progress"
              </span>
              <span
                *ngIf="
                  selection_pre_registered.hasValue() ||
                  selection_in_progress.hasValue() ||
                  selection_checked_in.hasValue()
                "
                (click)="onBroadCastClick($event, 'checked-in', 'panel-action')"
                style="pointer-events: auto"
                class="bg-black"
              >
                Send a bulk message
              </span>
            </ng-container>
          </mat-panel-description>
          <span
            (click)="toggleDrawer($event, 'checked-in')"
            matRippleDisabled
            *ngIf="panelcheckedIn.expanded"
            style="pointer-events: auto"
            class="table-btn-icon"
          >
            <i class="healent-gear-fill healent-gear-fill-gray" [matTooltip]="'Manage Columns'" [matTooltipClass]="'dashboard-tooltip'"></i>
          </span>
          <span
            (click)="expandPanel('checked-in'); $event.stopPropagation()"
            matRippleDisabled
            style="pointer-events: auto"
            class="table-btn-icon"
            [matTooltip]="panelTooltips['checked-in']"
            [matTooltipClass]="'dashboard-tooltip'"
          >
            <i class="healent-q-1"></i>
          </span>
        </mat-expansion-panel-header>
        <div class="table-content">
          <mat-card class="mat-elevation-z24">
            <div class="table-wrapper">
              <table mat-table [dataSource]="dataSourceCheckedIn" matSort>
                <ng-container matColumnDef="select">
                  <th class="w2" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                      (change)="$event ? toggleAllRows('checked-in') : null"
                      [checked]="
                        selection_checked_in.hasValue() &&
                        isAllSelected('checked-in')
                      "
                      [indeterminate]="
                        selection_checked_in.hasValue() &&
                        !isAllSelected('checked-in')
                      "
                      [aria-label]="checkboxLabel('checked-in')"
                    >
                    </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                      (change)="
                        $event ? selection_checked_in.toggle(row) : null
                      "
                      [checked]="selection_checked_in.isSelected(row)"
                      [aria-label]="checkboxLabel(row)"
                    >
                    </mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="client_active">
                  <th
                    class="w2"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  ></th>
                  <td class="reg_status-icon" mat-cell *matCellDef="let row">
                    @if(row.client_activated_time_local) {
                    <i
                      [ngClass]="['healent-form-input-icon-26', row.randomColorClass]"
                      [matTooltip]="getClentActivatedTimeToolTip(row)"
                      [matTooltipClass]="'dashboard-tooltip'"
                    ></i>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="client_name">
                  <th
                    class="w20"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Patient Name
                  </th>
                  <td class="w20" mat-cell *matCellDef="let row">
                    <a
                      class="client-name-link"
                      (click)="openLink(row.old_patient_profile, row, $event, 'patientInfo')"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ row.client_name || '-' }}
                    </a>
                  </td>
                </ng-container>

                <ng-container matColumnDef="alerts">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Alerts
                  </th>

                  <td class="reg_status-icon" mat-cell *matCellDef="let row">
                    @if(row.alertIcons.length > 0) {
                    <i
                      *ngFor="let icon of row.alertIcons"
                      [matTooltip]="getRegStatusTooltip(icon)"
                      [ngClass]="icon"
                      [matTooltipClass]="'dashboard-tooltip'"
                    ></i>
                    } @else {
                    {{ '-' }}
                    }
                  </td>
                </ng-container>

                <ng-container matColumnDef="date_of_birth">
                  <th
                    class="w10"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    DOB
                  </th>
                  <td class="w10" mat-cell *matCellDef="let row">
                    {{ (row.date_of_birth | date: 'MMM dd, yyyy') || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="chart_number">
                  <th
                    class="w3"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Chart #
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <a
                      class="client-name-link"
                      (click)="openLink(row.athena_profile_link, row, $event, 'patientInfo')"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ row.chart_number || '-' }}
                    </a>
                  </td>
                </ng-container>

                <ng-container matColumnDef="surgeon_name">
                  <th
                    class="w30"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Provider
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row?.surgeon_name || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="appointment_type">
                  <th
                    class="w10"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Appt Type
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.appointment_type || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="appointment_unixts">
                  <th
                    class="w10"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Appt Time
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.appointment_time || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="insurance_name">
                  <th
                    class="w15"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Insurance
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.insurance_name || '-' }}
                  </td>
                </ng-container>

                <!-- <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Pre</th>
                  <td class="reg_status-icon" mat-cell *matCellDef="let row">
                    <i
                      [ngClass]="
                        getRegStatusIcon(row?.alerts?.form_status || '')
                      "
                      [matTooltip]="
                        getRegStatusTooltip(row?.alerts?.form_status || '')
                      "
                      [matTooltipClass]="'dashboard-tooltip'"
                    ></i>
                  </td>
                </ng-container> -->

                <ng-container matColumnDef="Delete">
                  <th
                    class="w5"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Actions
                  </th>
                  <td class="reg_status-icon" mat-cell *matCellDef="let row">
                    <i
                      [matTooltip]="
                        getRegStatusTooltip(row?.alerts?.form_status || '')
                      "
                      [ngClass]="
                        getRegStatusIcon(row?.alerts?.form_status || '')
                      "
                      [matTooltipClass]="'dashboard-tooltip'"
                      style="padding: 3px"
                      (click)="handleStatusClick(row, $event, 'checked-in')"
                    ></i>
                    <i
                      [matTooltip]="getTooltipState(row)"
                      [ngClass]="getIconState(row)"
                      (click)="toggleIconState(row)"
                      style="padding: 3px"
                      [matTooltipClass]="'dashboard-tooltip'"
                    >
                    </i>
                    <i
                      *ngFor="let action of actionsList.slice(1)"
                      [matTooltip]="getRegStatusTooltip(action)"
                      [matTooltipClass]="'dashboard-tooltip'"
                      [ngClass]="[
                        action,
                        action === 'healent-path-3627' &&
                        row.alerts.patient_notes.patient_notes_count > 0
                          ? 'icon-patient-count'
                          : '',
                        action === 'healent-path-3627' &&
                        row.alerts.meeting_notes.meeting_notes_count > 0
                          ? 'icon-visit-count'
                          : ''
                      ]"
                      style="padding: 3px"
                      (click)="
                        onActionsClick($event, action, row, 'checked-in')
                      "
                    ></i>
                    <!-- <mat-form-field>
                      <mat-select
                        placeholder="Move to"
                        (selectionChange)="
                          onMoveChange('checked-in', row, $event)
                        "
                      >
                        <mat-option
                          *ngFor="let client of move_select_CheckedIn_list"
                          [value]="client.value"
                        >
                          {{ client.viewValue }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field> -->
                  </td>
                </ng-container>
                <ng-container matColumnDef="copay">
                  <th
                    class="w5"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    CoPay
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row?.payment_details?.copay || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="paid">
                  <th
                    class="w5"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Paid
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row?.paid || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="balance">
                  <th
                    class="w5"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Balance
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row?.balance || '-' }}
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsCheckedIn"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsCheckedIn"
                  class="element-row"
                  [style.display]="row.mask === 0 ? 'none' : ''"
                  (click)="getTableRecord(row)"
                  [ngClass]="{ 'marked-row': row.marked }"
                ></tr>
              </table>
            </div>
            <div
              *ngIf="dataSourceCheckedIn?.data?.length === 0"
              class="mat-row no-entries"
              style="text-align: center"
            >
              <span class="mat-cell">No entries</span>
            </div>
          </mat-card>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <ng-container *ngIf="is_loading_table">
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  </ng-container>
  <!-- <ng-container>
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  </ng-container> -->
</div>

<!-- Drawer for managing columns for pre-registered -->
<app-table-settings-drawer
  #tableSettingsDrawerPreRegistered
  [columns]="allColumnsPreRegistered"
  (columnToggled)="toggleColumnVisibilityPreRegistered($event)"
  (dropped)="dropPreRegistered($event)"
></app-table-settings-drawer>

<!-- Drawer for managing columns for in-progress -->
<app-table-settings-drawer
  #tableSettingsDrawerInProgress
  [columns]="allColumnsInProgress"
  (columnToggled)="toggleColumnVisibilityInProgress($event)"
  (dropped)="dropInProgress($event)"
></app-table-settings-drawer>

<!-- Drawer for managing columns for checked-in -->
<app-table-settings-drawer
  #tableSettingsDrawerCheckedIn
  [columns]="allColumnsCheckedIn"
  (columnToggled)="toggleColumnVisibilityCheckedIn($event)"
  (dropped)="dropCheckedIn($event)"
></app-table-settings-drawer>

<app-table-actions-drawer
  #actionsDrawer
  [rowData]="selectedRow"
  [action]="selectedAction"
  (cancelAppt)="cancelAppointment($event)"
  [missedSections]="missed_sections"
  (triggerRefresh)="triggerDataRefresh($event)"
  (markRowEvent)="markRowEvent($event)"
  [tableType]="tableType"
  [rawRow]="rawRow"
  [selectedRecords]="selectedRecords"
  [title]="title"
  [timezone_name]="timezone_name"
  [isFuturePastDate]="showActionButtons"
  [registrationPhase]="registrationPhase"
></app-table-actions-drawer>
