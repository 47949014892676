<div class="patient-info-container">
  <div class="patient-header">
    <div class="info-header">Patient Info</div>
    <div class="actions">
      <button class="icon-button">
        <i [ngClass]="'healent-document'"></i>
      </button>
      <button class="icon-button">
        <i [ngClass]="'healent-edit'"></i>
      </button>
    </div>
  </div>

  <div class="patient-profile">
    <img [src]="'assets/profile-placeholder.jpg'" alt="Patient photo" class="profile-image">
    <div class="patient-name">
      <h3>{{ patientData.client_name || '-' }}</h3>
      <p class="last-update">Last update: {{ patientData.deactivated_at || '&lt;No Data&gt;' }}</p>
    </div>
  </div>

  <div class="info-flex">
    <div class="info-row">
      <div class="info-item">
          <label>Patient ID</label>
          <p>#{{ patientData.client_id || '-' }}</p>
      </div>
      <div class="info-item">
          <label>Hospital Name</label>
          <p>{{ patientData.hospital_name || '-' }}</p>
      </div>
      <div class="info-item">
          <label>Location Name</label>
          <p>{{ patientData.location_name || '-' }}</p>
      </div>
  </div>
    <div class="info-row">
        <div class="info-item">
            <label>Date Of Birth</label>
            <p>{{ patientData.date_of_birth || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Phone No</label>
            <p>{{ patientData.phone || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Email</label>
            <p>{{ patientData.email || '-' }}</p>
        </div>
    </div>
    <div class="info-row">
        <div class="info-item">
            <label>BMI</label>
            <p>{{ patientData.bmi || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Weight</label>
            <p>{{ patientData.weight || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Height</label>
            <p>{{ patientData.height || '-' }}</p>
        </div>
    </div>
    <div class="info-row">
        <div class="info-item">
            <label>Facility Provider</label>
            <p>{{ patientData.surgeon_name || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Billing Provider</label>
            <p>{{ patientData.billing_physician_name || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Assigned care provider</label>
            <p>{{ patientData.assigned_nurse_name || '-' }}</p>
        </div>
    </div>
    <div class="info-row">
        <div class="info-item">
            <label>Confirmed At</label>
            <p>{{ patientData.confirmed_at || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Last In App Activity</label>
            <p>{{ patientData.last_login_activity || '-' }}</p>
        </div>
        <div class="info-item">
          <label>Date Seen For Optimization</label>
          <p>{{ patientData.date_seen_for_optimization || '-' }}</p>
      </div>
    </div>
    <div class="info-row">
        <div class="info-item">
            <label>A1C</label>
            <p>{{ patientData.a1c || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Medical Record Number</label>
            <p>{{ patientData.mrn || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Monitoring  Programm</label>
            <p>{{ patientData.monitoring_program || '-' }}</p>
        </div>

    </div>
    <div class="info-row">
        <div class="info-item">
            <label>Surgery Date</label>
            <p>{{ patientData.surgery_date || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Care Protocol</label>
            <p>{{ patientData.care_protocol_name || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Date For Return Visit</label>
            <p>{{ patientData.plan_end_date || '-' }}</p>
        </div>
    </div>
    <div class="info-row">
        <div class="info-item">
            <label>First Appointment At</label>
            <p>{{ patientData.first_meeting_time || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Last Appointment At</label>
            <p>{{ patientData.last_meeting_time || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Total Appointments</label>
            <p>{{ patientData.total_meetings || '-' }}</p>
        </div>
    </div>
    <div class="info-row">
        <div class="info-item">
            <label>Activated At</label>
            <p>{{ patientData.activated_at || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Deactivated At</label>
            <p>{{ patientData.deactivated_at || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Plan Start Date</label>
            <p>{{ patientData.plan_start_date || '-' }}</p>
        </div>
    </div>
    <div class="info-row">
        <div class="info-item">
            <label>Plan End Date</label>
            <p>{{ patientData.plan_end_date || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Primary Device</label>
            <p>{{ patientData.primary_device || '-' }}</p>
        </div>
        <div class="info-item">
            <label>Mobile Type</label>
            <p>{{ patientData.mobile_type || '-' }}</p>
        </div>
    </div>
</div>

  <div class="additional-info">
    <div class="info-item info-item-bottom-border" *ngIf="patientData.insuranceInfo">
      <label>Insurance Info</label>
      <p>{{ patientData.insuranceInfo || '-' }}</p>
    </div>
    <div class="info-item info-item-bottom-border" *ngIf="patientData.diagnosis">
      <label>Diagnosis</label>
      <p>{{ patientData.diagnosis || '-' }}</p>
    </div>
    <div class="info-item" *ngIf="patientData.outstandingIssues">
      <label>Outstanding Issues Or Concerns</label>
      <p>{{ patientData.outstandingIssues || '-' }}</p>
    </div>
    <div class="info-item" *ngIf="patientData.outstandingIssues">
      <label>What Is Needed For "Clearance - Cards, Id, Vascular, Pulmonary"</label>
      <p>{{ patientData.outstandingIssues || '-' }}</p>
    </div>
    <div class="info-item" *ngIf="patientData.outstandingIssues">
      <label>Outstanding issues to follow-up on</label>
      <p>{{ patientData.outstandingIssues || '-' }}</p>
    </div>
    <div class="info-item" *ngIf="patientData.outstandingIssues">
      <label>Where they stand</label>
      <p>{{ patientData.outstandingIssues || '-' }}</p>
    </div>
    <div class="info-item" *ngIf="patientData.outstandingIssues">
      <label>Repeat Check In</label>
      <p>{{ patientData.outstandingIssues || '-' }}</p>
    </div>
  </div>
</div>
