<ng-container *ngIf="is_logged_in">
  <app-main-nav>
    <router-outlet></router-outlet>
  </app-main-nav>
</ng-container>

<ng-container *ngIf="!is_logged_in">
  <mat-toolbar color="accent"><span class="ref"></span></mat-toolbar>
  <router-outlet></router-outlet>
</ng-container>
