import {Injectable} from "@angular/core";
import {environment} from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import {of, map, Observable, delay} from "rxjs";
import {shareReplay} from "rxjs/operators";



export interface Message {
  "name": string;
  "message": string;
  "client_id": number;
  "date": string;
}

export interface Messages {
  alert:[]
}

export interface MessagesResponse {
  message: Message[];
}





@Injectable({providedIn: 'root'})
export class MessagesService {

  url:string = `${environment.API_URL}`


  constructor(private http: HttpClient,) {
  }

  getMessageByUser(endpoint: string, user_id: number, is_mocked: boolean = false ): Observable<Message[]> {

    if(is_mocked){
      return of<Message[]>(
        [
          {
            "name": "Dude1",
            "message": "wow my dude",
            "client_id": 2247,
            "date": "2023-02-27T19:46:36",
          },
          {
            "name": "Dude2",
            "message": "wow my dude",
            "client_id": 2247,
            "date": "2023-02-27T19:46:36",
          },
          {
            "name": "Dude2",
            "message": "wow my dude",
            "client_id": 2247,
            "date": "2023-02-27T19:46:36",
          }
          ]
      ).pipe(delay(2000))
    }

    const url = `${this.url}/${endpoint}/${user_id}`;
    return this.http.get<MessagesResponse>(url).pipe(
      shareReplay(),
      map(
        res => {
          return res.message;
        }
      )
    );
  } //end getAlerts



} //
