<mat-form-field class="location-select-container">
  <i class="select-icon healent-form-input-icon-24"></i>
  <input #locationInput type="text"
         placeholder="Select Location"
         matInput
         [(ngModel)]="searchText"
         (click)="openAutocompletePanelOnClick()"
         (blur)="onBlur()"
         (input)="filterLocations()"
         [matAutocomplete]="auto"
         (focus)="onInputFocus()"
         >
         @if (searchText && showClearIcon()) {
            <i class="select-icon healent-form-input-icon-11" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchText()"></i>
         }
  <mat-autocomplete class="auto-complete" #auto="matAutocomplete" (optionSelected)="onLocationChange($event)" [displayWith]="displayNameFunc">
    <mat-option *ngFor="let location of filteredLocations" [value]="location">
      {{ location.location_name }}
    </mat-option>
    <mat-option *ngIf="filteredLocations.length === 0" disabled>
      No locations found
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

