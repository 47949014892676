import { Component, DestroyRef, inject, NgZone, Renderer2, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { MainNavComponent } from "./main-nav/main-nav.component";
import {
  CurrentLoggedInBehaviorService,
  CurrentRefreshTokenService,
  LoadingService,
  SnackBarService,
  SuperAdminViewBehaviorService
} from "./objectPassingService";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { environment } from "../environments/environment";
import { interval, retry, Subject, takeUntil } from "rxjs";
import { LoginService } from "./auth/login/login.service";
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinner, MatProgressSpinnerModule } from "@angular/material/progress-spinner"

declare global {
  interface Window {
    clarity: (event: string, ...args: any[]) => void;
  }
}


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MainNavComponent, MatToolbarModule, MatSnackBarModule
    , MatDatepickerModule, MatButtonModule, MatIconModule, MatMenuModule, MatProgressSpinnerModule ],
  providers: [MatDatepickerModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  destroyed_interval = new Subject();
  destroyed_refresh = new Subject();

  private breakpointObserver = inject(BreakpointObserver);
  this_breakpoint: string = 'Small';

  is_logged_in = false;
  refresh_token_service: any;
  superadmin_view = false;

  constructor(
    private currentLoggedInBehaviorService: CurrentLoggedInBehaviorService,
    private currentRefreshTokenService: CurrentRefreshTokenService,
    private superAdminViewBehaviorService: SuperAdminViewBehaviorService,
    private snackBarService: SnackBarService,
    public snackBar: MatSnackBar,
    private loginService: LoginService,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private router: Router,
    public loadingService: LoadingService
  ) {
    //[ngClass]="this_breakpoint=='Small' ? 'small' : 'large'"
    this.breakpointObserver
      .observe([Breakpoints.XSmall,
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape,
      Breakpoints.Tablet,
      Breakpoints.TabletPortrait,
      Breakpoints.TabletLandscape,
      Breakpoints.Web,
      Breakpoints.WebPortrait,
      Breakpoints.WebLandscape,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge]) // or '(min-width: 900px)'
      .pipe(takeUntilDestroyed())
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints[Breakpoints.XSmall]) {
          console.log('Matches XSmall viewport');
          this.this_breakpoint = 'XSmall';
        }
        else if (state.breakpoints[Breakpoints.Small]) {
          this.this_breakpoint = 'Small';
          console.log('Matches Small viewport');
        }
        else if (state.breakpoints[Breakpoints.Medium]) {
          this.this_breakpoint = 'Medium';
          console.log('Matches Medium  viewport');
        }
        else if (state.breakpoints[Breakpoints.Large]) {
          this.this_breakpoint = 'Large';
          console.log('Matches Large viewport');
        }
        else if (state.breakpoints[Breakpoints.XLarge]) {
          this.this_breakpoint = 'XLarge';
          console.log('Matches XLarge viewport');
        }
        //this.resizeService.announceResize(this.this_breakpoint);
      });

    this.superAdminViewBehaviorService.currentSuperAdminViewBehaviorAnnounced$
      .pipe(takeUntilDestroyed())
      .subscribe(superadmin_view => {
        this.superadmin_view = superadmin_view
      })// end superAdminViewBehaviorService
    console.log("superadmin_view", this.superadmin_view)
    this.snackBarService.snackbarAnnounced$
      .pipe(takeUntilDestroyed())
      .subscribe(
        message => {
          this.openSnackBar(message, 'Dismiss');
        }); //end role_sub

    this.currentLoggedInBehaviorService.currentLoggedInBehaviorAnnounced$
      .pipe(takeUntilDestroyed())
      .subscribe(
        (is_logged_in: boolean) => {
          console.log('AppComponent currentLoggedInBehaviorAnnounced$', is_logged_in)
          this.is_logged_in = is_logged_in;
        });

    // this is started when the user logs in
    this.refresh_token_service = this.currentRefreshTokenService.currentRefreshTokenAnnounced$
      .pipe(takeUntilDestroyed())
      .subscribe(
        x => {
          if (x === true) {
            console.log('AppComponent currentRefreshTokenAnnounced$', x)
            this.pollingTokenRefreshService();
          } else {
            this.destroyed_interval.next('');
            this.destroyed_interval.complete();
            this.destroyed_refresh.next('');
            this.destroyed_refresh.complete();
          }
        });  //end refresh_token_service

  } //end constructor

  ngOnInit(): void {


    this.loginService.isLoggedin()
    this.destroyRef.onDestroy(() => {
      this.destroyed_interval.next('');
      this.destroyed_interval.complete();
      this.destroyed_refresh.next('');
      this.destroyed_refresh.complete();
    });

    // this handles the polling of the refresh token on reload
    let refresh_token = localStorage.getItem('refresh_token');
    let email = localStorage.getItem('email');
    const pageValue = window.location.hash.substring(1);
    if (refresh_token) {
      this.currentRefreshTokenService.announceRefreshToken(true);
      //this.pollingTokenRefreshService();
    }
    if (window.clarity && typeof window.clarity === "function") {
      window.clarity("identify", String(email), String(refresh_token), String(pageValue), String(email));
    } else {
      console.warn("Clarity is not initialized yet.");
    }
    this.renderer.addClass(document.body, `${environment.slug}`);

  } //end ngOnInit

  openSnackBar(message: string, action: string) {
    let snackBarRef = this.snackBar.open(message, action, {
      duration: 5000,
    });
    snackBarRef.afterDismissed().subscribe(() => {
      console.log('The snack-bar was dismissed');
    });
    snackBarRef.onAction().subscribe(() => {
      console.log('The snack-bar action was triggered!');
    });
    //snackBarRef.dismiss();
  } //end openSnackBar

  pollingTokenRefreshService() {

    this.ngZone.runOutsideAngular(() => {


      interval(10000).pipe(
        retry(3),
        takeUntil(this.destroyed_interval)).subscribe(x => {
          const url = `${environment.API_AUTH_URL}/refresh`
          const username = localStorage.getItem('username')!;
          const refresh_token = localStorage.getItem('refresh_token')!;
          const exp = localStorage.getItem('exp')! as unknown as number;
          const current_time = new Date().getTime() / 1000;
          const time_left = exp - current_time;
          console.log('login bootstrap expirationDuration', time_left)
          if (time_left < 60 && refresh_token) {
            this.loginService.refreshToken(url, username, refresh_token).pipe(takeUntil(this.destroyed_refresh)).subscribe(x => {
              console.log('refresh token', x)
            }, (error) => {
              this.currentRefreshTokenService.announceRefreshToken(false)
              this.destroyed_interval.next(''); // happens if user hits back button to got back to login page so wee need to stop the interval
              this.destroyed_interval.complete();
              this.destroyed_refresh.next('');
              this.destroyed_refresh.complete();
              console.log('error from a1', error)
              this.loginService.logout();
            }, () => { });
          } else {
            //console.log('token is still valid')
          }
        }, (error) => {
          console.log('error from b1', error)
          this.currentRefreshTokenService.announceRefreshToken(false)
          this.destroyed_interval.next(''); // happens if user hits back button to got back to login page so wee need to stop the interval
          this.destroyed_interval.complete();
          this.destroyed_refresh.next('');
          this.destroyed_refresh.complete();
          this.loginService.logout();
        }, () => { }
        ) // end of interval

    }) // end of ngZone
  } //end pollingCallService



} //end AppComponent
