<div class="appointment-details-card">
  <div class="appointment-details-header">
    <div>Appointment Details</div>
    <i [ngClass]="'healent-logout'" (click)="onClose()" style="cursor: pointer;"></i>
  </div>
  <div class="appointment-details-content">
    <div class="patient-section">
      <div class="label">Patient</div>
      <div class="patient-name">{{ patientName }}</div>
    </div>

    <app-appointment-form-fields
      [date]="selectedDate"
      (dateChange)="selectedDate = $event"
      [duration]="duration"
      (durationChange)="duration = $event"
      (timeChange)="formData.time = $event"
      (notesChange)="formData.notes = $event"
      (formDataChange)="onFormDataChange($event)"
      [buttonConfig]="buttonConfig"
      (buttonAction)="onButtonAction($event)"
    ></app-appointment-form-fields>
  </div>
</div>
