import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {IntakeAssessmentsGetInterface} from "./intake-assements-get.interface";


@Injectable({providedIn: 'root'})
export class IntakeAssessmentsService {

  url:string = `${environment.API_URL}`

  constructor(private http: HttpClient) {
  }

  getIntakeAssessments(endpoint: string, is_mocked: boolean = false ): Observable<IntakeAssessmentsGetInterface>{

    const url = `${this.url}/${endpoint}`;

    return this.http.get<IntakeAssessmentsGetInterface>(url);
  } //end login





} //
