<div class="app-container">
  <div class="user-sidebar">
    <div class="search-container">
      <input
        type="text"
        placeholder="Search patient..."
        [(ngModel)]="searchTerm"
        (input)="filterUsers()"
      />
      <!-- Plus Button for adding new chat -->
      <button *ngIf="!isAdmin" class="add-chat-button" (click)="createDirectConversation()">+</button>
    </div>

    <div class="user-list">
      <div
        *ngFor="let user of filteredUsers"
        (click)="selectUser(user)"
        [class.active]="user === selectedUser"
        [class.unread]="user.unreadCount > 0"
      >
        <span class="user-name">{{ user.name }}</span>
        <span *ngIf="user.unreadCount > 0" class="unread-badge">
          {{ user.unreadCount }}
        </span>
      </div>
    </div>
  </div>

  <!-- Chat Container -->
  <div class="chat-container">
    <div class="channel-name-header" *ngIf="selectedUser && from_user_id != current_user_id && channel">
      {{channel.name}}
    </div>
    <div class="chat-messages">
      <!-- Display messages -->
      <div
        *ngFor="let message of messages"
        [ngClass]="{
          'message-wrapper': true,
          'sent': message.userId == from_user_id,
          'received': message.userId != from_user_id
        }"
      >
        <!-- Message bubble -->
        <div
          [ngClass]="{
            'sent-message': message.userId == from_user_id,
            'received-message': message.userId != from_user_id
          }"
        >
          <div class="file-message" *ngIf="message.files.length > 0">
            <!-- Image preview for image files -->
            <img *ngIf="message.files[0].name.match('.(jpg|jpeg|png|gif)$')"
                 [src]="message.files[0].url"
                 alt="Shared image"
                 class="shared-image"
                 (click)="openImagePreview(message.files[0].url)">
          </div>
          <div class="message-content">
            <div *ngIf="message.content?.type === messageType.URL">
              <app-link-preview [url]="message.content.text"></app-link-preview>
            </div>
            <div *ngIf="message.content?.type === messageType.TEXT">
              {{ message.content.text }}
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isTyping" class="typing-indicator">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>

    <!-- Chat Input Container -->
    <div *ngIf="selectedUser && !isDirectConversationModalOpen && from_user_id == current_user_id" class="bottom-container">
      <div *ngIf="selectedFile" class="file-preview">
        <span class="file-description">{{ selectedFile.name }}</span>
        <button class="file-remove-button" (click)="removeSelectedFile()">Remove</button>
      </div>
      <div class="message-input-container">
        <textarea
          [(ngModel)]="currentMessage"
          placeholder="Type your message..."
          class="message-input"
          (input)="onInputChange()"
          [ngClass]="{'error-input': isMessageFieldError}"
          (keydown.enter)="sendMessage()"
        ></textarea>
        <label class="file-input-label">
          📎
          <input
            type="file"
            (change)="onFileSelected($event)"
            [disabled]="isUploading"
            accept="image/*,.mp4,.mp3">
        </label>
        <button (click)="sendMessage()" [disabled]="isUploading" class="send-button">
          {{ isUploading ? 'Uploading...' : 'Send' }}
        </button>
      </div>
    </div>
  </div>

  <div class="large-modal-overlay" *ngIf="isDirectConversationModalOpen">
    <div class="large-modal">
      <span class="large-modal-close" (click)="closeDirectConversation()">&#10005;</span>
      <h2>Create Direct Conversation</h2>

      <!-- Search Input for Patients in the Modal -->
      <input
        type="text"
        [(ngModel)]="directConversationSearchTerm"
        (input)="filterUsers()"
        placeholder="Search for a patient..."
        class="user-search-input"
        [ngClass]="{'error-input': isSearchFieldError}"
        [value]="selectedUser?.name || ''"
      />

      <!-- Display error message if chat with user already exists -->
      <div *ngIf="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>

      <!-- Show user list only if search term is not empty and showSearchResults is true -->
      <div class="user-selection-list" *ngIf="directConversationSearchTerm.trim() && showSearchResults">
        <div
          *ngFor="let user of directFilteredUsers"
          (click)="selectUser(user)"
          class="user-selection-item"
        >
          <span>{{ user.name }}</span>
        </div>
      </div>
      <!-- Message Input Field -->
      <div class="message-input-container">
        <textarea
          [(ngModel)]="currentMessage"
          placeholder="Type your message..."
          class="message-input"
          [ngClass]="{'error-input': isMessageFieldError}"
        ></textarea>
        <button class="send-button" (click)="sendMessage()">Send</button>
      </div>

      <!-- Error message displayed below the text box -->
      <div *ngIf="messageError" class="error-message">
        {{ messageError }}
      </div>
    </div>
  </div>

  <div class="fullscreen-modal" *ngIf="previewImageUrl" (click)="closeImagePreview()">
    <span class="fullscreen-close">&times;</span>
    <img [src]="previewImageUrl" alt="Full-screen preview" class="fullscreen-image">
  </div>
</div>
