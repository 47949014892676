import {Injectable} from "@angular/core";
import {environment} from '../../environments/environment';
import { HttpClient } from "@angular/common/http";
import {from, of, map, Observable, delay} from "rxjs";




export interface FormRoot {
  message: FormMessage[]
  status: number
}

export interface FormMessage {
  allDay: boolean
  backgroundColor: string
  borderColor: string
  date: string
  id: string
  textColor: string
  title: string
}

@Injectable({providedIn: 'root'})
export class CelTestService {

  url:string = `${environment.API_URL}`

  constructor(private http: HttpClient,) {
  }

  getCalendar(endpoint: string, is_mocked: boolean = false ): Observable<FormRoot> {

    // if(is_mocked){
    //   return of<AlertsResponse>({
    //       "message":  [
    //         {
    //           "alert_id": 1,
    //           "alert_info": "The patient has missing readings since 7 days",
    //           "alert_type": "Follow-up Required",
    //           "assigned_nurse_id": 3174,
    //           "client_id": 2342,
    //           "created_at": "2023-02-28T07:23:25",
    //           "created_at_utc": "2023-02-28T07:23:25",
    //           "days_with_readings": 0,
    //           "last_call_date": "2023-05-28T21:00:00",
    //           "nurse_action": null,
    //           "patient_name": "Test 1 Onboarding",
    //           "priority": null,
    //           "age": 0,
    //           "phone": "1234567890",
    //           "priority_ord": 10
    //         },
    //         ]
    //     })
    // }

    const url = `${this.url}/${endpoint}`;
    return this.http.get<FormRoot>(url);
  } //end getAlerts



} //
