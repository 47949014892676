<form [formGroup]="locationForm">
<mat-form-field class="location-select-container">
  <i class="select-icon healent-form-input-icon-24" matPrefix></i>
  <input #locationInput type="text"
         placeholder="Select Location"
         matInput
         formControlName="location"
         (click)="openAutocompletePanelOnClick()"
         [matAutocomplete]="auto"
         (focus)="onInputFocus()"
         (blur)="onBlur()">
  <i class="select-icon cancel-icon healent-form-input-icon-11"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="clearSearchText()"
    [ngClass]="{ 'visible': locationForm.get('location')?.value && showClearIcon() }"
    style="cursor: pointer;">
  </i>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onLocationChange($event)"
    [displayWith]="displayNameFunc">
    <mat-option *ngFor="let location of filteredLocations" [value]="location">
      {{ location.location_name }}
    </mat-option>
    <mat-option *ngIf="filteredLocations.length === 0" disabled>
      No locations found
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

</form>
