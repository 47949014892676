import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output,OnChanges } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TableActionsDrawerService } from '../../table-actions-drawer.service';

@Component({
  selector: 'app-payment-info',
  standalone: true,
  imports: [CommonModule, MatTableModule],
  templateUrl: './payment-info.component.html',
  styleUrl: './payment-info.component.css'
})
export class PaymentInfoComponent implements OnInit,OnChanges {
  @Output() closeDrawerEvent = new EventEmitter<void>();
  @Input({ required: true }) patientName!: string;
  @Input({required:true}) patientId!: String | number;
  balance:string = '0'
  constructor(private tableActionsDrawerService: TableActionsDrawerService){}

  ngOnChanges(): void { 
    this.balance = '0'
    this.tableActionsDrawerService.getPaymentBalance(`patient_notes/balance/${this.patientId}`,{}).subscribe(res=>{
      if(res?.message?.balance){
        this.balance = res?.message?.balance
        return
      }
      this.balance = "0"
    })
  }

  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['amount', 'description', 'method', 'receipt'];
  tableDataArray = [
    {
      amount: '$ 15.00',
      description: 'Copayment amount for todays visit',
      method: 'Credit Card'
    },
    {
      amount: '$ 25.00',
      description: 'Balance from 08/23/2024',
      method: 'Credit Card'
    },
    {
      amount: '$ 55.00',
      description: 'Balance from 10/10/2024',
      method: 'Credit Card'
    }
  ]
  totalAmount: number = 0

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.tableDataArray)
    this.calculateTotal()
  }
  triggerCloseDrawer() {
    this.closeDrawerEvent.emit()
  }

  calculateTotal() {
    this.totalAmount = this.tableDataArray.reduce((acc, item) => acc + Number(item.amount.split(' ')[1]), 0);
  }
}
