import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {ClientIdService} from "../../dashboardPassingService"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-patient-view',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './patient-view.component.html',
  styleUrl: './patient-view.component.css'
})
export class PatientViewComponent implements OnInit, OnDestroy, OnChanges{


  @Input('client_id') client_id: number = 0;
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    private clientIdService: ClientIdService,
  ) {
      this.clientIdService.ClientIdBehaviorAnnounced$.pipe(takeUntilDestroyed()).subscribe(
        client_id => {
          console.log('patient view got client_id: ', client_id);
          this.client_id = client_id;
          this.loadPatientView();
        }
      ); //end subscribe
  } // constructor

  ngOnInit(): void {

  } // ngOnInit

  ngOnDestroy(): void {

    console.log('kill patient view ngOnDestroy()');
  } // ngOnDestroy

  ngOnChanges(changes: SimpleChanges) {
    // better to do a subscription to the changes
    if (changes.hasOwnProperty('client_id')){
      this.client_id = changes['client_id'].currentValue;
      this.loadPatientView();
    }
  } // ngOnChanges


  loadPatientView() {

    console.log('loadPatientView() for client_id: ', this.client_id);

  }

  backToTasks() {
    console.log('backToTasks()');

    this.close.emit('close');
  }

}
