import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { InputEmailComponent } from "../../resusable/input-email/input-email.component";
import { InputPasswordComponent } from "../../resusable/input-password/input-password.component";
import { MatCardModule } from "@angular/material/card";
import { MatToolbarModule } from "@angular/material/toolbar";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { LoginService } from "./login.service";
import { environment } from "../../../environments/environment";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatFormFieldModule } from "@angular/material/form-field";
import { Subject, takeUntil } from "rxjs";
import { CurrentLoggedInBehaviorService } from "../../objectPassingService";


interface LoginData {
  email: FormControl<string>
  password: FormControl<string>
}

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, InputEmailComponent, InputPasswordComponent, MatCardModule,
    MatToolbarModule, RouterLink, MatButtonModule, MatProgressBarModule, MatFormFieldModule, RouterLinkActive],
  providers: [LoginService],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit, OnDestroy {


  private destroyRef = inject(DestroyRef);
  destroyed = new Subject();

  myForm: FormGroup = this.fb.group({
    email: ['', Validators.required],
    password: ['', [Validators.required]]
  });
  is_loading: boolean = false;
  is_form_error: boolean = false;
  form_error_message: string = "";
  is_auth_error: boolean = false;

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private loginService: LoginService,
    private currentLoggedInBehaviorService: CurrentLoggedInBehaviorService,
  ) {
  }

  ngOnInit(): void {


    console.log(environment)

    //localStorage.clear();
    sessionStorage.clear();
    this.currentLoggedInBehaviorService.announceCurrentLoggedInBehavior(false);
    this.destroyRef.onDestroy(() => {
      this.destroyed.next('');
      this.destroyed.complete();
    });
  } //end ngOnInit

  ngOnDestroy(): void {
  } //end ngOnDestroy

  onSubmit() {


    this.is_loading = true;
    this.is_form_error = false;
    const data = this.myForm.value;

    let url = `${environment.API_AUTH_URL}/auth/login`

    this.loginService.login(url, data['email'], data['password']).pipe(takeUntil(this.destroyed)).subscribe(res => {
      this.is_loading = false;

      console.log('login res', res)

      if (res.hasOwnProperty('AccessToken')) {
        this.loginService.handleAuthentication(res.AccessToken!, res.IdToken!, res.ExpiresIn!, res.RefreshToken!, res.TokenType!);
      }



      if (res.hasOwnProperty('message') && res['message']?.includes('PasswordResetRequiredException')) {





        console.log('PasswordResetRequiredException')
        console.log('res', res)

        this.router.navigate(['forgot-password'], {
          queryParams: {
            email: data['email']
          }
        })

        // this.router.navigate(['new-password-required'], {
        //   queryParams: {
        //     session: res.session,
        //     email: data['email']
        //   }
        // })

      }

      if (res.hasOwnProperty('enum') && (res.enum == 'IncorrectUsernameOrPassword')) {
        this.is_auth_error = true
        this.is_form_error = true;
        this.form_error_message = 'Incorrect username or password' //res['message']
      }

      if (res.ChallengeName == 'MFA_SETUP') {
        this.router.navigate(['qrcode'], {
          queryParams: {
            session: res.session,
            qr: res.qr
          }
        })
      }

      if (res.ChallengeName == 'NEW_PASSWORD_REQUIRED') {
        this.router.navigate(['new-password-required'], {
          queryParams: {
            session: res.session,
            email: data['email']
          }
        })
      }

      if (res.ChallengeName == 'SOFTWARE_TOKEN_MFA') {
        this.router.navigate(['mfa'], {
          queryParams: {
            session: res.session,
            email: data['email']
          }
        })
      }

    },
      error => {
        console.log('error', error)
        this.is_loading = false;
        this.is_auth_error = true
        this.is_form_error = true;
        this.form_error_message = 'Incorrect username or password' //res['message']
      },
      () => {
      })


  } //end onSubmit


} //end class
