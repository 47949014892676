import {AfterContentInit, AfterViewInit, Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatCardModule} from "@angular/material/card";
import {GroupTasksService, MakeEngagingCallsBehaviorService} from "../../dashboardPassingService";
import {delay, finalize, Observable, startWith, tap} from "rxjs";
import {Alert} from "../../dashboard/dashboard.service";
import {map} from "rxjs/operators";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {LoadingComponent} from "../../resusable/loading/loading.component";
import {LoadingService} from "../../objectPassingService";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";


@Component({
  selector: 'app-make-engaging-calls',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatListModule, MatIconModule, MatExpansionModule, MatProgressBarModule, LoadingComponent],
  templateUrl: './make-engaging-calls.component.html',
  styleUrl: './make-engaging-calls.component.css'
})
export class MakeEngagingCallsComponent implements OnInit{

  is_loading:boolean = true;
  is_expanded:boolean = true;
  is_task_group_enabled:boolean = true;

  alerts$!: Observable<Alert[]>

    constructor(
      private makeEngagingCallsBehaviorService: MakeEngagingCallsBehaviorService,
      private groupTasksService: GroupTasksService,
      private loadingService: LoadingService,
    ) {

      this.groupTasksService.GroupTasksBehaviorAnnounced$.pipe(takeUntilDestroyed()).subscribe(
        (data: boolean) => {
          this.is_task_group_enabled = data;
        },
      )

    } // constructor


    ngOnInit(): void {

      const alerts$= this.makeEngagingCallsBehaviorService.currentMakeEngagingCallsBehaviorAnnounced$
      this.alerts$ = alerts$.pipe() // end pipe
    } // ngOnInit

} //MakeEngagingCallsComponent
