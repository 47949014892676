import {Injectable} from '@angular/core';
import {Channel, Chat, Membership, Message, User} from "@pubnub/chat";
import { Observable, Subject } from 'rxjs';
import { ModelValidators } from '../validators/validators';
import { MessageType } from '../models/message-type.model';


interface IUser {
  user_id: string;
  name: string;
  timeToken: string;
  unreadCount: number;
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  chat!: Chat;
  current_user_id: string | null = localStorage.getItem('email');
  from_user_name: string | null = this.current_user_id;
  private userChannelIdMap: { [userId: string]: string } = {};
  private historyUsers: Map<string, IUser> = new Map();
  private sortedHistoryUsers: Array<IUser> = [];
  isAdmin: boolean | null = localStorage.getItem('role') === 'admin';

  constructor() {
    this.userChannelIdMap = {};
  }

  public setCurrentUserId(userId: string | null) {
    this.current_user_id = userId;
  }

  async initializeChat() {
    try {
      if (!this.current_user_id) {
        throw new Error('Current user ID is missing. Chat initialization cannot proceed.');
      }
      this.chat = await Chat.init({
        publishKey: 'pub-c-2eb78a3b-28d4-47e2-8a3f-82be2a9f33e4',
        subscribeKey: 'sub-c-8edc5a70-7b2f-4219-8dc9-52d22ee8ccca',
        userId: this.current_user_id,
        typingTimeout: 3000
      });
      console.log('Chat SDK initialized:', this.chat);
    } catch (error) {
      console.error('Error initializing Chat SDK:', error);
    }
  }

  waitForChatInitialization(): Promise<void> {
    return this.initializeChat().then(() => {
      console.log('Chat SDK initialization complete.');
    }).catch((error) => {
      console.error('Chat SDK initialization incomplete.');
    });
  }

  async getAllUnreadMessages(): Promise<Array<any>> {
    return this.chat.getUnreadMessagesCounts();
  }

  async getOneMessageForEachChannel(channels: Array<string>): Promise<any> {
    return await this.chat.sdk.fetchMessages({
      channels,
      count: 1,
      includeMeta: true,
    });
  }

  destroyVariables(){
    this.historyUsers = new Map<string, IUser>();
    this.userChannelIdMap = {};
    this.chat.sdk.unsubscribeAll();
    // @ts-ignore
    this.chat.sdk.removeAllListeners();
  }
  
  async createUser(selectedUser: any) {
    try {
      const user: User = await this.chat.createUser(String(selectedUser.user_id), {
        name: selectedUser.name,
        custom: {
          email: selectedUser.email
        }
      });
      console.log('User created successfully:', user);
      return user;
    } catch (error) {
      console.error('Error creating user:', error);
      return null;
    }
  }
  
  async createDirectConversation(channelId: string, selectedUser: any, topic: string = 'Conversation Topic', purpose: string = 'chat') {
    try {
        let userToChatWith = await this.chat.getUser(String(selectedUser.user_id));

        if (!userToChatWith) {
            console.log(`User with ID ${selectedUser.user_id} not found. Creating user...`);
            userToChatWith = await this.createUser(selectedUser);

            if (!userToChatWith) {
                console.error('Error creating user. Cannot proceed with conversation creation.');
                return null;
            }
        } else {
            console.log(`User with ID ${selectedUser.user_id} found:`, userToChatWith);
        }

        // Check if my user have the same username as id
        const myUser = await this.chat.getUser(String(this.current_user_id));
        if (myUser?.name === myUser?.id) {
          await myUser?.update?.({name: this.from_user_name || ''});
        }

        // Add the user to historyUsers with 'active' status
        this.addHistoryUser(selectedUser.user_id, selectedUser.name, 'active');

        // Create the direct conversation
        const { channel, hostMembership, inviteeMembership } = await this.chat.createDirectConversation({
            user: userToChatWith,
            channelId: channelId,
            channelData: {
                name: `Chat between ${this.current_user_id} (${this.from_user_name}) and ${selectedUser.user_id} (${selectedUser.name})`,
                custom: {
                    topic: topic
                }
            },
            membershipData: {
                custom: {
                    purpose: purpose
                }
            }
        });
        this.userChannelIdMap[selectedUser.user_id] = channelId;

        console.log(`Created direct conversation with channel: ${channel.id}`);
        return { channel, hostMembership, inviteeMembership };
    } catch (error) {
        console.error('Error creating direct conversation:', error);
        return null;
    }
  }

  async sendTextMessage(
    channelId: string,
    text: string,
    to_user_id: string,
    to_user_name: string,
    from_user_name: string,
    file: File | null = null) {
    try {
      let channel: Channel | null = await this.chat.getChannel(channelId);
      if (!channel) {
        const selectedUser = { user_id: to_user_id, name: to_user_name };
        const result = await this.createDirectConversation(channelId, selectedUser);

        if (!result || !result.channel) {
            console.error('Failed to create channel. Cannot send message.');
            return null;
        }
        channel = result.channel;
      }

      const messageType = ModelValidators.validUrl(text)
        ? MessageType.URL
        : MessageType.TEXT;

      return await channel.sendText(text, {
        storeInHistory: true,
        meta: {
          to_user_id: to_user_id,
          to_user_name: to_user_name,
          from_user_name: from_user_name,
          type: messageType,
        },
        ...(file ? {files: [file]} : {})
      });
    } catch (error) {
      console.error('Error sending message:', error);
      return null;
    }
  }

  async getChannelHistory(channelId: string, startTimetoken?: string, endTimetoken?: string, count?: number) {
    try {
      const channel: Channel | null = await this.chat.getChannel(channelId);
      if (!channel) {
        console.log(`Channel with name ${channelId} not found.`);
        return;
      }

      const historyOptions: any = {};

      if (startTimetoken) {
        historyOptions.startTimetoken = startTimetoken;
      }
      if (endTimetoken) {
        historyOptions.endTimetoken = endTimetoken;
      }
      if (count) {
        historyOptions.count = count;
      }

      return await channel.getHistory(historyOptions);
    } catch (error) {
      console.error('Error fetching history:', error);
      return null;
    }
  }

  async initUserChannels({
    onMessageReceived,
    onTypingEventReceived,
    getUser,
  }: {
    onMessageReceived: (message: any, channelId: any) => any;
    onTypingEventReceived: (channelId: any) => any;
    getUser: (userId: number) => any;
  }): Promise<void> {
    let memberships = [];
    let membersIds = new Set<string>();

    if (this.isAdmin) {
      const channels = await this.chat.getChannels();
      for (const channel of channels.channels) {
        const members = await channel.getMembers();
        const nurceId = members.members[0].user.id;

        membersIds.add(nurceId);
      }

      for (const memberId of membersIds) {
        const userMemberships = await this.getUserMemberships(memberId);
        memberships.push(userMemberships)
      }

      memberships = memberships.flat();
    } else {
      memberships =  await this.getUserMemberships(this.current_user_id || '');
    }

    const allUnreadMessages = await this.getAllUnreadMessages();
    const allChannelsArray = memberships.map((el) => el.channel.id);
    const allOneCountMessagesForEachChannel = await this.getOneMessageForEachChannel(allChannelsArray);

    for (const membership of memberships) {
      const channelId = membership.channel.id;
      let lastMessage = allOneCountMessagesForEachChannel.channels[channelId]?.[0];

      const userId = channelId.split('.')[1];

      if (lastMessage) {
        const lastReadMessageTimetoken = localStorage.getItem(`lastReadMessageTimetoken[${channelId}]`);
        const lastMessageTimetoken = lastMessage.timetoken;
        const isHasNewMessages = lastReadMessageTimetoken && +lastReadMessageTimetoken < +lastMessageTimetoken;
        const unreadCount = allUnreadMessages.find((el) => el.channel.id === channelId)?.count || isHasNewMessages
          ? await membership.getUnreadMessagesCount() || 0
          : 0;

        const nurceUserId = channelId.split('.')[0];
        this.updateHistoryUsers(lastMessage, unreadCount || 0, 'inactive', nurceUserId);
      } else {
        if (userId) {
          const user = getUser(Number(userId));

          this.addHistoryUser(
            userId,
            user.name,
            'inactive',
            membership.custom.lastReadMessageTimetoken
          );
        }
      }
    }

    if (this.isAdmin) {
      for (const memberId of membersIds) {
        this.initWildcardSubscription(onMessageReceived, onTypingEventReceived, memberId, false);
      }
    } else {
      this.initWildcardSubscription(onMessageReceived, onTypingEventReceived, this.current_user_id || '');
    }
  }

  async initWildcardSubscription(
    onMessageReceived: any,
    onTypingEventReceived: any,
    userId: string,
    shouldUnsubscribe: boolean = true
  ): Promise<any> {
    const memberships = await this.getUserMemberships(userId || '');

    if (memberships.length === 0) {
      console.log('No memberships found for user:', userId);
      return;
    }

    if (shouldUnsubscribe) {
      this.chat.sdk.unsubscribeAll();
      // @ts-ignore
      this.chat.sdk.removeAllListeners();
    }

    this.chat.sdk.subscribe({
      channels: [`${userId}.*`],
    });

    this.chat.sdk.addListener({
      message: async (event) => {
        const message = {
          ...event.message,
          content: event.message,
          meta: event.userMetadata,
          timetoken: event.timetoken,
          userId: event.publisher,
        };

        const channelId = event.channel;
        const to_user_id = message.meta?.['to_user_id'] ?? null;
        const from_user_id = event.publisher;
        const current_user_id = userId;
        const otherUserId = current_user_id === from_user_id ? to_user_id : from_user_id;

        const messageIdentifier = `${message.timetoken}-${channelId}-${from_user_id}`;
        console.log(`This is the message Identifier: ${messageIdentifier}`);

        const existingUser = this.historyUsers.get(otherUserId);
        if (this.current_user_id === current_user_id &&existingUser && existingUser['status'] === 'active') {
          onMessageReceived(message, channelId)
          this.updateHistoryUsers(message, 0, 'active', userId);
          await this.markMessagesAsRead(channelId, event.timetoken, this.current_user_id);
        } else {
          const membership = memberships.find((el) => el.channel.id === channelId);
          const unreadCount = await membership.getUnreadMessagesCount();

          this.updateHistoryUsers(message, unreadCount, 'active', userId);

          onMessageReceived(message, channelId)
        }
      },
      signal: (event) => {
        if (event?.message?.type === 'typing') {
          onTypingEventReceived(event.channel);
        }
      }
    });
  }

  private sortUsers(): void {
    this.sortedHistoryUsers = [...this.historyUsers.values()].sort((a, b) => parseInt(b.timeToken) - parseInt(a.timeToken));
  }

  getRecentChats() {
    return this.sortedHistoryUsers;
  }

  updateHistoryUsers(msg: any, unreadCount: number, status?:string, userId?: string): void{
    let to_user_id = msg.meta.to_user_id;
    console.log(`Message is: `, msg);
    console.log(`Message meta data is: `, msg.meta, userId);
    if(to_user_id != userId) {
      let to_user_name = msg.meta.to_user_name;
      let timeToken = msg.timetoken;
      const existingUser = this.historyUsers.get(to_user_id);
      if (existingUser) {
        existingUser.timeToken = timeToken;
        existingUser.unreadCount = unreadCount;
      } else {
        this.historyUsers.set(to_user_id, {
          user_id: to_user_id,
          name: to_user_name,
          timeToken: timeToken,
          unreadCount: unreadCount,
          status:status || 'inactive'
        });
      }
    } else {
      let from_user_id = msg.userId || msg.meta.from_user_id;
      let from_user_name = msg.meta.from_user_name;
      let timeToken = msg.timetoken;
      const existingUser = this.historyUsers.get(from_user_id);
      if (existingUser) {
        existingUser.timeToken = timeToken;
        existingUser.unreadCount = unreadCount;
      } else {
        this.historyUsers.set(from_user_id, {
          user_id: from_user_id,
          name: from_user_name,
          timeToken: timeToken,
          unreadCount:unreadCount,
          status:status || 'inactive',
        });
      }
    }

    this.sortUsers();
  }

  updateUserStatus(userId: string, status?: string, unreadCounter?:number) {
    const foundUser = this.historyUsers.get(userId);
    if (foundUser) {
      if (unreadCounter != undefined) {
        foundUser.unreadCount = unreadCounter;
        console.log(`Unread Count inside UserStatus is ${foundUser.unreadCount}`);
      }
      foundUser.status = status || foundUser.status;
    } else {
      console.log('User not found');
    }
  }

  addHistoryUser(
    user_id: string,
    name: string,
    status: string,
    timeToken: string = '',
    unreadCount: number = 0,
  ): void {
    const newUser = {
      user_id,
      name,
      timeToken,
      unreadCount,
      status,
    };

    this.historyUsers.set(user_id, newUser);
    this.sortUsers();
  }

  async startTyping(channelId: string) {
    try {
      const channel = await this.chat.getChannel(channelId);
      if (channel) {
        console.log('Typing started on channel:', channelId);
        await channel.startTyping();
      }
    } catch (error) {
      console.error('Error starting typing indicator:', error);
    }
  }

  async stopTyping(channelId: string) {
    try {
      const channel = await this.chat.getChannel(channelId);
      if (channel) {
        await channel.stopTyping();
        console.log('Typing stopped on channel:', channelId);
      }
    } catch (error) {
      console.error('Error stopping typing indicator:', error);
    }
  }

  async markMessagesAsRead(channelId: string, endTimetoken?: string, userId?: string): Promise<void> {
    try {
      if (!userId) {
        console.error('Error: userId is null. Cannot mark messages as read.');
        return;
      }

      const user = await this.chat.getUser(userId);
      if (!user) {
        console.error(`Error: User with ID ${userId} not found.`);
        return;
      }

      const memberships = await user.getMemberships({
        filter: `channel.id == '${channelId}'`
      });
      const channel = await this.chat.getChannel(channelId);
      if (!channel) {
        console.error(`Error: Channel with ID ${channelId} not found.`);
        return;
      }
      const history = await this.getChannelHistory(channelId, undefined, endTimetoken, 1);

      if (history && history.messages.length > 0) {
        const message = history.messages[0];
        const lastMessageTimetoken = message.timetoken;
        console.log(`message timetoken is ${lastMessageTimetoken}`);

        const membership = memberships.memberships[0];
        const customFields = membership.custom;

        if ((!customFields || !customFields['lastReadMessageTimetoken'])
          || customFields['lastReadMessageTimetoken'] !== lastMessageTimetoken) {
          localStorage.setItem(`lastReadMessageTimetoken[${membership.channel.id}]`, lastMessageTimetoken);

          await membership.setLastReadMessage(message);
          await membership.setLastReadMessageTimetoken(message.timetoken);
        }
      } else {
        console.error('No messages found in the channel');
      }
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  }

  async getUserMemberships(userId: string): Promise<any[]> {
    try {
      if (!userId) {
        console.error('Error: userId is null. Cannot get memberships.');
        return [];
      }

      const user = await this.chat.getUser(userId);
      if (!user) {
        console.error(`Error: User with ID ${userId} not found.`);
        return [];
      }

      const memberships = await user.getMemberships();

      for (const membership of memberships.memberships) {
        const channel = membership.channel;

        const membersResponse = await channel.getMembers();
        const members = membersResponse.members;

        members.forEach(member => {
          const otherUser = member.user;
          if (otherUser.id !== userId) {
            this.userChannelIdMap[otherUser.id] = channel.id;
          }
        });
      }

      console.log('User-Channel Map:', this.userChannelIdMap);
      return memberships.memberships;
    } catch (error) {
      console.error('Error retrieving user memberships:', error);
      return [];
    }
  }

  getChannelIdForUser(userId: string): string {
    return this.userChannelIdMap[userId];
  }

  async getChannel(channelId: string): Promise<Channel> {
    const channel = await this.chat.getChannel(channelId);
    if (!channel) {
      throw new Error(`Channel with ID ${channelId} not found.`);
    }
    return channel;
  }

  async leaveChannel(channelId: string): Promise<boolean> {
    try {
      const channel = await this.chat.getChannel(channelId);
      if (channel) {
        await channel.leave();
        console.log(`Successfully left the channel with ID: ${channelId}`);
        return true;
      } else {
        console.error(`Channel with ID ${channelId} not found.`);
        return false;
      }
    } catch (error) {
      console.error('Error leaving the channel:', error);
      return false;
    }
  }
  async leaveAllUserChannels(userId?: string): Promise<void> {
    try {
      const memberships = await this.getUserMemberships(userId || '');

      for (const membership of memberships) {
        const channelId = membership.channel.id;
        const leftChannel = await this.leaveChannel(channelId);

        if (leftChannel) {
          console.log(`Successfully left channel with ID: ${channelId}`);
        } else {
          console.error(`Failed to leave channel with ID: ${channelId}`);
        }
      }
    } catch (error) {
      console.error('Error leaving all user channels:', error);
    }
  }
  async toggleReaction(message: Message, reaction: string): Promise<Message | null> {
    try {
      const updatedMessage = await message.toggleReaction(reaction);
      console.log('Does it succeed on updating the message?');
      console.log(`Updated message is reaction ${updatedMessage.reactions}`);
      return updatedMessage;
    } catch (error) {
      console.error('Error toggling reaction: blah blah', error);
      return null;
    }
  }

  async deleteAllChannels(softDelete: boolean = false): Promise<void> {
    try {
      let page = { next: '' };
      let hasNextPage = true;

      while (hasNextPage) {
        const channelsResponse = await this.chat.getChannels({ page });
        const channels = channelsResponse.channels;

        if (channels.length === 0) {
          console.log("No channels found to delete.");
          break;
        }

        for (const channel of channels) {
          try {
            await this.chat.deleteChannel(channel.id, { soft: softDelete });
            console.log(`Deleted channel: ${channel.id}`);
          } catch (error) {
            console.error(`Failed to delete channel ${channel.id}:`, error);
          }
        }
        page.next = channelsResponse.page.next ?? '';
        hasNextPage = !!channelsResponse.page.next;
      }
      console.log("All channels deleted successfully.");
    } catch (error) {
      console.error("Error deleting channels:", error);
    }
  }
}
