<div class="main-notes">
  <div class="notes-body">
    <div class="notes-header">
      <div>
        Notes - {{patientName}}
      </div>
      <i [ngClass]="'healent-logout'" style="color: var(--secondary_shade_50); cursor: pointer;" (click)="triggerCloseDrawer()"></i>
    </div>
    @if (is_loading_notes) {
    <div style="margin-top: 30px;">
      <h2>Loading notes...</h2>
    </div>
    } @else {
    <div class="notes-container" [ngClass]="{'notes-body-full-height': !isAddingNote}">
      <div class="notes-section">
        <div class="notes-section-header">
          <div>
            <i [ngClass]="'healent-path-3656-1'"></i>
            <h2 style="margin-right: 5px;">Patient notes</h2>
            <h2>
              (<span style="color: var(--warning-shade-1);">{{ (patientNotes$ | async)?.length }}</span>)
            </h2>
          </div>
          <button *ngIf="enableButton" class="new-button patient-button"
            (click)="startAddingNote('patient', $event)">New</button>
        </div>
        <div class="notes-list">
          <div class="note-item" *ngFor="let note of (patientNotes$ | async) ?? []; let i = index">
            <div class="notes-item-content">
              <div class="note-card">
                <div
                  class="note-text"
                  [class.expanded]="note.isExpanded"
                  [class.collapsed]="!note.isExpanded"
                >
                  {{ (!(note.note.lastIndexOf(' ') > 300)) ? note.note : (note.note| slice:0:note.note.lastIndexOf(' ')) + '...' }}
                </div>
                @if (note.note.lastIndexOf(' ') > 300) {
                  <div style="color: var(--accent); cursor: pointer;" *ngIf="note.note" (click)="toggleText(i, 'patient');
                  $event.stopPropagation()
                  ">
                    {{ note.isExpanded ? 'Show less' : 'Show more' }}
                  </div>
                }
              </div>
              <div class="notes-subtext">
                {{ (note.date | date: 'MMM dd, yyyy') }} <!-- (note?.providerName || '') + ' - ' + add this when name is added in backend response-->
              </div>
            </div>
            <div>
              <i [ngClass]="'healent-edit'" (click)="editNote(note, i, 'patient', $event)"></i>
              <i [ngClass]="'healent-android-delete'" (click)="deleteNote(i, 'patient', $event)"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="notes-section">
        <div class="notes-section-header">
          <div>
            <i [ngClass]="'healent-path-3627'"></i>
            <h2 style="margin-right: 5px;">Visit notes</h2>
            <h2>
              (<span style="color: var(--dangerous);">{{ (visitNotes$ | async)?.length }}</span>)
            </h2>
          </div>
          <button *ngIf="enableButton" class="new-button visit-button"
            (click)="startAddingNote('visit', $event)">New</button>
        </div>
        <div class="notes-list">
          <div class="note-item" *ngFor="let note of (visitNotes$ | async) ?? []; let i = index">
            <div class="notes-item-content">
              <div class="note-card">
                <div
                  class="note-text"
                  [class.expanded]="note.isExpanded"
                  [class.collapsed]="!note.isExpanded"
                >
                  {{ (!(note.note.lastIndexOf(' ') > 300)) ? note.note : (note.note| slice:0:note.note.lastIndexOf(' ')) + '...' }}
                </div>
                @if (note.note.lastIndexOf(' ') > 300) {
                  <div style="color: var(--accent); cursor: pointer;" *ngIf="note.note" (click)="toggleText(i, 'visit');
                  $event.stopPropagation()
                  ">
                    {{ note.isExpanded ? 'Show less' : 'Show more' }}
                  </div>
                }
              </div>
              <div class="notes-subtext">
                {{ (note.date | date: 'MMM dd, yyyy') }} <!-- (note?.providerName || '') + ' - ' + add this when name is added in backend response-->
              </div>
            </div>
            <div style="width: 10%;">
              <i [ngClass]="'healent-edit'" (click)="editNote(note, i, 'visit', $event)"></i>
              <i [ngClass]="'healent-android-delete'" (click)="deleteNote(i, 'visit', $event)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    }
  </div>

  <div class="new-note-form">
    <hr *ngIf="isAddingNote" class="divider-line" />
    <div class="new-note-form-content" *ngIf="isAddingNote">
      <div class="new-note-form-header"
        [ngStyle]="{'color': noteType === 'patient' ? 'var(--warning-shade-1)' : 'var(--dangerous)'}">
        <i [ngClass]="noteType === 'patient' ? 'healent-path-3656-1' : 'healent-path-3627'"></i>
        <h2>New / Edit {{noteType === 'patient' ? 'Patient' : 'Visit'}} Note</h2>
      </div>
      <div class="template-buttons">
        <!-- Loop through the templateButtons array to create dynamic buttons -->
        <button *ngFor="let btn of (noteType === 'patient' ? templateButtons : vistNotesTemplates)"
                class="template-button"
                (click)="addTemplate(btn.templateText)"
                [matTooltip]="btn.tooltipText">
          {{ btn.buttonName }}
        </button>
      </div>
      <textarea [(ngModel)]="newNote" placeholder="Enter new {{ noteType }} note"></textarea>
      <div class="action-buttons">
        <button class="save-button" (click)="saveNote($event)" [disabled]="isSavingNote">
          <div *ngIf="!isSavingNote">
            Save
          </div>
          <span *ngIf="isSavingNote" class="loading-dots">
            <span class="dot"></span>
          </span>
        </button>
        <button class="cancel-button" (click)="cancelNoteAddition($event)">Cancel</button>
      </div>
    </div>
  </div>
</div>
