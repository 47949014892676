import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef, signal, SimpleChanges, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { HospitalLocationInterface } from '../../registration/registration.interface';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import {CONTROL} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-custom-location-select',
  templateUrl: './custom-location-select.component.html',
  styleUrls: ['./custom-location-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatIconModule,
    FormsModule,
    MatAutocompleteTrigger,
    MatInputModule,
    MatAutocomplete
  ]
})
export class CustomLocationSelectComponent implements OnInit, OnChanges {
  @Input() locations: HospitalLocationInterface[] = [];
  @Input() selectedLocation: number | string = '';
  @Output() locationChange = new EventEmitter<{ locationId: number; phone: string | undefined }>();

  searchText: string | undefined = '';
  filteredLocations: HospitalLocationInterface[] = [];
  hasClicked: boolean = false

  @ViewChild(MatAutocompleteTrigger) matAutocomplete!: MatAutocompleteTrigger;
  @ViewChild('locationInput') locationInput!: ElementRef;
  showClearIcon = signal(false);


  init_flag = true;
  selectedLocationLoad: string = '';
  ngOnInit(): void {
    this.selectedLocationLoad = localStorage.getItem('selectedLocation') || '';
    this.filteredLocations = this.locations;
    this.setInitialLocation();
  }

  //triggeres changes when the selectedLocation has value

  ngOnChanges(changes: SimpleChanges): void {

    // //THIS LOADS THE LAST SELECTED LOCATION
    if (this.init_flag) {
      if (this.selectedLocationLoad && this.locations.length > 0) {
        this.init_flag = false;
        let location = this.locations.find(loc => loc.location_id ===  parseInt(this.selectedLocationLoad, 10));
        const location_name: string | undefined = location?.location_name ;
        if (location_name) {
          this.searchText = location_name;
          this.filteredLocations = this.locations;
        }
      }
    } //END OF LOADS THE LAST SELECTED LOCATION



    if (changes['selectedLocation'] && this.selectedLocation) {
      this.setInitialLocation();
    }
  }

  filterLocations(): void {
    const filterValue = this.searchText?.toLowerCase();

    // If the search input is empty, show all locations
    if (!filterValue) {
      this.filteredLocations = this.locations;
    } else {
      this.filteredLocations = this.locations.filter(location =>
        location.location_name.toLowerCase().includes(filterValue)
      );
    }

    // If there are no matching results, automatically open the panel
    if (this.hasClicked && this.matAutocomplete && !this.matAutocomplete.panelOpen) {
      this.matAutocomplete.openPanel();
    }
  }

  onLocationChange(event: any): void {
    const selectedLocationObj = this.locations.find(loc => loc.location_id === event.option.value.location_id);
    const locationPhnNum = selectedLocationObj?.phone || '';

    if (selectedLocationObj) {
      this.selectedLocation = selectedLocationObj.location_id;
      this.locationChange.emit({
        locationId: this.selectedLocation,
        phone: locationPhnNum,
      });

      localStorage.setItem('selectedLocation', this.selectedLocation.toString());
      localStorage.setItem('locationPhoneNumber', locationPhnNum.toString());
      setTimeout(() => {
        this.locationInput.nativeElement.blur();
        this.showClearIcon.set(false);
      });
    }
  }

  clearSearchText(): void {
    this.searchText = '';
    this.filteredLocations = this.locations;
    this.selectedLocation = '';
    this.showClearIcon.set(false);
  }

  displayNameFunc (location: HospitalLocationInterface): string {
    if (typeof(location) === 'string') {
      return location ? location : ''
    }
    return location ? location.location_name : ''
  }

  // initial selected location based on the input
  private setInitialLocation(): void {
    const selectedLocationObj = this.locations.find(loc => loc.location_id.toString() === this.selectedLocation.toString());
    if (selectedLocationObj) {
      this.searchText = selectedLocationObj.location_name;
    }
  }

  // to open the autocomplete panel when user selects the input
  openAutocompletePanelOnClick(): void {
    this.hasClicked = true;
    if (!this.searchText) {
      this.filteredLocations = this.locations;
    }
    if (this.matAutocomplete && !this.matAutocomplete.panelOpen) {
      this.matAutocomplete.openPanel();
    }
  }

  onBlur(): void {
    this.hasClicked = false;  // Reset the flag when the input loses focus
    if (!this.searchText) {
      this.showClearIcon.set(false);
    }
  }

  onInputFocus(): void {
    this.showClearIcon.set(true);
  }
}
