import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {CommonModule, NgIf} from '@angular/common';
import {Router} from "@angular/router";
import {CampaignService} from "./campaign.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  CurrentClientIdSelectService,
  CurrentClientSlugSelectService,
  CurrentCampaignSelectService,
  SnackBarService,
  CurrentAdgroupSelectService,
  CurrentAdSelectService,
  CurrentCampaignGroupSelectService
} from "../../objectPassingService";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {ClientInterfaceData, DialogAddCampaignGroup} from "../campaign-groups/campaign-groups.component";
import {MatCardModule} from "@angular/material/card";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {CampaignInterface} from "../adserver.interface";
import {MatDividerModule} from "@angular/material/divider";
import {MatListModule} from "@angular/material/list";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
//import {co} from "@fullcalendar/core/internal-common";
import {forkJoin, take} from "rxjs";
//import {loadProxyConfiguration} from "@angular-devkit/build-angular/src/builders/dev-server/load-proxy-config";

export interface  CampaignTableInterfaceData {
  campaign_id: string,
  status: string,
  name: string,
  description: string,
}

@Component({
  selector: 'app-campaigns',
  standalone: true,
  imports: [CommonModule, MatDividerModule, MatListModule, MatProgressBarModule, MatButtonModule, MatCardModule, MatFormFieldModule, MatIconModule, MatInputModule, MatPaginatorModule, MatSortModule, MatTableModule, MatTooltipModule],
  templateUrl: './campaigns.component.html',
  styleUrl: './campaigns.component.css'
})
export class CampaignsComponent implements OnInit {

  client_id: string | unknown = '';
  client_slug: string | unknown = '';
  campaign_group_id: string | unknown = '';
  is_loading: boolean = true;

  dataSource!: MatTableDataSource<CampaignTableInterfaceData>;
  displayedColumns: string[] = ['campaign_id', 'status', 'name', 'description', 'View', 'Edit', 'Delete'];
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  constructor(
    private router: Router,

    private campaignService: CampaignService,
    public dialog: MatDialog,

    private currentClientIdSelectService: CurrentClientIdSelectService,
    private currentClientSlugSelectService: CurrentClientSlugSelectService,
    private currentCampaignGroupSelectService: CurrentCampaignGroupSelectService,
    private currentCampaignSelectService: CurrentCampaignSelectService,
    private currentAdgroupSelectService: CurrentAdgroupSelectService,
    private currentAdSelectService: CurrentAdSelectService,
  ) { }

  ngOnInit(): void {

    localStorage.removeItem('campaign_id');
    localStorage.removeItem('adgroup_id');
    localStorage.removeItem('ad_id');

    if (localStorage.getItem('client_id')) {
      this.client_id = localStorage.getItem('client_id') || '';
      if (typeof this.client_id  === 'string') {
        this.currentClientIdSelectService.announceClientIdSelect(this.client_id);
      }
    }
    if (localStorage.getItem('client_slug')) {
      this.client_slug = localStorage.getItem('client_slug') || '';
      if (typeof this.client_slug  === 'string') {
        this.currentClientSlugSelectService.announceClientSlugSelect(this.client_slug);
      }

    }
    if (localStorage.getItem('campaign_group_id')) {
      this.campaign_group_id = localStorage.getItem('campaign_group_id') || '';
      if (typeof this.campaign_group_id  === 'string') {
        this.currentCampaignGroupSelectService.announceCampaignGroupSelect(this.campaign_group_id);
      }
    }


    this.currentCampaignSelectService.announceCampaignSelect('');
    this.currentAdgroupSelectService.announceAdgroupSelect('');
    this.currentAdSelectService.announceAdSelect('');

    let currentClientIdSelectService$ = this.currentClientIdSelectService.currentClientIdSelectAnnounced$.pipe(take(1));

    let currentClientSlugSelectService$ = this.currentClientSlugSelectService.currentClientSlugSelectAnnounced$.pipe(take(1));

    let currentCampaignGroupSelectService$ = this.currentCampaignGroupSelectService.currentCampaignGroupSelectAnnounced$.pipe(take(1));

    //let currentCampaignSelectService$ = this.currentCampaignSelectService.currentCampaignSelectAnnounced$.pipe(take(1));

    //let currentAdgroupSelectService$ = this.currentAdgroupSelectService.currentAdgroupSelectAnnounced$.pipe(take(1));

    forkJoin([currentClientIdSelectService$, currentClientSlugSelectService$, currentCampaignGroupSelectService$]).subscribe({
      next: ([client_id, client_slug, campaign_group_id]) => {
        this.client_id = client_id;
        this.client_slug = client_slug;
        this.campaign_group_id = campaign_group_id;
        console.log('client_id', client_id);
        console.log('client_slug', client_slug);
        console.log('campaign_group_id', campaign_group_id);
        if (this.campaign_group_id) {
          this.getData();
        } else {
          this.is_loading = false;
          console.log('no client_id')
        }
      },
      error: (error) => {
        // Handle any error that occurs in any Observable
        console.error('An error occurred:', error);
        this.is_loading = false;
      },
      complete: () => {
        // This is called when all observables complete
        console.log('All observables completed');
      }
    });





  }  // end ngOnInit

  getData(){

    this.is_loading = true;
    let endpoint = `adserver/campaign/all/${this.campaign_group_id}`;
    this.campaignService.get(endpoint).subscribe((data: any) => {
        console.log('campaign data', data);
        data = data['message'];
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.is_loading = false;
      },
      (error) => {
        console.log('campaign_group error', error);
        this.is_loading = false;
      });

  } // end getData

  dialogRefAddCampaign!: MatDialogRef<DialogAddCampaign>;
  addCampaign(is_edit:boolean, row: object) {
    console.log('addCampaign',row);
    let row_data = {}
    if (is_edit == true) {
      row_data = row;
    }

    // @ts-ignore
    row_data['client_id'] = this.client_id;
    // @ts-ignore
    row_data['client_slug'] = this.client_slug;
    // @ts-ignore
    row_data['campaign_group_id'] = this.campaign_group_id;


    var enterAnimationDuration = '0ms';
    var exitAnimationDuration = '0ms';


    // @ts-ignore
    let dialogRef = this.dialog.open(DialogAddCampaign, {
      width: '450px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: row_data
    });


    dialogRef.afterClosed().subscribe(result => {

      if (result == 'cancel' || result == undefined) {
        console.log('we cancled')
      } else {
        this.getData();
      }
    });//end dialogRef


  } // end addClient


  getTableRecord(row: object){
    console.log('getTableRecord', row);
  } //end getTableRecord


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  } //end applyFilter


  viewData(row: CampaignTableInterfaceData) {
    console.log('viewData', row);
    const campaign_id = row['campaign_id']
    this.currentCampaignSelectService.announceCampaignSelect(campaign_id);
    localStorage.setItem('campaign_id', campaign_id);
    //this.superAdminViewBehaviorService.announceSuperAdminViewBehavior(false);
    this.router.navigate(['/adserver-nav',  { outlets: { admeta: ['adgroups'] } }]).then(r => console.log('redirect to campaigns'));
  } //end viewData(row)


  deleteRow(row: CampaignTableInterfaceData){
    console.log('deleteRow', row);

    const campaign_id = row['campaign_id']
    console.log('deleteRow', campaign_id);


    this.campaignService.delete(`adserver/campaign/${campaign_id}`).subscribe(x => {
      //this.snackBarService.announceSackBar('Campaign Group Deleted');
      console.log(x)
      localStorage.removeItem('campaign_id')
      this.currentCampaignSelectService.announceCampaignSelect('');
      this.getData();
    });



  } //end deleteRow


} // end CampaignsComponent










/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/

/*---------------------------------------------
Dialog for adding a new client
---------------------------------------------*/
@Component({
  selector: 'add-campaign-dialog',
  templateUrl: 'add-campaign-dialog.html',
  imports: [
    MatCardModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    NgIf,
    MatDividerModule,
    MatListModule
  ],
  standalone: true
})
export class DialogAddCampaign implements OnInit{


  myForm!: FormGroup;
  is_form_error: boolean = false;
  form_error_message: string = "";
  is_loading = false;
  is_edit = false;
  title_text = "Create Campaign";
  submit_text = "Create"
  client_id = "";
  client_slug = "";
  campaign_group_id = "";
  campaign_id = "";





  constructor(private fb: FormBuilder,
              public dialogRef: MatDialogRef<DialogAddCampaign>,
              private campaignService: CampaignService,
              private snackBarService: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public data: CampaignInterface) {}

  ngOnInit(): void {


    this.client_id = this.data['client_id'] || '';
    this.client_slug = this.data['client_slug'] || '';
    this.campaign_group_id = this.data['campaign_group_id'] || '';
    this.campaign_id = this.data['campaign_id'] || '';



    console.log('DialogAddCampaign',this.data)
    let name = '';
    let description = '';
    let campaign_type = 'registration_reminders';
    let status = 'active';
    let start_date = "2021-10-01";
    let end_date = "2021-10-01";
    let audience = {};
    let frequency_cap_daily = 1;
    let frequency_cap_weekly = 1;
    let frequency_cap_total = 1;
    let campaign_blown = false;
    let hod = '';
    let dow = '';



    console.log('data@@@@@@@@@@@@@@',this.data)

    if (Object.keys(this.data).length==3) {
      console.log('we have empty data')
      this.is_edit = false;
    } else {
      name = this.data['name'];
      description = this.data['description'];
      status = this.data['status'];
      start_date = this.data['start_date'];
      end_date = this.data['end_date'];
      audience = this.data['audience'];
      frequency_cap_daily = this.data['frequency_cap_daily'];
      frequency_cap_weekly = this.data['frequency_cap_weekly'];
      frequency_cap_total = this.data['frequency_cap_total'];
      campaign_blown = this.data['campaign_blown'];
      hod = this.data['audience']['hod'];
      dow = this.data['audience']['dow'];


      this.is_edit = true;
      this.title_text = "Edit Campaign";
      this.submit_text = "Update"
    }

    this.myForm = this.fb.group({
      name: [name, [Validators.required]],
      description: [description, [Validators.required]],
      campaign_type: [{value: campaign_type, disabled: this.is_edit}],
      status: [status],
      start_date: [start_date],
      end_date: [end_date],
      //audience: [audience],
      frequency_cap_daily: [frequency_cap_daily],
      frequency_cap_weekly: [frequency_cap_weekly],
      frequency_cap_total: [frequency_cap_total],
      campaign_blown: [campaign_blown],
      audience: this.fb.group({
        hod: hod,
        dow: dow,
      })
    });

  } //end ngOnInit

  onFocus(){
    console.log('onFocus')
    this.is_form_error = false;
    this.form_error_message = "";
  }

  onCancel() {
    this.dialogRef.close('cancel');
  }

  onSubmit() {
    this.is_loading = true;
    let form_data = this.myForm.value;
    console.log(form_data)

    if (!this.is_edit) {
      this.campaignService.post(`adserver/campaign/create/${this.campaign_group_id}/${this.client_id}`, form_data).subscribe(x => {
        //this.snackBarService.announceSackBar('Client Created');
        this.dialogRef.close(form_data);
      }, error => {
        console.log('error',error)
        this.dialogRef.close('cancel');

      });

    } else {

      console.log('we are editing')
      let endpoint = `adserver/campaign/${this.campaign_id}`;
      console.log('endpoint',endpoint)
      this.campaignService.put(endpoint, form_data).subscribe(x => {
        //this.snackBarService.announceSackBar('Client Updated');
        this.dialogRef.close(form_data);
      }, error => {
        console.log('error',error)
        this.dialogRef.close('cancel');
      });
    }



  } //end onSubmit


} //end DialogAddClient
























