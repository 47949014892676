import {Injectable} from "@angular/core";
import {environment} from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import {of, map, Observable, delay} from "rxjs";
import {shareReplay} from "rxjs/operators";



export interface Calendar {
  "name": string;
  "client_id": number;
  "date": string;
}

export interface Calendars {
  alert:[]
}

export interface CalendarsResponse {
  message: Calendar[];
}





@Injectable({providedIn: 'root'})
export class CalendarService {

  url:string = `${environment.API_URL}`


  constructor(private http: HttpClient,) {
  }


  getCalendarByUser(endpoint: string, user_id: number, is_mocked: boolean = false ): Observable<Calendar[]> {

    if(is_mocked){
      return of<Calendar[]>(
        [
          {
            "name": "Dude1",
            "client_id": 2247,
            "date": "2023-02-27T19:46:36",
          },
          {
            "name": "Dude2",
            "client_id": 2247,
            "date": "2023-02-27T19:46:36",
          },
          {
            "name": "Dude3",
            "client_id": 2247,
            "date": "2023-02-27T19:46:36",
          }
          ]
      ).pipe(delay(2000))
    }

    const url = `${this.url}/${endpoint}/${user_id}`;
    return this.http.get<CalendarsResponse>(url).pipe(
      shareReplay(),
      map(
        res => {
          return res.message;
        }
      )
    );
  } //end getAlerts



} //
