
<mat-progress-bar color="accent" mode="indeterminate" *ngIf="is_loading"></mat-progress-bar>

<div class="full-screen-div">
<full-calendar #calendar
               [options]="calendarOptions"
               [events]="eventsPromise | async"
               [matTooltip]="tooltipMessage"
               matTooltipPosition="above"></full-calendar>
</div>
