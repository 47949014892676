<!--<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">-->
<!--  &lt;!&ndash; This is the tree node template for leaf nodes &ndash;&gt;-->
<!--  &lt;!&ndash; There is inline padding applied to this node using styles.-->
<!--    This padding value depends on the mat-icon-button width. &ndash;&gt;-->
<!--  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle (click)="onNodeClick(node)">-->
<!--    {{node.name}}-->
<!--  </mat-tree-node>-->
<!--  &lt;!&ndash; This is the tree node template for expandable nodes &ndash;&gt;-->
<!--  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">-->
<!--    <div class="mat-tree-node">-->
<!--      <button mat-icon-button matTreeNodeToggle-->
<!--              [attr.aria-label]="'Toggle ' + node.name">-->
<!--        <mat-icon class="mat-icon-rtl-mirror">-->
<!--          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}-->
<!--        </mat-icon>-->
<!--      </button>-->
<!--      {{node.name}}-->
<!--    </div>-->
<!--    &lt;!&ndash; There is inline padding applied to this div using styles.-->
<!--        This padding value depends on the mat-icon-button width.  &ndash;&gt;-->
<!--    <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"-->
<!--         role="group">-->
<!--      <ng-container matTreeNodeOutlet></ng-container>-->
<!--    </div>-->
<!--  </mat-nested-tree-node>-->
<!--</mat-tree>-->

<ng-container *ngIf="is_loading">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</ng-container>


<div>
  <mat-list>
    <mat-list-item>Campaign Group Sequences</mat-list-item>
    <mat-divider></mat-divider>
  </mat-list>
</div>

<ng-container *ngIf="client_id==''">
  <span>Must select Hospital</span>
</ng-container>

<!--formControlName="campaign_type"-->

<mat-form-field style="margin-top: 15px; width: 400px">
  <mat-label>Campaign Type:</mat-label>
  <mat-select (selectionChange)="onCampaignTypeChange($event)">
    <mat-option *ngFor="let campaign_type of campaign_type_list" [value]="campaign_type.value">
      {{ campaign_type.viewValue }}
    </mat-option>
  </mat-select>
  <mat-error>
    <strong>Campaign Type</strong>
  </mat-error>
</mat-form-field>

<br>
<br>
<br>

<form  (ngSubmit)="onSubmit()"  novalidate>


<ng-container *ngIf="movies">
  <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
    @for (movie of movies; track movie) {
      <div class="example-box" cdkDrag>
        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
        {{movie}}
      </div>
    }
  </div>
</ng-container>





<!--<div class="flex-container" style="margin-top: 10px;">-->
<!--  <button mat-raised-button color="primary" [disabled]="!myForm.valid || is_loading" type="submit" style="margin: 15px">{{submit_text}}</button>-->
<!--  <button mat-raised-button color="accent" [disabled]="is_loading" type="button" (click)="onCancel()" style="margin: 15px">Cancel</button>-->
<!--</div>-->

<div class="flex-container" style="margin-top: 10px;">
  <button mat-raised-button color="primary" [disabled]="is_loading" type="submit" style="margin: 15px">Submit</button>
  <button mat-raised-button color="accent" [disabled]="is_loading" type="button"  style="margin: 15px">Cancel</button>
</div>


</form>






