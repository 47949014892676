import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {LoginService} from "../login/login.service";
import {ModelValidators} from "../../validators/validators";
import {MatCardModule} from "@angular/material/card";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {environment} from "../../../environments/environment";
import {SnackBarService} from "../../objectPassingService";
import {Subject, takeUntil} from "rxjs";











@Component({
  selector: 'app-qr-code',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatCardModule, MatToolbarModule, MatProgressBarModule, MatFormFieldModule, MatButtonModule, MatInputModule],
  templateUrl: './qr-code.component.html',
  styleUrl: './qr-code.component.css'
})
export class QrCodeComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  destroyed = new Subject();

  is_loading = false;
  is_form_error: boolean = false;
  form_error_message: string = "";
  is_auth_error: boolean = false;

  session: string | null = "";
  qr: string | null = "";
  qr_image: string | null = null

  myForm: FormGroup = this.fb.group({
    mfa_code: ['', [Validators.required, ModelValidators.validMfa, ModelValidators.cannotContainSpace]]
  });

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private fb: FormBuilder,
    private loginService: LoginService,
    private snackBarService: SnackBarService,
  ) {
  }

  ngOnInit(): void {
    let query_parms = this.route.snapshot.queryParamMap;
    this.session = query_parms.get('session');
    this.qr = query_parms.get('qr');
    this.qr_image = this.qr;

    this.destroyRef.onDestroy(() => {
      this.destroyed.next('');
      this.destroyed.complete();
    });

  } //end ngOnInit


  goBack(){
    window.history.back();
  }

  onFocus(){
    // console.log('onFocus')
    // this.is_form_error = false;
    // this.form_error_message = "";
  }

  onSubmit(){

    this.is_loading = true;
    console.log('submit login data')
    this.is_form_error = false;
    let data = this.myForm.value;
    console.log(data);


    const url = `${environment.API_AUTH_URL}/auth/verifymfa`

    //let authObs: Observable<AuthResponseData>
    this.loginService.verifyMfaDevice(url, this.session, data['mfa_code']).pipe(takeUntil(this.destroyed)).subscribe(
      res => {
        //{message: 'Incorrect username or password.', status_code: 400}
        console.log('res', res)

        // @ts-ignore
        if (res.hasOwnProperty('enum') && (res['code']=='CodeMismatchException')){
          this.is_auth_error = true
          this.is_form_error = true;
          this.form_error_message = 'Invalid code received for user' //res['message']
        }

        // @ts-ignore
        if (res.hasOwnProperty('Status') && (res['Status']=='SUCCESS')){
          this.is_loading = false;
          this.snackBarService.announceSackBar('MFA device verified successfully');
          this.router.navigate([ 'login' ], )
        }







      },
      errorMessage => {
        this.is_loading = false;
        this.is_auth_error = true;
        this.is_form_error = true;
        this.form_error_message = 'Invalid code received for user' //res['message']
      },
      () => {
        console.log('auth completed')
      }
    ); //end subscribe



  }



} //end class
