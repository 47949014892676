import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatCardModule} from "@angular/material/card";
import {ActivatedRoute} from "@angular/router";
import {Calendar, CalendarService} from "./calendar.service";
import {Observable} from "rxjs";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatListModule} from "@angular/material/list";
import {LoadingCalendarService} from "../../objectPassingService";
import {MatProgressBarModule} from "@angular/material/progress-bar";

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatExpansionModule, MatListModule, MatProgressBarModule],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.css'
})
export class CalendarComponent implements OnInit{

  is_expanded: boolean = true;
  calendars$!: Observable<Calendar[]>

  constructor(
    private route: ActivatedRoute,
    private calendarService: CalendarService,
    public loadingCalendarService: LoadingCalendarService,

  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(parms => {
      const user_id = parms['user_id'];
      this.getCalendarData(user_id);
    });

  } // end ngOnInit


  getCalendarData(user_id: number) {

    const calendars$ =  this.calendarService.getCalendarByUser('calendars', user_id, true);

    this.loadingCalendarService.showLoadingUntilCompleted(calendars$).subscribe();
    this.calendars$ = calendars$.pipe();

  } // end getCalendarData

} // end Class
