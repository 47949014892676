import {Component, DestroyRef, EventEmitter, inject, Injectable, Input, OnInit, Output, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Subject} from "rxjs";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Router, RouterLinkActive} from "@angular/router";
import {InputEmailComponent} from "../input-email/input-email.component";
import {InputPasswordComponent} from "../input-password/input-password.component";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatInputModule} from "@angular/material/input";
import {MatDatepicker, MatDatepickerModule} from "@angular/material/datepicker";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  NativeDateAdapter
} from "@angular/material/core";
import {CustomDateAdapter} from "../../date.adapter";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";



@Component({
  selector: 'app-add-task',
  standalone: true,
  imports: [CommonModule, FormsModule, InputEmailComponent, InputPasswordComponent,
    MatButtonModule, MatCardModule, MatFormFieldModule, MatProgressBarModule,
    MatToolbarModule, ReactiveFormsModule, RouterLinkActive, MatInputModule,
    MatDatepickerModule, MatNativeDateModule, MatSlideToggleModule],
  providers: [
    MatDatepickerModule,MatNativeDateModule,
    {
      provide: DateAdapter, useClass: CustomDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        display: {
          dateInput: 'YYYY-MM-DD',
        },
      },
    }
  ],
  templateUrl: './add-task.component.html',
  styleUrl: './add-task.component.css'
})
export class AddTaskComponent implements OnInit {

  // npm i @angular/material-moment-adapter
  @ViewChild(MatDatepicker) datepicker!: MatDatepicker<Date>;


  @Input('user_id') user_id: number = 0;
  @Input('client_id') client_id: number = 0;


  @Output() close: EventEmitter<any> = new EventEmitter();


  minDate!: Date;
  maxDate!: Date;
  is_recurring: boolean = false;

  private destroyRef = inject(DestroyRef);
  destroyed = new Subject();

  myForm: FormGroup = this.fb.group({
    title: ['', Validators.required],
    due_on: ['', [Validators.required]],
    recurring: [false],
    repeat_each_day: [''],
  });
  is_loading: boolean = false;
  is_form_error: boolean = false;
  form_error_message: string = "";




  constructor(
    public router: Router,
    private fb: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
  ) { }

  ngOnInit() {

    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date(currentYear + 1, 11, 31);

    console.log(this.user_id);


    this.destroyRef.onDestroy(() => {
      this.destroyed.next('');
      this.destroyed.complete();
    });
  } // ngOnInit

  onFocus(){
    this.is_form_error = false;
  }

  onRecurringChange(event: any){
    console.log(event);
    this.myForm.controls['recurring'].setValue(event.checked);
    this.is_recurring = event.checked;
    // if(event.checked){
    //   this.myForm.controls['repeat_each_day'].setValidators([Validators.required]);
    // } else {
    //   this.myForm.controls['repeat_each_day'].setValidators([]);
    // }

  }

  onSubmit() {
    const form_data = this.myForm.value;
    console.log(form_data);
    this.is_loading = true;

    this.close.emit(form_data);
  } // onSubmit

}//
