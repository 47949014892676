<mat-drawer-container class="drawer-container"(backdropClick)="closeDrawer()" >
  <mat-drawer #drawer mode="over" position="end" [ngClass]="[getDrawerClass(action), getBackgroundColorClass(action), getPaddingClass(action)]">
    @if (action !== 'broadcast' && action !== 'patientInfo') {
    <div class="drawer-header">
      <div>
        {{getDrawerTitle(action, name)}}
      </div>
      <i [ngClass]="'healent-logout'" style="color: var(--secondary_shade_50)" (click)="closeDrawer()"></i>
    </div>
    }
    <div class="drawer-content" #drawerContent>
      <ng-container *ngIf="loading">
        <div class="drawer-spinner">
          <div class="spinner-content">
            <mat-spinner class="custom-spinner"></mat-spinner>
            <span class="loading-text">Loading...</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading">
        @if (action === 'healent-action-icon') {
        <app-alerts [alerts]="combinedAthenaAlerts || []" (alertStatusChanged)="onAlertStatusChanged($event)"
          *ngIf="(combinedAthenaAlerts || []).length  > 0">
        </app-alerts>
        <div class="manage-appointment">
          <div class="manage-header">
            <i [ngClass]="'healent-action-icon'"></i>
            <h2>
              Manage
            </h2>
          </div>
          <div class="drawer-table">
            <table mat-table [dataSource]="rowData" class="mat-elevation-z8">
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element?.name || '-'" matTooltipPosition="below" [matTooltipClass]="'dashboard-tooltip'">
                  {{ element?.name || '-' }}
                </td>
              </ng-container>

              <!-- Chart# Column -->
              <ng-container matColumnDef="chartNumber">
                <th mat-header-cell *matHeaderCellDef> Chart # </th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element?.chartNumber || '-'" [matTooltipClass]="'dashboard-tooltip'"
                  matTooltipPosition="below">
                  {{ element?.chartNumber || '-' }}
                </td>
              </ng-container>

              <!-- Provider Column -->
              <ng-container matColumnDef="provider">
                <th mat-header-cell *matHeaderCellDef> Provider </th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element?.provider || '-'" [matTooltipClass]="'dashboard-tooltip'"
                  matTooltipPosition="below">
                  {{ element?.provider || '-' }}
                </td>
              </ng-container>

              <!-- Appt Type Column -->
              <ng-container matColumnDef="apptType">
                <th mat-header-cell *matHeaderCellDef> Appt Type </th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element?.apptType || '-'" [matTooltipClass]="'dashboard-tooltip'"
                  matTooltipPosition="below">
                  {{ element?.apptType || '-' }}
                </td>
              </ng-container>

              <!-- Appt Time Column -->
              <ng-container matColumnDef="apptTime">
                <th mat-header-cell *matHeaderCellDef> Appt Time </th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element?.apptTime || '-'" [matTooltipClass]="'dashboard-tooltip'"
                  matTooltipPosition="below">
                  {{ element?.apptTime || '-' }}
                </td>
              </ng-container>

              <!-- Delete Action Column -->
              <!-- <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" style="color: var(--dangerous); text-align: right;">
                <div (click)="onCancelAppointment(rowObject.meetingId, $event)" style="cursor: pointer;" matTooltip="Cancel appointment" matTooltipPosition="below">
                  <span>
                    <i [ngClass]="'healent-android-delete'" style="margin-right: 5px;"></i>Cancel
                  </span>
                </div>
              </td>
            </ng-container> -->

              <!-- Table headers and rows -->
              <tr mat-header-row *matHeaderRowDef="['name', 'chartNumber', 'provider', 'apptType', 'apptTime']">
                <!-- add delete column after implementing the confimation overlay-->
              </tr>
              <tr mat-row *matRowDef="let row; columns: ['name', 'chartNumber', 'provider', 'apptType', 'apptTime']">
              </tr>
            </table>
          </div>
        </div>
        <div>
          <app-push-form-to-app [rowData]="rowObject" [missedSections]="missedSections" [timezone_name]="timezone_name"
            (triggerDataRefresh)="triggerDataRefresh($event)" [formDetails]="formDetails" [timezone]="timezone_name" [allAthenaNotes]="allAthenaNotes"
            [registrationPhase]="registrationPhase"></app-push-form-to-app>
        </div>
        <div>
          <app-process-appointment [rawRow]="rawRow" [tableType]="tableType" [isFuturePastDate]="isFuturePastDate"></app-process-appointment>
        </div>
        } @else if (action === 'healent-path-3625') {
        <app-contact-info (closeDrawerEvent)="closeDrawer()" [patientName]="rowObject.name"
          [contactDetails]="rowObject.contactDetails"></app-contact-info>
        } @else if (action === 'healent-action-icon-1') {
        <app-payment-info [patientName]="rowObject.name" [patientId]="rowObject.patientId"
          [meetingId]="rowObject.meetingId" (closeDrawerEvent)="closeDrawer()"
          (triggerDataRefresh)="triggerDataRefresh($event)" [patientBalance]="rowObject.payment_details.client_balance"
          [patientCopay]="rowObject.payment_details?.copay"></app-payment-info>
        } @else if (action === 'healent-path-3627') {
        <app-patient-notes [patientName]="rowObject.name" (closeDrawerEvent)="closeDrawer()" (markRowEvent)="markRow($event)"
          [meetingId]="rowObject.meetingId" [patientId]="rowObject.patientId" [rowData]="rowObject"></app-patient-notes>
        } @else if (action === 'broadcast') {
        <app-broadcast (closeDrawerEvent)="closeDrawer()" [selectedRecords]="selectedRecords"
          [title]="title"></app-broadcast>
        } @else if (action === 'patientInfo') {
          <app-patient-info (closeDrawerEvent)="closeDrawer()" [patientId]="rowObject.patientId"></app-patient-info>
        }
      </ng-container>
    </div>
  </mat-drawer>
</mat-drawer-container>
