<mat-form-field style="margin-top: 15px; width: 100%">
  <mat-label>Email:</mat-label>
  <input matInput
         [placeholder]="placeholder"
         [formControl]="formControl"
         [type]="type"
         autocomplete="email"
  >
  <mat-error style="font-size: 11px">Valid email required</mat-error>
</mat-form-field>



