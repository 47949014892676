<mat-progress-bar
  color="accent"
  mode="indeterminate"
  *ngIf="is_loading"
></mat-progress-bar>
<div class="main-container">
  <div class="main-header">
    <div class="header-left">
      <h1>Registration Status</h1>
    </div>
    <div class="header-right">
      <mat-form-field class="search-input">
        <i class="healent-search" matPrefix></i>
        <input
          matInput
          placeholder="Search patients"
          (keyup)="applyFilter($event)"
          #input
          [(ngModel)]="searchText"
        />
        <span class="btn-hint" matSuffix *ngIf="!searchText">
          (Shift + Space)
        </span>
        <i
          class="btn-close healent-group-4555"
          matSuffix
          *ngIf="searchText"
          (click)="clearSearch(input)"
        >
        </i>
      </mat-form-field>
    </div>
  </div>
  <div class="main-body">
    <div class="table-content">
      <mat-card class="mat-elevation-z24">
        <div class="users table-wrapper">
          <table mat-table [dataSource]="dataSource" matSort>
            <!-- User Id Column -->
            <ng-container matColumnDef="patient_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>EMR ID</th>
              <td mat-cell *matCellDef="let row">{{ row.patient_id }}</td>
            </ng-container>

            <ng-container matColumnDef="full_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let row">{{ row.full_name }}</td>
            </ng-container>

            <ng-container matColumnDef="created_at">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
              <td mat-cell *matCellDef="let row">
                {{ row.created_at | date: 'short' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="updated_at">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated</th>
              <td mat-cell *matCellDef="let row">
                {{ row.updated_at | date: 'short' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
              <td
                mat-cell
                *matCellDef="let row"
                [style.color]="getColor(row.status)"
              >
                {{ row.status }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              class="element-row"
              (click)="getTableRecord(row)"
            ></tr>
          </table>

          <mat-paginator
            [pageSizeOptions]="[5, 10, 20, 50, 100, 250, 500]"
            [pageSize]="20"
            showFirstLastButtons
            aria-label="Select page of periodic elements"
          >
          </mat-paginator>
        </div>
      </mat-card>
    </div>
  </div>
</div>
