<ng-container *ngIf="is_loading">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</ng-container>

<form [formGroup]="myForm" (ngSubmit)="onSubmit()" novalidate>


  <ng-container *ngIf="!is_loading">

    <mat-form-field style="margin-top: 15px; width: 400px">
      <mat-label>Client:</mat-label>
      <mat-select formControlName="client" (selectionChange)="onClientChange($event)">
        <mat-option *ngFor="let client of client_select_list" [value]="client.value">
          {{ client.viewValue }}
        </mat-option>
      </mat-select>
      <mat-error>
        <strong>Valid client required</strong>
      </mat-error>
    </mat-form-field>

<!--    <div class="flex-container">-->
<!--      <button mat-raised-button color="primary" [disabled]="!myForm.valid" type="submit"-->
<!--              style="margin: 15px">Submit-->
<!--      </button>-->
<!--      <button mat-raised-button color="accent" type="button" style="margin: 15px" (click)="cancel()">Cancel</button>-->
<!--    </div>-->

  </ng-container>


</form>
