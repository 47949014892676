import {Component, Input, OnInit, forwardRef, ChangeDetectionStrategy} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import {MatButtonModule} from "@angular/material/button";
import {ModelValidators} from "../../validators/validators";

@Component({
  selector: 'app-input-verification-code',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
  templateUrl: './input-verification-code.component.html',
  styleUrl: './input-verification-code.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputVerificationCodeComponent),
      multi: true,
    },
  ],
})
export class InputVerificationCodeComponent implements OnInit{

  /*
  <app-input-verification-code placeholder="Enter your code"  formControlName="mfa_code"></app-input-verification-code>
  */

  @Input('placeholder') placeholder: string = '';
  @Input() required: boolean = false;

  formControl = new FormControl('',);
  onTouched: any;
  onChange: any;

  ngOnInit() {
    // @ts-ignore
    const validators: ValidatorFn[] = [];
    validators.push(Validators.required);
    validators.push(Validators.minLength(6));
    validators.push(Validators.maxLength(6));
    this.formControl.addValidators(validators);
  } // end ngOnInit


  writeValue(value: any): void {
    this.formControl.setValue(value);
  } // end writeValue

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.formControl.valueChanges.subscribe(fn);
  } // end registerOnChange

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  } // end registerOnTouched

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  } // end setDisabledState




} // end class InputVerificationCodeComponent
