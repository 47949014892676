<div class="dialog-container">
  <div class="dialog-header">
    <div class="filter-icon">
      <i [ngClass]="'healent-custom-filer'"></i>
    </div>
    <h2 class="dialog-title">Save Filters</h2>
  </div>
  <div class="dialog-content">
    <p class="dialog-subtitle">Give applied filters a name to recognize them later</p>
    <input
      type="text"
      [(ngModel)]="filterName"
      placeholder="Enter filter name"
      class="input-field"
      (click)="$event.stopPropagation()"
      maxlength="20"
    >
    <div *ngIf="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
  </div>
  <div class="dialog-actions">
    <button
      class="save-button"
      (click)="onSave()"
      [disabled]="!filterName"
    >
      Save
    </button>
  </div>
</div>
