import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { LoadingService } from '../../objectPassingService';

@Injectable({
  providedIn: 'root'
})
export class TableActionsDrawerService {
  url: string = `${environment.API_URL}`;
  private triggerActionSubject = new Subject<any>();
  triggerAction$ = this.triggerActionSubject.asObservable();

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService
  ) { }

  getNotesData(endpoint: string, data: {}): Observable<any> {
    const request$ = this.http.get(`${this.url}/${endpoint}`, data);
    return this.loadingService.showLoadingUntilCompleted(request$);
  }

  putStatus(endpoint: string, data: {}): Observable<any> {
    const request$ = this.http.put(`${this.url}/${endpoint}`, data);
    return this.loadingService.showLoadingUntilCompleted(request$);
  }

  getPaymentBalance(endpoint: string, data: {}): Observable<any> {
    const request$ = this.http.get(`${this.url}/${endpoint}`, data);
    return this.loadingService.showLoadingUntilCompleted(request$);
  }

  deleteNotes(endpoint: string, payload: any) {
    const request$ = this.http.request('DELETE', `${this.url}/${endpoint}`, {
      body: payload,
      observe: 'response',
    });
    return this.loadingService.showLoadingUntilCompleted(request$);
  }

  triggerAction(data: any) {
    this.triggerActionSubject.next(data);
  }

  sendBroadCast(endpoint: string, data: any): Observable<any> {
    const request$ = this.http.post(`${this.url}/${endpoint}`, data);
    return this.loadingService.showLoadingUntilCompleted(request$);
  }

  updateCopay(endPoint: string, data: any): Observable<any> {
    const request$ = this.http.post(`${this.url}/${endPoint}`, data);
    return this.loadingService.showLoadingUntilCompleted(request$);
  }

  toggleAlertStatus(data: {alert_id: string; meeting_id: number; is_ignored: boolean }): Observable<any> {
    return this.http.patch(`${this.url}/alerts/athena`, data);
  }

  postformDetails(endpoint: string, data: {patient_id: number; timezone_name: string}): Observable<any> {
    return this.http.post(`${this.url}/${endpoint}`, data)
  }
}
