<div class="app-container">
  <div class="user-sidebar">
    <div class="search-container">
      <input
        type="text"
        placeholder="Search patient..."
        [(ngModel)]="searchTerm"
        (input)="filterUsers()"
      />
      <!-- Plus Button for adding new chat -->
      <button class="add-chat-button" (click)="openModal()">+</button>
    </div>

    <div class="user-list">
      <div
        *ngFor="let user of filteredUsers"
        (click)="selectUser(user)"
        [class.active]="user === selectedUser"
        [class.unread]="user.unreadCount > 0"
      >
        <span class="user-name">{{ user.name }}</span>
        <span *ngIf="user.unreadCount > 0" class="unread-badge">
          {{ user.unreadCount }}
        </span>
      </div>
    </div>
  </div>

  <!-- Chat Container -->
  <div class="chat-container">
    <div class="chat-messages">
      <!-- Display messages -->
      <div
        *ngFor="let message of messages"
        [ngClass]="{
          'message-wrapper': true,
          'sent': message.userId === from_user_id,
          'received': message.userId !== from_user_id
        }"
      >
        <!-- Message bubble -->
        <div
          [ngClass]="{
            'sent-message': message.userId === from_user_id,
            'received-message': message.userId !== from_user_id
          }"
        >
          <div class="message-content">
            {{ message.text }}
          </div>
        </div>
      </div>

      <div *ngIf="isTyping" class="typing-indicator">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>

    <!-- Chat Input Container -->
    <div *ngIf="selectedUser && !isDirectConversationOpen" class="chat-input-container">
      <input
        type="text"
        [(ngModel)]="currentMessage"
        (input)="onInputChange()"
        placeholder="Type a message"
        (keydown.enter)="sendMessage()"
      />
      <button (click)="sendMessage()">Send</button>
    </div>
  </div>

  <div class="modal-overlay" *ngIf="isModalOpen">
    <div class="modal">
      <span class="modal-close" (click)="closeModal()">&#10005;</span>
      <h2>Create New Conversation</h2>
      <button (click)="createDirectConversation()">Create Direct Conversation</button>
      <button (click)="createBroadcast()">Create Broadcast</button>
      <button (click)="createGroupConversation()">Create Group Conversation</button>
    </div>
  </div>
  <div class="large-modal-overlay" *ngIf="isDirectConversationOpen">
    <div class="large-modal">
      <span class="large-modal-close" (click)="closeDirectConversation()">&#10005;</span>
      <h2>Create Direct Conversation</h2>

      <!-- Search Input for Patients in the Modal -->
      <input
        type="text"
        [(ngModel)]="directConversationSearchTerm"
        (input)="filterUsers()"
        placeholder="Search for a patient..."
        class="patient-search-input"
        [ngClass]="{'error-input': isSearchFieldError}"
        [value]="selectedUser?.name || ''"
      />

      <!-- Display error message if chat with user already exists -->
      <div *ngIf="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>

      <!-- Show user list only if search term is not empty and showSearchResults is true -->
      <div class="patient-list" *ngIf="directConversationSearchTerm.trim() && showSearchResults">
        <div
          *ngFor="let user of directFilteredUsers"
          (click)="selectUser(user)"
          class="patient-item"
        >
          <span>{{ user.name }}</span>
        </div>
      </div>

      <!-- Message Input Field -->
      <div class="message-input-container">
        <textarea
          [(ngModel)]="currentMessage"
          placeholder="Type your message..."
          class="message-input"
          [ngClass]="{'error-input': isMessageFieldError}"
          (keydown.enter)="sendMessage()"
        ></textarea>
        <button class="send-button" (click)="sendMessage()">Send</button>
      </div>
      <!-- Error message displayed below the text box -->
      <div *ngIf="messageError" class="error-message">
        {{ messageError }}
      </div>
    </div>
  </div>
</div>
