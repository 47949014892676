import {Component, inject, OnInit} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {AsyncPipe, NgIf} from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule} from "@angular/forms";
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {
  CurrentCampaignGroupSelectService,
  CurrentClientIdSelectService,
  CurrentClientSlugSelectService,
  CurrentCampaignSelectService,
  CurrentAdgroupSelectService
} from "../../objectPassingService";

@Component({
  selector: 'app-adserver-nav',
  templateUrl: './adserver-nav.component.html',
  styleUrls: ['./adserver-nav.component.css'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    AsyncPipe,
    MatCheckboxModule,
    FormsModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    NgIf,
  ]
})
export class AdserverNavComponent implements OnInit {
  private breakpointObserver = inject(BreakpointObserver);

  events: string[] = [];
  opened: boolean | undefined;

  client_id: string = '';
  client_slug: string = '';
  campaign_group_id: string = '';
  campaign_id: string = '';
  adgroup_id: string = '';


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private currentClientIdSelectService: CurrentClientIdSelectService,
    private currentClientSlugSelectService: CurrentClientSlugSelectService,
    private currentCampaignGroupSelectService: CurrentCampaignGroupSelectService,
    private currentCampaignSelectService: CurrentCampaignSelectService,
    private currentAdgroupSelectService: CurrentAdgroupSelectService
  ) { } // end constructor

  ngOnInit(){

    this.currentClientIdSelectService.currentClientIdSelectAnnounced$.subscribe(client_id => {
      this.client_id = client_id;
      console.log('client_id', client_id);
    });

    this.currentClientSlugSelectService.currentClientSlugSelectAnnounced$.subscribe(client_slug => {
      this.client_slug = client_slug;
      console.log('client_slug', client_slug);
    });

    this.currentCampaignGroupSelectService.currentCampaignGroupSelectAnnounced$.subscribe(campaign_group_id => {
      console.log('campaign_group_id', campaign_group_id);
      this.campaign_group_id = campaign_group_id;
    });

    this.currentCampaignSelectService.currentCampaignSelectAnnounced$.subscribe(campaign_id => {
      console.log('campaign_id', campaign_id);
      this.campaign_id = campaign_id;
    });

    this.currentAdgroupSelectService.currentAdgroupSelectAnnounced$.subscribe(adgroup_id => {
      console.log('adgroup_id', adgroup_id);
      this.adgroup_id = adgroup_id;
    });

  } // end ngOnInit





} // end AdserverNavComponent
