import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from "./environments/environment";

// Disable console.log in production
if (environment.production) {
  window.console.log = () => { };
}

const clarityScriptId = environment.production
  ? window.location.hostname === 'navigator.cps.healent.com'
    ? 'q99n4hp44m'
    : 'q8pi1nne2n'
  : 'q8pi1nne2n'; // Use 'q8pi1nne2n' when not in production

const clarityScript = document.createElement('script');
clarityScript.type = 'text/javascript';
clarityScript.textContent = `
(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${clarityScriptId}")
`;

document.head.appendChild(clarityScript);

// Define titles for different slugs
const titles: Record<string, string> = {
  'akps': 'AK Pain & Spine',
  'cps': 'Commonwealth Pain & Spine',
};

const startTime = performance.now();

// Function to set the favicon and title dynamically
function setFavIconAndTitle(slug: string): void {
  const iconElement = document.querySelector('link[rel="icon"]');
  if (iconElement) {
    iconElement.setAttribute('href', `${slug}-fav.ico`);
  } else {
    const newIcon = document.createElement('link');
    newIcon.setAttribute('rel', 'icon');
    newIcon.setAttribute('href', `${slug}-fav.ico`);
    document.head.appendChild(newIcon);
  }

  if (titles[slug]) {
    document.title = titles[slug];
  } else {
    console.error(`Title not found for slug: ${slug}`);
  }

  document.body.classList.add(slug);
}

// Get the slug from the environment
const slug = environment.slug;
if (slug) {
  setFavIconAndTitle(slug);
} else {
  console.error('No slug found in the environment');
}

// Bootstrap the Angular application
bootstrapApplication(AppComponent, appConfig)
.then(() => {
  const endTime = performance.now();
  const initializationTime = endTime - startTime;
  console.log(`Angular initialization time: ${initializationTime}ms`);
})
.catch((err) => console.error(err));
