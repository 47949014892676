<div class="tasks-container">
  <div class="tasks-header">
    <div class="tasks-header-left">
      <div class="title-count">
        <h2>Tasks</h2>
        <span class="task-count">({{totalTasks}})</span>
      </div>
      <div class="toggles">
        <mat-slide-toggle [checked]="groupedTasks" (change)="toggleGrouping()" color="warn">
          Group tasks
        </mat-slide-toggle>
        <mat-slide-toggle [checked]="showCompleted" (change)="toggleCompleted()" color="primary">
          Completed tasks
        </mat-slide-toggle>
      </div>
    </div>
    <div class="tasks-header-right">
      <button class="button-class">
        <i [ngClass]="'healent-form-input-icon-8'"></i>
        <span>New</span>
      </button>
    </div>
  </div>

  <!-- No tasks message -->
  <div *ngIf="(groupedTasks && taskGroups.length === 0) || (!groupedTasks && filteredTasks.length === 0)" class="no-tasks-message">
    <p>No {{showCompleted ? 'completed' : 'active'}} tasks available</p>
  </div>

  <!-- Grouped Tasks View -->
  @if(groupedTasks && taskGroups.length > 0) {
  <mat-accordion displayMode="default" multi>
    <mat-expansion-panel *ngFor="let group of taskGroups; trackBy: trackByFn" [hideToggle]="true"
      #panel="matExpansionPanel" (click)="toggleExpansion(group.category)" [expanded]="group.expanded"
      class="mat-elevation-z0 expansion-class-task">
      <mat-expansion-panel-header style="height: 40px !important;">
        <mat-panel-title class="expansion-flex-class">
          <i [ngClass]="group.expanded ? 'healent-path-3322' : 'healent-path-3321-1'"></i>
          <span style="color: var(--accent);">{{ group.category }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="table-wrapper">
        <table mat-table [dataSource]="group.tasks" class="grouped-table">
          <ng-container matColumnDef="patientname">
            <th mat-header-cell *matHeaderCellDef class="w20">Patient Name</th>
            <td mat-cell *matCellDef="let task" class="w20" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
              <div class="flex-class">
                <mat-checkbox [checked]="task.completed" (change)="toggleTaskCompletion(task)" color="primary"
                  (click)="$event.stopPropagation()">
                </mat-checkbox>
                <span class="task-title" (click)="openPatientInfo(task)">{{task.title}}</span>
              </div>
            </td>
          </ng-container>

          @if (showCompleted) {
          <ng-container matColumnDef="dateTime">
            <th mat-header-cell *matHeaderCellDef class="w30">Date & Time</th>
            <td mat-cell *matCellDef="let task" class="w30" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
              <div class="flex-class">
                <span class="icon-background">
                  <i [ngClass]="'healent-age'"></i>
                </span>
                <span>{{task.date}} - {{task.apptTime}}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="nursename">
            <th mat-header-cell *matHeaderCellDef class="w15">Nurse Name</th>
            <td mat-cell *matCellDef="let task" class="w15" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
              <div class="flex-class">
                <span>{{task.nurseName}}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="actionstatus">
            <th mat-header-cell *matHeaderCellDef class="w25">Action Status</th>
            <td mat-cell *matCellDef="let task" class="w25" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
              <div class="flex-class">
                <span>{{task.completedStatus}}</span>
              </div>
            </td>
          </ng-container>
          } @else {
          <ng-container matColumnDef="phoneNum">
            <th mat-header-cell *matHeaderCellDef class="w20">Phone Number</th>
            <td mat-cell *matCellDef="let task" class="w20" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
              <div class="flex-class">
                <span>{{task.phoneNum}}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="w25">Status</th>
            <td mat-cell *matCellDef="let task" class="w25" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
              <div class="flex-class">
                <span class="icon-background">
                  <i [ngClass]="'healent-topic'"></i>
                </span>
                <span>{{task.status}}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="readings">
            <th mat-header-cell *matHeaderCellDef class="w15">Readings</th>
            <td mat-cell *matCellDef="let task" class="w15" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
              <div class="flex-class">
                <span>{{task.readings}} Readings</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="w15">Last called-on</th>
            <td mat-cell *matCellDef="let task" class="w15" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
              <div class="flex-class">
                <span class="icon-background">
                  <i [ngClass]="'healent-last-successful-call'"></i>
                </span>
                <span>{{task.date}}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="w5">Actions</th>
            <td mat-cell *matCellDef="let task" class="w5" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
              <div class="actions-group">
                <span class="icon-background" matTooltip="Call patient" matTooltipClass="dashboard-tooltip">
                  <i [ngClass]="'healent-call-1'"></i>
                </span>
                <span class="icon-background" [style.background-color]="getPriorityColor(task.priority)" matTooltip="{{ task.priority }}" matTooltipClass="dashboard-tooltip">
                  <i [ngClass]="'healent-priority'"></i>
                </span>
                <span class="icon-background" matTooltip="Edit" matTooltipClass="dashboard-tooltip" (click)="openEditDrawer(task); $event.stopPropagation()">
                  <i [ngClass]="'healent-edit'"></i>
                </span>
              </div>
            </td>
          </ng-container>
          }

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  } @else if (!groupedTasks && filteredTasks.length > 0) {
  <div class="table-wrapper">
    <table mat-table [dataSource]="filteredTasks" class="ungrouped-table">
      <ng-container matColumnDef="patientname">
        <th mat-header-cell *matHeaderCellDef class="w20">Patient Name</th>
        <td mat-cell *matCellDef="let task" class="w20" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
          <div class="flex-class">
            <mat-checkbox [checked]="task.completed" (change)="toggleTaskCompletion(task)" color="primary">
            </mat-checkbox>
            <span class="task-title" (click)="openPatientInfo(task)">{{task.title}}</span>
          </div>
        </td>
      </ng-container>

      @if (showCompleted) {
      <ng-container matColumnDef="dateTime">
        <th mat-header-cell *matHeaderCellDef class="w30">Date & Time</th>
        <td mat-cell *matCellDef="let task" class="w30" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
          <div class="flex-class">
            <span class="icon-background">
              <i [ngClass]="'healent-age'"></i>
            </span>
            <span>{{task.date}} - {{task.apptTime}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="nursename">
        <th mat-header-cell *matHeaderCellDef class="w15">Nurse Name</th>
        <td mat-cell *matCellDef="let task" class="w15" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
          <div class="flex-class">
            <span>{{task.nurseName}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actionstatus">
        <th mat-header-cell *matHeaderCellDef class="w25">Action Status</th>
        <td mat-cell *matCellDef="let task" class="w25" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
          <div class="flex-class">
            <span>{{task.completedStatus}}</span>
          </div>
        </td>
      </ng-container>
      } @else {
      <ng-container matColumnDef="phoneNum">
        <th mat-header-cell *matHeaderCellDef class="w20">Phone Number</th>
        <td mat-cell *matCellDef="let task" class="w20" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
          <div class="flex-class">
            <span>{{task.phoneNum}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="w25">Status</th>
        <td mat-cell *matCellDef="let task" class="w25" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
          <div class="flex-class">
            <span class="icon-background">
              <i [ngClass]="'healent-topic'"></i>
            </span>
            <span>{{task.status}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="readings">
        <th mat-header-cell *matHeaderCellDef class="w15">Readings</th>
        <td mat-cell *matCellDef="let task" class="w15" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
          <div class="flex-class">
            <span>{{task.readings}} Readings</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef class="w15">Last called-on</th>
        <td mat-cell *matCellDef="let task" class="w15" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
          <div class="flex-class">
            <span class="icon-background">
              <i [ngClass]="'healent-last-successful-call'"></i>
            </span>
            <span>{{task.date}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="w5">Actions</th>
        <td mat-cell *matCellDef="let task" class="w5" [@taskRemoval]="task.isRemoving ? 'removing' : ''">
          <div class="actions-group">
            <span class="icon-background" matTooltip="Call patient" matTooltipClass="dashboard-tooltip">
              <i [ngClass]="'healent-call-1'"></i>
            </span>
            <span class="icon-background" [style.background-color]="getPriorityColor(task.priority)" matTooltip="{{ task.priority }}" matTooltipClass="dashboard-tooltip">
              <i [ngClass]="'healent-priority'"></i>
            </span>
            <span class="icon-background" matTooltip="Edit" matTooltipClass="dashboard-tooltip" (click)="openEditDrawer(task); $event.stopPropagation()">
              <i [ngClass]="'healent-edit'"></i>
            </span>
          </div>
        </td>
      </ng-container>
      }

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  }

  <!-- Existing patient info drawer -->
  <mat-drawer-container class="drawer-container" *ngIf="isDrawerOpen" (backdropClick)="closeDrawer()">
    <mat-drawer #drawer mode="over" position="end" [opened]="true" style="background-color: var(--secondary_shade_20); padding: 0;" ngClass="drawer-large">
      <app-patient-info *ngIf="selectedPatientId" (closeDrawerEvent)="closeDrawer()" [patientId]="selectedPatientId">
      </app-patient-info>
    </mat-drawer>
  </mat-drawer-container>

  <!-- Edit alert drawer -->
  <mat-drawer-container class="drawer-container" *ngIf="isEditDrawerOpen" (backdropClick)="cancelEdit()">
    <mat-drawer #editDrawer mode="over" position="end" [opened]="true" style="background-color: var(--secondary_shade_20); padding: 30px; width: 35%;">
      <div class="edit-drawer-content">
        <div class="drawer-header">
          <h2>Edit alert</h2>
          <i class="healent-logout" (click)="cancelEdit()" style="cursor: pointer; font-size: 24px; color: var(--secondary_shade_50);"></i>
        </div>
        <div class="edit-form-container">
          <div class="static-info">
            <p><strong>Patient name:</strong> {{ selectedTask?.title }}</p>
            <p><strong>Patient ID:</strong> {{ selectedTask?.patientId }}, <strong>MRN:</strong> {{ selectedTask?.mrn }}, <strong>Facility:</strong> {{ selectedTask?.facility }}</p>
            <p><strong>Topic:</strong> {{ selectedTask?.topic }}</p>
            <p><strong>Date:</strong> {{ selectedTask?.date }}</p>
          </div>
          <form [formGroup]="editAlertForm" (ngSubmit)="confirmEdit()" class="edit-form">
            <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label>Alert status</mat-label>
                <mat-select formControlName="status">
                  <mat-option *ngFor="let status of statuses" [value]="status">
                    {{ status }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Intervention</mat-label>
                <textarea matInput formControlName="intervention" rows="8"></textarea>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Patient action</mat-label>
                <textarea matInput formControlName="patientAction" rows="8"></textarea>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Nurse action</mat-label>
                <textarea matInput formControlName="nurseAction" rows="8"></textarea>
              </mat-form-field>
            </div>
          </form>
          <div class="form-actions">
            <button type="submit" mat-button color="primary" class="action-button" (click)="confirmEdit()">Confirm</button>
            <button type="button" mat-button class="action-button" (click)="cancelEdit()">Close</button>
          </div>
        </div>
      </div>
    </mat-drawer>
  </mat-drawer-container>
</div>
