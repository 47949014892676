<div class="clinical-dashboard-class">
  <div class="header">
    <div class="header-left">
      Clinical Dashboard
    </div>
    <div class="header-right">

    </div>
  </div>
  <div class="cd-body">
    <div class="calendar-stepper">
      <div class="stepper-top">
        <h2 class="calendar-title">Calendar</h2>
        <app-custom-datepicker
        [(ngModel)]="selectedDate"
        (dateChange)="onDateChange($event)"
        style="height: 30px"
        [isAlternativeStyle]="true"
      ></app-custom-datepicker>
      </div>

      <div class="stepper-container">
        <mat-stepper [orientation]="'vertical'" #stepper [disableRipple]="true">
          <mat-step *ngFor="let step of steps; let i = index" [label]="step.title" [state]="step.state" [completed]="false" [editable]="false">
            <ng-template matStepLabel>
              <div (click)="openDrawer(i + 1)" class=“openDrawerTitle” style="display: flex; flex-direction: row; justify-content: space-between; font-weight: bold; font-size: 14px;">
                <div>{{ step.title }}</div>
                <div>{{step.time}}</div>
              </div>
            </ng-template>
            <ng-container *ngFor="let step of steps">
              <ng-template [matStepperIcon]="step.state">
                <div class="custom-icon" (click)="openDrawer(i + 1)">
                  <i style="font-size: 20px;" [ngClass]="step.icon"></i>
                </div>
              </ng-template>
            </ng-container>
          </mat-step>
        </mat-stepper>
      </div>
      <div class="button-container">
        <button class="new-appt-button">
          <i [ngClass]="'healent-form-input-icon-8'"></i>
          <span>New Appointment</span>
        </button>
      </div>
    </div>
    <div class="tasks-card">
      <app-tasks></app-tasks>
    </div>
    <div class="alerts-messages">
      <div class="alerts">
        <div class="alerts-header">
          <div class="alerts-header-left">
            <h2>Alerts</h2>
            <span style="margin-top: 8px; color: var(--dangerous);">({{alertsCount}})</span>
          </div>
          <div>
            <i class="healent-path-3621 blink"></i>
          </div>
        </div>
        <div class="alerts-content">
          <div class="alert-card" *ngFor="let alert of alerts">
            <div class="alert-content">
              <div class="patient-info">
                <p class="patient-name">{{ alert.patientName }}</p>
                <div class="alert-details">
                  <p class="alert-text">
                    <span class="alert-type">{{ alert.readingType }}</span> - <span class="alert-time">{{ alert.time }}</span>
                  </p>
                  <div class="alert-icons">
                    <ng-container *ngFor="let icon of alertIcons">
                      <i [ngClass]="icon" class="alert-icon"></i>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="messages">
        <div class="messages-header">
          <div class="messages-header-left">
            <h2>Messages</h2>
            <span style="margin-top: 8px; color: var(--secondary_shade_50);">(0)</span>
          </div>
        </div>
        <div class="messages-content">
          <p class="no-messages">No new messages</p>
        </div>
      </div>
    </div>
  </div>

</div>

<mat-drawer-container class="drawer-container" *ngIf="isDrawerOpen" (backdropClick)="closeDrawer()">
  <mat-drawer #drawer mode="over" position="end" [opened]="true">
    <app-appointment-details (closeDrawer)="closeDrawer()" [buttonConfig]="appointmentButtons"></app-appointment-details>
  </mat-drawer>
</mat-drawer-container>
