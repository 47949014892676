import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { PatientInfoComponentComponent } from './components/patient-info-component/patient-info-component.component';
import { TableActionsDrawerService } from '../../table-actions-drawer.service';
import { AppointmentListComponent } from '../../../appointments/appointment-list/appointment-list.component';
import { AppointmentDetailsComponent } from "../../../../clinical-dashboard/components/appointment-details/appointment-details.component";
import { EditAppointmentComponent } from '../../../appointments/edit-appointment/edit-appointment.component';

export interface Client {
  id: number;
  name: string;
  email: string;
}

export interface Note {
  id: number;
  text: string;
  appointmentTimeUsed: number; // Time used in seconds
}

export interface Appointment {
  id: number;
  client: Client;
  type: string | null;
  startDate: string; // Format: DD/MM/YYYY
  startTime: string; // Format: HH:MM AM/PM
  duration: number; // In minutes
  timeSpent: number; // In minutes
  programType: string;
  note: string;
  completed: boolean;
}

export interface AppointmentResponse {
  id: number;
  client: Client;
  note: Note;
  start_date_time_utc: string; // Format: "YYYY-MM-DD HH:mm:ss"
  duration_minutes: number;
  duration_seconds: number;
  appointment_type: string | null;
  is_completed: boolean;
  planned_duration: number;
  monitoring_type: string;
}

@Component({
  selector: 'app-patient-info',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatDialogModule,
    PatientInfoComponentComponent,
    AppointmentDetailsComponent,
    AppointmentListComponent,
    EditAppointmentComponent
  ],
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.css']
})
export class PatientInfoComponent implements OnChanges {
  @Output() closeDrawerEvent = new EventEmitter();
  @Input({ required: true }) patientId!: string | number;
  @ViewChild('deleteModal') deleteModal: any;
  @ViewChild('appointmentList') appointmentList!: AppointmentListComponent;

  buttons = [
    { key: 'PatientInfo', label: 'Patient Info' },
  ];
  selectedSection = 'PatientInfo';
  patientInfoData: any;
  isDrawerOpen = false;
  isEditing = false;
  selectedAppointment: Appointment | null = null;
  appointmentButtons: any[] = [
    { key: 'create', label: 'Create', action: 'create', customClass: 'create-button' },
    { key: 'cancel', label: 'Cancel', action: 'cancel', customClass: 'cancel-button' }
  ];
  private dialogRef: MatDialogRef<any> | null = null; // Store the dialog reference

  constructor(private tableActionsService: TableActionsDrawerService, private dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['patientId'] && this.patientId) {
      this.tableActionsService.getPatientInfo({}, this.patientId).subscribe({
        next: (res) => {
          console.log(res, '=======response===');
          this.patientInfoData = res?.message;
        },
        error: () => {
          console.error('Failed to load patient info');
        }
      });
    }
    console.log(this.patientInfoData, '=====');
  }

  navigateTo(section: string) {
    this.selectedSection = section;
    console.log(`Navigating to ${section}`);
  }

  triggerCloseDrawer() {
    this.closeDrawerEvent.emit();
  }

  openEditAppointment(appointment: Appointment) {
    this.selectedAppointment = appointment;
    this.isEditing = true;
  }

  openNewAppointment() {
    this.isDrawerOpen = true;
  }

  closeDrawer() {
    this.isDrawerOpen = false;
  }

  backToList() {
    this.isEditing = false;
    this.selectedAppointment = null;
    this.refreshAppointmentList();
  }

  deleteAppointment(appointment: Appointment) {
    // Open the dialog and store the reference
    this.dialogRef = this.dialog.open(this.deleteModal, {
      width: '400px',
      panelClass: 'custom-modal', // Add a custom class for styling
      data: { appointment } // Pass the appointment data to the dialog if needed
    });

    this.dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        console.log('Deleting appointment:', appointment);
        this.tableActionsService.removeAppointment(appointment.id).subscribe({
          next: () => {
            console.log('Appointment deleted successfully');
            this.backToList();
          },
          error: () => {
            console.error('Failed to delete appointment');
          }
        });
      }
      this.dialogRef = null; // Clear the reference after closing
    });
  }

  confirmDelete() {
    if (this.dialogRef) {
      this.dialogRef.close(true); // Close the dialog with a true result
    }
  }

  cancelDelete() {
    if (this.dialogRef) {
      this.dialogRef.close(false); // Close the dialog with a false result
    }
  }

  refreshAppointmentList() {
    if (this.appointmentList) {
      this.appointmentList.loadAppointments();
    }
  }
}