import {Component, inject, OnInit} from '@angular/core';
import {MatDivider} from "@angular/material/divider";
import {MatDrawer, MatDrawerContainer, MatDrawerContent, MatSidenav} from "@angular/material/sidenav";
import {MatListItem, MatNavList} from "@angular/material/list";
import {AsyncPipe, NgIf} from "@angular/common";
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";

@Component({
  selector: 'app-registration-nav',
  standalone: true,
  imports: [
    MatDivider,
    MatDrawer,
    MatDrawerContainer,
    MatDrawerContent,
    MatListItem,
    MatNavList,
    NgIf,
    RouterLinkActive,
    RouterOutlet,
    RouterLink,
    AsyncPipe,
    MatSidenav,
    MatIcon,
    MatIconButton
  ],
  templateUrl: './registration-nav.component.html',
  styleUrl: './registration-nav.component.css'
})
export class RegistrationNavComponent  implements OnInit {



  events: string[] = [];
  opened: boolean | undefined;

  private breakpointObserver = inject(BreakpointObserver);
  // https://v6.material.angular.io/cdk/layout/overview
  // Small: 0px - 599px is the breakpoint for mobile devices.
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall, Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
    .pipe(
      takeUntilDestroyed(),
      map(result => {
        return result.matches
      }),
      shareReplay()
    );

  constructor() { } // End of constructor

  ngOnInit() {
  } // End of ngOnInit

} // End of RegistrationNavComponent
