import {Injectable, NgZone} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Observable, retry, tap} from "rxjs";
import {CurrentLoggedInBehaviorService, CurrentRefreshTokenService, SuperAdminViewBehaviorService} from "../../objectPassingService";
import {Router} from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import {environment} from "../../../environments/environment";
import {CampaignGroupInterface} from "../adserver.interface";




@Injectable({providedIn: 'root'})
export class CampaignGroupService {

  url:string = `${environment.API_URL}`

  constructor(
    private http: HttpClient,
    public router: Router,
    private currentLoggedInBehaviorService: CurrentLoggedInBehaviorService,
    private currentRefreshTokenService: CurrentRefreshTokenService,
    private superAdminViewBehaviorService: SuperAdminViewBehaviorService,
    public ngZone: NgZone,
    private cookieService: CookieService
  ) {}


  get(endpoint: string, is_mocked: boolean = false ): Observable<any> {
    const url = `${this.url}/${endpoint}`;
    return this.http.get<any>(url);
  } //end getAlerts

  post(endpoint: string, data: CampaignGroupInterface){
    console.log('post data', `${this.url}/${endpoint}`);
    return this.http.post(`${this.url}/${endpoint}`, data,{observe: 'response'});
  }

  put(endpoint: string, data: CampaignGroupInterface){
    return this.http.put(`${this.url}/${endpoint}`, data,{observe: 'response'});
  }

  delete(endpoint: string): Observable<any> {
    const url = `${this.url}/${endpoint}`;
    return this.http.delete<any>(url);
  } //end getAlerts


}
