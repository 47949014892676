<mat-form-field style="margin-top: 15px; width: 100%">
  <mat-label>Password:</mat-label>
  <input matInput [type]="hide ? 'password' : 'text'" [placeholder]="placeholder"  [formControl]="formControl" autocomplete="current-password">
  <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
    <mat-icon class="add-button">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
  </button>
  <mat-error style="font-size: 11px">Requires at least 1 Uppercase, Lowercase, Number and Special Character and min lentgh of 8</mat-error>
</mat-form-field>


