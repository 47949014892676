import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RegistrationService } from '../../../../registration/registration.service';
import { MatList, MatListItem } from '@angular/material/list';

type ManageDrawerIntrface = {
  name: string;
  chartNumber: string;
  provider: string;
  apptType: string;
  apptTime: string;
  contactDetails: {
    email: string;
    phone: string;
  }
  meetingId: number;
  patientId: number;
  athenaNotes: string[]
}

type ActionButtonInterface = { type: string, label: string, className: string, added: boolean, formId: number }

@Component({
  selector: 'app-push-form-to-app',
  standalone: true,
  imports: [CommonModule, FormsModule, MatList, MatListItem],
  templateUrl: './push-form-to-app.component.html',
  styleUrl: './push-form-to-app.component.css'
})
export class PushFormToAppComponent implements OnChanges {
  searchTerm: string = '';
  selectedItems: { form_name: string; form_id: number, formatted_form_name: string }[] = [];
  allItems: { form_name: string; form_id: number, formatted_form_name: string }[] = [
    { form_name: 'Full Registration', form_id: 1, formatted_form_name: 'Full Registration' },
    { form_name: 'Express', form_id: 12, formatted_form_name: 'Express' },
    { form_name: 'PHQ9', form_id: 9, formatted_form_name: 'PHQ9' },
    { form_name: 'GAD7', form_id: 7, formatted_form_name: 'GAD7' },
    { form_name: 'ORT', form_id: 8, formatted_form_name: 'ORT' },
    { form_name: 'NDI', form_id: 10, formatted_form_name: 'NDI' },
    { form_name: 'ODI', form_id: 11, formatted_form_name: 'ODI' },
    { form_name: 'CCM Consent', form_id: 5, formatted_form_name: 'CCM Consent' },
    { form_name: 'RTM Consent', form_id: 6, formatted_form_name: 'RTM Consent' }
  ];
  @Input({ required: true }) rowData!: ManageDrawerIntrface;
  @Input({ required: true }) missedSections!: { form_name: string; form_id: number }[];
  @Output() triggerDataRefresh = new EventEmitter<boolean>(false);

  // Button flags
  actionButtons: ActionButtonInterface[] = [];
  isLoading: boolean = false;
  notes: string[] = [];
  showAddButton: boolean = false; // Control the visibility of the button
  selectedText: string = '';
  allWords: string[] = []

  constructor(private registrationService: RegistrationService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rowData'] && this.rowData) {
      console.log(this.rowData, 'push form to app row')
      this.selectedItems = [];
      this.notes = this.rowData.athenaNotes;
      this.allWords = this.rowData.athenaNotes.flatMap(note => note.split(' '));
    }

    this.initializeActionButtons();
  }

  initializeActionButtons() {
    // Initial buttons
    this.actionButtons = [];

    // Add missed sections to the beginning of the action buttons array
    if (this.missedSections && this.missedSections.length > 0) {
      this.missedSections.forEach((section) => {
        this.actionButtons.unshift({
          type: section.form_name,
          label: `+${section.form_name}`,
          className: 'missed-section-button',
          added: false,
          formId: section.form_id
        });
      });
    }
  }

  filterItems(search: string) {
    return this.allItems.filter(item =>
      item.form_name.toLowerCase().includes(search.toLowerCase())
    );
  }

  isChecked(item: { form_name: string; form_id: number }): boolean {
    return this.selectedItems.some(selected => selected.form_name === item.form_name);
  }

  // event handler for selection container
  toggleItem(item: { form_name: string; form_id: number, formatted_form_name: string }) {
    const index = this.selectedItems.findIndex(selected => selected.form_name === item.form_name);
    if (index > -1) {
      this.removeItem(item);
    } else {
      this.selectedItems.unshift(item);
      const buttonIndex = this.actionButtons.findIndex(button => button.type === item.form_name);
      if (buttonIndex > -1) {
        this.actionButtons[buttonIndex].added = true;
      }
    }
  }

  // event handler to remove the items from the to be pushed container
  removeItem(item: { form_name: string; form_id: number }) {
    const index = this.selectedItems.findIndex(selected => selected.form_name === item.form_name);
    if (index > -1) {
      this.selectedItems.splice(index, 1);
      const buttonIndex = this.actionButtons.findIndex(button => button.type === item.form_name);
      if (buttonIndex > -1) {
        this.actionButtons[buttonIndex].added = false;
      }
    }
  }

  // event handler for action buttons
  // addToPushList(type: ActionButtonInterface, event: MouseEvent) {
  //   event.stopPropagation();
  //   const buttonIndex = this.actionButtons.findIndex(btn => btn.type === type.type);
  //   if (buttonIndex > -1) {
  //     if (buttonIndex > -1) {
  //       this.selectedItems.unshift({
  //         form_id: type.formId,
  //         form_name: type.type,
  //         formatted_form_name: type.type
  //       })
  //     }
  //     this.actionButtons[buttonIndex].added = true;
  //   }
  // }

  // push form to app button event
  pushItems(event: MouseEvent) {
    event.stopPropagation();
    this.isLoading = true;
    const endPoint = `dashboard/form/status/${this.rowData.patientId}/${this.rowData.meetingId}`;
    this.registrationService.patchStatus(endPoint, {
      send_back_forms: this.selectedItems.map((item) => {
        return {
          form_id: item.form_id,
          form_name: item.form_name.split('(')[0].trim()
        }
      })
    }).subscribe((res) => {
      if (res.status === 200) {
        this.triggerDataRefresh.emit(true);
        this.isLoading = false;
        this.initializeActionButtons();
        this.selectedItems = []
      }
    }, (error) => {
      console.error(error);
      this.isLoading = false;
    });
  }

  // this was written when using mouseup event
  // showIcon(event: MouseEvent): void {
  //   event.stopPropagation()
  //   const selection = window.getSelection();
  //   console.log(window.getSelection()?.toString(), '======')
  //   if (selection && selection.toString() && window.getSelection()?.toString().trim()) {
  //     this.selectedText = selection.toString();
  //     this.showAddButton = true;
  //   } else {
  //     this.showAddButton = false;
  //   }
  // }

  addSelectedText(event: MouseEvent, btnType: string): void {
    event.stopPropagation();
    if (this.selectedText !== '') {
      const ifFormExists = this.selectedItems.some(item => item.form_name === this.selectedText.trim());
      // const isWordExists = this.selectedText.trim().split(' ').every((word) => this.allWords.includes(word))
      if (!ifFormExists) {
        if (btnType === 'sedation') {
          this.selectedItems.unshift({
            form_name: this.selectedText.trim(),
            form_id: 2,
            formatted_form_name: `Sedation (${this.selectedText.trim()})`
          });
        } else {
          this.selectedItems.unshift({
            form_name: this.selectedText.trim(),
            form_id: 3,
            formatted_form_name: `No Sedation (${this.selectedText.trim()})`
          });
        }
      }
      this.showAddButton = false;
      this.selectedText = '';
    }
  }

  //using selection change as we can select using shift+arrows too selectionchange detes both mouseup and selection using keyboard events
  @HostListener('document:selectionchange', ['$event'])
  onSelectionChange(): void {
    const selection = window.getSelection();

    if (selection && selection.toString().trim()) {
      // Ensure selection is within the .emr-text element
      const selectionContainer = selection.anchorNode?.parentElement;
      if (selectionContainer?.classList.contains('emr-text')) {
        this.selectedText = selection.toString();
      }
    }
  }

  // Hide the button if clicked outside using emr-text as hiding button is handled on the emr-text
  // @HostListener('document:click', ['$event'])
  // handleClickOutside(event: MouseEvent) {
  //   const target = event.target as HTMLElement;
  //   const isInside = target.closest('.emr-text') !== null;
  //   if (!isInside) {
  //     this.showAddButton = false;
  //   }
  // }
}
