<mat-accordion>

  <div *ngIf="loadingMessagesService.loading$ | async">
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
  <mat-expansion-panel [expanded]="is_expanded" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Messages
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-list>
      <div mat-subheader></div>
      <ng-container *ngFor="let message of (messages$ | async)">
        <mat-list-item>
          <div matListItemTitle>{{message.name}}</div>
          <div matListItemLine>{{message.message}}</div>
          <div matListItemLine>{{message.date | date}}</div>
        </mat-list-item>
      </ng-container>
    </mat-list>
  </mat-expansion-panel>

</mat-accordion>