<div>
  <div class="haeder-patient-info">
    <div class="patient-info-header-right">
      <div class="header-description">Default Hospital - Patient Name</div>
      <div class="header-buttons">
        <button *ngFor="let button of buttons" class="header-button" [class.selected]="selectedSection === button.key" (click)="navigateTo(button.key)">
          {{ button.label }}
        </button>
      </div>
    </div>
    <i [ngClass]="'healent-logout'" style="color: var(--secondary_shade_50); cursor: pointer; display: flex; align-items: center;" (click)="triggerCloseDrawer()"></i>
  </div>
  <div class="body-patient-info">
    <div style="width: 46%;">
      <app-appointment-list *ngIf="!isEditing" #appointmentList [patientId]="patientId" (openDetails)="openEditAppointment($event)" (createNew)="openNewAppointment()"></app-appointment-list>
      <app-edit-appointment *ngIf="isEditing" [appointment]="selectedAppointment!" (backToList)="backToList()" (deleteConfirmed)="deleteAppointment($event)"></app-edit-appointment>
    </div>
    <div class="appointment-actions">
      <div class="actions-header">
        Patient Info
      </div>
      <div class="actions-content">
        <app-patient-info-component [patientData]="patientInfoData || {}"></app-patient-info-component>
      </div>
    </div>
  </div>
</div>
<div (click)="$event.stopPropagation()">
  <mat-drawer-container class="drawer-container" *ngIf="isDrawerOpen" (backdropClick)="closeDrawer()">
    <mat-drawer #drawer mode="over" position="end" [opened]="true" style="z-index: 9999 !important;" (click)="$event.stopPropagation()">
      <app-appointment-details [buttonConfig]="appointmentButtons" (closeDrawer)="closeDrawer()" style="overflow: auto;"></app-appointment-details>
    </mat-drawer>
  </mat-drawer-container>
</div>

<!-- Delete Confirmation Modal -->
<ng-template #deleteModal>
  <div class="modal-content">
    <h3 class="modal-title">Confirm Deletion</h3>
    <p class="modal-message">Are you sure you want to delete this appointment?</p>
    <div class="modal-actions">
      <button class="modal-button confirm-button" (click)="confirmDelete()">Yes</button>
      <button class="modal-button cancel-button" (click)="cancelDelete()">No</button>
    </div>
  </div>
</ng-template>