import {Component, DestroyRef, inject, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {LoginService} from "../login/login.service";
import {environment} from "../../../environments/environment";
import {MatCardModule} from "@angular/material/card";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";

import {SnackBarService} from "../../objectPassingService";
import {ModelValidators} from "../../validators/validators";
import {Subject, takeUntil} from "rxjs";

// @ts-ignore
@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatCardModule, MatToolbarModule, MatProgressBarModule, MatFormFieldModule, MatButtonModule, MatInputModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css'
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  private destroyRef = inject(DestroyRef);
  destroyed = new Subject();

  is_loading = false;
  is_form_error: boolean = false;
  form_error_message: string = "";
  is_auth_error: boolean = false;

  myForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]]
  });

  constructor( public router: Router,
               private fb: FormBuilder,
               private loginService: LoginService,
                private snackBarService: SnackBarService,
      ) {}

  ngOnInit() {

    this.destroyRef.onDestroy(() => {
      this.destroyed.next('');
      this.destroyed.complete();
    });

  } //end ngOnInit

  ngOnDestroy() {
  } //end ngOnDestroy

  goBack(){
    window.history.back();
  }

  onFocus(){
    // console.log('onFocus')
    // this.is_form_error = false;
    // this.form_error_message = "";
  }

  onSubmit() {



    this.is_loading = true;
    console.log('this.is_loading',this.is_loading)
    console.log('submit login data')
    this.is_form_error = false;
    let data = this.myForm.value;
    const url = `${environment.API_AUTH_URL}/auth/password-forgot`

    this.loginService.forgotPassword(url, data.email).pipe(takeUntil(this.destroyed)).subscribe(res => {
      console.log('res', res)
      this.is_loading = false;

      // @ts-ignore
      if (res.hasOwnProperty('enum') && (res['enum']=='LimitExceededException')){
        this.is_auth_error = true
        this.is_form_error = true;
        this.form_error_message = 'Attempt limit exceeded, please try after some time.' //res['message']
      } else {
        this.snackBarService.announceSackBar('Password Rest Code sent to email address');
        this.router.navigate(['/forgot-password-confirmation']).then(r => console.log('navigate to forgot-password-confirmation'));
      }


    }, error => {
      console.log('error', error)
      this.is_loading = false;
      this.is_form_error = true;
      //this.form_error_message = error.error.message;
    }, () => {
      this.is_loading = false;
      //this.router.navigate(['/login']);
    });

  } //end onSubmit



} //end ForgotPasswordComponent
