import { ApplicationConfig } from '@angular/core';
import {provideRouter, withHashLocation} from '@angular/router';

import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors, HTTP_INTERCEPTORS } from "@angular/common/http";
import {authInterceptor} from "./http-interceptors/auth-intercepter.service";
import { HttpClientModule } from '@angular/common/http';
import { LoadingInterceptor } from './resusable/loading/loading-interceptor.service';


// https://stackoverflow.com/questions/47236963/no-provider-for-httpclient




export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes,withHashLocation()),
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor])),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    }
    ],

};

