import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

interface TimeValue {
  hour: string;
  minute: string;
  period: 'AM' | 'PM';
}

@Component({
  selector: 'app-time-picker',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule
  ],
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent {
  @ViewChild('timeInputDecorator') timeInputDecorator!: ElementRef;
  @ViewChild('timePicker') timePicker!: ElementRef;

  @Input() initialTime: TimeValue = { hour: '09', minute: '00', period: 'AM' };
  @Input() disabled: boolean = false; // Add disabled input
  @Output() timeChange = new EventEmitter<TimeValue | null>();

  timeInput: string = '09:00'; // Default value in HH:MM format for type="time"
  timeError: string = '';
  showTimePicker: boolean = false;
  hours = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));
  minutes = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));

  selectedTime: TimeValue;

  constructor() {
    this.selectedTime = { ...this.initialTime };
  }

  ngOnInit() {
    this.selectedTime = { ...this.initialTime };
    this.timeInput = `${this.selectedTime.hour}:${this.selectedTime.minute}`;
  }

  toggleTimePicker(event: Event) {
    if (this.disabled) return;
    event.stopPropagation();
    this.showTimePicker = !this.showTimePicker;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.disabled) return;
    const clickedElement = event.target as HTMLElement;
    const clickedIcon = clickedElement.classList.contains('time-icon');
    const clickedInsideDropdown = this.timePicker?.nativeElement.contains(clickedElement);

    if (!clickedIcon && !clickedInsideDropdown) {
      this.showTimePicker = false;
    }
  }

  onTimeKeydown(event: KeyboardEvent) {
    if (this.disabled) return false;
    const charCode = event.keyCode;

    if (
      (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 186) && // numbers and colon
      charCode !== 8 && // backspace
      charCode !== 9 && // tab
      charCode !== 37 && // left arrow
      charCode !== 39 // right arrow
    ) {
      if (event.key.toLowerCase() === 'a') {
        this.selectedTime.period = 'AM';
        this.updateTimeInput();
        return false;
      }
      if (event.key.toLowerCase() === 'p') {
        this.selectedTime.period = 'PM';
        this.updateTimeInput();
        return false;
      }
      return false;
    }
    return true;
  }

  onManualTimeInput(event: Event) {
    if (this.disabled) return;
    const inputElement = event.target as HTMLInputElement;
    const input = inputElement.value;

    if (!input) {
      this.timeError = '';
      this.timeChange.emit(null);
      return;
    }

    const [hour, minute] = input.split(':');
    const hourNum = parseInt(hour);

    if (hourNum >= 0 && hourNum <= 23 && parseInt(minute) >= 0 && parseInt(minute) <= 59) {
      this.selectedTime = {
        hour: String(hourNum % 12 || 12).padStart(2, '0'),
        minute: minute.padStart(2, '0'),
        period: hourNum < 12 ? 'AM' : 'PM'
      };
      this.timeError = '';
      this.timeChange.emit(this.selectedTime);
    } else {
      this.timeError = 'Invalid time';
    }
  }

  selectHour(hour: string) {
    if (this.disabled) return;
    this.selectedTime.hour = hour;
    this.updateTimeInput();
  }

  selectMinute(minute: string) {
    if (this.disabled) return;
    this.selectedTime.minute = minute;
    this.updateTimeInput();
  }

  selectPeriod(period: 'AM' | 'PM') {
    if (this.disabled) return;
    this.selectedTime.period = period;
    this.updateTimeInput();
    this.showTimePicker = false;
  }

  private updateTimeInput() {
    const hourNum = parseInt(this.selectedTime.hour);
    const hour24 = this.selectedTime.period === 'PM' && hourNum !== 12
      ? hourNum + 12
      : this.selectedTime.period === 'AM' && hourNum === 12
      ? 0
      : hourNum;
    this.timeInput = `${String(hour24).padStart(2, '0')}:${this.selectedTime.minute}`;
    this.timeError = '';
    this.timeChange.emit(this.selectedTime);
  }
}