import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { AppointmentFormFieldsComponent } from "../../../resusable/appointment-form-fields/appointment-form-fields.component";

interface TimeValue {
  hour: string;
  minute: string;
  period: 'AM' | 'PM';
}

interface ButtonConfig {
  key: string;
  label: string;
  action: string; // e.g., 'update', 'complete', 'delete'
  customClass?: string; // CSS class name for custom styling
}

@Component({
  selector: 'app-appointment-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    AppointmentFormFieldsComponent
  ],
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.css']
})
export class AppointmentDetailsComponent implements OnInit {
  @Output() closeDrawer = new EventEmitter<void>();
  @Input() buttonConfig: ButtonConfig[] = []; // Dynamic button configuration

  patientName = 'Jessica Costa';
  selectedDate: Date | null = new Date();
  duration = 30;
  notes = '';

  // Form data received from app-appointment-form-fields
  formData: any = {};

  ngOnInit() {
    this.selectedDate = new Date();
  }

  onClose() {
    this.closeDrawer.emit();
  }

  // Receive form data from app-appointment-form-fields
  onFormDataChange(data: any) {
    this.formData = data;
    console.log('Form data updated:', data);
  }

  // Handle button actions emitted from app-appointment-form-fields
  onButtonAction(event: { action: string, data: any }) {
    console.log(`Button action: ${event.action} with data:`, event.data);
    // Add logic here to handle the action (e.g., update, complete, delete)
    this.onClose(); // Close drawer after action (optional)
  }
}
