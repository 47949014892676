<mat-drawer-container class="drawer-container">
  <mat-drawer #drawer mode="side" position="end" [style.width]="largewidthDrawerList.includes(action) ? '900px' : '650px'" style="height: 100%;">
    @if (action === 'healent-action-icon') {
    <div class="drawer-header">
      <div>
        Appointment - {{name}}
      </div>
      <i [ngClass]="'healent-logout'" style="color: var(--secondary_shade_50)" (click)="closeDrawer()"></i>
    </div>
    <div class="manage-appointment">
      <div class="manage-header">
        <i [ngClass]="'healent-action-icon'"></i>
        <h2>
          Manage
        </h2>
      </div>
      <div class="drawer-table">
        <table mat-table [dataSource]="[rowData]" class="mat-elevation-z8">
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{ element?.name || '-' }} </td>
          </ng-container>

          <!-- Chart# Column -->
          <ng-container matColumnDef="chartNumber">
            <th mat-header-cell *matHeaderCellDef> Chart # </th>
            <td mat-cell *matCellDef="let element"> {{ element?.chartNumber || '-' }} </td>
          </ng-container>

          <!-- Provider Column -->
          <ng-container matColumnDef="provider">
            <th mat-header-cell *matHeaderCellDef> Provider </th>
            <td mat-cell *matCellDef="let element"> {{ element?.provider || '-' }} </td>
          </ng-container>

          <!-- Appt Type Column -->
          <ng-container matColumnDef="apptType">
            <th mat-header-cell *matHeaderCellDef> Appt Type </th>
            <td mat-cell *matCellDef="let element"> {{ element?.apptType || '-' }} </td>
          </ng-container>

          <!-- Appt Time Column -->
          <ng-container matColumnDef="apptTime">
            <th mat-header-cell *matHeaderCellDef> Appt Time </th>
            <td mat-cell *matCellDef="let element"> {{ element?.apptTime || '-' }} </td>
          </ng-container>

          <!-- Delete Action Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" style="color: var(--dangerous);">
              <div (click)="onCancelAppointment(rowData.meetingId, $event)" style="cursor: pointer;">
              <span *ngIf="!isLoadingCancel">
                <i [ngClass]="'healent-android-delete'" style="margin-right: 5px;" ></i>Cancel
              </span>
              <span *ngIf="isLoadingCancel" class="loading-dots">
                <span class="dot"></span>
              </span>
              </div>
            </td>
          </ng-container>

          <!-- Table headers and rows -->
          <tr mat-header-row *matHeaderRowDef="['name', 'chartNumber', 'provider', 'apptType', 'apptTime', 'delete']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['name', 'chartNumber', 'provider', 'apptType', 'apptTime', 'delete']"></tr>
        </table>
      </div>
    </div>
    <div style="min-height: 50%;">
      <app-push-form-to-app [rowData]="rowData" [missedSections]="missedSections" (triggerDataRefresh)="triggerDataRefresh($event)"></app-push-form-to-app>
    </div>
    <div>
      <app-process-appointment [rawRow]="rawRow" [tableType]="tableType"></app-process-appointment>
    </div>
    } @else if (action === 'healent-path-3625') {
      <app-contact-info
      (closeDrawerEvent)="closeDrawer()"
      [patientName]="rowData.name"
      [contactDetails]="rowData.contactDetails"
      ></app-contact-info>
    } @else if (action === 'healent-action-icon-1') {
      <app-payment-info
      [patientName]="rowData.name"
      [patientId]="rowData.patientId"
      (closeDrawerEvent)="closeDrawer()"
      ></app-payment-info>
    } @else if (action === 'healent-path-3627') {
      <app-patient-notes
      [patientName]="rowData.name"
      (closeDrawerEvent)="closeDrawer()"
      [meetingId]="rowData.meetingId"
      [patientId]="rowData.patientId"
      [rowData]="rowData"
      ></app-patient-notes>
    }
  </mat-drawer>
</mat-drawer-container>
