import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RegistrationService } from '../../../../registration/registration.service';
import { MatList, MatListItem } from '@angular/material/list';
import moment from 'moment-timezone';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';

type ManageDrawerIntrface = {
  name: string;
  chartNumber: string;
  provider: string;
  apptType: string;
  apptTime: string;
  contactDetails: {
    email: string;
    phone: string;
  }
  meetingId: number;
  patientId: number;
  athenaNotes: string[];
  excluded_forms: string[]
}

type ActionButtonInterface = { type: string, label: string, className: string, added: boolean, formId: number }

@Component({
  selector: 'app-push-form-to-app',
  standalone: true,
  imports: [CommonModule, FormsModule, MatList, MatListItem, MatCheckboxModule, MatTooltipModule],
  templateUrl: './push-form-to-app.component.html',
  styleUrl: './push-form-to-app.component.css'
})
export class PushFormToAppComponent implements OnChanges {
  searchTerm: string = '';
  selectedItems: { form_name: string; form_id: number, formatted_form_name: string }[] = [];
  allItems: { form_name: string; form_id: number, formatted_form_name: string, lastModified?: string, currentStatus?: string, currentActiveModified?: string }[] = [
    { form_name: 'Full Registration', form_id: 1, formatted_form_name: 'Full Registration' },
    { form_name: 'Express', form_id: 12, formatted_form_name: 'Express' },
    { form_name: 'Consents', form_id: 13, formatted_form_name: 'Consents'},
    { form_name: 'PHQ9', form_id: 9, formatted_form_name: 'PHQ9' },
    { form_name: 'GAD7', form_id: 7, formatted_form_name: 'GAD7' },
    { form_name: 'ORT', form_id: 8, formatted_form_name: 'ORT' },
    { form_name: 'NDI', form_id: 10, formatted_form_name: 'NDI' },
    { form_name: 'ODI', form_id: 11, formatted_form_name: 'ODI' },
    { form_name: 'CCM Consent', form_id: 5, formatted_form_name: 'CCM Consent' },
    { form_name: 'RTM Consent', form_id: 6, formatted_form_name: 'RTM Consent' }
  ];
  @Input({ required: true }) rowData!: ManageDrawerIntrface;
  @Input({ required: true }) missedSections!: { form_name: string; form_id: number }[];
  @Input({ required: true }) timezone_name!: string;
  @Input({ required: true }) formDetails!: any;
  @Input({ required: true }) timezone!: string;
  @Input({ required: true }) allAthenaNotes!: { appointmentHeader: string; note: string }[];
  @Input({ required: true }) registrationPhase!: string
  @Output() triggerDataRefresh = new EventEmitter<boolean>(false);

  // Button flags
  actionButtons: ActionButtonInterface[] = [];
  notes: { appointmentHeader: string; note: string }[] = [];
  showAddButton: boolean = false; // Control the visibility of the button
  selectedText: string = '';
  allWords: string[] = [];
  disableSedationButtons: boolean = true;
  tooltipTimeout: any;

  constructor(private registrationService: RegistrationService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rowData'] && this.rowData) {
      this.selectedItems = [];
      this.notes = this.allAthenaNotes;
      this.allWords = this.allAthenaNotes.flatMap(note => note.note.split(' '));

      // Get excluded forms from rowData
      const excludedForms: string[] = this.rowData.excluded_forms || [];

      // Filter out excluded forms from allItems
      this.allItems = this.allItems
        .filter(item => !excludedForms.includes(item.form_name))
        .map(item => {
          const currentStatus = this.formDetails[item.form_name]?.current_status;
          const lastSubmittedTimeStamp = currentStatus === 'complete'
            ? this.formDetails[item.form_name]?.last_submitted
            : this.formDetails[item.form_name]?.current_status_updated;

          if (lastSubmittedTimeStamp) {
            return {
              ...item,
              lastModified: this.formatRelativeTime(lastSubmittedTimeStamp, this.timezone),
              currentStatus: currentStatus ? currentStatus : ''
            };
          } else {
            const { lastModified, ...rest } = item;
            return rest;
          }
        });
    }
    this.initializeActionButtons();
  }


  getCurrentStatus(currentStatus: string) {
    switch (currentStatus) {
      case 'not_started':
        return 'Not Started: '
      case 'in_progress':
        return 'In-Progress: '
      case 'complete':
        return 'Submitted: '
      default:
        return ''
    }
  }

  getStatusColorClass(status: string): string {
    switch (status.toLowerCase()) {
      case 'not_started':
        return 'status-red';
      case 'in_progress':
        return 'status-orange';
      case 'completed':
        return 'status-accent';
      default:
        return '';
    }
  }

  formatRelativeTime(timestamp: string, timezone: string): string {
    const time = moment.tz(timestamp, "YYYY-MM-DD hh:mm A", timezone);
    const now = moment.tz(timezone);
    const diffInDays = now.diff(time, 'days');

    if (diffInDays > 1) {
      return `${time.format('MMM DD, YYYY hh:mm A')}`; // Show full timestamp for times older than or equal to 2 days
    } else if (diffInDays === 1) {
      return `Yesterday at ${time.format('hh:mm A')}`;
    } else {
      return `${time.fromNow()}`;
    }
  }

  initializeActionButtons() {
    // Initial buttons
    this.actionButtons = [];

    // Add missed sections to the beginning of the action buttons array
    if (this.missedSections && this.missedSections.length > 0) {
      this.missedSections.forEach((section) => {
        this.actionButtons.unshift({
          type: section.form_name,
          label: `+${section.form_name}`,
          className: 'missed-section-button',
          added: false,
          formId: section.form_id
        });
      });
    }
  }

  filterItems(search: string) {
    return this.allItems.filter(item =>
      item.form_name.toLowerCase().includes(search.toLowerCase())
    );
  }

  isChecked(item: { form_name: string; form_id: number }): boolean {
    return this.selectedItems.some(selected => selected.form_name === item.form_name);
  }

  // event handler for selection container
  toggleItem(item: { form_name: string; form_id: number, formatted_form_name: string }) {
    const index = this.selectedItems.findIndex(selected => selected.form_name === item.form_name);
    if (index > -1) {
      this.removeItem(item);
    } else {
      this.selectedItems.unshift(item);
      const buttonIndex = this.actionButtons.findIndex(button => button.type === item.form_name);
      if (buttonIndex > -1) {
        this.actionButtons[buttonIndex].added = true;
      }
    }
  }

  // event handler to remove the items from the to be pushed container
  removeItem(item: { form_name: string; form_id: number }) {
    const index = this.selectedItems.findIndex(selected => selected.form_name === item.form_name);
    if (index > -1) {
      this.selectedItems.splice(index, 1);
      const buttonIndex = this.actionButtons.findIndex(button => button.type === item.form_name);
      if (buttonIndex > -1) {
        this.actionButtons[buttonIndex].added = false;
      }
    }
  }

  // event handler for action buttons
  // addToPushList(type: ActionButtonInterface, event: MouseEvent) {
  //   event.stopPropagation();
  //   const buttonIndex = this.actionButtons.findIndex(btn => btn.type === type.type);
  //   if (buttonIndex > -1) {
  //     if (buttonIndex > -1) {
  //       this.selectedItems.unshift({
  //         form_id: type.formId,
  //         form_name: type.type,
  //         formatted_form_name: type.type
  //       })
  //     }
  //     this.actionButtons[buttonIndex].added = true;
  //   }
  // }

  // push form to app button event
  pushItems(event: MouseEvent) {
    event.stopPropagation();
    const endPoint = `dashboard/form/status/${this.rowData.patientId}/${this.rowData.meetingId}`;
    this.registrationService.patchStatus(endPoint, {
      send_back_forms: this.selectedItems.map((item) => {
        return {
          form_id: item.form_id,
          form_name: item.form_name.split('(')[0].trim()
        }
      })
    }).subscribe((res) => {
      if (res.status === 200) {
        this.triggerDataRefresh.emit(true);
        this.initializeActionButtons();
        this.selectedItems = []
      }
    }, (error) => {
      console.error(error);
    });
  }

  // this was written when using mouseup event
  // showIcon(event: MouseEvent): void {
  //   event.stopPropagation()
  //   const selection = window.getSelection();
  //   console.log(window.getSelection()?.toString(), '======')
  //   if (selection && selection.toString() && window.getSelection()?.toString().trim()) {
  //     this.selectedText = selection.toString();
  //     this.showAddButton = true;
  //   } else {
  //     this.showAddButton = false;
  //   }
  // }

  addSelectedText(event: MouseEvent, btnType: string): void {
    event.stopPropagation();
    const selection = window.getSelection();
    if (selection) {
      let selectedText = selection.toString().trim();

      // Remove EMR headers from selected text
      document.querySelectorAll('.emr-header').forEach((header) => {
        if (header.textContent) {
          selectedText = selectedText.replace(header.textContent.trim(), "").trim();
        }
      });

      if (selectedText !== '') {
        const ifFormExists = this.selectedItems.some(item => item.form_name === selectedText);

        if (!ifFormExists) {
          const newItem = {
            form_name: selectedText,
            form_id: btnType === 'sedation' ? 2 : 3,
            formatted_form_name: btnType === 'sedation'
              ? `Sedation (${selectedText})`
              : `No Sedation (${selectedText})`
          };
          this.selectedItems.unshift(newItem);
        }

        this.showAddButton = false;
        this.selectedText = '';
      }
    }
  }

  private isTripleClick = false;
  // muse up event use to detect the triple click
  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    if (event.detail === 3) {
      this.isTripleClick = true; // Flag to prevent immediate override in selectionchange
      const selection = window.getSelection();
      if (!selection) return;

      const range = document.createRange();
      const target = event.target as HTMLElement;
      const emrTextElement = target.closest('.emr-text');

      if (emrTextElement) {
        range.selectNodeContents(emrTextElement);
        selection.removeAllRanges();
        selection.addRange(range);

        this.selectedText = selection.toString().trim();
        this.disableSedationButtons = false;
      }

      // Reset the flag after a short delay so selectionchange doesn't override
      setTimeout(() => {
        this.isTripleClick = false;
      }, 50);
    }
  }

  //using selection change as we can select using shift+arrows too selectionchange detes both mouseup and selection using keyboard events
  @HostListener('document:selectionchange')
  onSelectionChange(): void {
    if (this.isTripleClick) return; // Skip if triple-click just happened

    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0 || !selection.toString().trim()) {
      this.selectedText = '';
      this.disableSedationButtons = true;
      return;
    }

    const range = selection.getRangeAt(0);
    const startContainer = range.startContainer;
    const endContainer = range.endContainer;

    // Check if the start OR end container is within `.emr-text`
    const isInsideEMRText =
      startContainer.parentElement?.closest('.emr-text') ||
      endContainer.parentElement?.closest('.emr-text');

    if (isInsideEMRText) {
      this.selectedText = selection.toString().trim();
      this.disableSedationButtons = false;
    } else {
      this.selectedText = '';
      this.disableSedationButtons = true;
    }
  }

  stopEvent(event: MouseEvent) {
    event.stopPropagation()
  }

  // Hide the button if clicked outside using emr-text as hiding button is handled on the emr-text
  // @HostListener('document:click', ['$event'])
  // handleClickOutside(event: MouseEvent) {
  //   const target = event.target as HTMLElement;
  //   const isInside = target.closest('.emr-text') !== null;
  //   if (!isInside) {
  //     this.showAddButton = false;
  //   }
  // }

  showTooltipForDuration(tooltip: MatTooltip) {
    tooltip.show();

    // Hide the tooltip after 3 seconds
    this.tooltipTimeout = setTimeout(() => {
      tooltip.hide();
    }, 3000);
  }
}
