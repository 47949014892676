import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableActionsDrawerService {

  url:string = `${environment.API_URL}`;
  private triggerActionSubject =  new Subject<any>()
  triggerAction$ = this.triggerActionSubject.asObservable()

  constructor(
    private http: HttpClient
  ) { }

  getNotesData(endpoint: string, data: {}): Observable<any> {
    return this.http.get(`${this.url}/${endpoint}`, data)
  }

  putStatus(endpoint: string, data: {}): Observable<any> {
    return this.http.put(`${this.url}/${endpoint}`, data);
  }

  getPaymentBalance(endpoint: string, data: {}): Observable<any> {
    return this.http.get(`${this.url}/${endpoint}`, data)
  }

  deleteNotes(endpoint: string, payload: any) {
    return this.http.request('DELETE', `${this.url}/${endpoint}`, {
      body: payload,
      observe: 'response',
    });
  }

  triggerAction(data: any) {
    this.triggerActionSubject.next(data);
  }

}
