<div class="container-full container ">
  <!--  <div class="column column1"><app-calendar></app-calendar></div>-->
  <div class="flex flex-1 overflow-hidden margin-l padding-0">
    <mat-card class="flex-1 height-100 width-100">
      <mat-card-header class="hide">
        <mat-card-title>
          <!--            <mat-slide-toggle #slide  color="primary" style="margin-left: 10px" [checked]="is_task_group_enabled" (change)="onTaskGroup($event, slide)">Group Tasks</mat-slide-toggle>-->
        </mat-card-title>
        <span class="example-spacer"></span>
        <mat-card-actions>
          <!--          <button mat-mini-fab  aria-label="Add Task" (click)="addTask()" color="primary" matTooltip="Add Task"-->
          <!--                  matTooltipPosition="right">-->
          <!--            <mat-icon class="add-button">add</mat-icon>-->
          <!--          </button>-->
        </mat-card-actions>
      </mat-card-header>

      <mat-card-content *ngIf="is_task_group_enabled">
        <div><app-make-engaging-calls></app-make-engaging-calls></div>
        <div><app-make-monthly-calls></app-make-monthly-calls></div>
        <div><app-tasks-active-pending-patients></app-tasks-active-pending-patients></div>
        <div><app-tasks-deal-with-alerts></app-tasks-deal-with-alerts></div>
        <div><app-tasks-repond-to-patients></app-tasks-repond-to-patients></div>
      </mat-card-content>

      <!--      https://stackoverflow.com/questions/38057537/how-to-check-the-length-of-an-observable-array-->

      <mat-card-content class="flex-1" *ngIf="!is_task_group_enabled">
        <app-tasks-all class="flex column flex-1 height-100"></app-tasks-all>
      </mat-card-content>
    </mat-card>
  </div>
  <!--  <div class="column column3">-->
  <!--    <div class="row"><app-alerts></app-alerts></div>-->
  <!--    <div class="row"><app-messages></app-messages></div>-->
  <!--  </div>-->
</div>



<!--<div class="flex-container">-->
<!--  <div><app-alerts></app-alerts></div>-->
<!--  <div><app-calendar></app-calendar></div>-->
<!--  <div><app-make-engaging-calls></app-make-engaging-calls></div>-->
<!--  <div><app-make-monthly-calls></app-make-monthly-calls></div>-->
<!--  <div><app-messages></app-messages></div>-->
<!--  <div><app-tasks-active-pending-patients></app-tasks-active-pending-patients></div>-->
<!--  <div><app-tasks-deal-with-alerts></app-tasks-deal-with-alerts></div>-->
<!--  <div><app-tasks-repond-to-patients></app-tasks-repond-to-patients></div>-->
<!--</div>-->





<!--<mat-grid-list cols="3" rowHeight="100px">-->

<!--  &lt;!&ndash; First Col&ndash;&gt;-->
<!--  <mat-grid-tile [colspan]="1"-->
<!--                 [rowspan]="5"-->
<!--                 ><div><app-calendar></app-calendar></div></mat-grid-tile>-->

<!--  &lt;!&ndash; Second Col&ndash;&gt;-->
<!--  <mat-grid-tile [colspan]="1"-->
<!--                 [rowspan]="1"><div><app-tasks-deal-with-alerts></app-tasks-deal-with-alerts></div></mat-grid-tile>-->

<!--  <mat-grid-tile [colspan]="1"-->
<!--                 [rowspan]="3"><div><app-alerts></app-alerts></div></mat-grid-tile>-->

<!--  <mat-grid-tile [colspan]="1"-->
<!--                 [rowspan]="1"><div><app-tasks-repond-to-patients></app-tasks-repond-to-patients></div></mat-grid-tile>-->

<!--  <mat-grid-tile [colspan]="1"-->
<!--                 [rowspan]="1"><div><app-make-engaging-calls></app-make-engaging-calls></div></mat-grid-tile>-->

<!--  <mat-grid-tile [colspan]="1"-->
<!--                 [rowspan]="1"><div><app-make-monthly-calls></app-make-monthly-calls></div></mat-grid-tile>-->


<!--  &lt;!&ndash; Third Col&ndash;&gt;-->
<!--  <mat-grid-tile  [colspan]="1"-->
<!--                  [rowspan]="2"><div><app-messages></app-messages></div></mat-grid-tile>-->

<!--  <mat-grid-tile [colspan]="1"-->
<!--                 [rowspan]="1"><div><div><app-tasks-active-pending-patients></app-tasks-active-pending-patients></div></div></mat-grid-tile>-->
<!--  -->

<!--</mat-grid-list>-->