

<mat-drawer-container class="sidenav-container">
  <mat-drawer mode="side" class="sidenav" opened>

        <mat-nav-list>
          <a mat-list-item routerLinkActive="active" [routerLink]="['/adserver-nav', { outlets: { admeta: ['client-select'] } }]">Select Hospital</a>
          <a mat-list-item routerLinkActive="active" [routerLink]="['/adserver-nav', { outlets: { admeta: ['campaign-group'] } }]">Campaign Groups</a>
          <a mat-list-item routerLinkActive="active" [routerLink]="['/adserver-nav', { outlets: { admeta: ['campaigns'] } }]">Campaigns</a>
          <a mat-list-item routerLinkActive="active" [routerLink]="['/adserver-nav', { outlets: { admeta: ['adgroups'] } }]">Ad Groups</a>
          <a mat-list-item routerLinkActive="active" [routerLink]="['/adserver-nav', { outlets: { admeta: ['ads'] } }]">Ads</a>
          <a mat-list-item routerLinkActive="active" [routerLink]="['/adserver-nav', { outlets: { admeta: ['campaign-group-sequence'] } }]">Campaign Groups Sequence</a>
          <mat-divider></mat-divider>
        </mat-nav-list>

  </mat-drawer>
  <mat-drawer-content>
    <span *ngIf="client_slug"> Hospital: {{client_slug}} </span>
    <br>
    <span *ngIf="campaign_group_id"> Campaign Group ID: {{campaign_group_id}} </span>
    <br>
    <span *ngIf="campaign_id"> Campaign ID: {{campaign_id}} </span>
    <br>
    <span *ngIf="adgroup_id"> Ad Group ID: {{adgroup_id}} </span>

<!--    Select Campaign Groups -> Campaigns -> Ad Groups -> Ads-->
    <router-outlet name="admeta"></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
