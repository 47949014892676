import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef, signal, SimpleChanges, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { HospitalLocationInterface } from '../../registration/registration.interface';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteTrigger, MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-custom-location-select',
  templateUrl: './custom-location-select.component.html',
  styleUrls: ['./custom-location-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule
  ]
})
export class CustomLocationSelectComponent implements OnInit, OnChanges {
  @Input() locations: HospitalLocationInterface[] = [];
  @Input() selectedLocation!: number | null;
  @Output() locationChange = new EventEmitter<{ locationId: number; phone: string | undefined }>();

  locationForm: FormGroup;
  filteredLocations: HospitalLocationInterface[] = [];
  hasClicked = false;

  @ViewChild(MatAutocompleteTrigger) matAutocomplete!: MatAutocompleteTrigger;
  @ViewChild('locationInput') locationInput!: ElementRef;
  showClearIcon = signal(false);

  constructor(private fb: FormBuilder) {
    this.locationForm = this.fb.group({
      location: [null]
    });

    this.locationForm.get('location')?.valueChanges.subscribe(() => {
      console.log('hello')
      this.filterLocations();
    });
  }

  ngOnInit(): void {
    this.filteredLocations = this.locations;
    this.loadSavedLocation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['locations']?.currentValue) {
      this.filteredLocations = this.locations;
      this.loadSavedLocation();
    }
    if (changes['selectedLocation']?.currentValue) {
      this.setInitialLocation();
    }
  }

  private loadSavedLocation(): void {
    const savedLocationId = localStorage.getItem('selectedLocation');
    if (savedLocationId && this.locations.length > 0) {
      const location = this.locations.find(loc => loc.location_id === parseInt(savedLocationId, 10));
      if (location) {
        // Set the full location object, not just the name
        this.locationForm.patchValue({ location });
        this.selectedLocation = location.location_id;
      }
    }
  }

  filterLocations(): void {
    const value = this.locationForm.get('location')?.value;
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    this.filteredLocations = !filterValue
      ? this.locations
      : this.locations.filter(location =>
          location.location_name.toLowerCase().includes(filterValue)
        );

    if (this.hasClicked && this.matAutocomplete && !this.matAutocomplete.panelOpen) {
      this.matAutocomplete.openPanel();
    }
  }

  onLocationChange(event: any): void {
    const selectedLocation = event.option.value;
    if (selectedLocation) {
      this.selectedLocation = selectedLocation.location_id;
      this.locationChange.emit({
        locationId: this.selectedLocation as number,
        phone: selectedLocation.phone
      });

      localStorage.setItem('selectedLocation', this.selectedLocation?.toString() || '');
      localStorage.setItem('locationPhoneNumber', selectedLocation.phone || '');

      setTimeout(() => {
        this.locationInput.nativeElement.blur();
        this.showClearIcon.set(false);
      });
    }
  }

  clearSearchText(): void {
    this.locationForm.patchValue({ location: null });
    this.filteredLocations = this.locations;
    this.selectedLocation = null;
    this.showClearIcon.set(false);
    this.matAutocomplete?.openPanel();
  }

  displayNameFunc = (location: HospitalLocationInterface): string => {
    if (!location) return '';
    return typeof location === 'string' ? location : location.location_name;
  }

  private setInitialLocation(): void {
    const selectedLocation = this.locations.find(loc =>
      loc.location_id.toString() === this.selectedLocation?.toString()
    );
    if (selectedLocation) {
      // Set the full location object, not just the name
      this.locationForm.patchValue({ location: selectedLocation });
    }
  }

  openAutocompletePanelOnClick(): void {
    this.hasClicked = true;
    if (!this.locationForm.get('location')?.value) {
      this.filteredLocations = this.locations;
    }
    this.matAutocomplete?.openPanel();
  }

  onBlur(): void {
    this.hasClicked = false;
    if (!this.locationForm.get('location')?.value) {
      this.showClearIcon.set(false);
    }
    setTimeout(() => {
      if (this.matAutocomplete && this.matAutocomplete.panelOpen) {
        this.matAutocomplete.closePanel();
      }
    }, 500);
  }

  onInputFocus(): void {
    this.showClearIcon.set(true);
  }
}
