<div class="broadcast-container">
  <div class="broadcast-header">
    <div class="broadcast-title">{{title.name}}</div>
    <i [ngClass]="'healent-logout'" style="color: var(--secondary_shade_50); cursor: pointer;" (click)="triggerCloseDrawer(); $event.stopPropagation()"></i>
  </div>

  <div class="selected-patient-content">
    <div class="selected-patient-top">
      <div class="selected-patient-count">{{selection.selected.length}}</div>
      <div class="selected-patient-text">Selected patients</div>
    </div>
  </div>
  @if (title.id === 'broadcast') {
    <div>
      Filters
    </div>
  }

  <div class="patients-table">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="client_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let patient"> {{patient.client_name}} </td>
      </ng-container>

      <ng-container matColumnDef="surgeon_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Provider </th>
        <td mat-cell *matCellDef="let patient"> {{patient.surgeon_name}} </td>
      </ng-container>

      <ng-container matColumnDef="checkin_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let patient"> {{patient.checkin_status}} </td>
      </ng-container>

      <ng-container matColumnDef="appointment_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Appt time </th>
        <td mat-cell *matCellDef="let patient"> {{patient.appointment_time}} </td>
      </ng-container>

      <ng-container matColumnDef="appointment_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Appt type </th>
        <td mat-cell *matCellDef="let patient"> {{patient.appointment_type}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
          (click)="selection.toggle(row)"
          [class.selected]="selection.isSelected(row)">
      </tr>
    </table>
  </div>

  <div class="new-note-form">
    <hr class="divider-line" />
    <div class="new-note-form-content">
      <h2 style="font-size: 24px; font-weight: 400px;">Content</h2>
      <div class="template-buttons">
        <button *ngFor="let btn of templateButtons"
                class="template-button"
                (click)="addTemplate(btn.templateText)"
                [matTooltip]="btn.templateText"
                [matTooltipClass]="'dashboard-tooltip'"
                >
          {{ btn.buttonName }}
        </button>
      </div>
      <textarea [(ngModel)]="newNote" placeholder="Enter broadcast message"></textarea>
      <div class="message-type-selection">
        @for (type of messageTypes; track type.id) {
          <div class="message-type-option">
            <mat-checkbox
              [checked]="type.checked"
              (change)="toggleMessageType(type.id)"
              disableRipple
            >
              {{type.label}}
            </mat-checkbox>
          </div>
        }
      </div>
      <div class="action-buttons">
        <button class="save-button" (click)="sendBroadcast();$event.stopPropagation()">Send</button>
        <button class="cancel-button" (click)="triggerCloseDrawer()">Cancel</button>
      </div>
    </div>
  </div>
</div>
