import { Component, DestroyRef, Inject, inject, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { MatButtonModule } from "@angular/material/button";
import { Router } from "@angular/router";
import { SnackBarService, SuperAdminViewBehaviorService } from "../objectPassingService";
import { UsersService } from "./users.service";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { Subject, takeUntil } from "rxjs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, FormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ModelValidators } from "../validators/validators";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { CookieService } from 'ngx-cookie-service';
import { TasksAllBehaviorService } from "../dashboardPassingService";


export interface UsersInterfaceData {
  user_id: number;
  name: string;
  email: string;
}

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatTableModule, MatIconModule, MatPaginatorModule, MatCardModule, MatFormFieldModule, MatInputModule, MatListModule, MatTooltipModule, MatSortModule, MatProgressBarModule, FormsModule],
  templateUrl: './users.component.html',
  styleUrl: './users.component.css'
})
export class UsersComponent implements OnInit {

  is_loading = false;
  searchText: string = '';
  private destroyRef = inject(DestroyRef);
  destroyed_get_users = new Subject();


  dataSource!: MatTableDataSource<UsersInterfaceData>;
  displayedColumns: string[] = ['user_id', 'name', 'email', 'View'];

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild('input') inputElement!: ElementRef;

  constructor(
    public router: Router,
    private superAdminViewBehaviorService: SuperAdminViewBehaviorService,
    private usersService: UsersService,
    public dialog: MatDialog,
    private cookieService: CookieService,
    private tasksAllBehaviorService: TasksAllBehaviorService,
  ) {
    this.superAdminViewBehaviorService.announceSuperAdminViewBehavior(true);

    //When superuser hits back button and select different user we need to reset the tasksAllBehaviorService
    this.tasksAllBehaviorService.announceTasksAllBehavior([])
  }

  ngOnInit(): void {


    console.log(
      'COOKIES!!!!!!!!!!!', this.cookieService.getAll()
    )

    this.getUsers()
    this.destroyRef.onDestroy(() => {
      this.destroyed_get_users.next('');
      this.destroyed_get_users.complete();
    });

  }

  getUsers() {
    this.is_loading = true;
    this.usersService.getUsers('users', false).pipe(takeUntil(this.destroyed_get_users)).subscribe((data: any) => {
      console.log(data);
      console.log('COOKIES!!!!!!!!!!!', this.cookieService.getAll())
      data = data["message"]
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.is_loading = false;

      console.log('COOKIES!!!!!!!!!!!', this.cookieService.getAll())

    }, (error: any) => {
      console.log(error);
      this.is_loading = false;
    },
      () => { });
  } //end getUsers

  addUser(is_edit: boolean, row: object) {
    console.log('addUser', row);

    let row_data = {}
    if (is_edit) {
      row_data = row;
    }

    const enterAnimationDuration = '0ms';
    const exitAnimationDuration = '0ms';


    let dialogRef = this.dialog.open(DialogAddUser, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: row_data
    });


    dialogRef.afterClosed().subscribe((result: string | undefined) => {

      if (result == 'cancel' || result == undefined) {
        console.log('we cancled')
      } else {
        this.getUsers()
        //this.loadAccounts(this.agency_id) //reload table
      }
    });//end dialogRef








  } //end addUser

  deleteRow(row: object) {
    console.log('deleteRow', row);
  } //end deleteRow

  getTableRecord(row: object) {
    console.log('getTableRecord', row);
  } //end getTableRecord

  applyFilter(event: Event | string) {
    const filterValue = typeof event === 'string' ? event : (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }//end applyFilter
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.code === 'Space') {
      event.preventDefault();
      this.inputElement.nativeElement.focus();
    }
  }
  clearSearch(input: HTMLInputElement) {
    this.searchText = '';
    input.value = '';
    this.applyFilter('');
  }
  viewData(row: UsersInterfaceData) {
    const user_id = row.user_id
    this.superAdminViewBehaviorService.announceSuperAdminViewBehavior(false);
    this.router.navigate(['/dashboard', user_id]).then(r => console.log('redirect to dashboard'));
  } //end viewData(row)

}
class DialogData {
}

/*---------------------------------------------
Dialog for adding a new user
---------------------------------------------*/
@Component({
  selector: 'add-user-dialog',
  templateUrl: 'add-user-dialog.html',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatProgressBarModule,
    MatInputModule,
    ReactiveFormsModule,
    NgIf
  ]
})
export class DialogAddUser implements OnInit {


  is_form_error: boolean = false;
  form_error_message: string = "";
  is_loading = false;
  is_edit = false;
  title_text = "Create User";
  submit_text = "Create"

  myForm: FormGroup = this.fb.group({
    username: [{
      value: '',
      disabled: this.is_edit
    }, [Validators.required]],
    email: [{
      value: '',
      disabled: this.is_edit
    }, [Validators.required, Validators.email]],
  }) //end myForm


  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogAddUser>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    let username = '';
    let email = '';

    if (Object.keys(this.data).length == 0) {
      this.is_edit = false;
    } else {
      // @ts-ignore
      username = this.data['username'];
      // @ts-ignore
      email = this.data['email'];
      this.is_edit = true;
      this.title_text = "Edit User";
      this.submit_text = "Update"
    }

    this.myForm = this.fb.group({
      username: [{
        value: username,
        disabled: this.is_edit
      }, [Validators.required]],
      email: [{
        value: email,
        disabled: this.is_edit
      }, [Validators.required, Validators.email]],
    })//end myForm



  } //end ngOnInit

  onFocus() {
    this.is_form_error = false;
    this.form_error_message = "";
  }

  onCancel() {
    this.dialogRef.close('cancel');
  }
  onSubmit() {

  } //end onSubmit


} //end DialogAddClient

















