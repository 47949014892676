import { Component, ElementRef, ViewChild, HostListener, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';

interface TimeValue {
  hour: string;
  minute: string;
  period: 'AM' | 'PM';
}

interface ButtonConfig {
  key: string;
  label: string;
  action: string; // e.g., 'update', 'complete', 'delete'
  customClass?: string; // CSS class name for custom styling
}

@Component({
  selector: 'app-appointment-form-fields',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule
  ],
  templateUrl: './appointment-form-fields.component.html',
  styleUrls: ['./appointment-form-fields.component.css'],
  providers: [provideNativeDateAdapter()],
})
export class AppointmentFormFieldsComponent implements OnInit {
  @ViewChild('timeInputDecorator') timeInputDecorator!: ElementRef;
  @ViewChild('timePicker') timePicker!: ElementRef;

  @Input() duration = 30;
  @Input() date: Date | null = null;
  @Input() buttonConfig: ButtonConfig[] = [];
  @Output() timeChange = new EventEmitter<TimeValue | null>();
  @Output() durationChange = new EventEmitter<number>();
  @Output() dateChange = new EventEmitter<Date>();
  @Output() notesChange = new EventEmitter<string>();
  @Output() formDataChange = new EventEmitter<any>();
  @Output() buttonAction = new EventEmitter<{ action: string, data: any }>();

  dateInput = '';
  timeInput: string = '09:00'; // Default value in HH:MM format for type="time"
  dateError = '';
  timeError = '';
  notes = '';
  showTimePicker = false;
  hours = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));
  minutes = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));

  selectedTime: TimeValue = {
    hour: '09',
    minute: '00',
    period: 'AM'
  };

  dateControl = new FormControl();

  constructor() {
    this.dateControl.valueChanges.subscribe(value => {
      this.handleDateChange(value);
    });
  }

  ngOnInit() {
    if (this.date) {
      this.dateControl.setValue(this.date);
    }
    this.timeInput = `${this.selectedTime.hour}:${this.selectedTime.minute}`;
    this.emitFormData();
  }

  formatDate(date: Date): string {
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const yyyy = date.getFullYear();
    return `${mm}/${dd}/${yyyy}`;
  }

  onManualDateInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let input = inputElement.value;

    const regex = /^[0-9/]*$/;
    if (!regex.test(input)) {
      this.dateError = 'Only numbers and slashes are allowed';
      return;
    }

    if (!input) {
      this.dateError = '';
      return;
    }

    const parsedDate = new Date(input + 'T00:00:00');

    if (!isNaN(parsedDate.getTime())) {
      this.dateControl.setValue(this.formatDate(parsedDate), { emitEvent: false });
      this.dateError = '';
      this.dateChange.emit(parsedDate);
      this.emitFormData();
    } else {
      this.dateError = 'Invalid date format';
    }
  }

  onDateSelected(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;

    if (selectedDate instanceof Date && !isNaN(selectedDate.getTime())) {
      this.dateControl.setValue(this.formatDate(selectedDate), { emitEvent: false });
      this.dateError = '';
      this.dateChange.emit(selectedDate);
      this.emitFormData();
    } else {
      this.dateError = 'Invalid date selected';
    }
  }

  private handleDateChange(value: any) {
    if (!value) {
      this.dateError = '';
      return;
    }

    const parsedDate = new Date(value);
    if (!isNaN(parsedDate.getTime())) {
      this.dateError = '';
      this.dateChange.emit(parsedDate);
      this.emitFormData();
    } else {
      this.dateError = 'Invalid date';
    }
  }

  toggleTimePicker(event: Event) {
    event.stopPropagation();
    this.showTimePicker = !this.showTimePicker;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    const clickedIcon = clickedElement.classList.contains('time-icon');
    const clickedInsideDropdown = this.timePicker?.nativeElement.contains(clickedElement);

    if (!clickedIcon && !clickedInsideDropdown) {
      this.showTimePicker = false;
    }
  }

  onTimeKeydown(event: KeyboardEvent) {
    const charCode = event.keyCode;

    if (
      (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 186) && // numbers and colon
      charCode !== 8 && // backspace
      charCode !== 9 && // tab
      charCode !== 37 && // left arrow
      charCode !== 39 // right arrow
    ) {
      if (event.key.toLowerCase() === 'a') {
        this.selectedTime.period = 'AM';
        this.updateTimeInput();
        return false;
      }
      if (event.key.toLowerCase() === 'p') {
        this.selectedTime.period = 'PM';
        this.updateTimeInput();
        return false;
      }
      return false;
    }
    return true;
  }

  onManualTimeInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const input = inputElement.value;

    if (!input) {
      this.timeError = '';
      return;
    }

    const [hour, minute] = input.split(':');
    const hourNum = parseInt(hour);

    if (hourNum >= 0 && hourNum <= 23 && parseInt(minute) >= 0 && parseInt(minute) <= 59) {
      this.selectedTime = {
        hour: String(hourNum % 12 || 12).padStart(2, '0'),
        minute: minute.padStart(2, '0'),
        period: hourNum < 12 ? 'AM' : 'PM'
      };
      this.timeError = '';
      this.timeChange.emit(this.selectedTime);
      this.emitFormData();
    } else {
      this.timeError = 'Invalid time';
    }
  }

  selectHour(hour: string) {
    this.selectedTime.hour = hour;
    this.updateTimeInput();
  }

  selectMinute(minute: string) {
    this.selectedTime.minute = minute;
    this.updateTimeInput();
  }

  selectPeriod(period: 'AM' | 'PM') {
    this.selectedTime.period = period;
    this.updateTimeInput();
    this.showTimePicker = false;
  }

  private updateTimeInput() {
    const hourNum = parseInt(this.selectedTime.hour);
    const hour24 = this.selectedTime.period === 'PM' && hourNum !== 12
      ? hourNum + 12
      : this.selectedTime.period === 'AM' && hourNum === 12
      ? 0
      : hourNum;
    this.timeInput = `${String(hour24).padStart(2, '0')}:${this.selectedTime.minute}`;
    this.timeError = '';
    this.timeChange.emit(this.selectedTime);
    this.emitFormData();
  }

  onDurationChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = parseInt(inputElement.value, 10);

    if (!isNaN(value) && value >= 0) {
      this.duration = value;
      this.durationChange.emit(this.duration);
      this.emitFormData();
    }
  }

  onNotesChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.notes = inputElement.value || '';
    this.notesChange.emit(this.notes);
    this.emitFormData();
  }

  incrementDuration() {
    if (this.duration < 999) {
      this.duration++;
      this.durationChange.emit(this.duration);
      this.emitFormData();
    }
  }

  decrementDuration() {
    if (this.duration > 0) {
      this.duration--;
      this.durationChange.emit(this.duration);
      this.emitFormData();
    }
  }

  numberOnly(event: KeyboardEvent): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  private emitFormData() {
    const formData = {
      date: this.date,
      time: this.selectedTime,
      duration: this.duration,
      notes: this.notes
    };
    this.formDataChange.emit(formData);
  }

  handleButtonAction(action: string) {
    const formData = {
      date: this.date,
      time: this.selectedTime,
      duration: this.duration,
      notes: this.notes
    };
    this.buttonAction.emit({ action, data: formData });
    console.log(`Button action: ${action} with data:`, formData);
  }
}
