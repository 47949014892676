import {Component, OnDestroy, OnInit, ChangeDetectorRef} from '@angular/core';
import { ChatService } from './chat.service';
import { UsersService } from '../users/users.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {last, Subscription} from "rxjs";
import { Membership, User } from '@pubnub/chat';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';
import { MessageType } from '../models/message-type.model';
import { LinkPreviewComponent } from '../components/link-preview/link-preview.component';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  standalone: true,
  styleUrls: ['./chat.component.css'],
  imports: [FormsModule, CommonModule, LinkPreviewComponent]
})
export class ChatComponent implements OnInit, OnDestroy {

  isTyping: boolean = false; // To track typing status of the other user
  private typingSubscription: (() => void) | null = null;
  errorMessage: string = '';
  messageError: string = '';
  isMessageFieldError: boolean = false;
  isSearchFieldError: boolean = false;
  showSearchResults: boolean = false;
  isDirectConversationModalOpen: boolean = false;
  currentMessage: string = '';
  directConversationSearchTerm: string = '';
  channelId: string = '';
  channel: any = null;
  users: any[] = [];
  directFilteredUsers: any[] = [];
  filteredUsers: any[] = [];
  selectedUser: any | null = null;
  last_selected_user: any | null = null;  // To store the last selected user
  reactions: string[] = ['👍', '❤️', '😂', '😢', '❗'];
  searchTerm: string = '';
  is_loading = false;
  current_user_id: string | null = localStorage.getItem('email');
  from_user_id: string | null = localStorage.getItem('email');
  from_user_name: string | null = localStorage.getItem('email');
  to_user_id: string | null = '';
  to_user_name: string | null = '';
  to_user_email: string | null = '';
  lastMessageWithReactionPanel: any | null = null;
  messages: any[] = [];
  selectedFile: any | null = null;
  isUploading: boolean = false;
  uploadProgress: number = 0;
  previewImageUrl: string | null = null;
  messageType = MessageType;
  typingTimer: ReturnType<typeof setTimeout> | null = null;
  isAdmin: boolean | null = localStorage.getItem('role') === 'admin';

  constructor(
    private chatService: ChatService,
    private usersService: UsersService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getUsers().then(() => {
      // Ensure chat is initialized before joining channels
      this.chatService.waitForChatInitialization().then(() => {
        console.log('Chat initialized. Proceeding to join channels.', this.from_user_id, '\nisAdmin: ', this.isAdmin);
        this.fetchUnreadMessagesAndListenForChannels();
      }).catch((error) => {
        console.error('Error initializing chat service:', error);
      });
    }).catch(() => {
      console.error('Error while get users')
    });
  }

  ngOnDestroy(): void {
    if (this.last_selected_user) {
      this.chatService.updateUserStatus(this.last_selected_user.user_id, 'inactive');
    }
    this.chatService.destroyVariables();
    clearTimeout(this.typingTimer || 0);
  }

  async getUsers() {
    this.is_loading = true;
    const slug = environment.slug;

    return new Promise((resolve, reject) => {
      this.usersService.getUsers(`clients/${slug}`, false).subscribe((response: any) => {
        this.users = response.message;
        const currentEmail = localStorage.getItem('email');

        this.current_user_id = this.users.find(el => el.email === currentEmail)?.user_id || currentEmail?.replaceAll('.', '_');
        
        this.chatService.setCurrentUserId(this.current_user_id?.toString() || currentEmail);

        this.is_loading = false;
        resolve(response);
      }, (error) => {
        reject(error);
        console.error('Error fetching users:', error);
        this.is_loading = false;
      });
    });
  }

  async fetchUnreadMessagesAndListenForChannels(): Promise<void> {
    try {
      await this.chatService.initUserChannels({
        onMessageReceived: this.messageListener,
        onTypingEventReceived: this.typingListener,
        getUser: (userId: number) => this.users.find(el => el.user_id === userId),
      });

      this.filterUsers();
      this.cdr.detectChanges();
    } catch (error) {
      console.error('Error fetching unread messages and listening to channels:', error);
    }
  }

  filterUsers(): void {
    const recentChats = this.chatService.getRecentChats();

    if (this.isDirectConversationModalOpen) {
      if (this.directConversationSearchTerm.trim()) {
        this.directFilteredUsers = this.users.filter(user =>
          user.name?.toLowerCase().includes(this.directConversationSearchTerm.toLowerCase())
        );
        this.showSearchResults = this.directFilteredUsers.length > 0;
        this.errorMessage = '';
        this.isSearchFieldError = false;
      } else {
        this.directFilteredUsers = [];
        this.showSearchResults = false;
      }
    } else {
      if (this.searchTerm.trim()) {
        this.filteredUsers = recentChats.filter(user =>
          user.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      } else {
        this.filteredUsers = recentChats;
        this.filteredUsers.forEach(user => {
          this.chatService.updateUserStatus(user.user_id, 'inactive');
        });
      }
    }
  }

  async selectUser(user: any): Promise<void> {
    if (this.last_selected_user) {
      this.chatService.updateUserStatus(this.last_selected_user.user_id, 'inactive');
    }
    if (this.isDirectConversationModalOpen) {
      const userExists = this.filteredUsers.some(existingUser => String(existingUser.user_id) === String(user.user_id));
      if (userExists) {
        this.errorMessage = "Chat with this user already exists, cannot create new chat";
        this.isSearchFieldError = true;
        console.log(this.errorMessage);
        this.showSearchResults = false;
        return;
      }

      this.errorMessage = '';
      this.isSearchFieldError = false;

      this.selectedUser = user;
      this.to_user_id = String(this.selectedUser.user_id);
      this.to_user_name = String(this.selectedUser.name);
      this.to_user_email = String(this.selectedUser.email);
      this.from_user_id = this.current_user_id ?? '';

      const selectedUserChannel = `${this.from_user_id}.${this.to_user_id}`;
      this.channelId = selectedUserChannel;

      // Fill the search field with the selected user's name
      this.directConversationSearchTerm = this.selectedUser.name;

      // Hide the search results
      this.showSearchResults = false;

      console.log("Selected user in Direct Conversation box:", this.selectedUser);
    } else {
      this.selectedUser = user;
      console.log(`This is the type of `, this.selectedUser);
      this.to_user_id = String(this.selectedUser.user_id);
      this.to_user_name = String(this.selectedUser.name);
      this.to_user_email = String(this.selectedUser.email);
      const selectedUserChannel = this.chatService.getChannelIdForUser(this.to_user_id);
      
      const nurceId = selectedUserChannel.split('.')[0];
      this.from_user_id = nurceId;
      this.channelId = selectedUserChannel;
      this.channel = await this.chatService.getChannel(this.channelId);

      this.chatService.updateUserStatus(this.selectedUser.user_id, 'active');
      this.last_selected_user = this.selectedUser;

      if (this.from_user_id === this.current_user_id) {
        this.chatService.markMessagesAsRead(this.channelId, undefined, this.current_user_id).then(() => {
          this.chatService.updateUserStatus(this.selectedUser.user_id, undefined, 0);
          this.cdr.detectChanges();
          console.log(`Unread count for user ${this.selectedUser.name} has been reset to 0.`);
        }).catch((error) => {
          console.error('Error marking messages as read:', error);
        });
      }


      this.chatService.getChannelHistory(this.channelId, undefined, undefined, 25).then(async (history) => {
        if (history) {
          this.messages = history.messages;
          await this.chatService.initWildcardSubscription(this.messageListener, this.typingListener, this.from_user_id || '');          
          this.cdr.detectChanges();
          this.scrollToBottomDiv();
        } else {
          console.error('No message history available.');
        }
      }).catch((error) => {
        console.error('Error fetching history or channel not available:', error);
      });
    }
  }

  scrollToBottomDiv(): void {
    try {
      console.log('Scrolling to bottom');
      const chatMessages = document.querySelector('.chat-messages');
      if (chatMessages) {
        chatMessages.scrollTop = chatMessages.scrollHeight;
      }
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }

  sendMessage(): void {
    if (!this.selectedUser) {
      // If no user is selected, show an error message and highlight the text box
      this.messageError = "Select user before sending message";
      this.isMessageFieldError = true;
      console.log(this.messageError);
      return;
    }

    // Check if the current message is empty
    if (!this.currentMessage.trim()) {
      this.messageError = "Cannot send empty message";
      this.isMessageFieldError = true;
      console.log("Message is empty, not sending");
      return;
    }

    if (!this.channelId) {
      this.messageError = "Channel not available";
      this.isMessageFieldError = true;
      console.log("Channel not available, cannot send message");
      return;
    }

    this.chatService.sendTextMessage(
      this.channelId,
      this.currentMessage,
      this.to_user_id || '',
      this.to_user_name || '',
      this.from_user_name || '',
      this.selectedFile)
      .then(async (message) => {
        const currentUser = this.selectedUser;

        this.currentMessage = '';  // Clear the input field after the message is sent
        this.messageError = "";
        this.removeSelectedFile();
        this.isMessageFieldError = false;
        if(this.isDirectConversationModalOpen) {
          this.closeDirectConversation();
          this.filterUsers();
          this.selectUser(currentUser);
          this.cdr.detectChanges();
        }
        this.chatService.stopTyping(this.channelId); // Stop typing after sending message
      })
      .catch((error) => {
        console.error('Error sending message:', error);
    });
  }

  typingListener = async (channelId: any): Promise<any> => {
    if (this.selectedUser && channelId === this.channelId) {
      if (this.typingTimer) {
        clearTimeout(this.typingTimer);
      }

      this.typingTimer = setTimeout(() => {
        this.isTyping = false;
        this.typingTimer = null;
      }, 1000);

      if (!this.isTyping) {
        this.isTyping = true;
        this.cdr.detectChanges();
        this.scrollToBottomDiv();
      }
    }
  }

  messageListener = async (message: any, channelId: any): Promise<any> => {
    if (this.selectedUser && channelId === this.channelId) {
      this.messages.push(message);
      this.cdr.detectChanges();
      this.scrollToBottomDiv();
    } else {
      console.log(`Message received for another channel: ${channelId}. Ignoring.`);
      this.cdr.detectChanges();
    }
  }

  onInputChange(): void {
    if (this.currentMessage.trim()) {
      this.chatService.startTyping(this.channelId); // Start typing when the user types
    }
  }

  createDirectConversation(): void {
    console.log('Creating Direct Conversation');
    this.selectedUser = null;
    this.isDirectConversationModalOpen = true;
  }

  closeDirectConversation(): void {
    this.isDirectConversationModalOpen = false;
    this.directConversationSearchTerm = ''; // Clear the search input
    this.directFilteredUsers = []; // Clear filtered users
    this.showSearchResults = false; // Hide search results
    this.errorMessage = ''; // Clear error message
    this.isSearchFieldError = false; // Reset error flag
    this.selectedUser = null; // Clear the selected user
    this.to_user_id = '';
    this.to_user_name = '';
    this.to_user_email = '';
    this.channelId = '';
    this.channel = null;
    this.currentMessage = ''; // Clear the current message input
    this.messageError = "";
    this.isMessageFieldError = false;
    console.log('Direct Conversation closed and reset.');
  }

  async onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) return;

    const file = input.files[0];

    // Basic file validation
    if (file.size > 5 * 1024 * 1024) { // 5MB limit
      this.messageError = "File size must be less than 5MB";
      this.isMessageFieldError = true;
      return;
    }

    this.selectedFile = file;
  }

  openImagePreview(imageUrl: string): void {
    this.previewImageUrl = imageUrl;
  }

  closeImagePreview(): void {
    this.previewImageUrl = null;
  }

  removeSelectedFile(): void {
    this.selectedFile = null;
  }
}
