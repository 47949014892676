import {AfterViewInit, ChangeDetectorRef, Component, DestroyRef, inject, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';


import {ClientSelectInterface} from '../adserver.interface';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {
  CurrentAdgroupSelectService, CurrentAdSelectService,
  CurrentCampaignGroupSelectService,
  CurrentCampaignSelectService,
  CurrentClientIdSelectService,
  CurrentClientSlugSelectService
} from "../../objectPassingService";
import {forkJoin, Subject, take} from "rxjs";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressBarModule} from "@angular/material/progress-bar";

@Component({
  selector: 'app-client-select',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatButtonModule, MatProgressBarModule],
  templateUrl: './client-select.component.html',
  styleUrl: './client-select.component.css'
})
export class ClientSelectComponent implements OnInit {


  client_select_list: ClientSelectInterface[] = [
    {value: 'cps', viewValue: 'CPS', 'client_id': '111'},
    {value: 'akps', viewValue: 'AKPS', 'client_id': '222'},
  ];

  private destroyRef = inject(DestroyRef);
  destroyed = new Subject();

  client_id: string = '';
  client_slug: string = '';
  is_loading = true;

  myForm!: FormGroup;


  constructor(
    private fb: FormBuilder,
    private currentClientIdSelectService: CurrentClientIdSelectService,
    private currentClientSlugSelectService: CurrentClientSlugSelectService,
    private currentCampaignSelectService: CurrentCampaignSelectService,
    private currentCampaignGroupSelectService: CurrentCampaignGroupSelectService,
    private currentAdgroupSelectService: CurrentAdgroupSelectService,
    private currentAdSelectService: CurrentAdSelectService,
    private cdr: ChangeDetectorRef
  ) {} // end constructor

  ngOnInit() {
    this.is_loading = true;

    localStorage.removeItem('campaign_group_id');
    localStorage.removeItem('campaign_id');
    localStorage.removeItem('adgroup_id');
    localStorage.removeItem('ad_id');
    this.currentCampaignSelectService.announceCampaignSelect('');
    this.currentCampaignGroupSelectService.announceCampaignGroupSelect('');
    this.currentAdgroupSelectService.announceAdgroupSelect('');
    this.currentAdSelectService.announceAdSelect('');



    if (localStorage.getItem('client_slug')) {
      this.client_slug = localStorage.getItem('client_slug')!;
    }
    if (localStorage.getItem('client_id')) {
      this.client_id = localStorage.getItem('client_id')!;
    }
    if (this.client_id && this.client_slug) {
      this.currentClientIdSelectService.announceClientIdSelect(this.client_id);
      this.currentClientSlugSelectService.announceClientSlugSelect(this.client_slug);
    }
    this.getData();
    this.is_loading = false;

    //
    // let currentClientIdSelectService$ = this.currentClientIdSelectService.currentClientIdSelectAnnounced$.pipe(take(1));
    // let currentClientSlugSelectService$ = this.currentClientSlugSelectService.currentClientSlugSelectAnnounced$.pipe(take(1));
    //
    // forkJoin([currentClientIdSelectService$, currentClientSlugSelectService$]).subscribe({
    //   next: ([client_id, client_slug]) => {
    //     this.client_id = client_id;
    //     this.client_slug = client_slug;
    //     console.log('client_id', client_id);
    //     console.log('client_slug', client_slug);
    //     //this.getData();
    //     this.is_loading = false;
    //     setTimeout(() => { // gets  ExpressionChangedAfterItHasBeenCheckedError error if not in setTimeout
    //       this.currentClientIdSelectService.announceClientIdSelect(this.client_id);
    //       this.currentClientSlugSelectService.announceClientSlugSelect(this.client_slug);
    //     }, 0);
    //
    //   },
    //   error: (error) => {
    //     // Handle any error that occurs in any Observable
    //     console.error('An error occurred:', error);
    //     this.is_loading = false;
    //   },
    //   complete: () => {
    //     // This is called when all observables complete
    //     console.log('All observables completed');
    //   }
    // });
  } // end ngOnInit

  getData() {
    if (this.client_id) {
      this.myForm = this.fb.group({
        client: [this.client_slug, Validators.required]
      })//end myForm
      // this.myForm.get('client')!.setValue(this.client_id);
      //
    } else {
      this.myForm = this.fb.group({
        client: ['', Validators.required]
      })//end myForm
    }
  } // end getData


  cancel() {
    //this.myForm.reset();
  } // end cancel


  onClientChange(event: any) {
    let client_slug = event.value;
    this.currentCampaignGroupSelectService.announceCampaignGroupSelect('');
    this.currentCampaignSelectService.announceCampaignSelect('');
    this.currentAdgroupSelectService.announceAdgroupSelect('');
    this.currentAdSelectService.announceAdSelect('');

    this.setStorage(client_slug)
  } // end onClientChange


  setStorage(client_slug: string) {
    console.log('client_slugWTF',client_slug)
    if (client_slug) {
      this.currentClientSlugSelectService.announceClientSlugSelect(client_slug);
      let obj = this.client_select_list.find(item => item.value === client_slug);
      let client_id = obj!.client_id;
      this.currentClientIdSelectService.announceClientIdSelect(String(client_id));
      localStorage.setItem('client_slug', client_slug);
      localStorage.setItem('client_id', client_id);
    }

  } // end setStorage


  onSubmit(){

    let client_slug = this.myForm.get('client')!.value;
    this.setStorage(client_slug)

  } // end onSubmit




} // end ClientSelectComponent
