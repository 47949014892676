import {Component, DestroyRef, inject, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {LoginService} from "../login/login.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {environment} from "../../../environments/environment";
import {ModelValidators} from "../../validators/validators";
import {SnackBarService} from "../../objectPassingService";
import {Subject, takeUntil} from "rxjs";
import {InputPasswordComponent} from "../../resusable/input-password/input-password.component";
import {
  InputVerificationCodeComponent
} from "../../resusable/input-verification-code/input-verification-code.component";
import {InputEmailComponent} from "../../resusable/input-email/input-email.component";

@Component({
  selector: 'app-forgot-password-confirmation',
  standalone: true,
  imports: [CommonModule, MatButtonModule, ReactiveFormsModule, MatCardModule, MatToolbarModule, MatProgressBarModule, MatFormFieldModule, MatIconModule, MatInputModule, InputPasswordComponent, InputVerificationCodeComponent, InputEmailComponent],
  templateUrl: './forgot-password-confirmation.component.html',
  styleUrl: './forgot-password-confirmation.component.css'
})
export class ForgotPasswordConfirmationComponent implements OnInit, OnDestroy{

  private destroyRef = inject(DestroyRef);
  destroyed = new Subject();

  is_loading = false;
  hide = true;
  is_form_error: boolean = false;
  form_error_message: string = "";

  myForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required,ModelValidators.passwordControls]],
    code: ['', Validators.required],
  });

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private loginService: LoginService,
    private snackBarService: SnackBarService,
  ) {} // end constructor

  ngOnInit() {
    this.destroyRef.onDestroy(() => {
      this.destroyed.next('');
      this.destroyed.complete();
    });
  } //end ngOnInit

  ngOnDestroy() {
  } //end ngOnDestroy

  goBack(){
    window.history.back();
  }

  onFocus(){
    // console.log('onFocus')
    // this.is_form_error = false;
    // this.form_error_message = "";
  }

  onSubmit(){

    this.is_loading = true;
    console.log('this.is_loading',this.is_loading)
    console.log('submit login data')
    this.is_form_error = false;
    let data = this.myForm.value;

    data = {
      email: data.email,
      password: data.password,
      code: data.code,
    }


    const url = `${environment.API_AUTH_URL}/auth/password-forgot-confirmation`
    this.loginService.forgotPasswordConfirmation(url, data).pipe(takeUntil(this.destroyed)).subscribe(res => {
      console.log('res', res)
      this.is_loading = false;
      this.snackBarService.announceSackBar('Password reset successfully. Please login.');
      this.router.navigate(['/login']).then(r => console.log('navigate to login'));
    }, error => {
      console.log('error', error)
      this.is_loading = false;
      this.is_form_error = true;
      //this.form_error_message = error.error.message;
    }, () => {
      this.is_loading = false;
      //this.router.navigate(['/login']);
    });

  } //end onSubmit



} //end class
