<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    class="sidenav"
    fixedInViewport
    [attr.role]="'navigation'"
    [mode]="'side'"
    [opened]="true"
  >
    <mat-toolbar color="primary">
      <img src="../../assets/images/healent-logo.svg" class="Logo" />
    </mat-toolbar>
    <div class="nav-list">
      <mat-nav-list>
        <!-- <a
          *ngIf="superadmin_view || isRouteActive('/users')"
          (click)="usersRedirect()"
          [ngClass]="{ active: isRouteActive('/users') }"
          matTooltip="users"
        >
          <mat-icon> <i class="healent-patients"></i></mat-icon>
        </a> -->
        <a
          *ngIf="superadmin_view || isRouteActive('/registration-nav')"
          (click)="registrationRedirect()"
          [ngClass]="{ active: isRouteActive('/registration-nav') }"
          matTooltip="Patient Navigator"
        >
          <mat-icon> <i class="healent-bxs-dashboard"></i></mat-icon>
        </a>
        <!-- <a
          *ngIf="superadmin_view || isRouteActive('/cal-details')"
          (click)="calendarDetailsRedirect()"
          [ngClass]="{ active: isRouteActive('/cal-details') }"
          matTooltip="Registration Status"
        >
          <mat-icon> <i class="healent-note-edit-solid"></i></mat-icon>
        </a>
        <a
          *ngIf="superadmin_view || isRouteActive('/cal')"
          (click)="calendarRedirect()"
          [ngClass]="{ active: isRouteActive('/cal') }"
          matTooltip="Calendar"
        >
          <mat-icon> <i class="healent-calendar-fill"></i></mat-icon>
        </a>
        <a
          *ngIf="superadmin_view || isRouteActive('/adserver-nav')"
          (click)="adserverRedirect()"
          [ngClass]="{ active: isRouteActive('/adserver-nav') }"
          matTooltip="Ad Server"
        >
          <mat-icon> <i class="healent-gear-fill"></i></mat-icon>
        </a> -->
      </mat-nav-list>
      <a (click)="logout()" matTooltip="Logout">
        <mat-icon> <i class="healent-logout-1"></i></mat-icon>
      </a>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
