<div class="date-navigation">
  <div class="left-btn" (click)="previousDay()">
    <mat-icon>chevron_left</mat-icon>
  </div>
  <div class="date" (click)="openDatePicker()">
    {{ getDisplayDate() }}
  </div>
  <div class="right-btn" (click)="nextDay()">
    <mat-icon>chevron_right</mat-icon>
  </div>
</div>
<div class="hide-date">
  <mat-form-field>
    <input
      matInput
      [matDatepicker]="picker"
      (dateInput)="onDateChange($event)"
    />
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
