<mat-drawer-container class="drawer-container">
  <mat-drawer #drawer mode="side" position="end">
    <div class="drawer-header">
      <h3 class="drawer-title">{{ title }}</h3>
      <i (click)="closeDrawer()" class="healent-logout"></i>
    </div>
    <div
      cdkDropList
      cdkDropListOrientation="vertical"
      (cdkDropListDropped)="drop($event)"
      cdkDropListAutoScrollDisabled
    >
      <div
        *ngFor="let column of columns"
        cdkDrag
        [cdkDragBoundary]="'.mat-drawer'"
        class="column-item"
      >
        <mat-slide-toggle
          [checked]="column.visible"
          (change)="toggleColumnVisibility(column)"
        >
          {{ column.label }}
        </mat-slide-toggle>
        <span class="drawer-drag" mat-icon-button cdkDragHandle>
          <i class="color-gray-1 healent-q-2"></i>
        </span>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>
