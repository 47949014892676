<!--<app-intake-assessments></app-intake-assessments>-->

<!--{{ '2024-01-18T04:24:25Z'    | date:'short' : '-05:00'  }}-->
<!--{{ '2024-01-18T04:24:25Z'    | date:'short' : 'UTC'  }}-->

<div *ngIf="loadingService.loading$ | async">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="flex column flex-1 padding-l" [hidden]="showPatientView">

  <div class="flex row space-between margin-bottom-l">
    <h2>Tasks</h2>
    <mat-form-field>
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
  </div>
  <mat-divider></mat-divider>


  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="priority_ord" matSortDirection="desc" (matSortChange)="announceSortChange($event)">
      <!-- Priority Column -->
      <ng-container matColumnDef="priority_ord">
        <th style="width: 2.5%;" mat-header-cell *matHeaderCellDef mat-sort-header>Priority</th>
        <td mat-cell *matCellDef="let row">
          <div class="flag-icon" [ngClass]="getClassForPriority(row.priority)">
            <mat-icon class="icon-16 text-secondary-shade-5">flag</mat-icon>
          </div>
        </td>
      </ng-container>

      <!-- Patient Name Column -->
      <ng-container matColumnDef="patient_name">
        <th style="width: 12.5%;" mat-header-cell *matHeaderCellDef mat-sort-header>Patient Name</th>
        <td mat-cell *matCellDef="let row">
          <a class="curser-pointer" (click)="patientDetialVeiw(row)">{{row.patient_name | titlecase }}</a>
        </td>
      </ng-container>

      <!-- Created Column -->
      <ng-container matColumnDef="created_at_utc">
        <th style="width: 10%;" mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
        <td mat-cell *matCellDef="let row">{{row.created_at_utc | date:'short'}}</td>
      </ng-container>

      <!-- Nurse Action Column -->
      <ng-container matColumnDef="nurse_action">
        <th style="width: 20%;" mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
        <td mat-cell *matCellDef="let row">{{row.nurse_action}}</td>
      </ng-container>

      <!-- Alert Info Column -->
      <ng-container matColumnDef="alert_info">
        <th style="width: 30%;" mat-header-cell *matHeaderCellDef mat-sort-header>Alert Info.</th>
        <td mat-cell *matCellDef="let row">{{row.alert_info}}</td>
      </ng-container>

      <!-- Days With Readings Column -->
      <ng-container matColumnDef="days_with_readings">
        <th style="width: 5%;" mat-header-cell *matHeaderCellDef mat-sort-header>Days With Readings</th>
        <td mat-cell *matCellDef="let row">{{row.days_with_readings}}</td>
      </ng-container>

      <!-- Last Call Date Column -->
      <ng-container matColumnDef="last_call_date">
        <th style="width: 5%;" mat-header-cell *matHeaderCellDef mat-sort-header> Last Call</th>
        <td mat-cell *matCellDef="let row">{{row.last_call_date | date:'short'}}</td>
      </ng-container>

      <!-- Age -->
      <ng-container matColumnDef="age">
        <th style="width: 2.5%;" mat-header-cell *matHeaderCellDef mat-sort-header>Age</th>
        <td mat-cell *matCellDef="let row">{{row.age}}</td>
      </ng-container>

      <!-- Phone -->
      <ng-container matColumnDef="phone">
        <th style="width: 5%;" mat-header-cell *matHeaderCellDef>Phone</th>
        <td mat-cell *matCellDef="let row">{{row.phone}}</td>
      </ng-container>

      <!-- Close Column -->
      <ng-container matColumnDef="close"> <!-- stickyEnd="true" -->
        <th style="width: 2.5%;" mat-header-cell *matHeaderCellDef>Close Task</th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button class="warn-icon-primary table-icon" (click)="closeRow(row)">
            <mat-icon color="warn" style="cursor: pointer;">close</mat-icon>
          </button>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"
        (click)="getTableRecord(row)"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100, 250, 500]"
      [pageSize]="100"
      showFirstLastButtons
      aria-label="Select page of tasks">
    </mat-paginator>

  </div>

</div>



<!--<div [hidden]="!showPatientView">-->
<!--  NOTE: IF WE USE *ngIf this removed patient view from the dDOM but if we use hidden keeps in DOM and can create memory issues
For the table though we use hidden because we want to keep in the DOM.
-->
<div *ngIf="showPatientView">
  <app-patient-view (close)="closePatientView($event)" [client_id]="client_id"></app-patient-view>
</div>

<!--<mat-drawer-container class="example-container" autosize>-->
<!--  <mat-drawer #drawer class="example-sidenav" mode="side" position="end">-->
<!--    <p>Auto-resizing sidenav</p>-->
<!--    @if (showFiller) {-->
<!--    <p>Lorem, ipsum dolor sit amet consectetur.</p>-->
<!--    }-->
<!--    <button (click)="showFiller = !showFiller" mat-raised-button>-->
<!--      Toggle extra text-->
<!--    </button>-->
<!--  </mat-drawer>-->

<!--  <div class="example-sidenav-content">-->
<!--    <button type="button" mat-button (click)="drawer.toggle()">-->
<!--      Toggle sidenav-->
<!--    </button>-->
<!--  </div>-->

<!--</mat-drawer-container>-->