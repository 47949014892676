import { HttpInterceptorFn } from "@angular/common/http";
import { tap } from "rxjs";


//https://www.angulararchitects.io/en/blog/the-refurbished-httpclient-in-angular-15-standalone-apis-and-functional-interceptors/

export const authInterceptor: HttpInterceptorFn = (req, next) => {


  //https://www.bezkoder.com/angular-13-jwt-auth-httponly-cookie/


  console.log('request', req.method, req.url);
  console.log('authInterceptor')
  const id_token = localStorage.getItem("id_token");

    // if (req.url.startsWith('https://testme/api/')) {
    if (id_token != null){
      const headers = req.headers.set('Authorization', id_token).set('Content-Type', 'application/json')
      console.log('headers auth', headers)
      req = req.clone({
        headers,
        //withCredentials: true  // send cookies for http only back to server   https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors/CORSNotSupportingCredentials
      });

      return next(req).pipe(
        tap(resp => console.log('response interceptor', resp))
      );

    } else {

      const headers = req.headers.set('Content-Type', 'application/json')

      req = req.clone({
        headers
      });

      return next(req).pipe(
        tap(resp => console.log('response interceptor', resp))
      );

    }

}
