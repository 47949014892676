import {AfterViewInit, Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {LoadingService, SnackBarService} from "../../objectPassingService";
import {interval, retry, Subject, takeUntil} from "rxjs";
import {MatTable, MatTableDataSource, MatTableModule} from "@angular/material/table";
import {Alert, DashboardService} from "../../dashboard/dashboard.service";
import {MatSort, MatSortModule, Sort} from "@angular/material/sort";
import {ClientIdService, TasksAllBehaviorService} from "../../dashboardPassingService";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {ActivatedRoute} from "@angular/router";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {PatientViewComponent} from "../patient-view/patient-view.component";
import {MatDividerModule} from "@angular/material/divider";
import {MatListModule} from "@angular/material/list";
import {MatSidenavModule} from "@angular/material/sidenav";
import {environment} from "../../../environments/environment";
import {IntakeAssessmentsComponent} from "../../intake-assessments/intake-assessments.component";


@Component({
  selector: 'app-tasks-all',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule,
    MatSortModule, MatTableModule, MatPaginatorModule, PatientViewComponent, MatDividerModule,
    MatListModule, MatSidenavModule, IntakeAssessmentsComponent ],
  templateUrl: './tasks-all.component.html',
  styleUrl: './tasks-all.component.css'
})
export class TasksAllComponent implements OnInit, AfterViewInit {

  private destroyRef = inject(DestroyRef);
  destroyed_get_tasks = new Subject();

  showPatientView: boolean = false;
  client_id: number = 0;
  showFiller = false;

  is_loading: boolean = true;
  alert_count: number = 0;
  data: Alert[] = [];
  user_id: number = 0;
  filterIntervalValue: string = '';

  dataSource!: MatTableDataSource<Alert>;
  displayedColumns: string[] = ['priority_ord', 'patient_name', 'created_at_utc', 'nurse_action', 'alert_info', 'days_with_readings', 'last_call_date', 'age', 'phone', 'close'];


  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatTable) table!: MatTable<Alert>;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  //@ViewChild(MatSort) sort!: MatSort;


  constructor(
    public loadingService: LoadingService,
    private tasksAllBehaviorService: TasksAllBehaviorService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private clientIdService: ClientIdService,
    private snackBarService: SnackBarService,
  ) {
    this.route.params.pipe(takeUntilDestroyed()).subscribe(parms => {
      this.user_id = parms['user_id'];
      this.getAllTasks();
      //this.pollingTasksService()
    });
  } // constructor

  ngOnInit(): void {

    //this.dataSource = new MatTableDataSource(this.data);



    // https://stackoverflow.com/questions/68093856/expanding-multiple-rows-in-a-nested-mat-table-in-angular-not-working

    this.showPatientView = false;
    this.destroyRef.onDestroy(() => {
      this.destroyed_get_tasks.next(true);
      this.destroyed_get_tasks.complete();
    });


  } // ngOnInit

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  } //end ngAfterViewInit


  getAllTasks() {

    this.tasksAllBehaviorService.tasksAllBehaviorAnnounced$.pipe(takeUntil(this.destroyed_get_tasks)).subscribe(data => {
        this.data = data;
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;


        //this.dataSource.sort.active = 'priority_ord';
        // this.dataSource.sort.direction = 'desc';

      //this.sort.sort({ id: 'priority_ord', start: 'desc', disableClear: false });


        // NOTE: ON INTERVAL, IF USER IS FILTERING, THE FILTER WILL BE LOST SO WE HAVE TO RE-APPLY IT ON REFRESH DATA
        if (this.filterIntervalValue) {
          this.dataSource.filter = this.filterIntervalValue.trim().toLowerCase();
        } else {
          console.log('no filter so passing on interval')
        }


      }, error => {
        console.log('error', error)
      },
      () => {
        console.log('complete')
      }
    ) // end subscribe
  } // end getAllTasks

  getTableRecord(row: Alert) {
    console.log('getTableRecord', row);
  } //end getTableRecord

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterIntervalValue = filterValue;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  } //end applyFilter

  closeRow(row: Alert) {

    //note if error the task will reshow on the next interval
    this.dashboardService.getAlertsResolveByUser('alerts/resolve', this.user_id, row.alert_id).pipe(takeUntil(this.destroyed_get_tasks)).subscribe(
      data => {
        const index: number = this.data.indexOf(row);
        this.data.splice(index, 1);
        this.dataSource.data = this.data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.snackBarService.announceSackBar('Task Closed');
        //this.getAllTasks()  //The next interval will get the new data no need to call this because of interval
      },
      error => {
        console.log('error', error)
      },
      () => {
        console.log('complete')
      }
    );
  } //end closeRow

  patientDetialVeiw(row: Alert) {
    console.log('patientDetialVeiw', row);
    this.client_id = row.client_id;
    const domain = environment.API_DOMAIN_LEGACY

    const url = `https://${domain}/#/surgery-navigator/patients?patientID=${this.client_id}`
    window.open(url, "_blank");

    // this.clientIdService.announceClientIdBehavior(this.client_id);
    // this.showPatientView =  true;
  } //end patientDetialVeiw

  closePatientView(event: any) {
    console.log('closePatientView', event);
    this.showPatientView = false;
  } //end closePatientView


  pollingTasksService() {

    interval(10000).pipe(retry(3), takeUntil(this.destroyed_get_tasks)).subscribe(x => {
      console.log('pollingTasksService')
      this.getAllTasks()
    }, (error) => {
      console.log('polling error', error)
    }, () => {

    }); //end interval


  } //end pollingTokenRefreshService

  getClassForPriority(priority: string) {
    if (priority.toLowerCase() === 'critical') {
      return 'priority-critical';
    } else if (priority.toLowerCase() === 'high') {
      return 'priority-high';
    } else {
      return 'priority-normal';
    }
  }

  announceSortChange(sortState: Sort) {

    // {active: 'priority_ord', direction: 'asc'}
    // {active: 'priority_ord', direction: 'desc'}
    // {active: 'priority_ord', direction: ''}

    console.log('announceSortChange', sortState)

  }

} // end TasksAllComponent
