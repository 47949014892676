import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {CommonModule, NgForOf, NgIf} from '@angular/common';
import {
  CurrentClientIdSelectService,
  CurrentClientSlugSelectService,
  CurrentCampaignGroupSelectService,
  SnackBarService,
  CurrentCampaignSelectService,
  CurrentAdgroupSelectService,
  CurrentAdSelectService
} from "../../objectPassingService";
import {CampaignGroupService} from "./campaign-group.service";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";

import {CampaignGroupInterface, SelectInterface} from "../adserver.interface";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatCardModule} from "@angular/material/card";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatInputModule} from "@angular/material/input";
import {UsersInterfaceData} from "../../users/users.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {co} from "@fullcalendar/core/internal-common";
import {Router} from "@angular/router";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {forkJoin, take} from "rxjs";


export interface  ClientInterfaceData {
  campaign_group_id: string,
  campaign_type: string,
  status: string,
  name: string,
  description: string,
}


@Component({
  selector: 'app-campaign-groups',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule, MatListModule, MatIconModule, MatTooltipModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatCardModule, MatPaginatorModule, MatSortModule, MatTableModule],
  templateUrl: './campaign-groups.component.html',
  styleUrl: './campaign-groups.component.css'
})
export class CampaignGroupsComponent implements OnInit{

  client_id: string = '';
  client_slug: string = '';
  campaign_group_id: string = '';
  is_loading: boolean = true;


  dataSource!: MatTableDataSource<ClientInterfaceData>;
  displayedColumns: string[] = ['campaign_group_id','campaign_type','status','name', 'description', 'View', 'Edit', 'Delete'];
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;


  constructor(
    private router: Router,

    private campaignGroupService: CampaignGroupService,
    private currentClientIdSelectService: CurrentClientIdSelectService,
    private currentClientSlugSelectService: CurrentClientSlugSelectService,
    private currentCampaignGroupSelectService: CurrentCampaignGroupSelectService,
    private currentCampaignSelectService: CurrentCampaignSelectService,
    private currentAdgroupSelectService: CurrentAdgroupSelectService,
    private currentAdSelectService: CurrentAdSelectService,
    public dialog: MatDialog,
  ) { } // end constructor

  ngOnInit(): void {

    localStorage.removeItem('campaign_group_id');
    localStorage.removeItem('campaign_id');
    localStorage.removeItem('adgroup_id');
    localStorage.removeItem('ad_id');

    if (localStorage.getItem('client_id')) {
      this.client_id = localStorage.getItem('client_id') || '';
      this.currentClientIdSelectService.announceClientIdSelect(this.client_id);
    }
    if (localStorage.getItem('client_slug')) {
      this.client_slug = localStorage.getItem('client_slug') || '';
      this.currentClientSlugSelectService.announceClientSlugSelect(this.client_slug);
    }


    this.currentCampaignGroupSelectService.announceCampaignGroupSelect('');
    this.currentCampaignSelectService.announceCampaignSelect('');
    this.currentAdgroupSelectService.announceAdgroupSelect('');
    this.currentAdSelectService.announceAdSelect('');

    let currentClientIdSelectService$ = this.currentClientIdSelectService.currentClientIdSelectAnnounced$.pipe(take(1));
    let currentClientSlugSelectService$ = this.currentClientSlugSelectService.currentClientSlugSelectAnnounced$.pipe(take(1));
    //let currentCampaignGroupSelectService$ = this.currentCampaignGroupSelectService.currentCampaignGroupSelectAnnounced$.pipe(take(1));
    //let currentCampaignSelectService$ = this.currentCampaignSelectService.currentCampaignSelectAnnounced$.pipe(take(1));
    //let currentAdgroupSelectService$ = this.currentAdgroupSelectService.currentAdgroupSelectAnnounced$.pipe(take(1));
    forkJoin([currentClientIdSelectService$, currentClientSlugSelectService$]).subscribe({
      next: ([client_id, client_slug]) => {
        this.client_id = client_id;
        this.client_slug = client_slug;
        console.log('client_id', client_id);
        console.log('client_slug', client_slug);
        if (this.client_id) {
          this.getData();
        } else {
          this.is_loading = false;
          console.log('no client_id')
        }
      },
      error: (error) => {
        // Handle any error that occurs in any Observable
        console.error('An error occurred:', error);
        this.is_loading = false;
      },
      complete: () => {
        // This is called when all observables complete
        console.log('All observables completed');
      }
    });

  } // end ngOnInit


  getData(){

    this.is_loading = true;
    let endpoint = `adserver/campaign_group/all/${this.client_id}`;
    this.campaignGroupService.get(endpoint).subscribe((data: any) => {
      console.log('campaign_group data', data);
      data = data['message'];
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.is_loading = false;
      this.is_loading = false;
    },
    (error) => {
      console.log('campaign_group error', error);
      this.is_loading = false;
    });



  } // end getData

  dialogRefAddCampaignGroup!: MatDialogRef<DialogAddCampaignGroup>;
  addCampaignGroup(is_edit:boolean, row: object) {
    console.log('addCampaignGroup',row);
    let row_data = {}
    if (is_edit == true) {
      row_data = row;
    }

    // @ts-ignore
    row_data['client_id'] = this.client_id;
    // @ts-ignore
    row_data['client_slug'] = this.client_slug;


    var enterAnimationDuration = '0ms';
    var exitAnimationDuration = '0ms';


    // @ts-ignore
    let dialogRef = this.dialog.open(DialogAddCampaignGroup, {
      width: '450px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: row_data
    });


    dialogRef.afterClosed().subscribe(result => {

      if (result == 'cancel' || result == undefined) {
        console.log('we cancled')
      } else {
        this.getData();
        //this.getClients()
        //this.loadAccounts(this.agency_id) //reload table
      }
    });//end dialogRef


  } // end addClient


  getTableRecord(row: object){
    console.log('getTableRecord', row);
  } //end getTableRecord


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  } //end applyFilter


  viewData(row: ClientInterfaceData) {
    console.log('viewData', row);
    const campaign_group_id = row['campaign_group_id']
    this.currentCampaignGroupSelectService.announceCampaignGroupSelect(campaign_group_id);
    localStorage.setItem('campaign_group_id', campaign_group_id);
    this.router.navigate(['/adserver-nav',  { outlets: { admeta: ['campaigns'] } }]).then(r => console.log('redirect to campaigns'));
  } //end viewData(row)


  deleteRow(row: ClientInterfaceData){
    console.log('deleteRow', row);

    const campaign_group_id = row['campaign_group_id']
    console.log('deleteRow', campaign_group_id);


    this.campaignGroupService.delete(`adserver/campaign_group/${campaign_group_id}`).subscribe(x => {
      //this.snackBarService.announceSackBar('Campaign Group Deleted');
      console.log(x);
      localStorage.removeItem('campaign_group_id');
      this.currentCampaignGroupSelectService.announceCampaignGroupSelect('');
      this.getData();
    });



  } //end deleteRow


} // end CampaignGroupsComponent





/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/

/*---------------------------------------------
Dialog for adding a new client
---------------------------------------------*/
@Component({
  selector: 'add-campaign-group-dialog',
  templateUrl: 'add-campaign-group-dialog.html',
  imports: [
    MatCardModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    NgIf,
    MatOptionModule,
    MatSelectModule,
    NgForOf
  ],
  standalone: true
})
export class DialogAddCampaignGroup implements OnInit{


  myForm!: FormGroup;
  is_form_error: boolean = false;
  form_error_message: string = "";
  is_loading = false;
  is_edit = false;
  title_text = "Create Campaign Group";
  submit_text = "Create"
  client_id = ""
  client_slug = ""
  campaign_group_id = ""

  campaign_type_list: SelectInterface[] = [
    {value: 'registration_reminders', viewValue: 'Registration Reminders'}
  ];




  constructor(private fb: FormBuilder,
              public dialogRef: MatDialogRef<DialogAddCampaignGroup>,
              private campaignGroupService: CampaignGroupService,
              private snackBarService: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public data: CampaignGroupInterface) {}

  ngOnInit(): void {


    this.client_id = this.data['client_id'] || '';
    this.client_slug = this.data['client_slug'] || '';
    this.campaign_group_id = this.data['campaign_group_id'] || '';


    console.log('DialogAddCampaignGroup',this.data)
    let name = '';
    let description = '';
    let campaign_type = 'registration_reminders';
    let status = 'active';

    console.log('this.data WLOWWWWWWW',this.data)
    if (Object.keys(this.data).length==2) {
      console.log('we have empty data')
      this.is_edit = false;
    } else {
      name = this.data['name'];
      description = this.data['description'];
      campaign_type = this.data['campaign_type'];
      status = this.data['status'];
      this.is_edit = true;
      this.title_text = "Edit Campaign Group";
      this.submit_text = "Update"
    }

    this.myForm = this.fb.group({
      name: [name, [Validators.required]],
      description: [description, [Validators.required]],
      campaign_type: [{value: campaign_type, disabled: this.is_edit}, [Validators.required]],
      status: [status, [Validators.required]],
    });

  } //end ngOnInit

  onFocus(){
    console.log('onFocus')
    this.is_form_error = false;
    this.form_error_message = "";
  }

  onCancel() {
    this.dialogRef.close('cancel');
  }

  onSubmit() {
    this.is_loading = true;
    let form_data = this.myForm.value;
    console.log(form_data)

    if (!this.is_edit) {
      this.campaignGroupService.post(`adserver/campaign_group/create/${this.client_id}`, form_data).subscribe(x => {
        //this.snackBarService.announceSackBar('Client Created');
        this.dialogRef.close(form_data);
      }, error => {
        console.log('error',error)
        this.dialogRef.close('cancel');

      });

    } else {

      this.campaignGroupService.put(`adserver/campaign_group/${this.campaign_group_id}`, form_data).subscribe(x => {
        //this.snackBarService.announceSackBar('Client Updated');
        this.dialogRef.close(form_data);
      }, error => {
        console.log('error',error)
        this.dialogRef.close('cancel');
      });
    }



  } //end onSubmit


} //end DialogAddClient
