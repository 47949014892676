import {Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatCardModule} from "@angular/material/card";
import {AlertsBehaviorService} from "../../dashboardPassingService";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {finalize, Subject, takeUntil, tap} from "rxjs";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {UsersInterfaceData} from "../../users/users.component";
import {Alert} from "../../dashboard/dashboard.service";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatBadgeModule} from "@angular/material/badge";
import {MatDividerModule} from "@angular/material/divider";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {LoadingComponent} from "../../resusable/loading/loading.component";

@Component({
  selector: 'app-alerts',
  standalone: true,
    imports: [CommonModule, MatCardModule, MatProgressBarModule, MatButtonModule, MatIconModule, MatSortModule, MatTableModule, MatBadgeModule, MatDividerModule, LoadingComponent],
  templateUrl: './alerts.component.html',
  styleUrl: './alerts.component.css'
})
export class AlertsComponent implements OnInit, OnDestroy{


  private destroyRef = inject(DestroyRef);
  destroyed_alerts = new Subject();

  is_loading: boolean = true;
  alert_count: number = 0;

  dataSource!: MatTableDataSource<Alert> ;
  displayedColumns: string[] = ['alert_info'];

  @ViewChild(MatSort, {static: true}) sort!: MatSort;


  constructor(
    private alertsBehaviorService: AlertsBehaviorService
  ) { }

  ngOnInit(): void {

    // ALERT SEND A DEFAULT VALUE OF [] WHICH HAS A LENGTH OF 0
    // ON PUSH OF NO DATA THEN ADD DATA OF EMPTY ALERT THAT WE HAVE TO CHECK FOR WHICH SUCKS UNTIL
    // WE CAN FIND A BETTER WAY TO DO THIS



    this.is_loading = false;
    (this.alertsBehaviorService.currentAlertsAnnouncedBehaviorSource.pipe(
      takeUntil(this.destroyed_alerts),
    ).subscribe(
      data => {
        this.alert_count = data.length;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
        // WE HAVE TO TEST IF NO DATA
        // if(this.alert_count > 0){
        //   this.is_loading = false;
        // }
      },
      error => {
        console.log(error);
        this.is_loading = false;
      },
      () => {
        this.is_loading = false;
      }
    ))

    this.destroyRef.onDestroy(() => {
      this.destroyed_alerts.next(true);
      this.destroyed_alerts.complete();
    });




  } // end ngOnInit

  ngOnDestroy(): void {
  } // end ngOnDestroy

  getTableRecord(row: any){
    console.log('row', row)
  } // end getTableRecord


}
