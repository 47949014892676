import { Component } from '@angular/core';
import moment from 'moment';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomDatepickerComponent } from "../resusable/custom-datepicker/custom-datepicker.component";
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AppointmentDetailsComponent } from "./components/appointment-details/appointment-details.component";
import { TasksComponent } from "./components/tasks/tasks.component";

interface Alert {
  patientName: string;
  readingType: string;
  time: string;
}

@Component({
  selector: 'app-clinical-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    CustomDatepickerComponent,
    AppointmentDetailsComponent,
    TasksComponent
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  templateUrl: './clinical-dashboard.component.html',
  styleUrl: './clinical-dashboard.component.css'
})
export class ClinicalDashboardComponent {
  selectedDate = new Date();
  selectedStep: number | null = null;

  patientNames = [
    'John Smith',
    'Emma Johnson',
    'Michael Brown',
    'Sarah Davis',
    'Robert Wilson',
    'Lisa Anderson',
    'David Taylor',
    'Emily Martinez'
  ];

  steps = Array.from({ length: 25 }, (_, i) => {
    const time = moment('10:00 AM', 'hh:mm A').add(i * 15, 'minutes').format('hh:mm A');
    const randomName = this.patientNames[Math.floor(Math.random() * this.patientNames.length)];
    return {
      title: randomName,
      time: time,
      state: `step-${i + 1}`,
      icon: this.getIcon(i)
    };
  });

  // Alerts data
  alerts: Alert[] = [
    {
      patientName: this.patientNames[Math.floor(Math.random() * this.patientNames.length)],
      readingType: 'BHI Alerts',
      time: '6h ago'
    },
    {
      patientName: this.patientNames[Math.floor(Math.random() * this.patientNames.length)],
      readingType: 'BHI Alerts',
      time: '2 mins ago'
    },
    {
      patientName: this.patientNames[Math.floor(Math.random() * this.patientNames.length)],
      readingType: 'BHI Alerts',
      time: '45 mins ago'
    },
    {
      patientName: this.patientNames[Math.floor(Math.random() * this.patientNames.length)],
      readingType: 'BHI Alerts',
      time: '2h ago'
    },
    {
      patientName: this.patientNames[Math.floor(Math.random() * this.patientNames.length)],
      readingType: 'BHI Alerts',
      time: '45 mins ago'
    },
    {
      patientName: this.patientNames[Math.floor(Math.random() * this.patientNames.length)],
      readingType: 'BHI Alerts',
      time: '4h ago'
    }
  ];

  isDrawerOpen: boolean = false;
  alertsCount: number = this.alerts.length; // Updated to reflect alerts array length
  alertIcons: string[] = ['healent-call', 'healent-message', 'healent-new-task', 'healent-ignore'];
  appointmentButtons: any[] = [
    { key: 'update', label: 'Update', action: 'update', customClass: 'update-button' },
    { key: 'markComplete', label: 'Mark as Complete', action: 'markComplete', customClass: 'mark-as-complete-button' },
    { key: 'delete', label: 'Delete', action: 'delete', customClass: 'delete-button' },
  ];

  getIcon(index: number): string {
    const icons = ['healent-path-4041', 'healent-form-input-icon-28', 'healent-tasks-solid', 'healent-path-4053'];
    return icons[index % icons.length];
  }

  openDrawer(stepNumber: number, drawer?: any) {
    this.selectedStep = stepNumber;
    this.isDrawerOpen = true;
  }

  closeDrawer(): void {
    this.selectedStep = null;
    this.isDrawerOpen = false;
  }

  isToday(): boolean {
    const today = new Date();
    return (
      this.selectedDate.getDate() === today.getDate() &&
      this.selectedDate.getMonth() === today.getMonth() &&
      this.selectedDate.getFullYear() === today.getFullYear()
    );
  }

  previousDay(): void {
    this.selectedDate = new Date(this.selectedDate);
    this.selectedDate.setDate(this.selectedDate.getDate() - 1);
  }

  nextDay(): void {
    this.selectedDate = new Date(this.selectedDate);
    this.selectedDate.setDate(this.selectedDate.getDate() + 1);
  }

  onDateChange(event: any): void {
    if (event.value) {
      console.log(event);
    }
  }
}
