import { Component, ViewChild, Output, EventEmitter, forwardRef } from '@angular/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDatepickerComponent),
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class CustomDatepickerComponent implements ControlValueAccessor {
  currentDate: Date = new Date();  // Ensure currentDate is initialized

  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  @ViewChild('picker') picker!: MatDatepicker<Date>;

  writeValue(date: Date | null): void {
    this.currentDate = date || new Date();  // If date is null, use a new Date
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle the disabled state if needed
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    if (event.value) {
      this.currentDate = event.value;
      this.onChange(this.currentDate);
      this.dateChange.emit(this.currentDate);
    }
  }

  openDatePicker(): void {
    this.picker.open();
  }

  getDisplayDate(): string {
    if (!this.currentDate) {
      return '';
    }
    const today = new Date();
    return this.isToday(this.currentDate) ? 'Today' : this.currentDate.toLocaleDateString('en-US');
  }

  isToday(date: Date): boolean {
    if (!date) {
      return false;
    }
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  previousDay(): void {
    if (this.currentDate) {
      this.currentDate.setDate(this.currentDate.getDate() - 1);
      this.onChange(this.currentDate);
      this.dateChange.emit(this.currentDate);
    }
  }

  nextDay(): void {
    if (this.currentDate) {
      this.currentDate.setDate(this.currentDate.getDate() + 1);
      this.onChange(this.currentDate);
      this.dateChange.emit(this.currentDate);
    }
  }
}
