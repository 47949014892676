import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-save-filter-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './filter-dialog-component.html',
  styleUrls: ['./filter-dialog-component.css']
})
export class SaveFilterDialogComponent implements OnInit {
  filterName: string = '';
  errorMessage: string = '';

  constructor(
    public dialogRef: MatDialogRef<SaveFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { currentFilters: any; existingFilters: string[]; filterNames: string[] }
  ) {}

  ngOnInit() {
    // Clear input when dialog is reopened
    this.dialogRef.beforeClosed().subscribe(() => {
      this.filterName = '';
      this.errorMessage = '';
    });
  }

  private findDuplicateFilter(currentFilters: any): string | null {

    for (const [filterName, filters] of Object.entries(this.data.existingFilters)) {
      if (JSON.stringify(filters) === JSON.stringify(currentFilters)) {
        return filterName; // Return the name of the duplicate filter
      }
    }
    return null;
  }

  onSave(): void {
    if (this.data.filterNames.includes(this.filterName)) {
      this.errorMessage = 'A filter with this name already exists';
      return;
    }

    const duplicateFilterName = this.findDuplicateFilter(this.data.currentFilters);
    if (duplicateFilterName) {
      this.errorMessage = `A filter with this combination already exists: "${duplicateFilterName}"`;
      return;
    }

    this.dialogRef.close({
      filterName: this.filterName,
      selectedFilters: this.data.currentFilters
    });
  }
}
