import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {CampaignGroupService} from "../campaign-groups/campaign-group.service";
import {CampaignGroupSequenceService} from "./campaign-group-sequence.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {NestedTreeControl} from "@angular/cdk/tree";
import {MatTreeModule, MatTreeNestedDataSource} from "@angular/material/tree";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";


import {
  CdkDrag,
  CdkDragDrop,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import {MatDividerModule} from "@angular/material/divider";
import {MatListModule} from "@angular/material/list";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {SelectInterface} from "../adserver.interface";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {CurrentClientIdSelectService, CurrentClientSlugSelectService} from "../../objectPassingService";

interface FoodNode {
  name: string;
  children?: FoodNode[];
}

interface OrderLookupInterface {
  [key: string]: number;
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'Fruit',
    children: [{name: 'Apple'}, {name: 'Banana'}, {name: 'Fruit loops'}],
  },
  {
    name: 'Vegetables',
    children: [
      {
        name: 'Green',
        children: [{name: 'Broccoli'}, {name: 'Brussels sprouts'}],
      },
      {
        name: 'Orange',
        children: [{name: 'Pumpkins'}, {name: 'Carrots'}],
      },
    ],
  },
];

@Component({
  selector: 'app-campaign-group-sequence',
  standalone: true,
  imports: [CommonModule, MatTreeModule, MatIconModule, MatButtonModule, CdkDropList, CdkDragPlaceholder, CdkDrag, MatDividerModule, MatListModule, MatProgressBarModule, MatFormFieldModule, MatOptionModule, MatSelectModule, ReactiveFormsModule, FormsModule],
  templateUrl: './campaign-group-sequence.component.html',
  styleUrl: './campaign-group-sequence.component.css'
})
export class CampaignGroupSequenceComponent implements OnInit{


  /*
  Short term goal:
  List campaign groups
  Order the campaign groups
  Backend creates parent child relationship from ordered campaign groups

  Long term goal:
  Use a tree




   */

  movies = [];

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.movies, event.previousIndex, event.currentIndex);
    console.log('Movies:', this.movies);
  }

  is_edit = false;
  client_id: string = '';
  client_slug: string = '';
  is_loading: boolean = false;
  campaign_group_id: string = '';
  name_index_lookup: OrderLookupInterface = {};
  campaign_group_id_index_lookup: OrderLookupInterface = {};

  // treeControl = new NestedTreeControl<FoodNode>(node => node.children);
  // dataSource = new MatTreeNestedDataSource<FoodNode>();

  campaign_type_list: SelectInterface[] = [
    {value: 'registration_reminders', viewValue: 'Registration Reminders'}
  ];

  constructor(
    private router: Router,
    private currentClientIdSelectService: CurrentClientIdSelectService,
    private currentClientSlugSelectService: CurrentClientSlugSelectService,
    private campaignGroupService: CampaignGroupService,
    private campaignGroupSequenceService: CampaignGroupSequenceService,
    public dialog: MatDialog,


  ) {
    //this.dataSource.data = TREE_DATA
  } // End of constructor
  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;

  ngOnInit() {

    if (localStorage.getItem('client_id')) {
      this.client_id = localStorage.getItem('client_id') || '';
      this.currentClientIdSelectService.announceClientIdSelect(this.client_id);
    }
    if (localStorage.getItem('client_slug')) {
      this.client_slug = localStorage.getItem('client_slug') || '';
      this.currentClientSlugSelectService.announceClientSlugSelect(this.client_slug);
    }

    this.is_loading = false;

  } // End of ngOnInit

  onCampaignTypeChange(event: any) {
    let value = event.value;
    console.log('Event:', value);

    this.is_loading = true;
    let endpoint = `adserver/campaign_group_sequence/${this.client_id}/${value}`;
    this.campaignGroupSequenceService.get(endpoint).subscribe((data: any) => {


        data = data['message'];
        console.log('data!!!!!!!!!!!!', data);
        if (data.length == 0) {
          // Means that we did not save any sequence yet
          this.getCampaignGroupData();
          this.is_loading = false;
          return;
        } else {
          this.is_edit = true;
          this.movies = []
          let data_temp = []
          this.name_index_lookup = {}
          for (let i = 0; i < data.length; i++) {
            if (data[i]['campaign_type'] == 'registration_reminders') {
              data_temp.push(data[i]);
              // @ts-ignore
              this.movies.push(data[i]['name']);
              this.name_index_lookup[data[i]['name']] = i;
              this.campaign_group_id_index_lookup[i] = data[i]['campaign_group_id'];
            }
          }

        }
        this.is_loading = false;
      },
      (error) => {
        console.log('error', error);
        //this.is_loading = false;
      });


    //this.getCampaignGroupData()
  }

  getCampaignGroupData(){


    // We need to find out of all ready created.  If so get the list for the saved tree

    this.is_loading = true;
    let endpoint = `adserver/campaign_group/all/${this.client_id}`;
    this.campaignGroupService.get(endpoint).subscribe((data: any) => {

        data = data['message'];
        console.log('campaign_group data', data);

        this.movies = []
        let data_temp = []
        this.name_index_lookup = {}
        for (let i = 0; i < data.length; i++) {
          if (data[i]['campaign_type'] == 'registration_reminders') {
            data_temp.push(data[i]);
            // @ts-ignore
            this.movies.push(data[i]['name']);
            this.name_index_lookup[data[i]['name']] = i;
            this.campaign_group_id_index_lookup[i] = data[i]['campaign_group_id'];
          }
        }
        console.log(this.campaign_group_id_index_lookup)




        // this.dataSource = new MatTableDataSource(data);
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
        // this.is_loading = false;
        this.is_loading = false;
      },
      (error) => {
        console.log('campaign_group error', error);
        this.is_loading = false;
      });

  } // end getData


  onSubmit() {
    this.is_loading = true;



    let ordered_campaign_group_list = []
    for (let i = 0; i < this.movies.length; i++) {
      let name = this.movies[i];
      let index = this.name_index_lookup[name];


      ordered_campaign_group_list.push(this.campaign_group_id_index_lookup[index]);
    }

    console.log('Ordered Campaign Group List:', ordered_campaign_group_list);

    const data = {
      "sequence": ordered_campaign_group_list,
      "campaign_type": "registration_reminders",
    }
    const endpoint = `adserver/campaign_group_sequence/create/${this.client_id}`;

    this.campaignGroupSequenceService.post(endpoint, data).subscribe((data: any) => {
        console.log('data', data);
        this.is_loading = false;
      },
      (error) => {
        console.log('error', error);
        this.is_loading = false;
      });

  } // End of submit




  /*******************************************************************************************************************/
  /*******************************************************************************************************************/
  /*******************************************************************************************************************/
  /*******************************************************************************************************************/



  /*******************************************************************************************************************/
  /*******************************************************************************************************************/
  /*******************************************************************************************************************/
  /*******************************************************************************************************************/
  // onNodeClick(node: any) {
  //   console.log('Node clicked:', node.name);
  //   let node_name = node.name;
  //   //this.addChildNode()
  //   const node_temp = this.findNode(node.name, this.dataSource.data);
  //   console.log('Node temp:', node_temp);
  //   // Add your logic here
  // } // End of onNodeClick
  //
  // addChildNode() {
  //   // Step 1: Identify the node
  //   const node = this.dataSource.data.find(node => node.name === 'Carrots');
  //   // Step 2: Add a new child to the node's children array
  //   if (node) {
  //     if (!node.children) {
  //       node.children = [];
  //     }
  //     node.children.push({ name: 'New Child' });
  //
  //     // Step 3: Update the dataSource.data
  //     this.dataSource.data = [...this.dataSource.data];
  //   }
  // } // End of addChildNode
  //
  // findNode(name: string, nodes: FoodNode[]): FoodNode | null {
  //   for (let i = 0; i < nodes.length; i++) {
  //     if (nodes[i].name === name) {
  //       return nodes[i];
  //     } else if (nodes[i].children) {
  //       // @ts-ignore
  //       const foundNode = this.findNode(name, nodes[i].children);
  //       if (foundNode) {
  //         console.log('Found node:', foundNode);
  //         if (!foundNode.children){
  //           foundNode.children = [];
  //           foundNode.children.push({ name: 'New Child' });
  //           //this.dataSource.data = [...this.dataSource.data];
  //           this.dataSource.data = JSON.parse(JSON.stringify(this.dataSource.data));
  //         }
  //
  //
  //         return foundNode;
  //       }
  //     }
  //   }
  //   return null;
  // }
  /*******************************************************************************************************************/
  /*******************************************************************************************************************/
  /*******************************************************************************************************************/
  /*******************************************************************************************************************/

} // End of CampaignGroupSequenceComponentpp
