<div class="patient-info-card">
  <div class="patient-balance">
    <i [ngClass]="'healent-action-icon-1'"></i>
    <h2>Patient Balance</h2>
  </div>
  <div class="amount-info">
    <h1>$ {{balance}}</h1>
    <h5 style="font-size: 16px;">The patient has paid in full all charges related to the visit.</h5>
  </div>
  <div style="display: flex; flex-direction: column;">
    <div class="copay-heading">
      <i [ngClass]="'healent-group-4692'"></i>
      <div>Visitor's Copayment </div>
    </div>
  </div>
  <form [formGroup]="paymentForm" class="payment-collection-details">
    <div class="input-wrapper">
      <span class="currency-symbol">$</span>
      <input class="copay-input" type="text" formControlName="copayment" (input)="onInputChange($event)"
        (blur)="onBlur()" [value]="displayValue" placeholder="0.00">
    </div>
    <button type="button" class="update-button" [disabled]="!paymentForm.valid" (click)="onUpdatePayment()">
      Update
    </button>
  </form>
  <!-- <div class="payment-collection-details"> -->
  <!-- <div class="patient-balance">
        <i [ngClass]="'healent-action-icon-1'"></i>
        <h2>Payment Collection Details</h2>
      </div> -->
  <!--implemented footer for this table-->
  <!-- <div class="drawer-table">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let element"> {{ element?.amount }} </td>
            <td mat-footer-cell *matFooterCellDef> {{ totalAmount | currency }} Total</td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element"> {{ element?.description }} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>

          <ng-container matColumnDef="method">
            <th mat-header-cell *matHeaderCellDef> Method </th>
            <td mat-cell *matCellDef="let element"> {{ element?.method }} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>

          <ng-container matColumnDef="receipt">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" style="color: var(--dangerous);">
              <a class="receipt-link">Receipt</a>
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
      </div> -->
  <!-- </div> -->
</div>
