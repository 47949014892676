import { Component, Input, Output, EventEmitter, ElementRef, HostListener, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-table-settings-drawer',
  standalone: true,
  imports: [CommonModule, MatSidenavModule, MatSlideToggleModule, MatIconModule, DragDropModule],
  templateUrl: './table-settings-drawer.component.html',
  styleUrls: ['./table-settings-drawer.component.css'],
})
export class TableSettingsDrawerComponent {
  @Input() title: string = 'Manage Columns';
  @Input() columns: any[] = [];
  @Output() columnToggled = new EventEmitter<any>();
  @Output() dropped = new EventEmitter<any>();
  @ViewChild('drawer') drawer!: MatDrawer;

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside && this.drawer.opened) {
      this.drawer.close();
    }
  }

  closeDrawer() {
    if (this.drawer) {
      this.drawer.close();
    }
  }

  openDrawer() {
    if (this.drawer) {
      this.drawer.open();
    }
  }

  toggleColumnVisibility(column: any) {
    this.columnToggled.emit(column);
  }

  drop(event: any) {
    this.dropped.emit(event);
  }
}
