import e from "pubnub";
function t(e, t) {
  var n = {};
  for (var s in e) Object.prototype.hasOwnProperty.call(e, s) && t.indexOf(s) < 0 && (n[s] = e[s]);
  if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
    var i = 0;
    for (s = Object.getOwnPropertySymbols(e); i < s.length; i++) t.indexOf(s[i]) < 0 && Object.prototype.propertyIsEnumerable.call(e, s[i]) && (n[s[i]] = e[s[i]]);
  }
  return n;
}
function n(e, t, n, s) {
  return new (n || (n = Promise))(function (i, r) {
    function a(e) {
      try {
        h(s.next(e));
      } catch (e) {
        r(e);
      }
    }
    function o(e) {
      try {
        h(s.throw(e));
      } catch (e) {
        r(e);
      }
    }
    function h(e) {
      var t;
      e.done ? i(e.value) : (t = e.value, t instanceof n ? t : new n(function (e) {
        e(t);
      })).then(a, o);
    }
    h((s = s.apply(e, t || [])).next());
  });
}
"function" == typeof SuppressedError && SuppressedError;
const s = "PUBNUB_INTERNAL_THREAD",
  i = "PUBNUB_INTERNAL_MODERATION_",
  r = "PUBNUB_INTERNAL_ADMIN_CHANNEL",
  a = "PUBNUB_INTERNAL_ERROR_LOGGER",
  o = {
    id: "PUBNUB_INTERNAL_MODERATOR",
    type: "internal"
  };
class h {
  constructor(e) {
    this.timestampKey = String(`${a}_${new Date().getTime()}`);
    const t = this;
    this.errorLoggerImplementation = e || new class {
      constructor() {
        this.storage = {};
      }
      setItem(e, n) {
        Object.keys(this.storage).length ? this.storage[t.timestampKey].push({
          key: n.key,
          error: n.error,
          thrownFunctionArguments: n.thrownFunctionArguments
        }) : this.storage[t.timestampKey] = [{
          key: n.key,
          error: n.error,
          thrownFunctionArguments: n.thrownFunctionArguments
        }];
      }
      getStorageObject() {
        const e = {};
        for (const t in this.storage) e[t] = this.storage[t].map(e => {
          const t = {};
          for (let n = 0; n < e.thrownFunctionArguments.length; ++n) "function" == typeof e.thrownFunctionArguments[n] ? t[n] = e.thrownFunctionArguments[n].name : null === e.thrownFunctionArguments[n] ? t[n] = "null" : "object" == typeof e.thrownFunctionArguments[n] ? t[n] = e.thrownFunctionArguments[n].constructor.name : "symbol" == typeof e.thrownFunctionArguments[n] ? t[n] = e.thrownFunctionArguments[n].toString() : t[n] = e.thrownFunctionArguments[n];
          return {
            key: e.key,
            error: e.error,
            thrownFunctionArguments: t
          };
        });
        return e;
      }
    }();
  }
  setItem(e, t, n) {
    if (!t) return;
    let s = t;
    "string" == typeof t && (s = t), t instanceof Error && (s = {
      name: t.name,
      message: t.message,
      status: t.status
    }), this.errorLoggerImplementation.setItem(this.timestampKey, {
      key: e,
      error: s,
      thrownFunctionArguments: n
    });
  }
  getStorageObject() {
    const e = this.errorLoggerImplementation.getStorageObject(),
      t = new File(["\ufeff" + JSON.stringify(e)], `pubnub_debug_log_${this.timestampKey}.txt`, {
        type: "text/plain:charset=UTF-8"
      }),
      n = window.URL.createObjectURL(t),
      s = document.createElement("a");
    s.style = "display: none", s.href = n, s.download = t.name, s.click(), window.URL.revokeObjectURL(n);
  }
}
function c(e, t) {
  const n = {
    get(e, n) {
      if ("function" != typeof e[n]) return e[n];
      const s = e[n];
      return function (...i) {
        const r = `${e.constructor.name}:${String(n)}`;
        try {
          const e = s.apply(this, i);
          return (null == e ? void 0 : e.then) ? e.then(e => e).catch(e => {
            throw t.setItem(r, e, arguments), e;
          }) : e;
        } catch (e) {
          throw t.setItem(r, e, arguments), e;
        }
      };
    }
  };
  return new Proxy(e, n);
}
const d = ["http://", "https://", "www."];
class l {
  static isUrl(e) {
    if (d.every(t => 0 !== e.indexOf(t))) return !1;
    return !(e.split(".").filter(e => "" !== e).length < (e.startsWith("www.") ? 3 : 2)) && /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(e.replaceAll("\n", ""));
  }
}
class u {
  static getPhraseToLookFor(e) {
    const t = e.lastIndexOf("@"),
      n = e.split("@").slice(-1)[0];
    if (-1 === t || n.length < 3) return null;
    const s = n.split(" ");
    return s.length > 2 ? null : s[0] + (s[1] ? ` ${s[1]}` : "");
  }
  static getChannelPhraseToLookFor(e) {
    const t = e.lastIndexOf("#"),
      n = e.split("#").slice(-1)[0];
    if (-1 === t || n.length < 3) return null;
    const s = n.split(" ");
    return s.length > 2 ? null : s[0] + (s[1] ? ` ${s[1]}` : "");
  }
  static getMessageElements({
    text: e,
    mentionedUsers: t,
    textLinks: n,
    referencedChannels: s
  }) {
    let i = "";
    const r = [],
      a = n.map(e => ((e, t, n = 1) => Array.from({
        length: (t - e) / n + 1
      }, (t, s) => e + s * n))(e.startIndex, e.endIndex)),
      o = a.flatMap(e => e),
      h = a.map(e => e[0]),
      c = a.map(e => e[e.length - 1]);
    let d = 0;
    e.split("").forEach((t, s) => {
      if (" " === t && d++, h.includes(s)) {
        const t = h.indexOf(s),
          a = e.substring(s, c[t]);
        return i += a, void r.push({
          start: d,
          end: d + a.split(" ").length,
          link: n[t].link,
          substring: a
        });
      }
      o.filter(e => !c.includes(e)).includes(s) || (i += t);
    });
    let u = 0,
      m = 0,
      g = [],
      p = [];
    const v = i.split(" "),
      f = [];
    return v.forEach((e, n) => {
      if (e.startsWith("@") || e.startsWith("#")) {
        if (e.startsWith("@")) {
          if (Object.keys(t).indexOf(String(u)) >= 0) {
            const s = t[u].id,
              i = t[u].name,
              r = i.split(" ");
            let a = "";
            r.length > 1 ? (g = r.map((e, t) => n + t), a = v[g[g.length - 1]].replace(r[r.length - 1], "")) : a = e.replace("@", "").replace(i, ""), a && (a = `${a} `), "" === a && (a = " "), u++, f.push({
              type: "mention",
              content: {
                name: i,
                id: s
              }
            }), f.push({
              type: "text",
              content: {
                text: a
              }
            });
          } else u++, f.push({
            type: "text",
            content: {
              text: e
            }
          }), f.push({
            type: "text",
            content: {
              text: " "
            }
          });
        } else {
          if (Object.keys(s).indexOf(String(m)) >= 0) {
            const t = s[m].id,
              i = s[m].name,
              r = i.split(" ");
            let a = "";
            r.length > 1 ? (p = r.map((e, t) => n + t), a = v[p[p.length - 1]].replace(r[r.length - 1], "")) : a = e.replace("#", "").replace(i, ""), a && (a = `${a} `), "" === a && (a = " "), m++, f.push({
              type: "channelReference",
              content: {
                name: i,
                id: t
              }
            }), f.push({
              type: "text",
              content: {
                text: a
              }
            });
          } else m++, f.push({
            type: "text",
            content: {
              text: e
            }
          }), f.push({
            type: "text",
            content: {
              text: " "
            }
          });
        }
      } else {
        if (g.includes(n) || p.includes(n)) return;
        const t = r.find(e => e.start === n);
        if (t) {
          const e = v.slice(t.start, t.end).join(" "),
            s = e.replace(t.substring, "");
          return f.push({
            type: "textLink",
            content: {
              link: t.link,
              text: t.substring
            }
          }), s && f.push({
            type: "text",
            content: {
              text: s
            }
          }), f.push({
            type: "text",
            content: {
              text: " "
            }
          }), void (g = e.split(" ").map((e, t) => n + t));
        }
        if (l.isUrl(e)) {
          const t = e.slice(-1);
          return void (["!", "?", ".", ","].includes(t) ? (f.push({
            type: "plainLink",
            content: {
              link: e.slice(0, -1)
            }
          }), f.push({
            type: "text",
            content: {
              text: t
            }
          }), f.push({
            type: "text",
            content: {
              text: " "
            }
          })) : (f.push({
            type: "plainLink",
            content: {
              link: e
            }
          }), f.push({
            type: "text",
            content: {
              text: " "
            }
          })));
        }
        e && (f.push({
          type: "text",
          content: {
            text: e
          }
        }), f.push({
          type: "text",
          content: {
            text: " "
          }
        }));
      }
    }), "text" === f[f.length - 1].type && (f[f.length - 1].content.text = f[f.length - 1].content.text.trim()), "text" === f[f.length - 1].type && [" ", ""].includes(f[f.length - 1].content.text) && (f.length = f.length - 1), f.reduce((e, t) => {
      let n;
      return e && e.length && (n = e[e.length - 1]), "text" === t.type && "text" === (null == n ? void 0 : n.type) ? (e = e.slice(0, -1), [...e, {
        type: "text",
        content: {
          text: `${n.content.text}${t.content.text}`
        }
      }]) : [...e, t];
    }, []);
  }
}
var m, g;
function p(e) {
  return {
    type: e.type,
    text: e.text,
    files: e.files
  };
}
function v(e) {
  return "string" == typeof e.message ? {
    type: m.TEXT,
    text: e.message,
    files: []
  } : e.message;
}
!function (e) {
  e.TEXT = "text";
}(m || (m = {})), function (e) {
  e.REACTIONS = "reactions", e.DELETED = "deleted", e.EDITED = "edited";
}(g || (g = {}));
const f = g.EDITED,
  y = g.DELETED,
  b = g.REACTIONS;
class I {
  get hasThread() {
    var e;
    if (!(null === (e = this.actions) || void 0 === e ? void 0 : e.threadRootId)) return !1;
    const t = Object.keys(this.actions.threadRootId)[0];
    return !!t && !!this.actions.threadRootId[t].length;
  }
  get mentionedUsers() {
    var e;
    return (null === (e = this.meta) || void 0 === e ? void 0 : e.mentionedUsers) ? this.meta.mentionedUsers : {};
  }
  get referencedChannels() {
    var e;
    return (null === (e = this.meta) || void 0 === e ? void 0 : e.referencedChannels) ? this.meta.referencedChannels : {};
  }
  get textLinks() {
    var e;
    return (null === (e = this.meta) || void 0 === e ? void 0 : e.textLinks) ? this.meta.textLinks : [];
  }
  get type() {
    return this.content.type;
  }
  get quotedMessage() {
    var e;
    if (null === (e = this.meta) || void 0 === e ? void 0 : e.quotedMessage) return this.meta.quotedMessage;
  }
  get files() {
    return this.content.files || [];
  }
  constructor(e, t) {
    this.chat = e, this.timetoken = t.timetoken, this.content = t.content, this.channelId = t.channelId, this.userId = t.userId, Object.assign(this, t);
  }
  static fromDTO(e, t) {
    const n = e.config.customPayloads.getMessageResponseBody || v,
      s = {
        timetoken: String(t.timetoken),
        content: n(t),
        channelId: t.channel,
        userId: "publisher" in t ? t.publisher : t.uuid || "unknown-user",
        actions: "actions" in t ? t.actions : void 0,
        meta: "meta" in t ? t.meta : "userMetadata" in t ? t.userMetadata : void 0,
        error: t.error || void 0
      };
    return c(new I(e, s), e.errorLogger);
  }
  clone(e) {
    const {
        timetoken: t,
        content: n,
        channelId: s,
        userId: i,
        actions: r,
        meta: a
      } = this,
      o = Object.assign({}, {
        timetoken: t,
        content: n,
        channelId: s,
        userId: i,
        actions: r,
        meta: a
      }, e);
    return new I(this.chat, o);
  }
  assignAction(e) {
    var t;
    const {
        actionTimetoken: n,
        type: s,
        value: i,
        uuid: r
      } = e,
      a = this.actions || {};
    return a[s] || (a[s] = {}), (t = a[s])[i] || (t[i] = []), a[s][i].find(e => e.actionTimetoken === n) || (a[s][i] = [...a[s][i], {
      uuid: r,
      actionTimetoken: n
    }]), a;
  }
  filterAction(e) {
    var t;
    const {
        actionTimetoken: n,
        type: s,
        value: i,
        uuid: r
      } = e,
      a = this.actions || {};
    a[s] || (a[s] = {}), (t = a[s])[i] || (t[i] = []);
    const o = a[s][i].filter(e => e.actionTimetoken !== n || e.uuid !== r);
    return 0 === o.length ? delete a[s][i] : a[s][i] = o, a;
  }
  static streamUpdatesOn(e, t) {
    if (!e.length) throw "Cannot stream message updates on an empty list";
    const n = {
        messageAction: n => {
          const s = e.find(e => e.timetoken === n.data.messageTimetoken);
          if (!s) return;
          if (s.channelId !== n.channel) return;
          let i;
          "added" === n.event && (i = s.assignAction(n.data)), "removed" === n.event && (i = s.filterAction(n.data));
          const r = s.clone({
              actions: i
            }),
            a = e.map(e => e.timetoken === r.timetoken ? r : e);
          t(a);
        }
      },
      {
        chat: s
      } = e[0],
      i = s.addListener(n),
      r = e.filter((t, n) => e.findIndex(e => t.channelId === e.channelId) === n).map(e => s.subscribe(e.channelId));
    return () => {
      i(), r.map(e => e());
    };
  }
  streamUpdates(e) {
    return I.streamUpdatesOn([this], t => e(t[0]));
  }
  get text() {
    var e;
    const t = null === (e = this.actions) || void 0 === e ? void 0 : e[this.chat.editMessageActionName];
    if (!t) return this.content.text || "";
    return Object.entries(t).map(([e, t]) => Object.assign({
      value: e
    }, t[0])).reduce((e, t) => e.actionTimetoken > t.actionTimetoken ? e : t).value;
  }
  getMessageElements() {
    const e = this.text;
    return u.getMessageElements({
      text: e,
      textLinks: this.textLinks,
      mentionedUsers: this.mentionedUsers,
      referencedChannels: this.referencedChannels
    });
  }
  getLinkedText() {
    return this.getMessageElements();
  }
  editText(e) {
    var t;
    return n(this, void 0, void 0, function* () {
      const n = this.chat.editMessageActionName;
      try {
        if ((null === (t = this.meta) || void 0 === t ? void 0 : t.PUBNUB_INTERNAL_AUTOMODERATED) && !this.chat.currentUser.isInternalModerator) throw "The automoderated message can no longer be edited";
        const {
            data: s
          } = yield this.chat.sdk.addMessageAction({
            channel: this.channelId,
            messageTimetoken: this.timetoken,
            action: {
              type: n,
              value: e
            }
          }),
          i = this.assignAction(s);
        return this.clone({
          actions: i
        });
      } catch (e) {
        throw e;
      }
    });
  }
  get deleted() {
    var e, t;
    const n = this.chat.deleteMessageActionName;
    return !!(null === (e = this.actions) || void 0 === e ? void 0 : e[n]) && !!(null === (t = this.actions) || void 0 === t ? void 0 : t[n][n].length);
  }
  delete(e = {}) {
    return n(this, void 0, void 0, function* () {
      const {
          soft: t
        } = e,
        n = this.chat.deleteMessageActionName;
      try {
        if (t) {
          const {
              data: t
            } = yield this.chat.sdk.addMessageAction({
              channel: this.channelId,
              messageTimetoken: this.timetoken,
              action: {
                type: n,
                value: n
              }
            }),
            s = this.assignAction(t);
          return yield this.deleteThread(e), this.clone({
            actions: s
          });
        }
        {
          const t = String(BigInt(this.timetoken) - BigInt(1));
          if (yield this.chat.sdk.deleteMessages({
            channel: this.channelId,
            start: t,
            end: this.timetoken
          }), yield this.deleteThread(e), this.files.length && !e.preserveFiles) for (const e of this.files) {
            const {
              id: t,
              name: n
            } = e;
            yield this.chat.sdk.deleteFile({
              channel: this.channelId,
              id: t,
              name: n
            });
          }
          return !0;
        }
      } catch (e) {
        throw e;
      }
    });
  }
  restore() {
    var e, t;
    return n(this, void 0, void 0, function* () {
      if (!this.deleted) return void console.warn("This message has not been deleted");
      const n = null === (t = null === (e = this.actions) || void 0 === e ? void 0 : e[this.chat.deleteMessageActionName]) || void 0 === t ? void 0 : t[this.chat.deleteMessageActionName];
      if (!n) return void console.warn("Malformed data", n);
      for (let e = 0; e < n.length; e++) {
        const t = n[e].actionTimetoken;
        yield this.chat.sdk.removeMessageAction({
          channel: this.channelId,
          messageTimetoken: this.timetoken,
          actionTimetoken: String(t)
        });
      }
      const [{
        data: s
      }, i] = yield Promise.all([this.chat.sdk.getMessageActions({
        channel: this.channelId,
        start: this.timetoken,
        end: this.timetoken
      }), this.restoreThread()]);
      let r = this.actions || {};
      delete r[this.chat.deleteMessageActionName];
      for (let e = 0; e < s.length; e++) {
        const t = this.assignAction(s[e]);
        r = Object.assign(Object.assign({}, r), t);
      }
      return i && (r = Object.assign(Object.assign({}, r), this.assignAction(i.data))), this.clone({
        actions: r
      });
    });
  }
  get reactions() {
    var e;
    const t = this.chat.reactionsActionName;
    return (null === (e = this.actions) || void 0 === e ? void 0 : e[t]) || {};
  }
  hasUserReaction(e) {
    var t;
    return !!(null === (t = this.reactions[e]) || void 0 === t ? void 0 : t.find(e => e.uuid === this.chat.sdk.getUUID()));
  }
  toggleReaction(e) {
    var t;
    return n(this, void 0, void 0, function* () {
      const n = this.chat.reactionsActionName,
        s = this.chat.sdk.getUUID(),
        i = this.timetoken,
        r = this.channelId,
        a = e;
      let o;
      try {
        const e = null === (t = this.reactions[a]) || void 0 === t ? void 0 : t.find(e => e.uuid === s);
        if (e) {
          const t = String(e.actionTimetoken);
          yield this.chat.sdk.removeMessageAction({
            actionTimetoken: t,
            channel: r,
            messageTimetoken: i
          }), o = this.filterAction({
            actionTimetoken: t,
            messageTimetoken: i,
            type: n,
            uuid: s,
            value: a
          });
        } else {
          const {
            data: e
          } = yield this.chat.sdk.addMessageAction({
            channel: r,
            messageTimetoken: i,
            action: {
              type: n,
              value: a
            }
          });
          o = this.assignAction(e);
        }
        return this.clone({
          actions: o
        });
      } catch (e) {
        throw e;
      }
    });
  }
  forward(e) {
    return n(this, void 0, void 0, function* () {
      return this.chat.forwardMessage(this, e);
    });
  }
  pin() {
    return n(this, void 0, void 0, function* () {
      const e = yield this.chat.getChannel(this.channelId);
      yield this.chat.pinMessageToChannel(this, e);
    });
  }
  DEPRECATED_report(e) {
    return n(this, void 0, void 0, function* () {
      const t = r,
        n = {
          text: this.text,
          reason: e,
          reportedMessageChannelId: this.channelId,
          reportedMessageTimetoken: this.timetoken,
          reportedUserId: this.userId
        };
      return yield this.chat.emitEvent({
        channel: t,
        type: "report",
        payload: n
      });
    });
  }
  report(e) {
    return n(this, void 0, void 0, function* () {
      const t = `${i}${this.channelId}`,
        n = {
          text: this.text,
          reason: e,
          reportedMessageChannelId: this.channelId,
          reportedMessageTimetoken: this.timetoken,
          reportedUserId: this.userId
        };
      return yield this.chat.emitEvent({
        channel: t,
        type: "report",
        payload: n
      });
    });
  }
  getThread() {
    return this.chat.getThreadChannel(this);
  }
  createThread() {
    return this.chat.createThreadChannel(this);
  }
  removeThread() {
    return this.chat.removeThreadChannel(this);
  }
  deleteThread(e = {}) {
    return n(this, void 0, void 0, function* () {
      if (this.hasThread) {
        const t = yield this.getThread();
        yield t.delete(e);
      }
    });
  }
  restoreThread() {
    return n(this, void 0, void 0, function* () {
      return this.chat.restoreThreadChannel(this);
    });
  }
}
class T {
  constructor(e, t) {
    this.isProcessing = !1, this.currentPenalty = 0, this.queue = [], this.baseInterval = e, this.exponentialFactor = t;
  }
  sleep(e) {
    return new Promise(t => setTimeout(t, e));
  }
  runWithinLimits(e) {
    return n(this, void 0, void 0, function* () {
      return 0 === this.baseInterval ? e() : (this.isProcessing && (this.currentPenalty += 1), new Promise((t, n) => {
        this.queue.push({
          func: e,
          resolve: t,
          reject: n,
          penalty: this.currentPenalty
        }), this.isProcessing || this.processQueue();
      }));
    });
  }
  processQueue() {
    return n(this, void 0, void 0, function* () {
      const e = this.queue.shift();
      if (!e) return this.isProcessing = !1, void (this.currentPenalty = 0);
      const {
        func: t,
        resolve: n,
        reject: s,
        penalty: i
      } = e;
      this.isProcessing = !0;
      try {
        n(yield t());
      } catch (e) {
        s(e);
      }
      yield this.sleep(this.baseInterval * Math.pow(this.exponentialFactor, i)), this.processQueue();
    });
  }
}
class k {
  constructor(e, t) {
    this.chat = e, this.id = t.id, Object.assign(this, t);
  }
  static fromDTO(e, t) {
    var n;
    const s = {
      id: t.id,
      name: t.name || void 0,
      externalId: t.externalId || void 0,
      profileUrl: t.profileUrl || void 0,
      email: t.email || void 0,
      custom: t.custom || void 0,
      updated: t.updated || void 0,
      status: t.status || void 0,
      type: t.type || void 0,
      lastActiveTimestamp: (null === (n = t.custom) || void 0 === n ? void 0 : n.lastActiveTimestamp) || void 0
    };
    return c(new k(e, s), e.errorLogger);
  }
  get isInternalModerator() {
    return this.id === o.id && this.type === o.type;
  }
  get active() {
    return !!(this.lastActiveTimestamp && new Date().getTime() - this.lastActiveTimestamp <= this.chat.config.storeUserActivityInterval);
  }
  update(e) {
    return n(this, void 0, void 0, function* () {
      return this.chat.updateUser(this.id, e);
    });
  }
  delete(e = {}) {
    return n(this, void 0, void 0, function* () {
      return this.chat.deleteUser(this.id, e);
    });
  }
  static streamUpdatesOn(e, t) {
    if (!e.length) throw "Cannot stream user updates on an empty list";
    const n = {
        objects: n => {
          if ("uuid" !== n.message.type) return;
          const s = e.find(e => e.id === n.channel || e.id === n.message.data.id);
          if (!s) return;
          const i = k.fromDTO(s.chat, n.message.data),
            r = e.map(e => e.id === i.id ? i : e);
          t(r);
        }
      },
      {
        chat: s
      } = e[0],
      i = s.addListener(n),
      r = e.map(e => s.subscribe(e.id));
    return () => {
      i(), r.map(e => e());
    };
  }
  streamUpdates(e) {
    return k.streamUpdatesOn([this], t => e(t[0]));
  }
  wherePresent() {
    return n(this, void 0, void 0, function* () {
      return this.chat.wherePresent(this.id);
    });
  }
  isPresentOn(e) {
    return n(this, void 0, void 0, function* () {
      return this.chat.isPresent(this.id, e);
    });
  }
  getMemberships(e = {}) {
    return n(this, void 0, void 0, function* () {
      const t = e.filter;
      let n = `!(channel.id LIKE '${i}*')`;
      t && (n = `${n} && (${t})`);
      const s = yield this.chat.sdk.objects.getMemberships(Object.assign(Object.assign({}, e), {
        uuid: this.id,
        include: {
          totalCount: !0,
          customFields: !0,
          channelFields: !0,
          customChannelFields: !0,
          channelTypeField: !0,
          statusField: !0,
          channelStatusField: !0
        },
        filter: n
      }));
      return {
        page: {
          next: s.next,
          prev: s.prev
        },
        total: s.totalCount,
        status: s.status,
        memberships: s.data.map(e => w.fromMembershipDTO(this.chat, e, this))
      };
    });
  }
  setRestrictions(e, t) {
    return n(this, void 0, void 0, function* () {
      if (!this.chat.sdk._config.secretKey) throw "Moderation restrictions can only be set by clients initialized with a Secret Key.";
      return this.chat.setRestrictions(this.id, e.id, t);
    });
  }
  getRestrictions(e, t) {
    return n(this, void 0, void 0, function* () {
      const n = e ? `channel.id == '${i}${e.id}'` : `channel.id LIKE '${i}*'`;
      return yield this.chat.sdk.objects.getMemberships(Object.assign({
        uuid: this.id,
        include: {
          totalCount: !0,
          customFields: !0
        },
        filter: n
      }, t));
    });
  }
  getChannelRestrictions(e) {
    var t;
    return n(this, void 0, void 0, function* () {
      const n = yield this.getRestrictions(e),
        s = n && (null === (t = n.data[0]) || void 0 === t ? void 0 : t.custom);
      return {
        ban: !!(null == s ? void 0 : s.ban),
        mute: !!(null == s ? void 0 : s.mute),
        reason: null == s ? void 0 : s.reason
      };
    });
  }
  getChannelsRestrictions(e) {
    return n(this, void 0, void 0, function* () {
      const t = yield this.getRestrictions(void 0, e);
      return {
        page: {
          next: t.next,
          prev: t.prev
        },
        total: t.totalCount,
        status: t.status,
        restrictions: t.data.map(({
          custom: e,
          channel: t
        }) => ({
          ban: !!(null == e ? void 0 : e.ban),
          mute: !!(null == e ? void 0 : e.mute),
          reason: null == e ? void 0 : e.reason,
          channelId: t.id.replace(i, "")
        }))
      };
    });
  }
  DEPRECATED_report(e) {
    return n(this, void 0, void 0, function* () {
      const t = r,
        n = {
          reason: e,
          reportedUserId: this.id
        };
      return yield this.chat.emitEvent({
        channel: t,
        type: "report",
        payload: n
      });
    });
  }
}
class w {
  constructor(e, t) {
    this.chat = e, this.channel = t.channel, this.user = t.user, this.custom = t.custom, this.updated = t.updated, this.eTag = t.eTag;
  }
  static fromMembershipDTO(e, t, n) {
    const s = {
      channel: M.fromDTO(e, t.channel),
      user: n,
      custom: t.custom,
      updated: t.updated,
      eTag: t.eTag
    };
    return c(new w(e, s), e.errorLogger);
  }
  static fromChannelMemberDTO(e, t, n) {
    const s = {
      user: k.fromDTO(e, t.uuid),
      channel: n,
      custom: t.custom,
      updated: t.updated,
      eTag: t.eTag
    };
    return c(new w(e, s), e.errorLogger);
  }
  exists() {
    return n(this, void 0, void 0, function* () {
      return !!(yield this.chat.sdk.objects.getMemberships({
        uuid: this.user.id,
        filter: `channel.id == '${this.channel.id}'`
      })).data.length;
    });
  }
  update({
    custom: e
  }) {
    return n(this, void 0, void 0, function* () {
      try {
        if (!(yield this.exists())) throw "No such membership exists";
        const t = yield this.chat.sdk.objects.setMemberships({
          uuid: this.user.id,
          channels: [{
            id: this.channel.id,
            custom: e
          }],
          include: {
            totalCount: !0,
            customFields: !0,
            channelFields: !0,
            customChannelFields: !0
          },
          filter: `channel.id == '${this.channel.id}'`
        });
        return w.fromMembershipDTO(this.chat, t.data[0], this.user);
      } catch (e) {
        throw e;
      }
    });
  }
  static streamUpdatesOn(e, t) {
    if (!e.length) throw "Cannot stream membership updates on an empty list";
    const n = {
        objects: n => {
          if ("membership" !== n.message.type) return;
          const s = e.find(e => e.channel.id === n.channel && e.user.id === n.message.data.uuid.id);
          if (!s) return;
          const i = new w(s.chat, {
              user: s.user,
              channel: s.channel,
              custom: n.message.data.custom,
              updated: n.message.data.updated,
              eTag: n.message.data.eTag
            }),
            r = e.map(e => e.channel.id === i.channel.id && e.user.id === i.user.id ? i : e);
          t(r);
        }
      },
      {
        chat: s
      } = e[0],
      i = s.addListener(n),
      r = e.map(e => s.subscribe(e.channel.id));
    return () => {
      i(), r.map(e => e());
    };
  }
  streamUpdates(e) {
    return w.streamUpdatesOn([this], t => e(t[0]));
  }
  get lastReadMessageTimetoken() {
    var e;
    return null === (e = this.custom) || void 0 === e ? void 0 : e.lastReadMessageTimetoken;
  }
  setLastReadMessage(e) {
    return n(this, void 0, void 0, function* () {
      return this.setLastReadMessageTimetoken(e.timetoken);
    });
  }
  setLastReadMessageTimetoken(e) {
    return n(this, void 0, void 0, function* () {
      try {
        const t = yield this.update({
            custom: Object.assign(Object.assign({}, this.custom), {
              lastReadMessageTimetoken: e
            })
          }),
          n = this.chat.accessManager.canI({
            permission: "write",
            resourceName: this.channel.id,
            resourceType: "channels"
          });
        return n && (yield this.chat.emitEvent({
          channel: this.channel.id,
          type: "receipt",
          payload: {
            messageTimetoken: e
          }
        })), !n && this.chat.config.saveDebugLog && console.warn(`'receipt' event was not sent to channel '${this.channel.id}' because PAM did not allow it.`), t;
      } catch (e) {
        throw e;
      }
    });
  }
  getUnreadMessagesCount() {
    var e;
    return n(this, void 0, void 0, function* () {
      try {
        const t = yield this.lastReadMessageTimetoken;
        if (t) {
          const n = yield this.chat.sdk.messageCounts({
            channels: [this.channel.id],
            channelTimetokens: [String(t)]
          });
          return null === (e = n.channels) || void 0 === e ? void 0 : e[this.channel.id];
        }
        return !1;
      } catch (e) {
        throw e;
      }
    });
  }
}
const C = (e, t, n = 1) => Array.from({
  length: (t - e) / n + 1
}, (t, s) => e + s * n);
class x {
  constructor(e, t, n) {
    this.value = "", this.previousValue = "", this.mentionedUsers = {}, this.referencedChannels = {}, this.textLinks = [], this.quotedMessage = void 0, this.files = void 0, this.chat = e, this.channel = t, this.config = Object.assign({
      userSuggestionSource: "channel",
      isTypingIndicatorTriggered: !0,
      userLimit: 10,
      channelLimit: 10
    }, n || {});
  }
  reindexTextLinks() {
    if (this.value.startsWith(this.previousValue)) return;
    if (this.value === this.previousValue) return;
    const e = Math.abs(this.previousValue.length - this.value.length);
    let t = [...this.textLinks],
      n = [];
    if (this.previousValue.startsWith(this.value)) {
      const e = this.value.length;
      t.forEach((s, i) => {
        s.endIndex < e || (s.startIndex >= e ? n.push(i) : s.startIndex < e && (t[i].endIndex = this.value.length));
      }), t = t.filter((e, t) => !n.includes(t)), this.textLinks = t;
    } else if (this.previousValue.endsWith(this.value)) {
      t = [...this.textLinks], n = [];
      const s = e;
      t.forEach((i, r) => {
        if (i.startIndex >= s) return t[r].startIndex -= e, void (t[r].endIndex -= e);
        i.endIndex <= s ? n.push(r) : i.startIndex < s && (t[r].startIndex = 0, t[r].endIndex -= e);
      }), t = t.filter((e, t) => !n.includes(t)), this.textLinks = t;
    } else if (this.previousValue.length > this.value.length) {
      t = [...this.textLinks], n = [];
      let s = -1,
        i = -1;
      this.previousValue.split("").forEach((e, t) => {
        this.value[t] !== this.previousValue[t] && -1 === s && (s = t), this.value[this.value.length - 1 - t] !== this.previousValue[this.previousValue.length - 1 - t] && -1 === i && (i = this.previousValue.length - t);
      }), t.forEach((r, a) => {
        if (s <= r.startIndex && i >= r.endIndex) n.push(a);else if (s > r.startIndex && i < r.endIndex) t[a].endIndex -= e;else {
          if (!(s >= r.startIndex && i >= r.endIndex && s < r.endIndex)) return i > r.startIndex && s <= r.startIndex ? (t[a].endIndex -= e, void (t[a].startIndex = s)) : i < r.endIndex ? (t[a].startIndex -= e, void (t[a].endIndex -= e)) : void 0;
          t[a].endIndex = s;
        }
      }), t = t.filter((e, t) => !n.includes(t)), this.textLinks = t;
    } else if (this.value.endsWith(this.previousValue)) t = [...this.textLinks], n = [], t.forEach((n, s) => {
      t[s].endIndex += e, t[s].startIndex += e;
    }), this.textLinks = t;else if (this.value.length > this.previousValue.length) {
      t = [...this.textLinks], n = [];
      let s = -1,
        i = -1;
      this.previousValue.split("").forEach((e, t) => {
        this.value[t] !== this.previousValue[t] && -1 === s && (s = t), this.value[this.value.length - 1 - t] !== this.previousValue[this.previousValue.length - 1 - t] && -1 === i && (i = this.previousValue.length - t);
      }), t.forEach((r, a) => {
        if (i <= r.startIndex) return t[a].startIndex += e, void (t[a].endIndex += e);
        s > r.startIndex && i < r.endIndex ? t[a].endIndex += e : s <= r.startIndex && i >= r.endIndex && n.push(a);
      }), t = t.filter((e, t) => !n.includes(t)), this.textLinks = t;
    }
  }
  getUserOrChannelReference({
    splitSymbol: e,
    referencesObject: t
  }) {
    let n = Object.assign({}, t);
    const s = this.previousValue.split(" ").filter(t => t.startsWith(e)),
      i = this.value.split(" ").filter(t => t.startsWith(e));
    let r = -1,
      a = null;
    for (let e = 0; e < i.length; e++) if (i[e] !== s[e]) {
      a = i[e], r = e;
      break;
    }
    if (s.length > i.length) {
      const e = s.findIndex(e => !i.includes(e)),
        t = s.findLastIndex(e => !i.includes(e));
      if (-1 !== t) {
        let s = Object.assign({}, n);
        Object.keys(n).forEach(i => {
          Number(i) >= e && Number(i) <= t && delete s[Number(i)], Number(i) > t && (delete s[Number(i)], s = Object.assign(Object.assign({}, s), {
            [Number(i) - t + e - 1]: n[Number(i)]
          }));
        }), n = s;
      }
    }
    return Object.keys(n).forEach(t => {
      var s, r;
      const a = null === (s = n[Number(t)]) || void 0 === s ? void 0 : s.name;
      a && !i[Number(t)] && delete n[Number(t)];
      const o = "@" === e ? /(^|\s)@([^\s@]+(?:\s+[^\s@]+)*)/g : /(^|\s)#([^\s#]+(?:\s+[^\s#]+)*)/g,
        h = (this.value.match(o) || []).map(e => e.trim().substring(1));
      a && !(null === (r = h[Number(t)]) || void 0 === r ? void 0 : r.startsWith(a)) && delete n[Number(t)];
    }), {
      referencesObject: n,
      differentReference: a,
      differentReferencePosition: r
    };
  }
  parseTextToGetSuggestedUser() {
    return n(this, void 0, void 0, function* () {
      const {
        differentReference: e,
        differentReferencePosition: t,
        referencesObject: n
      } = this.getUserOrChannelReference({
        splitSymbol: "@",
        referencesObject: this.mentionedUsers
      });
      if (this.mentionedUsers = n, !e) return {
        nameOccurrenceIndex: -1,
        suggestedUsers: []
      };
      let s;
      return s = "channel" === this.config.userSuggestionSource ? (yield this.channel.getUserSuggestions(e, {
        limit: this.config.userLimit
      })).map(e => e.user) : yield this.chat.getUserSuggestions(e, {
        limit: this.config.userLimit
      }), {
        nameOccurrenceIndex: t,
        suggestedUsers: s
      };
    });
  }
  parseTextToGetSuggestedChannels() {
    return n(this, void 0, void 0, function* () {
      const {
        differentReference: e,
        differentReferencePosition: t,
        referencesObject: n
      } = this.getUserOrChannelReference({
        splitSymbol: "#",
        referencesObject: this.referencedChannels
      });
      if (this.referencedChannels = n, !e) return {
        channelOccurrenceIndex: -1,
        suggestedChannels: []
      };
      return {
        channelOccurrenceIndex: t,
        suggestedChannels: yield this.chat.getChannelSuggestions(e, {
          limit: this.config.channelLimit
        })
      };
    });
  }
  onChange(e) {
    return n(this, void 0, void 0, function* () {
      return this.previousValue = this.value, this.value = e, this.config.isTypingIndicatorTriggered && (this.value ? this.channel.startTyping() : this.channel.stopTyping()), this.reindexTextLinks(), {
        users: yield this.parseTextToGetSuggestedUser(),
        channels: yield this.parseTextToGetSuggestedChannels()
      };
    });
  }
  addMentionedUser(e, t) {
    let n = 0,
      s = "",
      i = !1;
    if (this.value.split(" ").forEach(r => {
      if (r.startsWith("@")) {
        if (n !== t) s += `${r} `;else {
          const n = r.slice(-1);
          s += `@${e.name}`, ["!", "?", ".", ","].includes(n) ? s += `${n} ` : s += " ", this.mentionedUsers[t] = e, i = !0;
        }
        n++;
      } else s += `${r} `;
    }), !i) throw "This user does not appear in the text";
    this.value = s.trim();
  }
  addReferencedChannel(e, t) {
    let n = 0,
      s = "",
      i = !1;
    if (this.value.split(" ").forEach(r => {
      if (r.startsWith("#")) {
        if (n !== t) s += `${r} `;else {
          const n = r.slice(-1);
          s += `#${e.name}`, ["!", "?", ".", ","].includes(n) ? s += `${n} ` : s += " ", this.referencedChannels[t] = e, i = !0;
        }
        n++;
      } else s += `${r} `;
    }), !i) throw "This channel does not appear in the text";
    this.value = s.trim();
  }
  removeReferencedChannel(e) {
    this.referencedChannels[e] ? delete this.referencedChannels[e] : console.warn("This is noop. There is no channel reference occurrence at this index.");
  }
  removeMentionedUser(e) {
    this.mentionedUsers[e] ? delete this.mentionedUsers[e] : console.warn("This is noop. There is no mention occurrence at this index.");
  }
  transformMentionedUsersToSend() {
    return Object.keys(this.mentionedUsers).reduce((e, t) => Object.assign(Object.assign({}, e), {
      [t]: {
        id: this.mentionedUsers[Number(t)].id,
        name: this.mentionedUsers[Number(t)].name
      }
    }), {});
  }
  transformReferencedChannelsToSend() {
    return Object.keys(this.referencedChannels).reduce((e, t) => Object.assign(Object.assign({}, e), {
      [t]: {
        id: this.referencedChannels[Number(t)].id,
        name: this.referencedChannels[Number(t)].name
      }
    }), {});
  }
  send(e = {}) {
    return n(this, void 0, void 0, function* () {
      return this.channel.sendText(this.value, Object.assign(Object.assign({}, e), {
        mentionedUsers: this.transformMentionedUsersToSend(),
        referencedChannels: this.transformReferencedChannelsToSend(),
        textLinks: this.textLinks,
        quotedMessage: this.quotedMessage,
        files: this.files
      }));
    });
  }
  getHighlightedMention(e) {
    const t = this.value.slice(0, e - 1).split(" "),
      n = t.filter(e => e.startsWith("@")),
      s = t.findLastIndex(e => e.startsWith("@")),
      i = t.slice(s, t.length),
      r = this.mentionedUsers[n.length - 1];
    return (null == r ? void 0 : r.name) && i.length <= r.name.split(" ").length ? {
      mentionedUser: r,
      nameOccurrenceIndex: n.length - 1
    } : {
      mentionedUser: null,
      nameOccurrenceIndex: -1
    };
  }
  addLinkedText(e) {
    const {
      text: t,
      link: n,
      positionInInput: s
    } = e;
    if (!l.isUrl(n)) throw "You need to insert a URL";
    if (this.textLinks.flatMap(e => C(e.startIndex, e.endIndex)).includes(s)) throw "You cannot insert a link inside another link";
    this.onChange(this.value.slice(0, s) + t + this.value.slice(s)), this.textLinks.push({
      startIndex: s,
      endIndex: s + t.length,
      link: n
    });
  }
  removeLinkedText(e) {
    if (Number.isNaN(e)) throw "You need to insert a number";
    const t = this.textLinks.findIndex(t => C(t.startIndex, t.endIndex).includes(e));
    -1 !== t ? this.textLinks = this.textLinks.filter((e, n) => n !== t) : console.warn("This operation is noop. There is no link at this position.");
  }
  getMessagePreview() {
    return u.getMessageElements({
      text: this.value,
      textLinks: this.textLinks,
      mentionedUsers: this.transformMentionedUsersToSend(),
      referencedChannels: this.transformReferencedChannelsToSend()
    });
  }
  addQuote(e) {
    if (e.channelId !== this.channel.id) throw "You cannot quote messages from other channels";
    this.quotedMessage = e;
  }
  removeQuote() {
    this.quotedMessage = void 0;
  }
}
class M {
  constructor(e, t) {
    this.typingSent = !1, this.typingIndicators = new Map(), this.chat = e, this.id = t.id, this.suggestedNames = new Map(), this.sendTextRateLimiter = new T(t.type && e.config.rateLimitPerChannel[t.type] || 0, e.config.rateLimitFactor), Object.assign(this, t);
  }
  static fromDTO(e, t) {
    const n = {
      id: t.id,
      name: t.name || void 0,
      custom: t.custom || void 0,
      description: t.description || void 0,
      updated: t.updated || void 0,
      status: t.status || void 0,
      type: t.type && ["direct", "group", "public"].includes(t.type) ? t.type : "unknown"
    };
    return c(new M(e, n), e.errorLogger);
  }
  update(e) {
    return n(this, void 0, void 0, function* () {
      return this.chat.updateChannel(this.id, e);
    });
  }
  delete(e = {}) {
    return n(this, void 0, void 0, function* () {
      return this.chat.deleteChannel(this.id, e);
    });
  }
  static streamUpdatesOn(e, t) {
    if (!e.length) throw "Cannot stream channel updates on an empty list";
    const n = {
        objects: n => {
          if ("channel" !== n.message.type) return;
          const s = e.find(e => e.id === n.channel);
          if (!s) return;
          const i = M.fromDTO(s.chat, n.message.data),
            r = e.map(e => e.id === i.id ? i : e);
          t(r);
        }
      },
      {
        chat: s
      } = e[0],
      i = s.addListener(n),
      r = e.map(e => s.subscribe(e.id));
    return () => {
      i(), r.map(e => e());
    };
  }
  streamUpdates(e) {
    return M.streamUpdatesOn([this], t => e(t[0]));
  }
  getPushPayload(t) {
    const {
      sendPushes: n,
      apnsTopic: s,
      apnsEnvironment: i
    } = this.chat.config.pushNotifications;
    if (!n) return {};
    const r = this.chat.currentUser.name || this.chat.currentUser.id,
      a = e.notificationPayload(r, t),
      o = ["fcm"];
    return a.sound = "default", this.name && (a.subtitle = this.name), s && (a.apns.configurations = [{
      targets: [{
        topic: s,
        environment: i
      }]
    }], o.push("apns2")), a.buildPayload(o);
  }
  emitUserMention({
    userId: e,
    timetoken: t,
    text: n
  }) {
    const s = Object.assign({
      messageTimetoken: String(t),
      channel: this.id
    }, this.getPushPayload(n));
    this.chat.emitEvent({
      type: "mention",
      user: e,
      payload: s
    });
  }
  sendText(e, s = {}) {
    return n(this, void 0, void 0, function* () {
      return this.sendTextRateLimiter.runWithinLimits(() => n(this, void 0, void 0, function* () {
        const {
            mentionedUsers: n,
            textLinks: i,
            quotedMessage: r,
            files: a,
            referencedChannels: o
          } = s,
          h = t(s, ["mentionedUsers", "textLinks", "quotedMessage", "files", "referencedChannels"]),
          c = [];
        if (r && r.channelId !== this.id) throw "You cannot quote messages from other channels";
        if ("string" != typeof e) throw "You can only send text messages using this method";
        if (a) {
          const e = Array.isArray(a) ? a : Array.from(a);
          for (const t of e) {
            const e = "type" in t ? t.type : t.mimeType,
              {
                name: n,
                id: s
              } = yield this.chat.sdk.sendFile({
                channel: this.id,
                file: t,
                storeInHistory: !1
              }),
              i = this.chat.sdk.getFileUrl({
                channel: this.id,
                id: s,
                name: n
              });
            c.push({
              url: i,
              name: n,
              id: s,
              type: e
            });
          }
        }
        const d = this.chat.config.customPayloads.getMessagePublishBody || p,
          l = Object.assign(Object.assign({}, d({
            type: m.TEXT,
            text: e,
            files: c
          }, this.id)), this.getPushPayload(e)),
          u = yield this.chat.publish(Object.assign(Object.assign({}, h), {
            channel: this.id,
            message: l,
            meta: Object.assign(Object.assign({}, h.meta || {}), {
              mentionedUsers: n,
              referencedChannels: o,
              textLinks: i,
              quotedMessage: r ? {
                timetoken: r.timetoken,
                text: r.text,
                userId: r.userId
              } : void 0
            })
          }));
        return n && Object.keys(n).forEach(t => {
          const s = n[Number(t)].id;
          this.emitUserMention({
            userId: s,
            timetoken: u.timetoken,
            text: e
          });
        }), u;
      }));
    });
  }
  forwardMessage(e) {
    return n(this, void 0, void 0, function* () {
      return this.chat.forwardMessage(e, this.id);
    });
  }
  sendTypingSignal(e) {
    return n(this, void 0, void 0, function* () {
      return yield this.chat.emitEvent({
        channel: this.id,
        type: "typing",
        payload: {
          value: e
        }
      });
    });
  }
  startTyping() {
    return n(this, void 0, void 0, function* () {
      if ("public" === this.type) throw "Typing indicators are not supported in Public chats.";
      if (!this.typingSent) return this.typingSent = !0, this.typingSentTimer = setTimeout(() => this.typingSent = !1, this.chat.config.typingTimeout - 1e3), yield this.sendTypingSignal(!0);
    });
  }
  stopTyping() {
    return n(this, void 0, void 0, function* () {
      if ("public" === this.type) throw "Typing indicators are not supported in Public chats.";
      if (clearTimeout(this.typingSentTimer), this.typingSent) return this.typingSent = !1, yield this.sendTypingSignal(!1);
    });
  }
  getTyping(e) {
    if ("public" === this.type) throw "Typing indicators are not supported in Public chats.";
    return this.chat.listenForEvents({
      channel: this.id,
      type: "typing",
      callback: t => {
        const {
          channelId: n,
          payload: s,
          userId: i,
          type: r
        } = t;
        if (n !== this.id) return;
        if ("typing" !== r) return;
        const a = this.typingIndicators.get(i);
        if (!s.value && a && (clearTimeout(a), this.typingIndicators.delete(i)), s.value && a) {
          clearTimeout(a);
          const t = setTimeout(() => {
            this.typingIndicators.delete(i), e(Array.from(this.typingIndicators.keys()));
          }, this.chat.config.typingTimeout);
          this.typingIndicators.set(i, t);
        }
        if (s.value && !a) {
          const t = setTimeout(() => {
            this.typingIndicators.delete(i), e(Array.from(this.typingIndicators.keys()));
          }, this.chat.config.typingTimeout);
          this.typingIndicators.set(i, t);
        }
        e(Array.from(this.typingIndicators.keys()));
      }
    });
  }
  connect(e) {
    const t = {
        message: t => {
          if (t.channel !== this.id) return;
          "text" === (this.chat.config.customPayloads.getMessageResponseBody || v)(t).type && e(I.fromDTO(this.chat, t));
        }
      },
      n = this.chat.addListener(t),
      s = this.chat.subscribe(this.id);
    return () => {
      n(), s();
    };
  }
  whoIsPresent() {
    return n(this, void 0, void 0, function* () {
      return this.chat.whoIsPresent(this.id);
    });
  }
  isPresent(e) {
    return n(this, void 0, void 0, function* () {
      return this.chat.isPresent(e, this.id);
    });
  }
  streamPresence(e) {
    return n(this, void 0, void 0, function* () {
      let t = yield this.whoIsPresent();
      e(t);
      const n = {
          presence: n => {
            n.channel === this.id && ("join" !== n.action || t.includes(n.uuid) || t.push(n.uuid), ["leave", "timeout"].includes(n.action) && (t = t.filter(e => e !== n.uuid)), e([...t]));
          }
        },
        s = this.chat.addListener(n),
        i = this.chat.subscribe(this.id);
      return () => {
        s(), i();
      };
    });
  }
  getHistory(e = {}) {
    var t, s;
    return n(this, void 0, void 0, function* () {
      try {
        const n = {
            channels: [this.id],
            count: e.count || 25,
            start: e.startTimetoken,
            end: e.endTimetoken,
            includeMessageActions: !0,
            includeMeta: !0
          },
          i = yield this.chat.sdk.fetchMessages(n);
        return {
          messages: (null === (t = i.channels[this.id]) || void 0 === t ? void 0 : t.map(e => I.fromDTO(this.chat, e))) || [],
          isMore: (null === (s = i.channels[this.id]) || void 0 === s ? void 0 : s.length) === (e.count || 25)
        };
      } catch (e) {
        throw e;
      }
    });
  }
  getMessage(e) {
    return n(this, void 0, void 0, function* () {
      const t = String(BigInt(e) + BigInt(1));
      return (yield this.getHistory({
        endTimetoken: e,
        startTimetoken: t
      })).messages[0];
    });
  }
  join(e, s = {}) {
    return n(this, void 0, void 0, function* () {
      try {
        const {
            custom: n
          } = s,
          i = t(s, ["custom"]),
          r = yield this.chat.sdk.objects.setMemberships(Object.assign(Object.assign({}, i), {
            channels: [{
              id: this.id,
              custom: n
            }],
            include: {
              totalCount: !0,
              customFields: !0,
              channelFields: !0,
              customChannelFields: !0
            },
            filter: `channel.id == '${this.id}'`
          }));
        return this.disconnect = this.connect(e), {
          membership: yield w.fromMembershipDTO(this.chat, r.data[0], this.chat.currentUser).setLastReadMessageTimetoken(String((yield this.chat.sdk.time()).timetoken)),
          disconnect: this.disconnect
        };
      } catch (e) {
        throw e;
      }
    });
  }
  leave() {
    return n(this, void 0, void 0, function* () {
      this.disconnect && this.disconnect();
      try {
        return yield this.chat.sdk.objects.removeMemberships({
          channels: [this.id]
        }), !0;
      } catch (e) {
        throw e;
      }
    });
  }
  getMembers(e = {}) {
    return n(this, void 0, void 0, function* () {
      const t = yield this.chat.sdk.objects.getChannelMembers(Object.assign(Object.assign({}, e), {
        channel: this.id,
        include: {
          totalCount: !0,
          statusField: !0,
          customFields: !0,
          UUIDFields: !0,
          customUUIDFields: !0
        }
      }));
      return {
        page: {
          next: t.next,
          prev: t.prev
        },
        total: t.totalCount,
        status: t.status,
        members: t.data.map(e => w.fromChannelMemberDTO(this.chat, e, this))
      };
    });
  }
  invite(e) {
    return n(this, void 0, void 0, function* () {
      if ("public" === this.type) throw "Channel invites are not supported in Public chats.";
      try {
        const t = yield this.getMembers({
          filter: `uuid.id == '${e.id}'`
        });
        if (t.members.length) return t.members[0];
        const n = yield this.chat.sdk.objects.setMemberships({
            uuid: e.id,
            channels: [this.id],
            include: {
              totalCount: !0,
              customFields: !0,
              channelFields: !0,
              customChannelFields: !0
            },
            filter: `channel.id == '${this.id}'`
          }),
          s = yield w.fromMembershipDTO(this.chat, n.data[0], e).setLastReadMessageTimetoken(String((yield this.chat.sdk.time()).timetoken));
        return yield this.chat.emitEvent({
          channel: e.id,
          type: "invite",
          payload: {
            channelType: this.type || "unknown",
            channelId: this.id
          }
        }), s;
      } catch (e) {
        throw e;
      }
    });
  }
  inviteMultiple(e) {
    return n(this, void 0, void 0, function* () {
      if ("public" === this.type) throw "Channel invites are not supported in Public chats.";
      const t = e.map(e => e.id),
        s = t.map(e => `uuid.id == '${e}'`).join(" || ");
      try {
        const i = yield this.chat.sdk.objects.setChannelMembers({
            channel: this.id,
            uuids: t,
            include: {
              totalCount: !0,
              customFields: !0,
              UUIDFields: !0,
              customUUIDFields: !0
            },
            filter: s
          }),
          {
            timetoken: r
          } = yield this.chat.sdk.time(),
          a = yield Promise.all(i.data.map(e => w.fromChannelMemberDTO(this.chat, e, this).setLastReadMessageTimetoken(String(r))));
        return yield Promise.all(e.map(e => n(this, void 0, void 0, function* () {
          yield this.chat.emitEvent({
            channel: e.id,
            type: "invite",
            payload: {
              channelType: this.type || "unknown",
              channelId: this.id
            }
          });
        }))), a;
      } catch (e) {
        throw e;
      }
    });
  }
  pinMessage(e) {
    return n(this, void 0, void 0, function* () {
      const t = yield this.chat.pinMessageToChannel(e, this);
      return M.fromDTO(this.chat, t.data);
    });
  }
  unpinMessage() {
    return n(this, void 0, void 0, function* () {
      const e = yield this.chat.pinMessageToChannel(null, this);
      return M.fromDTO(this.chat, e.data);
    });
  }
  getPinnedMessage() {
    var e, t;
    return n(this, void 0, void 0, function* () {
      try {
        const n = null === (e = this.custom) || void 0 === e ? void 0 : e.pinnedMessageTimetoken,
          s = null === (t = this.custom) || void 0 === t ? void 0 : t.pinnedMessageChannelID;
        if (!n || !s) return null;
        if (s === this.id) return this.getMessage(String(n));
        const i = yield this.chat.getChannel(String(s));
        if (!i) throw "The thread channel does not exist";
        return i.getMessage(String(n));
      } catch (e) {
        return console.error(e), null;
      }
    });
  }
  getUserSuggestions(e, t = {
    limit: 10
  }) {
    return n(this, void 0, void 0, function* () {
      const n = u.getPhraseToLookFor(e);
      if (!n) return [];
      if (this.suggestedNames.get(n)) return this.suggestedNames.get(n);
      const s = yield this.getMembers({
        filter: `uuid.name LIKE "${n}*"`,
        limit: t.limit
      });
      return this.suggestedNames.set(n, s.members), this.suggestedNames.get(n);
    });
  }
  createMessageDraft(e) {
    return c(new x(this.chat, this, e), this.chat.errorLogger);
  }
  registerForPush() {
    return this.chat.registerPushChannels([this.id]);
  }
  unregisterFromPush() {
    return this.chat.unregisterPushChannels([this.id]);
  }
  streamReadReceipts(e) {
    return n(this, void 0, void 0, function* () {
      if ("public" === this.type) throw "Read receipts are not supported in Public chats.";
      function t() {
        const e = {};
        return s.forEach((t, n) => {
          var s;
          null !== (s = e[t]) && void 0 !== s || (e[t] = []), e[t].push(n);
        }), e;
      }
      const {
          members: n
        } = yield this.getMembers(),
        s = new Map();
      n.forEach(e => {
        var t;
        const n = null === (t = e.custom) || void 0 === t ? void 0 : t.lastReadMessageTimetoken;
        n && s.set(e.user.id, String(n));
      }), e(t());
      return this.chat.listenForEvents({
        channel: this.id,
        type: "receipt",
        callback: n => {
          const {
            userId: i,
            payload: r
          } = n;
          s.set(i, r.messageTimetoken), e(t());
        }
      });
    });
  }
  getFiles(e = {}) {
    return n(this, void 0, void 0, function* () {
      const t = yield this.chat.sdk.listFiles(Object.assign({
        channel: this.id
      }, e));
      return {
        files: t.data.map(e => {
          const {
            name: t,
            id: n
          } = e;
          return {
            name: t,
            id: n,
            url: this.chat.sdk.getFileUrl({
              channel: this.id,
              id: n,
              name: t
            })
          };
        }),
        next: t.next,
        total: t.count
      };
    });
  }
  deleteFile(e) {
    return n(this, void 0, void 0, function* () {
      return this.chat.sdk.deleteFile(Object.assign({
        channel: this.id
      }, e));
    });
  }
  setRestrictions(e, t) {
    return n(this, void 0, void 0, function* () {
      if (!this.chat.sdk._config.secretKey) throw "Moderation restrictions can only be set by clients initialized with a Secret Key.";
      return this.chat.setRestrictions(e.id, this.id, t);
    });
  }
  getRestrictions(e, t) {
    return n(this, void 0, void 0, function* () {
      return yield this.chat.sdk.objects.getChannelMembers(Object.assign(Object.assign({
        channel: `${i}${this.id}`,
        include: {
          totalCount: !0,
          customFields: !0
        }
      }, e && {
        filter: `uuid.id == '${e.id}'`
      }), t));
    });
  }
  getUserRestrictions(e) {
    var t;
    return n(this, void 0, void 0, function* () {
      const n = yield this.getRestrictions(e),
        s = n && (null === (t = n.data[0]) || void 0 === t ? void 0 : t.custom);
      return {
        ban: !!(null == s ? void 0 : s.ban),
        mute: !!(null == s ? void 0 : s.mute),
        reason: null == s ? void 0 : s.reason
      };
    });
  }
  getUsersRestrictions(e) {
    return n(this, void 0, void 0, function* () {
      const t = yield this.getRestrictions(void 0, e);
      return {
        page: {
          next: t.next,
          prev: t.prev
        },
        total: t.totalCount,
        status: t.status,
        restrictions: t.data.map(({
          custom: e,
          uuid: t
        }) => ({
          ban: !!(null == e ? void 0 : e.ban),
          mute: !!(null == e ? void 0 : e.mute),
          reason: null == e ? void 0 : e.reason,
          userId: t.id
        }))
      };
    });
  }
  getMessageReportsHistory(e = {}) {
    return n(this, void 0, void 0, function* () {
      const t = `${i}${this.id}`;
      return this.chat.getEventsHistory(Object.assign(Object.assign({}, e), {
        channel: t
      }));
    });
  }
  streamMessageReports(e) {
    const t = `${i}${this.id}`;
    return this.chat.listenForEvents({
      channel: t,
      callback: e,
      type: "report"
    });
  }
}
class O {
  constructor(e, t) {
    this.chat = e, this.timetoken = t.timetoken, this.type = t.type, this.payload = t.payload, this.channelId = t.channelId, this.userId = t.userId, Object.assign(this, t);
  }
  static fromDTO(e, n) {
    const s = n.message,
      {
        type: i
      } = s,
      r = t(s, ["type"]),
      a = {
        timetoken: String(n.timetoken),
        type: i,
        payload: r,
        channelId: n.channel,
        userId: "publisher" in n ? n.publisher : n.uuid || "unknown-user"
      };
    return c(new O(e, a), e.errorLogger);
  }
}
class U extends I {
  constructor(e, t) {
    super(e, t), this.parentChannelId = t.parentChannelId;
  }
  static fromDTO(e, t) {
    const n = {
      timetoken: String(t.timetoken),
      parentChannelId: t.parentChannelId,
      content: t.message,
      channelId: t.channel,
      userId: "publisher" in t ? t.publisher : t.uuid || "unknown-user",
      actions: "actions" in t ? t.actions : void 0,
      meta: "meta" in t ? t.meta : "userMetadata" in t ? t.userMetadata : void 0
    };
    return c(new U(e, n), e.errorLogger);
  }
  clone(e) {
    const {
        timetoken: t,
        content: n,
        channelId: s,
        userId: i,
        actions: r,
        meta: a,
        parentChannelId: o
      } = this,
      h = Object.assign({}, {
        parentChannelId: o,
        timetoken: t,
        content: n,
        channelId: s,
        userId: i,
        actions: r,
        meta: a
      }, e);
    return new U(this.chat, h);
  }
  static streamUpdatesOn(e, t) {
    if (!e.length) throw "Cannot stream message updates on an empty list";
    const n = {
        messageAction: n => {
          const s = e.find(e => e.timetoken === n.data.messageTimetoken);
          if (!s) return;
          if (s.channelId !== n.channel) return;
          let i;
          "added" === n.event && (i = s.assignAction(n.data)), "removed" === n.event && (i = s.filterAction(n.data));
          const r = s.clone({
              actions: i
            }),
            a = e.map(e => e.timetoken === r.timetoken ? r : e);
          t(a);
        }
      },
      {
        chat: s
      } = e[0],
      i = s.addListener(n),
      r = e.filter((t, n) => e.findIndex(e => t.channelId === e.channelId) === n).map(e => s.subscribe(e.channelId));
    return () => {
      i(), r.map(e => e());
    };
  }
  pinToParentChannel() {
    return n(this, void 0, void 0, function* () {
      const e = yield this.chat.getChannel(this.parentChannelId);
      if (!e) throw "Parent channel doesn't exist";
      const t = yield this.chat.pinMessageToChannel(this, e);
      return M.fromDTO(this.chat, t.data);
    });
  }
  unpinFromParentChannel() {
    return n(this, void 0, void 0, function* () {
      const e = yield this.chat.getChannel(this.parentChannelId);
      if (!e) throw "Parent channel doesn't exist";
      const t = yield this.chat.pinMessageToChannel(null, e);
      return M.fromDTO(this.chat, t.data);
    });
  }
}
class j extends M {
  constructor(e, t) {
    super(e, t), this.parentChannelId = t.parentChannelId, this.parentMessage = t.parentMessage;
  }
  static fromDTO(e, t) {
    const n = {
      id: t.id,
      parentChannelId: t.parentChannelId,
      parentMessage: t.parentMessage,
      name: t.name || void 0,
      custom: t.custom || void 0,
      description: t.description || void 0,
      updated: t.updated || void 0,
      status: t.status || void 0,
      type: t.type && ["direct", "group", "public"].includes(t.type) ? t.type : "unknown"
    };
    return c(new j(e, n), e.errorLogger);
  }
  pinMessage(e) {
    return n(this, void 0, void 0, function* () {
      const t = yield this.chat.pinMessageToChannel(e, this);
      return j.fromDTO(this.chat, Object.assign(Object.assign({}, t.data), {
        parentMessage: this.parentMessage,
        parentChannelId: this.parentChannelId
      }));
    });
  }
  unpinMessage() {
    return n(this, void 0, void 0, function* () {
      const e = yield this.chat.pinMessageToChannel(null, this);
      return j.fromDTO(this.chat, Object.assign(Object.assign({}, e.data), {
        parentMessage: this.parentMessage,
        parentChannelId: this.parentChannelId
      }));
    });
  }
  pinMessageToParentChannel(e) {
    return n(this, void 0, void 0, function* () {
      const t = yield this.chat.getChannel(this.parentChannelId);
      if (!t) throw "Parent channel doesn't exist";
      const n = yield this.chat.pinMessageToChannel(e, t);
      return M.fromDTO(this.chat, n.data);
    });
  }
  unpinMessageFromParentChannel() {
    return n(this, void 0, void 0, function* () {
      const e = yield this.chat.getChannel(this.parentChannelId);
      if (!e) throw "Parent channel doesn't exist";
      const t = yield this.chat.pinMessageToChannel(null, e);
      return M.fromDTO(this.chat, t.data);
    });
  }
  getHistory(e = {}) {
    const t = Object.create(null, {
      getHistory: {
        get: () => super.getHistory
      }
    });
    return n(this, void 0, void 0, function* () {
      const n = yield t.getHistory.call(this, e);
      return {
        messages: n.messages.map(e => new U(this.chat, {
          timetoken: e.timetoken,
          parentChannelId: this.parentChannelId,
          content: e.content,
          channelId: e.channelId,
          userId: e.userId,
          actions: e.actions,
          meta: e.meta
        })),
        isMore: n.isMore
      };
    });
  }
  delete(e = {}) {
    return n(this, void 0, void 0, function* () {
      return (yield this.chat.removeThreadChannel(this.parentMessage, e))[1];
    });
  }
  emitUserMention({
    userId: e,
    timetoken: t,
    text: n
  }) {
    const s = Object.assign({
      messageTimetoken: String(t),
      channel: this.id,
      parentChannel: this.parentChannelId
    }, this.getPushPayload(n));
    this.chat.emitEvent({
      user: e,
      type: "mention",
      payload: s
    });
  }
}
const D = [];
for (let e = 0; e < 256; e++) D[e] = (e + 256).toString(16).substr(1);
const A = () => {
  const e = (() => {
    const e = new Array(16);
    for (let t = 0; t < 16; t++) e[t] = 255 & 256 * Math.random() + 0;
    return e;
  })();
  return e[6] = 15 & e[6] | 64, e[8] = 63 & e[8] | 128, ((e, t) => {
    let n = t || 0;
    const s = D;
    return s[e[n++]] + s[e[n++]] + s[e[n++]] + s[e[n++]] + "-" + s[e[n++]] + s[e[n++]] + "-" + s[e[n++]] + s[e[n++]] + "-" + s[e[n++]] + s[e[n++]] + "-" + s[e[n++]] + s[e[n++]] + s[e[n++]] + s[e[n++]] + s[e[n++]] + s[e[n++]];
  })(e);
};
class L {
  constructor(e) {
    this.chat = e;
  }
  canI({
    permission: e,
    resourceType: t,
    resourceName: n
  }) {
    var s, i, r, a;
    const o = this.chat.config.authKey;
    if (!o) return !0;
    const h = this.chat.sdk.parseToken(o),
      c = null === (r = null === (i = null === (s = h.resources) || void 0 === s ? void 0 : s[t]) || void 0 === i ? void 0 : i[n]) || void 0 === r ? void 0 : r[e];
    if ("boolean" == typeof c) return c;
    const d = (null === (a = h.patterns) || void 0 === a ? void 0 : a[t]) || {},
      l = Object.keys(d);
    for (const t of l) {
      if (new RegExp(t).test(n)) return d[t][e] || !1;
    }
    return !1;
  }
}
class P {
  constructor(n) {
    const {
        saveDebugLog: s,
        typingTimeout: i,
        storeUserActivityInterval: r,
        storeUserActivityTimestamps: a,
        pushNotifications: o,
        rateLimitFactor: c,
        rateLimitPerChannel: d,
        errorLogger: l,
        customPayloads: u
      } = n,
      m = t(n, ["saveDebugLog", "typingTimeout", "storeUserActivityInterval", "storeUserActivityTimestamps", "pushNotifications", "rateLimitFactor", "rateLimitPerChannel", "errorLogger", "customPayloads"]);
    this.errorLogger = new h(l), this.editMessageActionName = (null == u ? void 0 : u.editMessageActionName) || f, this.deleteMessageActionName = (null == u ? void 0 : u.deleteMessageActionName) || y, this.reactionsActionName = (null == u ? void 0 : u.reactionsActionName) || b;
    try {
      if (r && r < 6e4) throw "storeUserActivityInterval must be at least 60000ms";
      if ("apns2" === (null == o ? void 0 : o.deviceGateway) && !(null == o ? void 0 : o.apnsTopic)) throw "apnsTopic has to be defined when deviceGateway is set to apns2";
    } catch (e) {
      throw this.errorLogger.setItem("PushNotificationError", e, arguments), e;
    }
    const g = new e(m);
    g._config._addPnsdkSuffix("chat-sdk", "CA-TS/0.8.4"), this.sdk = g, this.user = new k(this, {
      id: "userId" in m ? m.userId : m.uuid
    }), this.subscriptions = {}, this.suggestedNamesCache = new Map(), this.suggestedChannelsCache = new Map(), this.config = {
      saveDebugLog: s || !1,
      typingTimeout: i || 5e3,
      storeUserActivityInterval: r || 6e5,
      storeUserActivityTimestamps: a || !1,
      pushNotifications: o || {
        sendPushes: !1,
        apnsEnvironment: "development",
        deviceGateway: "gcm"
      },
      rateLimitFactor: c || 2,
      rateLimitPerChannel: d || {
        direct: 0,
        group: 0,
        public: 0,
        unknown: 0
      },
      customPayloads: {
        getMessagePublishBody: null == u ? void 0 : u.getMessagePublishBody,
        getMessageResponseBody: null == u ? void 0 : u.getMessageResponseBody
      },
      authKey: m.authKey
    }, this.accessManager = new L(this);
  }
  static init(e) {
    return n(this, void 0, void 0, function* () {
      const t = new P(e);
      t.user = (yield t.getUser(t.sdk.getUUID())) || (yield t.createUser(t.sdk.getUUID(), {
        name: t.sdk.getUUID()
      })), e.storeUserActivityTimestamps && t.storeUserActivityTimestamp();
      return c(t, t.errorLogger);
    });
  }
  subscribe(e) {
    var t;
    const n = Math.floor(Math.random() * Date.now()).toString(36),
      s = (t = this.subscriptions)[e] || (t[e] = new Set());
    return s.size || this.sdk.subscribe({
      channels: [e],
      withPresence: !0
    }), s.add(n), () => {
      s && s.has(n) && (s.delete(n), s.size || this.sdk.unsubscribe({
        channels: [e]
      }));
    };
  }
  addListener(e) {
    return this.sdk.addListener(e), () => {
      this.sdk.removeListener(e);
    };
  }
  publish(e) {
    return this.sdk.publish(e);
  }
  signal(e) {
    if (this.accessManager.canI({
      permission: "write",
      resourceName: e.channel,
      resourceType: "channels"
    })) return this.sdk.signal(e);
    throw new Error(`You tried to send a signal containing message: ${JSON.stringify(e.message)} to channel: ${e.channel} but PubNub Access Manager prevented you from doing so.`);
  }
  methodForEvent(e) {
    switch (e.type) {
      case "custom":
        return e.method;
      case "typing":
      case "receipt":
        return "signal";
      default:
        return "publish";
    }
  }
  emitEvent(e) {
    const {
        payload: t,
        type: n
      } = e,
      s = "channel" in e ? e.channel : e.user,
      i = this.methodForEvent(e),
      r = {
        channel: s,
        message: Object.assign(Object.assign({}, t), {
          type: n
        })
      };
    return "signal" === i ? this.signal(r) : this.publish(r);
  }
  listenForEvents(e) {
    const {
        type: t,
        callback: n
      } = e,
      s = "channel" in e ? e.channel : e.user,
      i = this.methodForEvent(e),
      r = e => {
        if (e.channel !== s) return;
        if (e.message.type !== t) return;
        const {
          channel: i,
          timetoken: r,
          message: a,
          publisher: o
        } = e;
        n(O.fromDTO(this, {
          channel: i,
          timetoken: r,
          message: a,
          publisher: o
        }));
      },
      a = Object.assign({}, "signal" === i ? {
        signal: r
      } : {
        message: r
      }),
      o = this.addListener(a),
      h = this.subscribe(s);
    return () => {
      o(), h();
    };
  }
  getEventsHistory(e) {
    var t, s;
    return n(this, void 0, void 0, function* () {
      try {
        const n = {
            channels: [e.channel],
            count: e.count || 100,
            start: e.startTimetoken,
            end: e.endTimetoken,
            includeMessageActions: !1,
            includeMeta: !1
          },
          i = yield this.sdk.fetchMessages(n);
        return {
          events: (null === (t = i.channels[e.channel]) || void 0 === t ? void 0 : t.map(e => O.fromDTO(this, e))) || [],
          isMore: (null === (s = i.channels[e.channel]) || void 0 === s ? void 0 : s.length) === (e.count || 100)
        };
      } catch (e) {
        throw e;
      }
    });
  }
  get currentUser() {
    return this.user;
  }
  getUser(e) {
    var t, s;
    return n(this, void 0, void 0, function* () {
      if (!e.length) throw "ID is required";
      try {
        const t = yield this.sdk.objects.getUUIDMetadata({
          uuid: e
        });
        return k.fromDTO(this, t.data);
      } catch (e) {
        const n = e;
        if (404 === (null === (s = null === (t = null == n ? void 0 : n.status) || void 0 === t ? void 0 : t.errorData) || void 0 === s ? void 0 : s.status)) return null;
        throw e;
      }
    });
  }
  createUser(e, t) {
    return n(this, void 0, void 0, function* () {
      if (!e.length) throw "ID is required";
      try {
        if (yield this.getUser(e)) throw "User with this ID already exists";
        const n = yield this.sdk.objects.setUUIDMetadata({
          uuid: e,
          data: t
        });
        return k.fromDTO(this, n.data);
      } catch (e) {
        throw e;
      }
    });
  }
  updateUser(e, t) {
    return n(this, void 0, void 0, function* () {
      if (!e.length) throw "ID is required";
      try {
        if (!(yield this.getUser(e))) throw "User with this ID does not exist";
        const n = yield this.sdk.objects.setUUIDMetadata({
          uuid: e,
          data: t
        });
        return k.fromDTO(this, n.data);
      } catch (e) {
        throw e;
      }
    });
  }
  deleteUser(e, t = {}) {
    return n(this, void 0, void 0, function* () {
      if (!e.length) throw "ID is required";
      const {
        soft: n
      } = t;
      try {
        if (n) {
          const t = yield this.sdk.objects.setUUIDMetadata({
            uuid: e,
            data: {
              status: "deleted"
            }
          });
          return k.fromDTO(this, t.data);
        }
        return yield this.sdk.objects.removeUUIDMetadata({
          uuid: e
        }), !0;
      } catch (e) {
        throw e;
      }
    });
  }
  getUsers(e = {}) {
    return n(this, void 0, void 0, function* () {
      const t = Object.assign({}, e, {
        include: {
          totalCount: !0,
          customFields: !0
        }
      });
      try {
        const e = yield this.sdk.objects.getAllUUIDMetadata(t);
        return {
          users: e.data.map(e => k.fromDTO(this, e)),
          page: {
            next: e.next,
            prev: e.prev
          },
          total: e.totalCount
        };
      } catch (e) {
        throw e;
      }
    });
  }
  getThreadId(e, t) {
    return `${s}_${e}_${t}`;
  }
  getThreadChannel(e) {
    var t, s;
    return n(this, void 0, void 0, function* () {
      if (!e) throw "Message is required";
      const n = this.getThreadId(e.channelId, e.timetoken);
      try {
        const t = yield this.sdk.objects.getChannelMetadata({
          channel: n
        });
        return j.fromDTO(this, Object.assign(Object.assign({}, t.data), {
          parentMessage: e,
          parentChannelId: e.channelId
        }));
      } catch (e) {
        const n = e;
        throw 404 === (null === (s = null === (t = null == n ? void 0 : n.status) || void 0 === t ? void 0 : t.errorData) || void 0 === s ? void 0 : s.status) ? "This message is not a thread" : e;
      }
    });
  }
  createThreadChannel(e) {
    return n(this, void 0, void 0, function* () {
      try {
        if (e.channelId.startsWith(s)) throw "Only one level of thread nesting is allowed";
        if (e.deleted) throw "You cannot create threads on deleted messages";
        const t = this.getThreadId(e.channelId, e.timetoken);
        if (yield this.getChannel(t)) throw "Thread for this message already exists";
        const i = new j(this, {
            description: `Thread on channel ${e.channelId} with message timetoken ${e.timetoken}`,
            id: t,
            parentMessage: e,
            parentChannelId: e.channelId
          }),
          r = this;
        let a = !1;
        return new Proxy(i, {
          get(s, i) {
            if ("sendText" !== i || a) return s[i];
            const o = s.sendText;
            return function (s, i = {}) {
              return n(this, void 0, void 0, function* () {
                try {
                  return yield Promise.all([r.sdk.objects.setChannelMetadata({
                    channel: t,
                    data: {
                      description: `Thread on channel ${e.channelId} with message timetoken ${e.timetoken}`
                    }
                  }), r.sdk.addMessageAction({
                    channel: e.channelId,
                    messageTimetoken: e.timetoken,
                    action: {
                      type: "threadRootId",
                      value: t
                    }
                  })]), a = !0, o.bind(this)(s, i);
                } catch (e) {
                  throw e;
                }
              });
            };
          }
        });
      } catch (e) {
        throw console.error(e), e;
      }
    });
  }
  removeThreadChannel(e, t = {}) {
    var s;
    return n(this, void 0, void 0, function* () {
      if (!e.hasThread) throw "There is no thread to be deleted";
      const n = null === (s = e.actions) || void 0 === s ? void 0 : s.threadRootId[this.getThreadId(e.channelId, e.timetoken)][0].actionTimetoken;
      if (!n) throw "There is no action timetoken corresponding to the thread";
      const i = this.getThreadId(e.channelId, e.timetoken),
        r = yield this.getChannel(i);
      if (!r) throw `There is no thread with id: ${i}`;
      return Promise.all([this.sdk.removeMessageAction({
        channel: e.channelId,
        messageTimetoken: e.timetoken,
        actionTimetoken: String(n)
      }), r.delete(t)]);
    });
  }
  restoreThreadChannel(e) {
    var t, s, i, r;
    return n(this, void 0, void 0, function* () {
      const n = this.getThreadId(e.channelId, e.timetoken);
      if (!(yield this.getChannel(n))) return;
      if (null === (r = null === (i = null === (s = null === (t = e.actions) || void 0 === t ? void 0 : t.threadRootId) || void 0 === s ? void 0 : s[this.getThreadId(e.channelId, e.timetoken)]) || void 0 === i ? void 0 : i[0]) || void 0 === r ? void 0 : r.actionTimetoken) throw "This thread is already restored";
      return this.sdk.addMessageAction({
        channel: e.channelId,
        messageTimetoken: e.timetoken,
        action: {
          type: "threadRootId",
          value: n
        }
      });
    });
  }
  getChannel(e) {
    var t, s;
    return n(this, void 0, void 0, function* () {
      if (!e || !e.length) throw "ID is required";
      try {
        const t = yield this.sdk.objects.getChannelMetadata({
          channel: e
        });
        return M.fromDTO(this, t.data);
      } catch (e) {
        const n = e;
        if (404 === (null === (s = null === (t = null == n ? void 0 : n.status) || void 0 === t ? void 0 : t.errorData) || void 0 === s ? void 0 : s.status)) return null;
        throw e;
      }
    });
  }
  updateChannel(e, t) {
    return n(this, void 0, void 0, function* () {
      if (!e.length) throw "ID is required";
      try {
        if (!(yield this.getChannel(e))) throw "Channel with this ID does not exist";
        const n = yield this.sdk.objects.setChannelMetadata({
          channel: e,
          data: t
        });
        return M.fromDTO(this, n.data);
      } catch (e) {
        throw console.error(e), e;
      }
    });
  }
  createChannel(e, t) {
    return n(this, void 0, void 0, function* () {
      if (!e.length) throw "ID is required";
      try {
        if (yield this.getChannel(e)) throw "Channel with this ID already exists";
        const n = yield this.sdk.objects.setChannelMetadata({
          channel: e,
          data: t
        });
        return M.fromDTO(this, n.data);
      } catch (e) {
        throw console.error(e), e;
      }
    });
  }
  getChannels(e = {}) {
    return n(this, void 0, void 0, function* () {
      const t = Object.assign({}, e, {
        include: {
          totalCount: !0,
          customFields: !0
        }
      });
      try {
        const e = yield this.sdk.objects.getAllChannelMetadata(t);
        return {
          channels: e.data.map(e => M.fromDTO(this, e)),
          page: {
            next: e.next,
            prev: e.prev
          },
          total: e.totalCount
        };
      } catch (e) {
        throw e;
      }
    });
  }
  deleteChannel(e, t = {}) {
    return n(this, void 0, void 0, function* () {
      if (!e.length) throw "ID is required";
      const {
        soft: n
      } = t;
      try {
        if (n) {
          const t = yield this.sdk.objects.setChannelMetadata({
            channel: e,
            data: {
              status: "deleted"
            }
          });
          return M.fromDTO(this, t.data);
        }
        return yield this.sdk.objects.removeChannelMetadata({
          channel: e
        }), !0;
      } catch (e) {
        throw e;
      }
    });
  }
  createPublicConversation({
    channelId: e,
    channelData: t = {}
  } = {}) {
    return n(this, void 0, void 0, function* () {
      const n = e || A();
      return this.createChannel(n, Object.assign(Object.assign({
        name: n
      }, t), {
        type: "public"
      }));
    });
  }
  wherePresent(e) {
    return n(this, void 0, void 0, function* () {
      if (!e.length) throw "ID is required";
      try {
        return (yield this.sdk.whereNow({
          uuid: e
        })).channels;
      } catch (e) {
        throw e;
      }
    });
  }
  whoIsPresent(e) {
    return n(this, void 0, void 0, function* () {
      if (!e.length) throw "ID is required";
      try {
        return (yield this.sdk.hereNow({
          channels: [e]
        })).channels[e].occupants.map(e => e.uuid);
      } catch (e) {
        throw e;
      }
    });
  }
  isPresent(e, t) {
    return n(this, void 0, void 0, function* () {
      if (!e.length) throw "User ID is required";
      if (!t.length) throw "Channel ID is required";
      try {
        return (yield this.sdk.whereNow({
          uuid: e
        })).channels.includes(t);
      } catch (e) {
        throw e;
      }
    });
  }
  forwardMessage(e, t) {
    return n(this, void 0, void 0, function* () {
      if (!t) throw "Channel ID is required";
      if (!e) throw "Message is required";
      const n = Object.assign(Object.assign({}, e.meta || {}), {
        originalPublisher: e.userId,
        originalChannelId: e.channelId
      });
      return this.publish({
        message: e.content,
        channel: t,
        meta: n
      });
    });
  }
  pinMessageToChannel(e, t) {
    const n = Object.assign({}, t.custom || {});
    return e ? (n.pinnedMessageTimetoken = e.timetoken, n.pinnedMessageChannelID = e.channelId) : (delete n.pinnedMessageTimetoken, delete n.pinnedMessageChannelID), this.sdk.objects.setChannelMetadata({
      channel: t.id,
      data: {
        custom: n
      }
    });
  }
  saveTimeStampFunc() {
    var e;
    return n(this, void 0, void 0, function* () {
      const t = yield this.sdk.objects.setUUIDMetadata({
        uuid: this.sdk.getUUID(),
        data: {
          custom: Object.assign(Object.assign({}, (null === (e = this.user) || void 0 === e ? void 0 : e.custom) || {}), {
            lastActiveTimestamp: new Date().getTime()
          })
        }
      });
      this.user = k.fromDTO(this, t.data);
    });
  }
  runSaveTimestampInterval() {
    this.saveTimeStampFunc(), this.lastSavedActivityInterval = setInterval(() => {
      this.saveTimeStampFunc();
    }, this.config.storeUserActivityInterval);
  }
  storeUserActivityTimestamp() {
    return n(this, void 0, void 0, function* () {
      this.lastSavedActivityInterval && clearInterval(this.lastSavedActivityInterval);
      try {
        const e = yield this.getUser(this.sdk.getUUID());
        if (!e || !e.lastActiveTimestamp) return void this.runSaveTimestampInterval();
        const t = new Date().getTime() - e.lastActiveTimestamp;
        if (t >= this.config.storeUserActivityInterval) return void this.runSaveTimestampInterval();
        const n = this.config.storeUserActivityInterval - t;
        setTimeout(() => {
          this.runSaveTimestampInterval();
        }, n);
      } catch (e) {
        throw e;
      }
    });
  }
  createDirectConversation({
    user: e,
    channelId: s,
    channelData: i = {},
    membershipData: r = {}
  }) {
    return n(this, void 0, void 0, function* () {
      try {
        if (!this.user) throw "Chat user is not set. Set them by calling setChatUser on the Chat instance.";
        const n = [this.user.id, e.id].sort(),
          a = s || `direct.${function (e, t = 0) {
            let n = 3735928559 ^ t,
              s = 1103547991 ^ t;
            for (let t, i = 0; i < e.length; i++) t = e.charCodeAt(i), n = Math.imul(n ^ t, 2246822519), s = Math.imul(s ^ t, 3266489917);
            return n ^= Math.imul(n ^ s >>> 15, 1935289751), s ^= Math.imul(s ^ n >>> 15, 3405138345), n ^= s >>> 16, s ^= n >>> 16, 2097152 * (s >>> 0) + (n >>> 11);
          }(`${n[0]}&${n[1]}`)}`,
          o = (yield this.getChannel(a)) || (yield this.createChannel(a, Object.assign(Object.assign({
            name: a
          }, i), {
            type: "direct"
          }))),
          {
            custom: h
          } = r,
          c = t(r, ["custom"]),
          d = this.sdk.objects.setMemberships(Object.assign(Object.assign({}, c), {
            channels: [{
              id: o.id,
              custom: h
            }],
            include: {
              totalCount: !0,
              customFields: !0,
              channelFields: !0,
              customChannelFields: !0
            },
            filter: `channel.id == '${o.id}'`
          })),
          [l, u] = yield Promise.all([d, o.invite(e)]);
        return {
          channel: o,
          hostMembership: w.fromMembershipDTO(this, l.data[0], this.user),
          inviteeMembership: u
        };
      } catch (e) {
        throw e;
      }
    });
  }
  createGroupConversation({
    users: e,
    channelId: s,
    channelData: i = {},
    membershipData: r = {}
  }) {
    return n(this, void 0, void 0, function* () {
      const n = s || A();
      try {
        const s = (yield this.getChannel(n)) || (yield this.createChannel(n, Object.assign(Object.assign({
            name: n
          }, i), {
            type: "group"
          }))),
          {
            custom: a
          } = r,
          o = t(r, ["custom"]),
          h = this.sdk.objects.setMemberships(Object.assign(Object.assign({}, o), {
            channels: [{
              id: s.id,
              custom: a
            }],
            include: {
              totalCount: !0,
              customFields: !0,
              channelFields: !0,
              customChannelFields: !0
            },
            filter: `channel.id == '${s.id}'`
          })),
          [c, d] = yield Promise.all([h, s.inviteMultiple(e)]);
        return {
          channel: s,
          hostMembership: w.fromMembershipDTO(this, c.data[0], this.user),
          inviteesMemberships: d
        };
      } catch (e) {
        throw e;
      }
    });
  }
  getUserSuggestions(e, t = {
    limit: 10
  }) {
    return n(this, void 0, void 0, function* () {
      const n = u.getPhraseToLookFor(e);
      if (!n) return [];
      if (this.suggestedNamesCache.get(n)) return this.suggestedNamesCache.get(n);
      const s = yield this.getUsers({
        filter: `name LIKE "${n}*"`,
        limit: t.limit
      });
      return this.suggestedNamesCache.set(n, s.users), this.suggestedNamesCache.get(n);
    });
  }
  getChannelSuggestions(e, t = {
    limit: 10
  }) {
    return n(this, void 0, void 0, function* () {
      const n = u.getChannelPhraseToLookFor(e);
      if (!n) return [];
      if (this.suggestedChannelsCache.get(n)) return this.suggestedChannelsCache.get(n);
      const s = yield this.getChannels({
        filter: `name LIKE "${n}*"`,
        limit: t.limit
      });
      return this.suggestedChannelsCache.set(n, s.channels), this.suggestedChannelsCache.get(n);
    });
  }
  getCommonPushOptions() {
    const {
      deviceToken: e,
      deviceGateway: t,
      apnsEnvironment: n,
      apnsTopic: s
    } = this.config.pushNotifications;
    if (!e) throw "Device Token has to be defined in Chat pushNotifications config.";
    return Object.assign({
      device: e,
      pushGateway: t
    }, "apns2" === t && {
      environment: n,
      topic: s
    });
  }
  registerPushChannels(e) {
    return n(this, void 0, void 0, function* () {
      return yield this.sdk.push.addChannels(Object.assign({
        channels: e
      }, this.getCommonPushOptions()));
    });
  }
  unregisterPushChannels(e) {
    return n(this, void 0, void 0, function* () {
      return yield this.sdk.push.removeChannels(Object.assign({
        channels: e
      }, this.getCommonPushOptions()));
    });
  }
  unregisterAllPushChannels() {
    return n(this, void 0, void 0, function* () {
      return yield this.sdk.push.deleteDevice(this.getCommonPushOptions());
    });
  }
  getPushChannels() {
    return n(this, void 0, void 0, function* () {
      return (yield this.sdk.push.listChannels(this.getCommonPushOptions())).channels;
    });
  }
  downloadDebugLog() {
    return this.errorLogger.getStorageObject();
  }
  getCurrentUserMentions(e = {}) {
    return n(this, void 0, void 0, function* () {
      const t = yield this.getEventsHistory(Object.assign(Object.assign({}, e), {
        channel: this.currentUser.id
      }));
      return {
        enhancedMentionsData: yield Promise.all(t.events.filter(e => "mention" === e.type).map(e => n(this, void 0, void 0, function* () {
          const t = String(BigInt(e.payload.messageTimetoken) + BigInt(1)),
            n = yield this.sdk.fetchMessages({
              channels: [e.payload.channel],
              start: t,
              end: e.payload.messageTimetoken
            });
          return e.payload.parentChannel ? {
            event: e,
            message: I.fromDTO(this, n.channels[e.payload.channel][0]),
            userId: e.userId,
            parentChannelId: e.payload.parentChannel,
            threadChannelId: e.payload.channel
          } : {
            event: e,
            channelId: e.payload.channel,
            message: I.fromDTO(this, n.channels[e.payload.channel][0]),
            userId: e.userId
          };
        }))),
        isMore: t.isMore
      };
    });
  }
  getUnreadMessagesCounts(e = {}) {
    return n(this, void 0, void 0, function* () {
      const t = yield this.currentUser.getMemberships(e);
      if (!t.memberships.length) return [];
      const n = yield this.sdk.messageCounts({
        channels: t.memberships.map(e => e.channel.id),
        channelTimetokens: t.memberships.map(e => e.lastReadMessageTimetoken || "0")
      });
      return Object.keys(n.channels).map(e => {
        const s = t.memberships.find(t => t.channel.id === e);
        if (!s) throw `Cannot find channel with id ${e}`;
        return {
          channel: s.channel,
          membership: s,
          count: n.channels[e]
        };
      }).filter(e => e.count > 0);
    });
  }
  markAllMessagesAsRead(e = {}) {
    return n(this, void 0, void 0, function* () {
      const t = yield this.currentUser.getMemberships(e),
        n = t.memberships.map(e => e.channel.id);
      if (!n.length) return;
      const s = yield this.sdk.fetchMessages({
          channels: n,
          count: 1
        }),
        i = n.map((e, n) => {
          const i = s.channels[encodeURIComponent(e)],
            r = i && i[0] ? i[0].timetoken : "0";
          return {
            id: e,
            custom: Object.assign(Object.assign({}, t.memberships[n].custom), {
              lastReadMessageTimetoken: r
            })
          };
        }),
        r = `${n.map(e => `channel.id == '${e}'`).join(" || ")}`,
        a = yield this.sdk.objects.setMemberships({
          uuid: this.user.id,
          channels: i,
          include: {
            totalCount: !0,
            customFields: !0,
            channelFields: !0,
            customChannelFields: !0
          },
          filter: r
        });
      return n.forEach(e => {
        const t = s.channels[encodeURIComponent(e)],
          n = t && t[0] ? String(t[0].timetoken) : "";
        this.emitEvent({
          channel: e,
          type: "receipt",
          payload: {
            messageTimetoken: n
          }
        });
      }), {
        page: {
          next: a.next,
          prev: a.prev
        },
        total: a.totalCount,
        status: a.status,
        memberships: a.data.map(e => w.fromMembershipDTO(this, e, this.user))
      };
    });
  }
  setRestrictions(e, t, s) {
    return n(this, void 0, void 0, function* () {
      const n = `${i}${t}`;
      s.ban || s.mute ? (yield this.sdk.objects.setChannelMembers({
        channel: n,
        uuids: [{
          id: e,
          custom: s
        }]
      }), yield this.emitEvent({
        type: "moderation",
        channel: e,
        payload: {
          channelId: n,
          restriction: s.ban ? "banned" : "muted",
          reason: s.reason
        }
      })) : (yield this.sdk.objects.removeChannelMembers({
        channel: n,
        uuids: [e]
      }), yield this.emitEvent({
        type: "moderation",
        channel: e,
        payload: {
          channelId: n,
          restriction: "lifted",
          reason: s.reason
        }
      }));
    });
  }
}
class N {
  static unixToTimetoken(e) {
    const t = Number(e);
    if (Number.isNaN(t)) throw "The value passed as unixTime is NaN";
    return 1e4 * t;
  }
  static timetokenToUnix(e) {
    const t = Number(e);
    if (Number.isNaN(t)) throw "The value passed as timetoken is NaN";
    return t / 1e4;
  }
  static timetokenToDate(e) {
    return new Date(this.timetokenToUnix(e));
  }
  static dateToTimetoken(e) {
    if (!(e instanceof Date)) throw "The value passed as date is not an instance of Date";
    return this.unixToTimetoken(e.getTime());
  }
}
class E {
  static decrypt({
    chat: e,
    message: t,
    decryptor: n
  }) {
    const s = n(t.content.text);
    return I.fromDTO(e, {
      timetoken: t.timetoken,
      message: s,
      channel: t.channelId,
      publisher: t.userId,
      actions: t.actions || {},
      meta: t.meta,
      error: void 0
    });
  }
}
const R = e.CryptoModule;
export { M as Channel, P as Chat, R as CryptoModule, E as CryptoUtils, a as ERROR_LOGGER_KEY_PREFIX, O as Event, r as INTERNAL_ADMIN_CHANNEL, i as INTERNAL_MODERATION_PREFIX, o as INTERNAL_MODERATOR_DATA, s as MESSAGE_THREAD_ID_PREFIX, w as Membership, I as Message, g as MessageActionType, x as MessageDraft, m as MessageType, j as ThreadChannel, U as ThreadMessage, N as TimetokenUtils, k as User };