import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-contact-info',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './contact-info.component.html',
  styleUrl: './contact-info.component.css'
})
export class ContactInfoComponent {
  @Output() closeDrawerEvent = new EventEmitter<void>();
  @Input({ required: true }) contactDetails!: { email: string; phone: string } | undefined
  @Input({ required: true }) patientName!: string;

  triggerCloseDrawer() {
    this.closeDrawerEvent.emit()
  }
}
