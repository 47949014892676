import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output,OnChanges } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TableActionsDrawerService } from '../../table-actions-drawer.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-payment-info',
  standalone: true,
  imports: [CommonModule, MatTableModule, ReactiveFormsModule],
  providers: [CurrencyPipe],
  templateUrl: './payment-info.component.html',
  styleUrl: './payment-info.component.css'
})
export class PaymentInfoComponent implements OnInit,OnChanges {
  @Output() closeDrawerEvent = new EventEmitter<void>();
  @Input({ required: true }) patientName!: string;
  @Input({required:true}) patientId!: string | number;
  @Input({required:true}) meetingId!: string | number;
  @Input({required:true}) patientBalance!: number;
  @Input({required:true}) patientCopay!: number | string;
  @Output() triggerDataRefresh = new EventEmitter<boolean>(false);
  balance!: string | number;;
  paymentForm: FormGroup;
  constructor(
    private tableActionsDrawerService: TableActionsDrawerService,
    private fb: FormBuilder,
    private currencyPipe: CurrencyPipe
  ) {
    this.paymentForm = this.fb.group({
      copayment: ['', [Validators.required, Validators.min(0)]]
    });
  }

  ngOnChanges(): void {
    const copayValue = parseFloat(this.patientCopay.toString()?.replace('$', '') || '0');
    this.balance = this.currencyPipe.transform(this.patientBalance, 'USD', '', '1.2-2')?.replace('$', '') || '';
    this.patientCopay = copayValue === 0 ? '' : this.patientCopay.toString().replace(/[^\d.]/g, '');
    this.paymentForm.patchValue({
      copayment: this.currencyPipe.transform(this.patientCopay, 'USD', '', '1.2-2')?.replace('$', '') || ''
    }, { emitEvent: false });
  }

  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['amount', 'description', 'method', 'receipt'];
  tableDataArray = [
    {
      amount: '$ 15.00',
      description: 'Copayment amount for todays visit',
      method: 'Credit Card'
    },
    {
      amount: '$ 25.00',
      description: 'Balance from 08/23/2024',
      method: 'Credit Card'
    },
    {
      amount: '$ 55.00',
      description: 'Balance from 10/10/2024',
      method: 'Credit Card'
    }
  ]
  totalAmount: number = 0;
  displayValue!: string | number;

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.tableDataArray)
    this.calculateTotal()
  }
  triggerCloseDrawer() {
    this.closeDrawerEvent.emit()
  }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove all non-digits
    value = value.replace(/[^\d.]/g, '');

    // Ensure only one decimal point
    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }

    // Limit to two decimal places
    if (parts.length > 1) {
      value = parts[0] + '.' + parts[1].slice(0, 2);
    }

    // Update the form control with the numeric value
    this.paymentForm.patchValue({
      copayment: value
    }, { emitEvent: false });
    this.displayValue = value;
  }

  onBlur(): void {
    const value = this.paymentForm.get('copayment')?.value;
    if (value) {
      const numericValue = parseFloat(value);
      this.displayValue = this.currencyPipe.transform(numericValue, 'USD', '', '1.2-2')?.replace('$', '') || '';
    } else {
      this.displayValue = '';
    }
  }

  onUpdatePayment() {
    if (this.paymentForm.valid) {
      const copaymentValue = this.paymentForm.get('copayment')?.value;
      const numericValue = parseFloat(copaymentValue.replace(/[^0-9.]/g, ''));

      this.tableActionsDrawerService.updateCopay('clients/adjust_copay', {
        client_id: this.patientId,
        meeting_id: this.meetingId,
        copay: numericValue
      }).subscribe(response => {
        if (response.status === 200) {
          this.triggerDataRefresh.emit(true)
        }
      }, error => {
        console.log(error)
      });
    }
  }

  calculateTotal() {
    this.totalAmount = this.tableDataArray.reduce((acc, item) => acc + Number(item.amount.split(' ')[1]), 0);
  }
}
