import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatCardModule} from "@angular/material/card";
import {ActivatedRoute} from "@angular/router";
import {Message, MessagesService} from "./messages.service";
import {Observable} from "rxjs";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatListModule} from "@angular/material/list";
import {LoadingMessagesService} from "../../objectPassingService";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {Alert} from "../../dashboard/dashboard.service";


@Component({
  selector: 'app-messages',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatExpansionModule, MatListModule, MatProgressBarModule],
  templateUrl: './messages.component.html',
  styleUrl: './messages.component.css'
})
export class MessagesComponent {

  is_expanded: boolean = true;
  messages$!: Observable<Message[]>

  constructor(
    private route: ActivatedRoute,
    private messagesService: MessagesService,
    public loadingMessagesService: LoadingMessagesService,
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(parms => {
      const user_id = parms['user_id'];
      this.getMessageData(user_id);
    });

  } // end ngOnInit

  getMessageData(user_id: number) {

    const messages$ =  this.messagesService.getMessageByUser('messages', user_id, true);
    this.loadingMessagesService.showLoadingUntilCompleted(messages$).subscribe(
      (data: Message[]) => {
      }
    );
    this.messages$ = messages$.pipe();

  } // end getMessageData


} // end Class
