

<mat-card class="mat-elevation-z24">


  <app-loading></app-loading>

  <mat-card-header>
    <mat-card-title  matBadgeColor="warn" matBadge="{{alert_count}}" matBadgeOverlap="false">Alerts</mat-card-title>

  </mat-card-header>
<!--  <mat-divider></mat-divider>-->
  <ng-container *ngIf="!is_loading">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- Alert Info Column -->
      <ng-container matColumnDef="alert_info">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let row">
          <div class="flex-container">
            <div><span><strong>{{row.name}}</strong></span></div>
            <div><span >{{row.alert_info}}</span></div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"
          (click)="getTableRecord(row)"></tr>
    </table>
  </ng-container>

</mat-card>
