import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { LoginService } from "../login/login.service";
import { ModelValidators } from "../../validators/validators";
import { MatCardModule } from "@angular/material/card";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { InputVerificationCodeComponent } from "../../resusable/input-verification-code/input-verification-code.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { environment } from "../../../environments/environment";
import { Subject, takeUntil } from "rxjs";
import { CurrentLoggedInBehaviorService, CurrentRefreshTokenService, SnackBarService } from "../../objectPassingService";



@Component({
  selector: 'app-mfa',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatToolbarModule, MatProgressBarModule, InputVerificationCodeComponent, MatFormFieldModule, MatButtonModule, ReactiveFormsModule],
  templateUrl: './mfa.component.html',
  styleUrl: './mfa.component.css'
})
export class MfaComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  destroyed = new Subject();

  is_loading = false;
  is_form_error: boolean = false;
  form_error_message: string = "";
  is_auth_error: boolean = false;

  session: string | null = "";
  email: string | null = "";

  myForm: FormGroup = this.fb.group({
    mfa_code: ['', [Validators.required, ModelValidators.validMfa, ModelValidators.cannotContainSpace]]
  });

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private fb: FormBuilder,
    private loginService: LoginService,
    private currentLoggedInBehaviorService: CurrentLoggedInBehaviorService,

  ) { }; //end constructor


  ngOnInit(): void {
    // localStorage.clear()
    this.currentLoggedInBehaviorService.announceCurrentLoggedInBehavior(false);

    this.destroyRef.onDestroy(() => {
      this.destroyed.next('');
      this.destroyed.complete();
    });
  }; // end ngOnInit


  goBack() {
    window.history.back();
  }

  onFocus() {
    // console.log('onFocus')
    // this.is_form_error = false;
    // this.form_error_message = "";
  }

  onSubmit() {

    const query_parms = this.route.snapshot.queryParamMap;
    const session = query_parms.get('session');
    const email = query_parms.get('email');

    this.is_loading = true;
    this.is_form_error = false;
    const data = this.myForm.value;



    const url = `${environment.API_AUTH_URL}/auth/mfa`


    this.loginService.mfa(url, email, session, data['mfa_code']).pipe(takeUntil(this.destroyed)).subscribe(
      res => {

        // @ts-ignore
        if (res.hasOwnProperty('enum') && (res['code'] == 'CodeMismatchException')) {
          this.is_auth_error = true
          this.is_form_error = true;
          this.form_error_message = 'Invalid code received for user' //res['message']
        }
        // @ts-ignore
        if (res.hasOwnProperty('enum') && (res['code'] == 'ExpiredCodeException')) {
          this.is_auth_error = true
          this.is_form_error = true;
          this.form_error_message = 'Your MFA code has expired or has been used already.' //res['message']
        }
        this.is_loading = false;
      },
      errorMessage => {
        this.is_loading = false;
        this.is_auth_error = true;
        this.is_form_error = true;
        this.form_error_message = 'Invalid code received for user' //res['message']
      },
      () => {
        console.log('auth completed')
      }
    ); //end subscribe

  } //end onSubmit


} // end class MfaComponent
