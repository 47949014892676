import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PatientInfoComponent } from "../../../resusable/table-actions-drawer/components/patient-info/patient-info.component";
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

interface Task {
  id: number;
  title: string;
  type: 'call' | 'respond' | 'activate';
  category: string;
  patientId: number;
  readings: number;
  date: string;
  status: string;
  completed: boolean;
  phoneNum: string;
  apptTime: string;
  isRemoving?: boolean;
  nurseName: string;
  completedStatus: string;
  priority: string;
  topic: string;
  mrn: string;
  facility: string;
  intervention: string;
  patientAction: string;
  nurseAction: string;
}

interface TaskGroup {
  category: string;
  tasks: Task[];
  expanded: boolean;
}

@Component({
  selector: 'app-tasks',
  standalone: true,
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatIconModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatTooltipModule,
    MatTableModule,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatSidenavModule,
    PatientInfoComponent,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule
  ],
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css'],
  animations: [
    trigger('taskRemoval', [
      state('removing', style({
        opacity: 0.5,
        textDecoration: 'line-through'
      })),
      transition('* => removing', [
        animate('1s')
      ])
    ])
  ]
})
export class TasksComponent implements OnInit {
  @ViewChild('drawer') drawer!: MatDrawer;
  @ViewChild('editDrawer') editDrawer!: MatDrawer;

  tasks: Task[] = [];
  groupedTasks: boolean = false;
  showCompleted: boolean = false;
  displayedColumns: string[] = ['patientname', 'phoneNum', 'status', 'readings', 'date', 'actions'];
  taskGroups: TaskGroup[] = [];
  filteredTasks: Task[] = [];
  categories: string[] = [];
  selectedPatientId: number | null = null;
  isDrawerOpen: boolean = false;
  isEditDrawerOpen: boolean = false;
  selectedTask: Task | null = null;

  // Reactive form for alert editing
  editAlertForm: FormGroup;
  statuses = ['Open', 'Resolved', 'Ignore'];

  get totalTasks(): number {
    return this.tasks.filter(task => !task.completed).length;
  }

  constructor(private fb: FormBuilder) {
    this.editAlertForm = this.fb.group({
      status: ['Open'],
      intervention: [''],
      patientAction: [''],
      nurseAction: ['']
    });
  }

  ngOnInit() {
    this.tasks = [
      {
        id: 1,
        title: 'John Smith',
        type: 'call',
        category: 'BHI Alerts',
        patientId: 17435,
        readings: 19,
        date: 'Mar 4, 2025',
        status: 'BHI Alerts',
        completed: false,
        phoneNum: '+1 (212) 555-1234',
        apptTime: '08:48 AM',
        nurseName: 'Angelina',
        completedStatus: 'Called the patient',
        priority: 'Critical',
        topic: 'PHQ-9 Question 9 other than "Not at all" (1)',
        mrn: '299541',
        facility: 'Commonwealth Pain & Spine',
        intervention: '',
        patientAction: '',
        nurseAction: ''
      },
      {
        id: 2,
        title: 'Emma Johnson',
        type: 'respond',
        category: 'Make engaging calls',
        patientId: 22956,
        readings: 19,
        date: 'Jan 23, 2025',
        status: 'Make engaging calls',
        completed: true,
        phoneNum: '+1 (415) 555-5678',
        apptTime: '08:48 AM',
        nurseName: 'July',
        completedStatus: 'Called the patient',
        priority: 'Critical',
        topic: 'PHQ-9 Question 9 other than "Not at all" (1)',
        mrn: '297401',
        facility: 'Commonwealth Pain & Spine',
        intervention: '',
        patientAction: '',
        nurseAction: ''
      },
      {
        id: 3,
        title: 'Sarah Davis',
        type: 'activate',
        category: 'Make monthly calls',
        patientId: 22982,
        readings: 19,
        date: 'Jan 16, 2025',
        status: 'Make monthly calls',
        completed: false,
        phoneNum: '+1 (305) 555-9012',
        apptTime: '08:48 AM',
        nurseName: 'April',
        completedStatus: 'Called the patient',
        priority: 'High',
        topic: 'PHQ-9 Question 9 other than "Not at all" (3)',
        mrn: '297480',
        facility: 'Commonwealth Pain & Spine',
        intervention: '',
        patientAction: '',
        nurseAction: ''
      },
      {
        id: 4,
        title: 'Steve Smith',
        type: 'call',
        category: 'Deal with Alerts',
        patientId: 17435,
        readings: 19,
        date: 'Mar 4, 2025',
        status: 'Deal with Alerts',
        completed: false,
        phoneNum: '+1 (212) 555-1234',
        apptTime: '08:48 AM',
        nurseName: 'Angelina',
        completedStatus: 'Called the patient',
        priority: 'Medium',
        topic: 'PHQ-9 Question 9 other than "Not at all" (1)',
        mrn: '299541',
        facility: 'Commonwealth Pain & Spine',
        intervention: '',
        patientAction: '',
        nurseAction: ''
      },
      {
        id: 5,
        title: 'Emma Watson',
        type: 'respond',
        category: 'Respond to patients',
        patientId: 22956,
        readings: 19,
        date: 'Jan 23, 2025',
        status: 'New Message',
        completed: true,
        phoneNum: '+1 (415) 555-5678',
        apptTime: '08:48 AM',
        nurseName: 'July',
        completedStatus: 'Called the patient',
        priority: 'High',
        topic: 'PHQ-9 Question 9 other than "Not at all" (1)',
        mrn: '297401',
        facility: 'Commonwealth Pain & Spine',
        intervention: '',
        patientAction: '',
        nurseAction: ''
      },
      {
        id: 6,
        title: 'Sarah Sanders',
        type: 'activate',
        category: 'Activate pending patients',
        patientId: 22982,
        readings: 19,
        date: 'Jan 16, 2025',
        status: 'Out of range Oximeter',
        completed: false,
        phoneNum: '+1 (305) 555-9012',
        apptTime: '08:48 AM',
        nurseName: 'April',
        completedStatus: 'Called the patient',
        priority: 'Low',
        topic: 'PHQ-9 Question 9 other than "Not at all" (3)',
        mrn: '297480',
        facility: 'Commonwealth Pain & Spine',
        intervention: '',
        patientAction: '',
        nurseAction: ''
      }
    ];
    this.updateCategories()
    this.updateTasksDisplay();
  }

  updateCategories() {
    this.categories = [...new Set(this.tasks.map(task => task.category))];
  }

  toggleExpansion(category: string): void {
    this.taskGroups = this.taskGroups.map((group) =>
      group.category === category ? { ...group, expanded: !group.expanded } : group
    );
  }

  trackByFn(index: number, item: any): any {
    return item.category;
  }

  toggleGrouping() {
    this.groupedTasks = !this.groupedTasks;
    this.updateDisplayColumns();
    this.updateTasksDisplay();
  }

  toggleCompleted() {
    this.showCompleted = !this.showCompleted;
    this.updateDisplayColumns();
    this.updateTasksDisplay();
  }

  updateDisplayColumns() {
    if (this.showCompleted) {
      this.displayedColumns = ['patientname', 'dateTime', 'nursename', 'actionstatus'];
    } else {
      this.displayedColumns = ['patientname', 'phoneNum', 'status', 'readings', 'date', 'actions'];
    }
  }

  updateTasksDisplay() {
    const relevantTasks = this.tasks.filter(task => task.completed === this.showCompleted);

    if (this.groupedTasks) {
      this.taskGroups = this.categories.map(category => ({
        category,
        tasks: relevantTasks.filter(task => task.category === category),
        expanded: true
      })).filter(group => group.tasks.length > 0);
    } else {
      this.taskGroups = [];
      this.filteredTasks = relevantTasks;
    }
  }

  toggleTaskCompletion(task: Task) {
    task.isRemoving = true;
    setTimeout(() => {
      task.completed = !task.completed;
      task.isRemoving = false;
      this.updateTasksDisplay();
    }, 1000);
  }

  onTaskClick(task: Task) {
    switch (task.type) {
      case 'call':
        console.log('Initiating call for:', task.title);
        break;
      case 'respond':
        console.log('Opening response dialog for:', task.title);
        break;
      case 'activate':
        console.log('Activating patient:', task.title);
        break;
    }
  }

  openPatientInfo(task: Task) {
    this.selectedPatientId = task.patientId;
    this.isDrawerOpen = true;
    this.onTaskClick(task);
  }

  openEditDrawer(task: Task) {
    this.selectedTask = task;
    this.editAlertForm.patchValue({
      status: this.editAlertForm.value.status,
      intervention: this.editAlertForm.value.intervention,
      patientAction: this.editAlertForm.value.patientAction,
      nurseAction: this.editAlertForm.value.nurseAction
    });
    this.isEditDrawerOpen = true;
  }

  confirmEdit() {
    if (this.selectedTask && this.editAlertForm.valid) {
      const formValues = this.editAlertForm.value;
      console.log('Form Values:', formValues);
      // Mock service call (replace with actual API call)
      this.selectedTask.status = formValues.status || this.selectedTask.status;
      this.selectedTask.intervention = formValues.intervention || this.selectedTask.intervention;
      this.selectedTask.patientAction = formValues.patientAction || this.selectedTask.patientAction;
      this.selectedTask.nurseAction = formValues.nurseAction || this.selectedTask.nurseAction;
      this.closeEditDrawer();
    }
  }

  cancelEdit() {
    this.editAlertForm.reset({
      status: 'Open',
      intervention: '',
      patientAction: '',
      nurseAction: ''
    });
    this.closeEditDrawer();
  }

  closeEditDrawer() {
    this.editAlertForm.reset({
      status: 'Open',
      intervention: '',
      patientAction: '',
      nurseAction: ''
    }); // Reset to initial values on close
    this.isEditDrawerOpen = false;
    this.selectedTask = null;
  }

  closeDrawer() {
    this.isDrawerOpen = false;
    this.selectedPatientId = null;
  }

  getPriorityColor(priority: string): string {
    switch (priority.toLowerCase()) {
      case 'critical':
        return 'var(--dangerous)';
      case 'high':
        return 'var(--warning-shade-1)';
      case 'medium':
        return 'var(--warning)';
      case 'low':
        return 'var(--safe)';
      default:
        return 'var(--safe)';
    }
  }
}
