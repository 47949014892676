import {Injectable, NgZone} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Observable, retry, tap} from "rxjs";
import {CurrentLoggedInBehaviorService, CurrentRefreshTokenService, SuperAdminViewBehaviorService} from "../../objectPassingService";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {CampaignInterface} from "../adserver.interface";



@Injectable({providedIn: 'root'})
export class CampaignService {

  url:string = `${environment.API_URL}`

  constructor(
    private http: HttpClient,
    public router: Router,
    private currentLoggedInBehaviorService: CurrentLoggedInBehaviorService,
    private currentRefreshTokenService: CurrentRefreshTokenService,
    private superAdminViewBehaviorService: SuperAdminViewBehaviorService,
    public ngZone: NgZone,
  ) {}


  get(endpoint: string, is_mocked: boolean = false ): Observable<any> {
    const url = `${this.url}/${endpoint}`;
    return this.http.get<any>(url);
  } //end getAlerts

  post(endpoint: string, data: CampaignInterface){
    return this.http.post(`${this.url}/${endpoint}`, data,{observe: 'response'});
  }

  put(endpoint: string, data: CampaignInterface){
    return this.http.put(`${this.url}/${endpoint}`, data,{observe: 'response'});
  }

  delete(endpoint: string): Observable<any> {
    const url = `${this.url}/${endpoint}`;
    return this.http.delete<any>(url);
  } //end getAlerts


}
