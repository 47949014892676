import { Component, DestroyRef, inject, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { CelTestService } from "../cel-test/cel-test.service";
import { CelDetailsTestService } from "./cel-details-test.service";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { FormsModule } from "@angular/forms";





export interface FormUsersInterfaceData {
  patient_id: number;
  full_name: string;
  created_at: string;
  updated_at: string;
  status: string;
}




@Component({
  selector: 'app-cel-details-test',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule, MatButtonModule, MatCardModule, MatDividerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule, MatPaginatorModule, MatSortModule, MatTableModule, FormsModule],
  templateUrl: './cel-details-test.component.html',
  styleUrl: './cel-details-test.component.css'
})
export class CelDetailsTestComponent implements OnInit {

  searchText: string = '';
  is_loading = true;
  private destroyRef = inject(DestroyRef);
  destroyed_get_cal = new Subject();

  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['patient_id', 'full_name', 'created_at', 'updated_at', 'status'];

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild('input') inputElement!: ElementRef;

  constructor(public router: Router,
    private route: ActivatedRoute,
    private celDetailsTestService: CelDetailsTestService
  ) { }



  ngOnInit(): void {

    this.getEvents();
  } // end ngOnInit

  getEvents() {

    this.celDetailsTestService.getCalendar('calendar/details', false).pipe(takeUntil(this.destroyed_get_cal)).subscribe(
      (data) => {
        console.log('data', data['message']);

        let meta = data['message'];

        this.dataSource = new MatTableDataSource(meta);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.is_loading = false;
        this.sort.start = 'desc'; // or 'asc' for ascending order
        this.sort.active = 'created_at'; // replace 'created_at' with the column you want to sort by default

        this.is_loading = false;
        //this.loadEvents(data);
      },
      (error) => {
        console.log('error', error);
        this.is_loading = false;
      }
    ) // end of subscribe



  } // end getEvents

  getTableRecord(row: object) {
    console.log('getTableRecord', row);
  } //end getTableRecord

  applyFilter(event: Event | string) {
    const filterValue = typeof event === 'string' ? event : (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }//end applyFilter
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.code === 'Space') {
      event.preventDefault();
      this.inputElement.nativeElement.focus();
    }
  }
  clearSearch(input: HTMLInputElement) {
    this.searchText = '';
    input.value = '';
    this.applyFilter('');
  }
  getColor(value: any): string {
    console.log('value', value);
    if (value == 'Completed') {
      return 'green';
    } else if (value == 'In Progress') {
      return '#DAA520';
    } else {
      return 'red';
    }
  }










} // end class CelDetailsTestComponent
