<div>
  <div class="contact-info-header">
    <div>
      Contact info - {{patientName}}
    </div>
    <i [ngClass]="'healent-logout'" style="color: var(--secondary_shade_50); cursor: pointer;" (click)="triggerCloseDrawer()"></i>
  </div>
  <div class="contact-info-card" style="border-bottom: 1px solid var(--secondary_shade_50); padding-bottom: 30px;">
    <div class="contact-header-section">
      <i [ngClass]="'healent-path-3625'"></i>
      <div>
        <h2>Phone Numbers</h2>
        <div class="contact-description">List of patients phone numbers</div>
      </div>
    </div>
    <div class="contact-body-description">
      <h4>Primary</h4>
      <h4 class="email-mobile-info">{{contactDetails?.phone || '-'}}</h4>
    </div>
  </div>
  <div class="contact-info-card">
    <div class="contact-header-section">
      <i [ngClass]="'healent-group-4610'"></i>
      <div>
        <h2>Email address</h2>
        <div class="contact-description">List of patients Email address</div>
      </div>
    </div>
    <div class="contact-body-description">
      <h4>Primary</h4>
      <h4 class="email-mobile-info">{{contactDetails?.email}}</h4>
    </div>
  </div>
</div>
