<div class="form-section">
  <!-- Date Picker -->
  <div class="form-field">
    <label>Start date:</label>
    <mat-form-field appearance="fill">
      <input matInput [matDatepicker]="picker" [formControl]="dateControl" (input)="onManualDateInput($event)" pattern="^[0-9/]*$">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="dateError">{{dateError}}</mat-error>
    </mat-form-field>
  </div>

  <!-- Time Picker -->
  <!-- Time Picker -->
<div class="form-field">
  <label>Start time:</label>
  <div class="time-input-container">
    <mat-form-field appearance="outline">
      <input
        type="time"
        matInput
        #timeInputDecorator
        [(ngModel)]="timeInput"
        (keydown)="onTimeKeydown($event)"
        (input)="onManualTimeInput($event)"
        placeholder="HH:MM AM/PM">
      <mat-icon
        matSuffix
        class="time-icon"
        (click)="toggleTimePicker($event)"
        style="cursor: pointer;">schedule</mat-icon>
      <mat-error *ngIf="timeError">{{timeError}}</mat-error>
    </mat-form-field>

    <div #timePicker class="time-picker-dropdown" *ngIf="showTimePicker">
      <div class="time-columns">
        <div class="time-column">
          <div *ngFor="let h of hours" [class.selected]="selectedTime.hour === h" (click)="selectHour(h)">
            {{h}}
          </div>
        </div>
        <div class="time-column">
          <div *ngFor="let m of minutes" [class.selected]="selectedTime.minute === m" (click)="selectMinute(m)">
            {{m}}
          </div>
        </div>
        <div class="time-column period">
          <div [class.selected]="selectedTime.period === 'AM'" (click)="selectPeriod('AM')">
            AM
          </div>
          <div [class.selected]="selectedTime.period === 'PM'" (click)="selectPeriod('PM')">
            PM
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  <!-- Duration -->
  <div class="form-field">
    <label>Duration, minutes:</label>
    <mat-form-field appearance="outline" class="duration-field">
      <input matInput type="text" [ngModel]="duration" (ngModelChange)="onDurationChange($event)" (keypress)="numberOnly($event)" placeholder="Duration in minutes" min="0">
      <div class="number-controls" matSuffix>
        <i [ngClass]="'healent-up'" class="duration-icon" (click)="incrementDuration()"></i>
        <i [ngClass]="'healent-down'" class="duration-icon" (click)="decrementDuration()"></i>
      </div>
    </mat-form-field>
  </div>

  <!-- Notes Text Area -->
  <div class="form-field">
    <label>Appointment Notes</label>
    <mat-form-field appearance="outline">
      <textarea matInput [(ngModel)]="notes" (ngModelChange)="onNotesChange($event)" style="height: 330px;"></textarea>
    </mat-form-field>
  </div>

  <!-- Dynamic Action Buttons -->
  <div class="action-buttons">
    <div class="group-1">
      <button
        *ngFor="let button of buttonConfig"
        class="action-button"
        [ngClass]="button.customClass"
        (click)="handleButtonAction(button.action)"
      >
        {{ button.label }}
      </button>
    </div>
  </div>
</div>
