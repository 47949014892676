import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatCardModule} from "@angular/material/card";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatListModule} from "@angular/material/list";
import {Observable} from "rxjs";
import {Alert} from "../../dashboard/dashboard.service";
import {GroupTasksService, MakeEngagingCallsBehaviorService} from "../../dashboardPassingService";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {LoadingComponent} from "../../resusable/loading/loading.component";

@Component({
  selector: 'app-make-monthly-calls',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatExpansionModule, MatListModule, LoadingComponent],
  templateUrl: './make-monthly-calls.component.html',
  styleUrl: './make-monthly-calls.component.css'
})
export class MakeMonthlyCallsComponent {



  is_loading:boolean = false;
  is_expanded:boolean = true;
  is_task_group_enabled:boolean = true;

  alerts$!: Observable<Alert[]>

  constructor(
    private makeEngagingCallsBehaviorService: MakeEngagingCallsBehaviorService,
    private groupTasksService: GroupTasksService,
  ) {
    this.groupTasksService.GroupTasksBehaviorAnnounced$.pipe(takeUntilDestroyed()).subscribe(
      (data: boolean) => {
        this.is_task_group_enabled = data;
      },
    )

  } // constructor


  ngOnInit(): void {


    const alerts$= this.makeEngagingCallsBehaviorService.currentMakeEngagingCallsBehaviorAnnounced$

    this.alerts$ = alerts$.pipe() // end pipe
  } // ngOnInit



}
