
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, finalize, of, tap, concatMap } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class CurrentLoggedInBehaviorService {

  constructor() {
  }

  private currentLoggedInAnnouncedBehaviorSource = new BehaviorSubject<boolean>(false);
  // Observable string streams
  currentLoggedInBehaviorAnnounced$ = this.currentLoggedInAnnouncedBehaviorSource.asObservable();

  // Service message commands
  announceCurrentLoggedInBehavior(current: boolean) {
    this.currentLoggedInAnnouncedBehaviorSource.next(current);
  }
}
@Injectable({ providedIn: 'root' })
export class SuperAdminViewBehaviorService {

  private readonly superAdminViewKey = 'superadmin_view'; // Key for sessionStorage
  private currentSuperAdminViewAnnouncedBehaviorSource: BehaviorSubject<boolean>;

  // Observable string stream
  currentSuperAdminViewBehaviorAnnounced$!: Observable<boolean>;

  constructor() {
    const storedValue = sessionStorage.getItem(this.superAdminViewKey);
    const initialValue = storedValue !== null ? JSON.parse(storedValue) : false;

    this.currentSuperAdminViewAnnouncedBehaviorSource = new BehaviorSubject<boolean>(initialValue);
    this.currentSuperAdminViewBehaviorAnnounced$ = this.currentSuperAdminViewAnnouncedBehaviorSource.asObservable();
  }

  // Service message commands
  announceSuperAdminViewBehavior(current: boolean) {
    sessionStorage.setItem(this.superAdminViewKey, JSON.stringify(current));
    this.currentSuperAdminViewAnnouncedBehaviorSource.next(current);
  }
}



@Injectable({ providedIn: 'root' })
export class CurrentRefreshTokenService {

  constructor() { }

  private currentRefreshTokenAnnouncedSource = new Subject<boolean>();
  // Observable string streams
  currentRefreshTokenAnnounced$ = this.currentRefreshTokenAnnouncedSource.asObservable();
  // Service message commands
  announceRefreshToken(is_logged_in: boolean) {
    this.currentRefreshTokenAnnouncedSource.next(is_logged_in);
  }

}


@Injectable({ providedIn: 'root' })
export class SnackBarService {

  constructor() { }

  private snackbarAnnouncedSource = new Subject<string>();
  // Observable string streams
  snackbarAnnounced$ = this.snackbarAnnouncedSource.asObservable();
  // Service message commands
  announceSackBar(snackbar: string) {
    this.snackbarAnnouncedSource.next(snackbar);
  }

}


@Injectable({ providedIn: 'root' })
export class LoadingService {

  /*
  https://www.youtube.com/watch?v=czf4Bh8eNPA
  concantMap is super cool
   */

  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  showLoadingUntilCompleted<T>(obs$: Observable<T>): Observable<T> {  // <T> is a generic type
    return of(null).pipe(
      tap(() => this.loadingOn()),
      concatMap(() => obs$),
      finalize(() => this.loadingOff())

    )
  }

  loadingOn() {
    this.loadingSubject.next(true);

  }

  loadingOff() {
    this.loadingSubject.next(false);

  }

} // end LoadingService


@Injectable({ providedIn: 'root' })
export class LoadingMessagesService {

  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  showLoadingUntilCompleted<T>(obs$: Observable<T>): Observable<T> {  // <T> is a generic type
    return of(null).pipe(
      tap(() => this.loadingOn()),
      concatMap(() => obs$),
      finalize(() => this.loadingOff())

    )
  }

  loadingOn() {
    this.loadingSubject.next(true);

  }

  loadingOff() {
    this.loadingSubject.next(false);

  }

} // end LoadingService


@Injectable({ providedIn: 'root' })
export class LoadingCalendarService {

  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  showLoadingUntilCompleted<T>(obs$: Observable<T>): Observable<T> {  // <T> is a generic type
    return of(null).pipe(
      tap(() => this.loadingOn()),
      concatMap(() => obs$),
      finalize(() => this.loadingOff())

    )
  }

  loadingOn() {
    this.loadingSubject.next(true);

  }

  loadingOff() {
    this.loadingSubject.next(false);

  }

} // end LoadingService


@Injectable({ providedIn: 'root' })
export class CurrentClientIdSelectService {

  constructor() { }

  private currentClientIdSelectAnnouncedSource = new BehaviorSubject<string>('');
  // Observable string streams
  currentClientIdSelectAnnounced$ = this.currentClientIdSelectAnnouncedSource.asObservable();
  // Service message commands
  announceClientIdSelect(client_id: string) {
    this.currentClientIdSelectAnnouncedSource.next(client_id);
  }
}

@Injectable({ providedIn: 'root' })
export class CurrentClientSlugSelectService {

  constructor() { }

  private currentClientSlugSelectAnnouncedSource = new BehaviorSubject<string>('');
  // Observable string streams
  currentClientSlugSelectAnnounced$ = this.currentClientSlugSelectAnnouncedSource.asObservable();
  // Service message commands
  announceClientSlugSelect(client_id: string) {
    this.currentClientSlugSelectAnnouncedSource.next(client_id);
  }
}


/*
* ****************CAMPAIGN RELATED SERVICES****************
*
* */

@Injectable({ providedIn: 'root' })
export class CurrentCampaignGroupSelectService {

  constructor() { }

  private currentCampaignGroupSelectAnnouncedSource = new BehaviorSubject<string>('');
  // Observable string streams
  currentCampaignGroupSelectAnnounced$ = this.currentCampaignGroupSelectAnnouncedSource.asObservable();
  // Service message commands
  announceCampaignGroupSelect(client_id: string) {
    this.currentCampaignGroupSelectAnnouncedSource.next(client_id);
  }
}

@Injectable({ providedIn: 'root' })
export class CurrentCampaignSelectService {

  constructor() { }

  private currentCampaignSelectAnnouncedSource = new BehaviorSubject<string>('');
  // Observable string streams
  currentCampaignSelectAnnounced$ = this.currentCampaignSelectAnnouncedSource.asObservable();
  // Service message commands
  announceCampaignSelect(client_id: string) {
    this.currentCampaignSelectAnnouncedSource.next(client_id);
  }
}

@Injectable({ providedIn: 'root' })
export class CurrentAdgroupSelectService {

  constructor() { }

  private currentAdgroupSelectAnnouncedSource = new BehaviorSubject<string>('');
  // Observable string streams
  currentAdgroupSelectAnnounced$ = this.currentAdgroupSelectAnnouncedSource.asObservable();
  // Service message commands
  announceAdgroupSelect(client_id: string) {
    this.currentAdgroupSelectAnnouncedSource.next(client_id);
  }
}


@Injectable({ providedIn: 'root' })
export class CurrentAdSelectService {

  constructor() { }

  private currentAdSelectAnnouncedSource = new BehaviorSubject<string>('');
  // Observable string streams
  currentAdSelectAnnounced$ = this.currentAdSelectAnnouncedSource.asObservable();
  // Service message commands
  announceAdSelect(client_id: string) {
    this.currentAdSelectAnnouncedSource.next(client_id);
  }
}









