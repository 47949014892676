import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { TableActionsDrawerService } from '../../table-actions-drawer.service';

export interface Alert {
  type: string;
  time: string;
  documentName?: string;
  errorDetails?: string;
  alert_id: string;
  ignored: boolean;
  meetingId: number
}

@Component({
  selector: 'app-alerts',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatIconModule],
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnChanges {
  @Input() alerts: Alert[] = [];
  @Output() alertStatusChanged = new EventEmitter<Alert[]>();

  displayedColumns: string[] = ['type', 'description', 'time', 'ignore'];

  constructor (private tableDrawerService: TableActionsDrawerService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['alerts']) {
      this.alerts.sort((a,b) => b.time.localeCompare(a.time))
    }
  }

  onIgnore(alert: Alert, event: Event): void {
    event.stopPropagation();
    const newIgnoredState = !alert.ignored;

    this.tableDrawerService.toggleAlertStatus({ alert_id: alert.alert_id, meeting_id: alert.meetingId, is_ignored: !alert.ignored }).subscribe({
      next: (response) => {
        if (response.status === 200) {
          alert.ignored = newIgnoredState;
          this.alertStatusChanged.emit(this.alerts);
        }
      },
      error: (error) => {
        console.error('Error updating alert status:', error);
      }
    });
  }

  getAlertType(type: string): string {
    if (type === 'check-in') {
      return 'Check-in error'
    } else if (type === 'payment') {
      return 'Payment failure'
    } else if (type === 'document') {
      return 'Document failure'
    } else if (type === 'consent') {
      return 'Consent unsigned'
    } else {
      return 'Failure'
    }
  }

  getIgnoreButtonText(alert: Alert): string {
    return alert.ignored ? 'Undo-Ignore' : 'Ignore';
  }

  get alertsCount(): number {
    return this.alerts.filter(alert => !alert.ignored).length;
  }
}
