

<!--<mat-card>-->
<!--  <mat-list>-->
<!--    <div mat-subheader>Make Engaging Calls</div>-->
<!--    <ng-container *ngFor="let alert of (alerts$ | async)">-->
<!--      <mat-list-item>-->
<!--        <div matListItemTitle>{{alert.alert_info}}</div>-->
<!--        <div matListItemLine>{{alert.last_call_date | date}}</div>-->
<!--      </mat-list-item>-->
<!--    </ng-container>-->

<!--  </mat-list>-->
<!--</mat-card>-->








<!--<div *ngIf="(alerts$ | async) as alerts; else loading">-->

<!--<mat-progress-bar color="primary" mode="indeterminate" *ngIf="is_loading"></mat-progress-bar>-->

<app-loading></app-loading>

<ng-container *ngIf="is_task_group_enabled">
  <mat-accordion>
    <mat-expansion-panel [expanded]="is_expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Make Engaging Calls
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-list>
        <div mat-subheader></div>
        <ng-container *ngFor="let alert of (alerts$ | async)">
          <mat-list-item>
            <div matListItemTitle>{{alert.alert_info}}</div>
            <div matListItemLine>{{alert.last_call_date | date}}</div>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </mat-expansion-panel>

  </mat-accordion>
</ng-container>












<!--</div>-->
<!--<ng-template #loading>-->
<!--  <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>-->
<!--</ng-template>-->
