import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {CommonModule, NgForOf, NgIf} from '@angular/common';
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {CampaignTableInterfaceData, DialogAddCampaign} from "../campaigns/campaigns.component";
import {Router} from "@angular/router";
import {
  CurrentAdgroupSelectService, CurrentAdSelectService,
  CurrentCampaignGroupSelectService,
  CurrentCampaignSelectService,
  CurrentClientIdSelectService,
  CurrentClientSlugSelectService, SnackBarService
} from "../../objectPassingService";
import {CampaignService} from "../campaigns/campaign.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AdService} from "./ad.service";
import {ClientInterfaceData} from "../campaign-groups/campaign-groups.component";
import {MatCardModule} from "@angular/material/card";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {AdInterface, SelectInterface} from "../adserver.interface";
import {AdgroupsService} from "../adgroups/adgroups.service";
import {MatDividerModule} from "@angular/material/divider";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatTooltipModule} from "@angular/material/tooltip";
import {forkJoin, take} from "rxjs";



export interface  AdTableInterfaceData {
  ad_id: string,
  status: string,
  name: string,
  description: string,
}


@Component({
  selector: 'app-ads',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatCardModule, MatDividerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule, MatPaginatorModule, MatProgressBarModule, MatSortModule, MatTableModule, MatTooltipModule],
  templateUrl: './ads.component.html',
  styleUrl: './ads.component.css'
})
export class AdsComponent implements OnInit {

  client_id: string = '';
  client_slug: string = '';
  campaign_group_id: string = '';
  campaign_id: string = '';
  adgroup_id: string = '';
  is_loading: boolean = true;

  dataSource!: MatTableDataSource<AdTableInterfaceData>;
  displayedColumns: string[] = ['ad_id', 'status', 'name', 'description', 'Edit', 'Delete'];
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  constructor(
    private router: Router,

    private currentClientIdSelectService: CurrentClientIdSelectService,
    private currentClientSlugSelectService: CurrentClientSlugSelectService,
    private currentCampaignGroupSelectService: CurrentCampaignGroupSelectService,
    private currentCampaignSelectService: CurrentCampaignSelectService,
    private currentAdgroupSelectService: CurrentAdgroupSelectService,
    private currentAdSelectService: CurrentAdSelectService,

    private adService: AdService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {

    if (localStorage.getItem('client_id')) {
      this.client_id = localStorage.getItem('client_id') || '';
      this.currentClientIdSelectService.announceClientIdSelect(this.client_id);
    }
    if (localStorage.getItem('client_slug')) {
      this.client_slug = localStorage.getItem('client_slug') || '';
      this.currentClientSlugSelectService.announceClientSlugSelect(this.client_slug);
    }
    if (localStorage.getItem('campaign_group_id')) {
      this.campaign_group_id = localStorage.getItem('campaign_group_id') || '';
      this.currentCampaignGroupSelectService.announceCampaignGroupSelect(this.campaign_group_id);
    }
    if (localStorage.getItem('campaign_id')) {
      this.campaign_id = localStorage.getItem('campaign_id') || '';
      this.currentCampaignSelectService.announceCampaignSelect(this.campaign_id);
    }
    if (localStorage.getItem('adgroup_id')) {
      this.adgroup_id = localStorage.getItem('adgroup_id') || '';
      this.currentAdgroupSelectService.announceAdgroupSelect(this.adgroup_id);
    }

    let currentClientIdSelectService$ = this.currentClientIdSelectService.currentClientIdSelectAnnounced$.pipe(take(1));
    let currentClientSlugSelectService$ = this.currentClientSlugSelectService.currentClientSlugSelectAnnounced$.pipe(take(1));
    let currentCampaignGroupSelectService$ = this.currentCampaignGroupSelectService.currentCampaignGroupSelectAnnounced$.pipe(take(1));
    let currentCampaignSelectService$ = this.currentCampaignSelectService.currentCampaignSelectAnnounced$.pipe(take(1));
    let currentAdgroupSelectService$ = this.currentAdgroupSelectService.currentAdgroupSelectAnnounced$.pipe(take(1));

    forkJoin([currentClientIdSelectService$, currentClientSlugSelectService$, currentCampaignGroupSelectService$, currentCampaignSelectService$, currentAdgroupSelectService$]).subscribe({
      next: ([client_id, client_slug, campaign_group_id, campaign_id, adgroup_id]) => {
        this.client_id = client_id;
        this.client_slug = client_slug;
        this.campaign_group_id = campaign_group_id;
        this.campaign_id = campaign_id;
        this.adgroup_id = adgroup_id;
        console.log('client_id', client_id);
        console.log('client_slug', client_slug);
        console.log('campaign_group_id', campaign_group_id);
        console.log('campaign_id', campaign_id);
        console.log('adgroup_id', adgroup_id);
        if (this.adgroup_id) {
          this.getData();
        } else {
          this.is_loading = false;
          console.log('no client_id')
        }
      },
      error: (error) => {
        // Handle any error that occurs in any Observable
        console.error('An error occurred:', error);
        this.is_loading = false;
      },
      complete: () => {
        // This is called when all observables complete
        console.log('All observables completed');
      }
    });
  } // end of ngOnInit

  getData(){

    this.is_loading = true;
    let endpoint = `adserver/ad/all/${this.adgroup_id}`;
    this.adService.get(endpoint).subscribe((data: any) => {
        console.log('ad data', data);
        data = data['message'];
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.is_loading = false;
        this.is_loading = false;
      },
      (error) => {
        console.log('campaign_group error', error);
        this.is_loading = false;
      });
  } // end getData

  dialogRefAddAd!: MatDialogRef<DialogAddAd>;
  addAd(is_edit:boolean, row: object) {
    console.log('addAd',row);
    let row_data: object = {}
    if (is_edit == true) {
      row_data = row;
    }

    // @ts-ignore
    row_data['client_id'] = this.client_id;
    // @ts-ignore
    row_data['client_slug'] = this.client_slug;
    // @ts-ignore
    row_data['campaign_group_id'] = this.campaign_group_id;
    // @ts-ignore
    row_data['campaign_id'] = this.campaign_id;
    // @ts-ignore
    row_data['adgroup_id'] = this.adgroup_id;


    var enterAnimationDuration = '0ms';
    var exitAnimationDuration = '0ms';


    // @ts-ignore
    let dialogRef = this.dialog.open(DialogAddAd, {
      width: '450px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: row_data
    });


    dialogRef.afterClosed().subscribe(result => {

      if (result == 'cancel' || result == undefined) {
        console.log('we cancled')
      } else {
        this.getData();
      }
    });//end dialogRef


  } // end addClient


  getTableRecord(row: object){
    console.log('getTableRecord', row);
  } //end getTableRecord


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  } //end applyFilter


  viewData(row: CampaignTableInterfaceData) {
    // console.log('viewData', row);
    // const campaign_id = row['campaign_id']
    //
    // localStorage.setItem('campaign_id', campaign_id);
    // this.currentCampaignSelectService.announceCampaignSelect(campaign_id);
    //
    // //this.superAdminViewBehaviorService.announceSuperAdminViewBehavior(false);
    // this.router.navigate(['/adserver-nav',  { outlets: { admeta: ['adgroups'] } }]).then(r => console.log('redirect to campaigns'));
  } //end viewData(row)


  deleteRow(row: AdTableInterfaceData){
    console.log('deleteRow', row);

    const ad_id = row['ad_id']
    console.log('deleteRow', ad_id);


    this.adService.delete(`adserver/ad/${ad_id}`).subscribe(x => {
      //this.snackBarService.announceSackBar('Campaign Group Deleted');
      console.log(x)
      this.getData();
    });



  } //end deleteRow

} // end of AdsComponent class






/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/

/*---------------------------------------------
Dialog for adding a new client
---------------------------------------------*/
@Component({
  selector: 'add-ad-dialog',
  templateUrl: 'add-ad-dialog.html',
  imports: [
    MatCardModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    NgIf,
    MatOptionModule,
    MatSelectModule,
    NgForOf
  ],
  standalone: true
})
export class DialogAddAd implements OnInit{


  myForm!: FormGroup;
  is_form_error: boolean = false;
  form_error_message: string = "";
  is_loading = false;
  is_edit = false;
  title_text = "Create Ad";
  submit_text = "Create"
  client_id = "";
  client_slug = "";
  campaign_group_id = "";
  campaign_id = "";
  adgroup_id = "";
  ad_id = "";

  constructor(private fb: FormBuilder,
              public dialogRef: MatDialogRef<DialogAddCampaign>,
              private adService: AdService,
              private snackBarService: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public data: AdInterface) {}

  ngOnInit(): void {


    this.client_id = this.data['client_id'] || '';
    this.client_slug = this.data['client_slug'] || '';
    this.campaign_group_id = this.data['campaign_group_id'] || '';
    this.campaign_id = this.data['campaign_id'] || '';
    this.adgroup_id = this.data['adgroup_id'] || '';
    this.ad_id = this.data['ad_id'] || '';



    console.log('DialogAddAd',this.data)
    let name = '';
    let description = '';
    let status = 'active';
    let text = '';


    console.log('data@@##@@@',this.data)
    if (Object.keys(this.data).length==5) {
      console.log('we have empty data')
      this.is_edit = false;
    } else {
      name = this.data['name'];
      description = this.data['description'];
      status = this.data['status'];
      text = this.data['text'];

      this.is_edit = true;
      this.title_text = "Edit Campaign";
      this.submit_text = "Update"
    }

    this.myForm = this.fb.group({
      name: [name, [Validators.required]],
      description: [description, [Validators.required]],
      status: [status],
      text: [text],
    });

  } //end ngOnInit

  onFocus(){
    console.log('onFocus')
    this.is_form_error = false;
    this.form_error_message = "";
  }

  onCancel() {
    this.dialogRef.close('cancel');
  }

  onSubmit() {
    this.is_loading = true;
    let form_data = this.myForm.value;
    console.log(form_data)

    if (!this.is_edit) {
      this.adService.post(`adserver/ad/create/${this.campaign_group_id}/${this.campaign_id}/${this.adgroup_id}/${this.client_id}`, form_data).subscribe(x => {
        //this.snackBarService.announceSackBar('Client Created');
        this.dialogRef.close(form_data);
      }, error => {
        console.log('error',error)
        this.dialogRef.close('cancel');

      });

    } else {

      this.adService.put(`adserver/ad/${this.ad_id}`, form_data).subscribe(x => {
        //this.snackBarService.announceSackBar('Client Updated');
        this.dialogRef.close(form_data);
      }, error => {
        console.log('error',error)
        this.dialogRef.close('cancel');
      });
    }



  } //end onSubmit


} //end DialogAddClient


