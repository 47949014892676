import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SaveFilterDialogComponent } from './filter-dialog-component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HospitalLocationUserInterface } from '../../registration/registration.interface';

interface FilterOption {
  id: string;
  name: string;
  selected?: boolean;
}

interface FilterCategory {
  icon: string;
  label: string;
  count: number;
  options: FilterOption[];
  isOpen?: boolean;
}

interface FilterSelections {
  [key: string]: { [category: string]: string[] };
}

interface UniqueCategories {
  [key: string]: string[];
}

@Component({
  selector: 'app-filter-bar',
  standalone: true,
  imports: [CommonModule, FormsModule, MatDialogModule, MatButtonModule, MatIconModule, MatMenuModule, MatFormFieldModule, MatCheckboxModule],
  templateUrl: './filters-bar.component.html',
  styleUrls: ['./filters-bar.component.css']
})
export class FiltersComponentComponent implements OnInit, OnChanges {
  console = console;
  searchText: string = '';
  filterName: string = '';
  savedFilters: string[] = [];
  isSavedFiltersOpen: boolean = false;
  selectedFiltersMap = new Map<string, string>();
  mapEntriesArray!: [string, string][];

  @Input({ required: true }) userData!: HospitalLocationUserInterface[];
  @Output() filterChanged = new EventEmitter<Array<[string, string]>>();
  @Input() dateType: string = '';
  @Output() savedFilterApplied = new EventEmitter<any>();

  // Clear all filters when location is changed
  @Input() set resetFilters(value: boolean) {
    if (value) {
      this.clearAll();
    }
  }

  uniqueCategories: UniqueCategories = {
    alerts: [],
    surgeon_name: [],
    appointment_type: [],
    insurance_name: [],
    status: [],
  };

  savedFilterSelections: FilterSelections = {};

  filterCategories: Record<string, FilterCategory> = {
    surgeon_name: {
      icon: "healent-provider",
      label: "By Provider",
      count: 0,
      options: [],
    },
    appointment_type: {
      icon: "healent-tag-1",
      label: "By Appt Type",
      count: 0,
      options: [],
    },
    insurance_name: {
      icon: "healent-insurance",
      label: "By Insurance",
      count: 0,
      options: [],
    },
    status: {
      icon: "healent-status",
      label: "By Form Status",
      count: 0,
      options: [],
    },
  };

  hoveredCategory: string = '';
  searchTexts: { [key: string]: string } = {};
  hasProcessedInitialChange: boolean = false;
  currentFilterLabel: string = 'Filters';
  originalFilterState: any = null;
  previousUserData: any[] = [];

  constructor(private dialog: MatDialog) {}

  private getEmailId(): string {
    const userEmail = localStorage.getItem('email');
    if (!userEmail) {
      throw new Error('User email not found in localStorage');
    }
    return userEmail;
  }

  private getStorageKey(suffix: string): string {
    const userId = this.getEmailId();
    return `filters_${userId}_${suffix}`;
  }

  private loadUserFilters(): void {
    try {
      const userId = this.getEmailId();
      const savedFiltersData = localStorage.getItem(`filters_${userId}`);
      if (savedFiltersData) {
        const parsedData = JSON.parse(savedFiltersData);
        this.savedFilters = Object.keys(parsedData);
        this.savedFilterSelections = parsedData;
      }
    } catch (error) {
      console.error('Error loading user filters:', error);
    }
  }

  private saveUserFilters(): void {
    try {
      const userId = this.getEmailId();
      localStorage.setItem(`filters_${userId}`, JSON.stringify(this.savedFilterSelections));
    } catch (error) {
      console.error('Error saving user filters:', error);
    }
  }

  private saveCurrentSelections(): void {
    const currentSelections = this.getSelectedFilters();
    localStorage.setItem(this.getStorageKey('current'), JSON.stringify(currentSelections));
    localStorage.setItem(this.getStorageKey('label'), this.currentFilterLabel);
  }

  private loadCurrentSelections(): void {
    const currentSelectionsData = localStorage.getItem(this.getStorageKey('current'));
    const savedLabel = localStorage.getItem(this.getStorageKey('label'));
    if (currentSelectionsData) {
      const currentSelections = JSON.parse(currentSelectionsData);
      this.applySelections(currentSelections, savedLabel || 'Filters');
    }
  }

  private applySelections(selections: any, label: string): void {
    this.currentFilterLabel = label;
    this.originalFilterState = JSON.parse(JSON.stringify(selections));

    // Clear current selections
    Object.keys(this.filterCategories).forEach(categoryKey => {
      this.filterCategories[categoryKey].options.forEach(option => {
        option.selected = false;
      });
      this.filterCategories[categoryKey].count = 0;
    });
    this.selectedFiltersMap.clear();

    // Apply selections
    Object.entries(selections).forEach(([category, values]) => {
      if (Array.isArray(values) && values.length > 0) {
        values.forEach(value => {
          const option = this.filterCategories[category].options.find(opt => opt.id === value);
          if (option) {
            option.selected = true;
            this.selectedFiltersMap.set(`${category}-${value}`, option.name);
          }
        });
        this.filterCategories[category].count = values.length;
      }
    });

    const filterArray: [string, string][] = Array.from(this.selectedFiltersMap.entries()).map(([key, value]) => {
      const [cat, option] = key.split(/-(.*)/, 2);
      return [cat, option];
    });
    this.filterChanged.emit(filterArray);
  }

  private saveLastSelectedFilter(filterName: string): void {
    localStorage.setItem(this.getStorageKey('last'), filterName);
  }

  private restoreLastSelectedFilter(): void {
    const lastFilter = localStorage.getItem(this.getStorageKey('last'));
    if (lastFilter && this.savedFilterSelections[lastFilter]) {
      this.applyFilter(lastFilter);
    } else {
      this.loadCurrentSelections(); // Fallback to unsaved selections
    }
  }

  isUserDataEqual(previousData: any[], newData: any[]): boolean {
    if (previousData.length !== newData.length) return false;
    for (let i = 0; i < newData.length; i++) {
      const prevItem = previousData[i];
      const newItem = newData[i];
      if (
        prevItem?.surgeon_name !== newItem.surgeon_name ||
        prevItem?.appointment_type !== newItem.appointment_type ||
        prevItem?.insurance_name !== newItem.insurance_name ||
        prevItem?.alerts?.form_status !== newItem.alerts?.form_status
      ) {
        return false;
      }
    }
    return true;
  }

  ngOnInit(): void {
    this.loadUserFilters();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userData'] || changes['dateType']) {
      if (this.userData && this.userData.length > 0) {
        const isNewData = !this.isUserDataEqual(this.previousUserData, this.userData);
        if (isNewData || !this.hasProcessedInitialChange || changes['dateType']) {
          // Filter userData based on dateType
          let filteredUserData = [...this.userData];
          if (this.dateType === 'past') {
            filteredUserData = this.userData.filter(item => item.checkin_status === 'checked-in');
          } else if (this.dateType === 'future') {
            filteredUserData = this.userData.filter(item => item.checkin_status === 'pre-registered');
          } else {
            // 'current' or null: use all data
            filteredUserData = this.userData;
          }

          this.uniqueCategories = {
            surgeon_name: [],
            appointment_type: [],
            insurance_name: [],
            status: []
          };

          // Populate uniqueCategories from filtered userData
          filteredUserData.forEach(item => {
            if (item.surgeon_name && !this.uniqueCategories['surgeon_name'].includes(item.surgeon_name)) {
              this.uniqueCategories['surgeon_name'].push(item.surgeon_name);
            }
            if (item.appointment_type && !this.uniqueCategories['appointment_type'].includes(item.appointment_type)) {
              this.uniqueCategories['appointment_type'].push(item.appointment_type);
            }
            if (item.insurance_name && !this.uniqueCategories['insurance_name'].includes(item.insurance_name)) {
              this.uniqueCategories['insurance_name'].push(item.insurance_name);
            }
            if (item.alerts?.form_status && !this.uniqueCategories['status'].includes(item.alerts.form_status)) {
              this.uniqueCategories['status'].push(item.alerts.form_status);
            }
          });

          const statusValues: { [key: string]: string } = {
            'in_progress': 'In-Progress',
            'complete': 'Completed',
            'not_started': 'Sent but not opened',
            'no_status': 'Not sent at all',
          };

          // Update filterCategories options
          Object.keys(this.filterCategories).forEach(category => {
            const categoryData = this.uniqueCategories[category];
            this.filterCategories[category].options = this.filterCategories[category].options.filter(option =>
              categoryData.includes(option.id)
            );
            categoryData.forEach((value: string) => {
              if (category === 'status') {
                const mappedValue = statusValues[value] || value;
                if (!this.filterCategories[category].options.some(option => option.id === value)) {
                  this.filterCategories[category].options.push({ id: value, name: mappedValue });
                }
              } else {
                if (!this.filterCategories[category].options.some(option => option.id === value)) {
                  this.filterCategories[category].options.push({ id: value, name: value });
                }
              }
              this.filterCategories[category].options.sort((a, b) => a.name.localeCompare(b.name));
            });
            // Reset count and selections if data or dateType changes
            this.filterCategories[category].count = 0;
            this.filterCategories[category].options.forEach(option => option.selected = false);
          });

          this.selectedFiltersMap.clear();
          this.filterChanged.emit([]);

          if (!this.hasProcessedInitialChange) {
            this.restoreLastSelectedFilter();
            this.hasProcessedInitialChange = true;
          }

          this.previousUserData = [...this.userData]; // Store unfiltered userData for comparison
        }
      } else {
        // Clear filter options if userData is empty
        Object.keys(this.filterCategories).forEach(category => {
          this.filterCategories[category].options = [];
          this.filterCategories[category].count = 0;
        });
        this.selectedFiltersMap.clear();
        this.filterChanged.emit([]);
        this.previousUserData = [];
      }
    }
  }

  toggleDropdown(category: string): void {
    this.hoveredCategory = '';
  }

  toggleSavedFilters(): void {
    this.isSavedFiltersOpen = !this.isSavedFiltersOpen;
    Object.keys(this.filterCategories).forEach(key => {
      this.filterCategories[key].isOpen = false;
    });
  }

  private resetFilterLabelIfModified(): void {
    if (this.currentFilterLabel !== 'Filters') {
      this.currentFilterLabel = 'Filters';
    }
  }

  getFilterNameBySelection(selection: any): string {
    for (const [filterName, filters] of Object.entries(this.savedFilterSelections)) {
      if (JSON.stringify(filters) === JSON.stringify(selection)) {
        return filterName;
      }
    }
    return 'Filters';
  }

  toggleOption(category: string, optionName: string): void {
    const option = this.filterCategories[category].options.find(opt => opt.name === optionName);

    if (option) {
      option.selected = !option.selected;
      this.filterCategories[category].count = this.filterCategories[category].options.filter(opt => opt.selected).length;

      if (option.selected) {
        this.selectedFiltersMap.set(`${category}-${option.id}`, option.name);
      } else {
        this.selectedFiltersMap.delete(`${category}-${option.id}`);
      }

      const mapEntriesArray: [string, string][] = Array.from(this.selectedFiltersMap.entries()).map(([key, value]) => {
        const [cat, option] = key.split(/-(.*)/, 2);
        return [cat, option];
      });

      if (this.originalFilterState) {
        const currentSelection = this.getSelectedFilters();
        if (JSON.stringify(currentSelection) === JSON.stringify(this.originalFilterState)) {
          this.currentFilterLabel = this.getFilterNameBySelection(this.originalFilterState);
        } else {
          this.currentFilterLabel = 'Filters';
        }
      }

      this.filterChanged.emit(mapEntriesArray);
      this.saveCurrentSelections(); // Save current state after every toggle
    }
  }

  clearCategoryFilters(category: string): void {
    const categoryData = this.filterCategories[category];
    categoryData.options.forEach(option => {
      option.selected = false;
    });
    categoryData.count = 0;

    Array.from(this.selectedFiltersMap.keys())
      .filter(key => key.startsWith(`${category}-`))
      .forEach(key => this.selectedFiltersMap.delete(key));

    const mapEntriesArray: [string, string][] = Array.from(this.selectedFiltersMap.entries()).map(([key, value]) => {
      const [cat, option] = key.split(/-(.*)/, 2);
      return [cat, option];
    });
    this.currentFilterLabel = 'Filters';
    this.filterChanged.emit(mapEntriesArray);
    this.saveCurrentSelections(); // Save cleared state
    localStorage.removeItem(this.getStorageKey('last')); // Clear last named filter
  }

  clearAll(): void {
    Object.values(this.filterCategories).forEach(category => {
      category.count = 0;
      category.options.forEach(option => {
        option.selected = false;
      });
    });
    this.currentFilterLabel = 'Filters';
    this.selectedFiltersMap.clear();
    this.filterChanged.emit([]);
    this.originalFilterState = null;
    this.saveCurrentSelections(); // Save cleared state
    localStorage.removeItem(this.getStorageKey('last'));
  }

  saveNewFilter(filterName: string, selectedFilters: any): void {
    const newFilterStr = JSON.stringify(selectedFilters);
    const isDuplicate = Object.values(this.savedFilterSelections).some(
      filters => JSON.stringify(filters) === newFilterStr
    );

    if (isDuplicate) {
      return;
    }

    this.savedFilterSelections[filterName] = selectedFilters;
    this.savedFilters.push(filterName);
    this.saveUserFilters();
    this.saveLastSelectedFilter(filterName);
    this.currentFilterLabel = filterName;
    this.originalFilterState = JSON.parse(JSON.stringify(selectedFilters));
    this.saveCurrentSelections(); // Save current state with the new filter
  }

  removeFilter(filterName: string, event: Event): void {
    event.stopPropagation();

    const index = this.savedFilters.indexOf(filterName);
    if (index > -1) {
      this.savedFilters.splice(index, 1);
    }

    delete this.savedFilterSelections[filterName];
    this.saveUserFilters();

    const lastSelectedFilter = localStorage.getItem(this.getStorageKey('last'));
    if (lastSelectedFilter === filterName) {
      localStorage.removeItem(this.getStorageKey('last'));
      this.currentFilterLabel = 'Filters';
      this.originalFilterState = null;
      this.saveCurrentSelections(); // Update current state after removal
    }
  }

  findExistingFilterName(currentSelection: any): string | null {
    for (const [filterName, filters] of Object.entries(this.savedFilterSelections)) {
      if (JSON.stringify(filters) === JSON.stringify(currentSelection)) {
        return filterName;
      }
    }
    return null;
  }

  openSaveDialog(): void {
    const currentFilters = this.getSelectedFilters();
    const existingFilterName = this.findExistingFilterName(currentFilters);

    const dialogRef = this.dialog.open(SaveFilterDialogComponent, {
      width: '400px',
      data: {
        currentFilters,
        existingFilters: this.savedFilterSelections,
        filterNames: this.savedFilters,
        existingFilterName
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveNewFilter(result.filterName, result.selectedFilters);
      }
    });
  }

  getSelectedFilters(): any {
    const selectedFilters: any = {};
    Object.entries(this.filterCategories).forEach(([key, category]) => {
      const selected = category.options.filter(opt => opt.selected);
      if (selected.length > 0) {
        selectedFilters[key] = selected.map(opt => opt.id);
      }
    });
    return selectedFilters;
  }

  applyFilter(filterName: string): void {
    const filterSelections = this.savedFilterSelections[filterName];
    if (filterSelections) {
      this.currentFilterLabel = filterName;
      this.originalFilterState = JSON.parse(JSON.stringify(filterSelections));

      Object.keys(this.filterCategories).forEach(categoryKey => {
        this.filterCategories[categoryKey].options.forEach(option => {
          option.selected = false;
        });
        this.filterCategories[categoryKey].count = 0;
      });
      this.selectedFiltersMap.clear();

      Object.entries(filterSelections).forEach(([category, values]) => {
        if (Array.isArray(values) && values.length > 0) {
          values.forEach(value => {
            const option = this.filterCategories[category].options.find(opt => opt.id === value);
            if (option) {
              option.selected = true;
              this.selectedFiltersMap.set(`${category}-${value}`, option.name);
            }
          });
          this.filterCategories[category].count = values.length;
        }
      });

      const filterArray: [string, string][] = Array.from(this.selectedFiltersMap.entries()).map(([key, value]) => {
        const [cat, option] = key.split(/-(.*)/, 2);
        return [cat, option];
      });
      this.filterChanged.emit(filterArray);
      this.saveLastSelectedFilter(filterName);
      this.saveCurrentSelections(); // Save current state after applying named filter
    }
    this.isSavedFiltersOpen = false;
  }

  filterOptions(options: FilterOption[], categoryKey: string): FilterOption[] {
    const searchText = this.searchTexts[categoryKey] || '';
    if (!searchText) return options;
    return options.filter(option =>
      option.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  onMouseEnter(categoryKey: string): void {
    this.hoveredCategory = categoryKey;
  }

  onMouseLeave(): void {
    this.hoveredCategory = '';
  }

  hasActiveFilters(): boolean {
    return Object.values(this.filterCategories).some(category => category.count > 0);
  }

  isFilterModified(): boolean {
    const currentSelection = this.getSelectedFilters();
    return JSON.stringify(currentSelection) !== JSON.stringify(this.originalFilterState);
  }

  shouldShowSaveButton(): boolean {
    if (!this.hasActiveFilters()) return false;
    return this.isFilterModified();
  }

  clearSelection(category: string): void {
    const categoryData = this.filterCategories[category];
    const visibleOptions = this.filterOptions(categoryData.options, category);

    visibleOptions.forEach(option => {
      if (option.selected) {
        this.toggleOption(category, option.name);
      }
    });
  }

  isAllSelected(category: string): boolean {
    const categoryData = this.filterCategories[category];
    const visibleOptions = this.filterOptions(categoryData.options, category);
    return visibleOptions.length > 0 && visibleOptions.every(option => option.selected);
  }

  isSomeSelected(category: string): boolean {
    const categoryData = this.filterCategories[category];
    const visibleOptions = this.filterOptions(categoryData.options, category);
    return visibleOptions.some(option => option.selected) && !this.isAllSelected(category);
  }

  toggleAllOptions(category: string, checked: boolean): void {
    const categoryData = this.filterCategories[category];
    const visibleOptions = this.filterOptions(categoryData.options, category);

    visibleOptions.forEach(option => {
      if (option.selected !== checked) {
        this.toggleOption(category, option.name);
      }
    });
  }
}
