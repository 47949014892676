import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTooltip } from '@angular/material/tooltip';
import { TableActionsDrawerService } from '../table-actions-drawer/table-actions-drawer.service';
import { environment } from '../../../environments/environment';

interface Patient {
  client_name: string;
  surgeon_name: string;
  checkin_status: string;
  appointment_time: string;
  appointment_type: string;
  meeting_id: number;
  client_id: number
}

@Component({
  selector: 'app-broadcast',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    MatTooltip
  ],
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.css']
})
export class BroadcastComponent implements OnChanges {
  @Output() closeDrawerEvent = new EventEmitter();
  @ViewChild(MatSort) sort!: MatSort;
  @Input() selectedRecords: any[] = [];
  @Input() title!: { id: string; name: string }; // title of broadcast

  displayedColumns: string[] = ['select', 'client_name', 'surgeon_name', 'checkin_status', 'appointment_time', 'appointment_type'];
  dataSource: MatTableDataSource<Patient>;
  selection = new SelectionModel<Patient>(true, []);
  appt_reminders: boolean | undefined;
  slug = environment.slug

  messageTypes = [
    { id: 'sms', label: 'SMS', checked: true },
    { id: 'email', label: 'Email', checked: false },
    { id: 'notification', label: 'Send as Notification', checked: false },
  ];

  templateButtons = [
    {
      buttonName: 'Upcoming Appt Outreach',
      templateText: `This is ${this.getHospitalName(this.slug)}. We have been trying to reach you regarding your upcoming appointment. Please call us at ${localStorage.getItem('locationPhoneNumber') || ''}. Thank you!`
    },
    { buttonName: 'Inclement Weather',
      templateText: `This is ${this.getHospitalName(this.slug)}. Unfortunately, due to inclement weather, our office has closed today. Staff should be in touch with you soon regarding your appointment/medications. We apologize for the inconvenience.`
    },
    { buttonName: 'New Year Insurance Cards',
      templateText: `“Friendly reminder from ${this.getHospitalName(this.slug)}. We ask that you bring your MOST CURRENT insurance card with you to your upcoming appointment. Thank you for your cooperation.`
    },
    {
      buttonName: 'Mobile App Invitation',
      templateText: `\u{1F44B} Welcome to ${this.getHospitalName(this.slug)}! We're thrilled to introduce our new custom app designed to enhance your healthcare experience. \u{1F4F1} Download the app now to access appointment scheduling, treatment plans, medical records, health tips, and more! Your health, organized.\n\nTap the link below to download the app and get started on your health journey.\n${this.getMobileLink(this.slug)}`
    },
    {
      buttonName: 'Registration Reminder',
      templateText: 'Hello {client_name},\n\u{23F0}\nA reminder of your appointment at {hospital_name}.\nYour appointment is reserved for {appointment_time}. Please arrive 10 minutes early to complete your registration.\n\nRemember to fill out registration form before your appointment: {form_link}.'
    }
  ];
  newNote: string = '';

  constructor(
    private tableActionsDrawerService: TableActionsDrawerService
  ) {
    this.dataSource = new MatTableDataSource<Patient>([]);
  }

  ngOnChanges() {
    if (this.selectedRecords?.length) {
      // Update the data source with the new selected records
      this.dataSource.data = this.selectedRecords;
      // Update the selection model
      this.selection.clear();
      this.selectedRecords.forEach(record => {
        this.selection.select(record);
      });
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  getHospitalName (slug: string) {
    switch(slug) {
      case 'akps':
        return 'AK Pain & Spine'
      case 'cps':
        return 'Commonwealth Pain & Spine'
      default:
        return 'Hospital Name'
    }
  }

  getMobileLink(slug: string) {
    switch(slug) {
      case 'akps':
        return 'ios : https://apps.apple.com/us/app/akpain-spine/id6471281069\nandroid : https://play.google.com/store/apps/details?id=com.akps.akpsapp'
      case 'cps':
        return 'https://qr2.mobi/1qoo0'
      default:
        return 'https://qr2.mobi/1qoo0'
    }
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  toggleMessageType(selectedType: string) {
    this.messageTypes = this.messageTypes.map(type => ({
      ...type,
      checked: type.id === selectedType ? !type.checked : type.checked
    }));
  }

  triggerCloseDrawer() {
    this.closeDrawerEvent.emit();
  }

  addTemplate(templateText: string, name: string) {
    this.newNote = templateText;
    this.appt_reminders = name === 'Registration Reminder' ? true : undefined
  }

  sendBroadcast() {
    const message = this.newNote;
    const clientMeetingKeyValues = this.selection.selected.reduce<Record<string, number>>((acc, cur) => {
      const clientString = cur.client_id.toString()
      acc[clientString] = cur.meeting_id
      return acc
    }, {})
    const data = {
      message: message,
      client_meeting: clientMeetingKeyValues,
      medium: this.messageTypes.filter((type) => type.checked).map((type) => type.id),
      appt_reminders: this.appt_reminders
    }

    // broadcast sending logic
    this.tableActionsDrawerService.sendBroadCast('broadcast', data).subscribe({
      next: (response) => {
      },
      error: (err) => {
      }
    });
  }
}
