import {Component, DestroyRef, Inject, inject, OnInit} from '@angular/core';
import {CommonModule, NgIf} from '@angular/common';
import {AlertsComponent} from "../dashboard_view/alerts/alerts.component";
import {CalendarComponent} from "../dashboard_view/calendar/calendar.component";
import {MakeEngagingCallsComponent} from "../dashboard_view/make-engaging-calls/make-engaging-calls.component";
import {MakeMonthlyCallsComponent} from "../dashboard_view/make-monthly-calls/make-monthly-calls.component";
import {MessagesComponent} from "../dashboard_view/messages/messages.component";
import {
  TasksActivePendingPatientsComponent
} from "../dashboard_view/tasks-active-pending-patients/tasks-active-pending-patients.component";
import {TasksDealWithAlertsComponent} from "../dashboard_view/tasks-deal-with-alerts/tasks-deal-with-alerts.component";
import {
  TasksRepondToPatientsComponent
} from "../dashboard_view/tasks-repond-to-patients/tasks-repond-to-patients.component";
import {MatGridListModule} from "@angular/material/grid-list";
import {DashboardService, Alert, Alerts} from "./dashboard.service";
import {map} from "rxjs/operators";
import {Observable, Subject, Subscription, takeUntil} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";

import {
  AlertsBehaviorService,
  MakeEngagingCallsBehaviorService,
  GroupTasksService,
  TasksAllBehaviorService
} from "../dashboardPassingService";
import {LoadingService} from "../objectPassingService";
import {LoadingComponent} from "../resusable/loading/loading.component";
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatListModule} from "@angular/material/list";
import {AddTaskComponent} from "../resusable/add-task/add-task.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatInputModule} from "@angular/material/input";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {MatBadgeModule} from "@angular/material/badge";
import {TasksAllComponent} from "../dashboard_view/tasks-all/tasks-all.component";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, AlertsComponent, CalendarComponent, MakeEngagingCallsComponent, MakeMonthlyCallsComponent, MessagesComponent,
    TasksActivePendingPatientsComponent, TasksDealWithAlertsComponent, TasksRepondToPatientsComponent, MatGridListModule,
    LoadingComponent, MatCardModule, MatButtonModule, MatIconModule,
    MatTooltipModule, MatSlideToggleModule, MatExpansionModule, MatListModule, AddTaskComponent, MatInputModule, MatDatepickerModule, MatBadgeModule, TasksAllComponent],
  providers: [ MatNativeDateModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  destroyed_alerts = new Subject();

  is_task_group_enabled = false;
  is_expanded = true;
  alerts$!: Observable<Alert[]>
  user_id: number = 0;

  //alerts$: Subscription | undefined
  //alertsCritical$: Observable<Alert[]> | undefined
  //alertsNormal$: Observable<Alert[]> | undefined
  //alertsS: Subscription | undefined

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private alertsBehaviorService: AlertsBehaviorService,
    private makeEngagingCallsBehaviorService: MakeEngagingCallsBehaviorService,
    private tasksAllBehaviorService: TasksAllBehaviorService,
    private groupTasksService: GroupTasksService,
    private loadingService: LoadingService,
  ) {

    this.groupTasksService.announceGroupTasksBehavior(this.is_task_group_enabled)

    this.groupTasksService.GroupTasksBehaviorAnnounced$.pipe(takeUntilDestroyed()).subscribe(
      (data: boolean) => {
        this.is_task_group_enabled = data;
      },
    )

    this.route.params.pipe(takeUntilDestroyed()).subscribe(parms => {
      const user_id = parms['user_id'];
      this.user_id = user_id;
      this.getParentData(user_id);
    });

  }

  ngOnInit(): void {



    this.destroyRef.onDestroy(() => {
      this.destroyed_alerts.next(true);
      this.destroyed_alerts.complete();
    });

    //const alerts$ =  this.dashboardService.getAlerts('alerts', true)

    // My Observable
    // this.alerts$ = alerts$.pipe(
    //   map(
    //     res => {
    //       // @ts-ignore
    //       return res['message'];
    //     }
    //   ))


    // this.alerts$ = alerts$.pipe(
    //   map(
    //     res =>  res.message
    //   ))


    // My Subscription
    // this.alertsS = alerts$.pipe(
    //   map(
    //     res => {
    //       // @ts-ignore
    //       return res.message;
    //     }
    //   )
    // ).subscribe(
    //   (data: Alert[]) => {
    //     console.log('dataOMG', data);
    //   },
    //   (error: any) => {
    //     console.log('error', error);
    //   }
    // )
    //
    // this.dashboardService.getAlerts('alerts', true).pipe(
    //   map(
    //     res => {
    //
    //       return res.message;
    //     })
    // ).subscribe(
    //       (data: Alert[]) => {
    //         console.log('dataYAY', data);
    //       },
    //       (error: any) => {
    //         console.log('error', error);
    //       }
    //     )



  } //end ngOnInit


  getParentData(user_id: number){
    console.log('user_id',user_id);

    const alerts$ =  this.dashboardService.getAlertsByUser('alerts', user_id, false);
    this.alerts$ = alerts$.pipe();

    this.loadingService.showLoadingUntilCompleted(alerts$).subscribe(
      (data: Alert[]) => {

      }
    )

    // lets pass all alerts to tasks all
    alerts$.pipe(
      takeUntil(this.destroyed_alerts)
    ).subscribe(
      (data: Alert[]) => {
        this.tasksAllBehaviorService.announceTasksAllBehavior(data);
      },
    )

    // alerts$.pipe(
    //   takeUntil(this.destroyed_alerts),
    //   map(
    //     alerts =>  alerts.filter(alert => alert.code === 'critical')
    //   )).subscribe(
    //   (data: Alert[]) => {
    //     this.alertsBehaviorService.announceAlertsBehavior(data);
    //   },
    // )
    //
    // alerts$.pipe(
    //   takeUntil(this.destroyed_alerts),
    //   map(
    //     alerts =>  alerts.filter(alert => alert.code === 'normal')
    //   )).subscribe(
    //   (data: Alert[]) => {
    //     this.makeEngagingCallsBehaviorService.announceMakeEngagingCallsBehavior(data);
    //   },
    // )


  } //end getParentData


  addTask() {
    console.log('addTask');



    let row_data = {
      user_id: this.user_id,
    }
    const  enterAnimationDuration = '0ms';
    const exitAnimationDuration = '0ms';

    let dialogRef = this.dialog.open(DialogAddTask, {
      width: '350px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: row_data
    });


    dialogRef.afterClosed().subscribe((result: string | undefined) => {

      if (result == 'cancel' || result == undefined) {
        console.log('we cancled')
      } else {
        this.getParentData(this.user_id)

      }
    });//end dialogRef






  } //end addTask

  onTaskGroup($event:any, slide:any) {
    console.log('onEnabledChange', $event, slide);
    this.is_task_group_enabled = $event.checked;
    this.groupTasksService.announceGroupTasksBehavior(this.is_task_group_enabled)
  } //end onEnabledChange

} //end DashboardComponent











/*---------------------------------------------
Dialog for adding a new user
---------------------------------------------*/


interface DialogAddTaskData{
  user_id: number;
  client_id?: number;

}

@Component({
  selector: 'add-user-dialog',
  templateUrl: 'add-user-dialog.html',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatProgressBarModule,
    MatInputModule,
    ReactiveFormsModule,
    NgIf,
    AddTaskComponent,
    MatDatepickerModule,MatNativeDateModule
  ],
  providers: [MatDatepickerModule],
})
export class DialogAddTask implements OnInit{

  client_id: number = 0;
  user_id: number = 0;

  constructor(
              public dialogRef: MatDialogRef<DialogAddTask>,
              @Inject(MAT_DIALOG_DATA) public data: DialogAddTaskData) {}

  ngOnInit(): void {
    // this.client_id = this.data.client_id;
    // @ts-ignore
    this.user_id = this.data.user_id;
  } //end ngOnInit

  result($event:any ) {
    this.dialogRef.close($event);
  } //end result



} //end DialogAddClient
