<div class="alerts-container">
  <div class="alerts-header">
    <div class="header-content">
      <i class="healent-path-3621 blink"></i>
      <span class="alerts-title">Alerts ({{alertsCount}})</span>
    </div>
  </div>

  <div class="mat-mdc-table-container">
    <table mat-table [dataSource]="alerts">
      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td class="alert-type" mat-cell *matCellDef="let alert" [ngClass]="{'ignored-text': alert.ignored}">{{getAlertType(alert.type)}}</td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let alert" [ngClass]="{'ignored-text': alert.ignored}">
          <ng-container>
            {{alert.errorDetails}}
          </ng-container>
        </td>
      </ng-container>

      <!-- Time Column -->
      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef>Time</th>
        <td mat-cell *matCellDef="let alert" [ngClass]="{'ignored-text': alert.ignored}">{{alert.time}}</td>
      </ng-container>

      <!-- Ignore Column -->
      <ng-container matColumnDef="ignore">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let alert">
          <span class="ignore-link" (click)="onIgnore(alert, $event)">
            {{getIgnoreButtonText(alert)}}
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
