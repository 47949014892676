<mat-progress-bar
  color="accent"
  mode="indeterminate"
  *ngIf="is_loading"
></mat-progress-bar>

<div class="main-container">
  <div class="main-header">
    <div class="header-left">
      <h1>Users</h1>
    </div>
    <div class="header-right">
      <mat-form-field class="search-input">
        <i class="healent-search" matPrefix></i>
        <input
          matInput
          placeholder="Search patients"
          (keyup)="applyFilter($event)"
          #input
          [(ngModel)]="searchText"
        />
        <span class="btn-hint" matSuffix *ngIf="!searchText">
          (Shift + Space)
        </span>
        <i
          class="btn-close healent-group-4555"
          matSuffix
          *ngIf="searchText"
          (click)="clearSearch(input)"
        >
        </i>
      </mat-form-field>
    </div>
  </div>
  <div class="main-body">
    <div class="table-content">
      <mat-card class="mat-elevation-z24">
        <div class="users table-wrapper">
          <table mat-table [dataSource]="dataSource" matSort>
            <!-- User Id Column -->
            <ng-container matColumnDef="user_id">
              <th class="w20" mat-header-cell *matHeaderCellDef mat-sort-header>
                User ID
              </th>
              <td mat-cell *matCellDef="let row">{{ row.user_id }}</td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <th class="w20" mat-header-cell *matHeaderCellDef mat-sort-header>
                Email
              </th>
              <td mat-cell *matCellDef="let row">{{ row.email }}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th class="w20" mat-header-cell *matHeaderCellDef mat-sort-header>
                Name
              </th>
              <td mat-cell *matCellDef="let row">{{ row.name }}</td>
            </ng-container>

            <!-- View Column -->
            <ng-container matColumnDef="View">
              <th class="w5" mat-header-cell *matHeaderCellDef mat-sort-header>
                View
              </th>
              <td mat-cell *matCellDef="let row">
                <button
                  mat-icon-button
                  class="any-icon-primary table-icon"
                  (click)="viewData(row)"
                >
                  <mat-icon color="accent">visibility</mat-icon>
                </button>
              </td>
            </ng-container>

            <!--  &lt;!&ndash; Edit Column (click)="addAccount(true,row.Id)" &ndash;&gt;-->
            <!--  <ng-container matColumnDef="Edit">-->
            <!--    <th mat-header-cell *matHeaderCellDef mat-sort-header>Edit</th>-->
            <!--    <td mat-cell *matCellDef="let row">-->
            <!--      <button mat-icon-button class="any-icon-primary table-icon" (click)="addUser(true,  row)">-->
            <!--        <mat-icon color="accent" style="cursor: pointer;">edit</mat-icon>-->
            <!--      </button>-->
            <!--    </td>-->
            <!--  </ng-container>-->

            <!--  &lt;!&ndash; Delete Column &ndash;&gt;-->
            <!--  <ng-container matColumnDef="Delete">-->
            <!--    <th mat-header-cell *matHeaderCellDef mat-sort-header>Delete</th>-->
            <!--    <td mat-cell *matCellDef="let row">-->
            <!--      <button mat-icon-button class="warn-icon-primary table-icon" (click)="deleteRow(row)">-->
            <!--        <mat-icon color="warn" style="cursor: pointer;">delete</mat-icon>-->
            <!--      </button>-->
            <!--    </td>-->
            <!--  </ng-container>-->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              class="element-row"
              (click)="getTableRecord(row)"
            ></tr>
          </table>

          <mat-paginator
            [pageSizeOptions]="[5, 10, 20, 50, 100, 250, 500]"
            [pageSize]="100"
            showFirstLastButtons
            aria-label="Select page of periodic elements"
          >
          </mat-paginator>
        </div>
      </mat-card>
    </div>
  </div>
</div>
