import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';

//https://angular.io/api/forms/Validators

export class ModelValidators{


  /*
	static cannotContainSpace(control: Control){
		if (control.value.indexOf(' ')>=0 )
			return { cannotContainSpace: true}
		return null;

	}
	(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)
	*/

	static isValidKeywordTemplate(control: FormControl){
		var valid: any;

		var str = control.value
		valid=null;
		if (str.startsWith("SYMBOL") || str.startsWith(" SYMBOL") || str.startsWith("SYMBOL ") || str.indexOf(" SYMBOL ") >= 0 || str.indexOf(" NAME") >= 0 || str.startsWith("NAME") || str.indexOf("FULLNAME") >= 0  ){
			valid = true;
		}

		return valid ? null : { isValidKeywordTemplate: true };
	}

	static isCurrencyFormatValidFloat(control: FormControl){

		var test  = control.value.replace(/[^0-9\.]+/g,"")

		var val = parseFloat(test)
		var isNum = isNaN(val)

		var valid: any;
		valid=null;
		if (isNum==false){
			valid = true;
		}

		//valid = true;
		return valid ? null : { isCurrencyFormatValidFloat: true };
	}

  static cannotContainSpaceValidator(): ValidatorFn {
    // https://blog.angular-university.io/angular-custom-validators/

    return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
        return null;
      }

      // const hasUpperCase = /[A-Z]+/.test(value);
      //
      // const hasLowerCase = /[a-z]+/.test(value);
      //
      // const hasNumeric = /[0-9]+/.test(value);
      //
      // const passwordValid = hasUpperCase && hasLowerCase && hasNumeric;
      //
      // return !passwordValid ? {passwordStrength:true}: null;


      const regEx = /^\S*$/;
      let valid = regEx.test(control.value);



      return valid ? null : { cannotContainSpaceValidator: true };

    }
  }

  static passwordCognitoControlsValidator(): ValidatorFn {
    // https://blog.angular-university.io/angular-custom-validators/

    return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
        return null;
      }

      // const hasUpperCase = /[A-Z]+/.test(value);
      //
      // const hasLowerCase = /[a-z]+/.test(value);
      //
      // const hasNumeric = /[0-9]+/.test(value);
      //
      // const passwordValid = hasUpperCase && hasLowerCase && hasNumeric;
      //
      // return !passwordValid ? {passwordStrength:true}: null;


      const regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.\[\]{}()?\-\\/,><:;|_~`+="'])(?=.{8,100})/;
      let valid = regEx.test(control.value);


      //console.log('passwordCognitoControls', valid, control.value, control.value.length)

      return valid ? null : { createPasswordStrengthValidator: true };

    }
  }


  static cannotContainSpace(control: FormControl){

		var regEx = /^\S*$/;
        var valid = regEx.test(control.value);
        return valid ? null : { cannotContainSpace: true };

	}

	static passwordControls(control: FormControl){

		var regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,100})/;
		var valid = regEx.test(control.value);


		return valid ? null : { passwordControls: true };

	}

  static passwordCognitoControls(control: FormControl){

    /*

    ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` + =

    [a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$

     */

    var regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#|\$%\^&\^,\^_\*])(?=.{8,100})/;
    var valid = regEx.test(control.value);

    return valid ? null : { passwordCognitoControls: true };

  }

  static emailControls(control: FormControl){

    var regEx = /^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$/;
    var valid = regEx.test(control.value);


    return valid ? null : { emailControls: true };

  }

	static onlyAlphaNurmeric(control: FormControl){

		var regEx = /^[a-zA-Z0-9]*$/;
        var valid = regEx.test(control.value);
        return valid ? null : { onlyAlphaNurmeric: true };

	}

	static onlyAlphaAndLowerCase(control: FormControl){

		var regEx = /^[a-z]*$/;
        var valid = regEx.test(control.value);
        return valid ? null : { onlyAlphaAndLowerCase: true };

	}

  static cantContainLeadingZero(control: FormControl){

    var regEx = /^[1-9]?[0-9]$/;
    var valid = regEx.test(control.value);


    return valid ? null : { cantContainLeadingZero: true };

  }


	static validPositiveInteger(control: FormControl){

    //

    var regEx = /^\+?(0|[1-9]\d*)$/;
    //var valid = regEx.test(control.value);

    // let ass = control.value.toString()
    // console.log(ass.includes('.'))

    var valid = false
    if (regEx.test(control.value)){
      valid = true;
    }


    return valid ? null : { validPositiveInteger: true };

	}

  static validPositiveIntegerGreaterThanZero(control: FormControl){

    //

    var regEx = /^\+?([1-9]\d*)$/;
    //var valid = regEx.test(control.value);

    // let ass = control.value.toString()
    // console.log(ass.includes('.'))

    var valid = false
    if (regEx.test(control.value)){
      valid = true;
    }


    return valid ? null : { validPositiveIntegerGreaterThanZero: true };

  }

  static validMfa(control: FormControl){

    var regEx = /[0-9]{6}/;
    var valid = regEx.test(control.value);


    return valid ? null : { validMfa: true };

  }

	static validPositiveIntegerSeed(control: FormControl){

        //valid=null
				if (!control.value || 0 === control.value.length){
					valid = true;
					return valid ? null : { validPositiveIntegerSeed: true };
				}

				var regEx = /^\+?(0|[1-9]\d*)$/;
				var valid = regEx.test(control.value);
				if (valid){

					if (control.value>=1 && control.value<=1000){
						valid = true
					} else {
						valid = false
					}
				} else {
					//valid=null
				}

				return valid ? null : { validPositiveIntegerSeed: true };

	}

	static validLag(control: FormControl){

        var valid: any;
		valid=null;

		var is_int = control.value % 1 === 0

		//var regEx = /^\+?(0|[1-9]\d*)$/;
        //var isInt = regEx.test(control.value);

		if (control.value>=0 && control.value<=60 && is_int==true){
			valid = true;
		}

        return valid ? null : { validLag: true };

	}

	static validPositiveFloat(control: FormControl){
		var valid: any;
		valid=null;
		if (control.value>=0){
			valid = true;
		}

        return valid ? null : { validPositiveFloat: true };

	}

	static validCurrency(control: FormControl){

		let number = 0.0;
		if (typeof control.value === 'string' || control.value instanceof String){
			console.log('We are a string...')
			if (control.value.indexOf("$") >= 0){
				number = Number(control.value.replace(/[^0-9\.-]+/g,""));
			}
		} else {
			number = control.value
		}

		var valid: any;
		valid=null;
		if (number>=0){
			valid = true;
		}
        return valid ? null : { validCurrency: true };

	}

	static validMinBid(control: FormControl){
		var valid: any;
		valid=null;
		if (control.value>=0.01){
			valid = true;
		}
        return valid ? null : { validMinBid: true };

	}

	static validPercentage(control: FormControl){
		var valid: any;
		valid=null;
		if (control.value>=0 && control.value<=1.0){
			valid = true;
		}
        return valid ? null : { validPercentage: true };

	}

	static validMaxAnalysisDate(control: FormControl){
        var valid: any;
		valid=null;
		var diff = new Date('2016-08-15').valueOf() - new Date(control.value).valueOf();

		if (diff>=0){
			valid=true;
		}

        return valid ? null : { validMaxAnalysisDate: true };

	}

	static validVar(control: FormControl){
		var valid: any;
		valid=null;
		if (control.value>=.8 && control.value<1){
			valid = true;
		}

        return valid ? null : { validVar: true };

	}

	static validLambda(control: FormControl){
		var valid: any;
		valid=null;
		if (control.value>0 && control.value<1){
			valid = true;
		}
        return valid ? null : { validLambda: true };

	}

	static validEtr(control: FormControl){
		var valid: any;
		valid=null;
		if (control.value>=0 && control.value<1){
			valid = true;
		}
        return valid ? null : { validEtr: true };

	}

	static validStartDate(control: FormControl){
		//http://stackoverflow.com/questions/14980014/how-can-i-calculate-the-time-between-2-dates-in-typescript
		var valid: any;
		valid=null;
        var diff:any;

		diff = new Date(control.value).valueOf() - new Date('2000-01-01').valueOf();

		if (diff>=0){
			valid=true;
		}
        return valid ? null : { validStartDate: true };

	}

	static validEndDate(control: FormControl){
		//http://stackoverflow.com/questions/14980014/how-can-i-calculate-the-time-between-2-dates-in-typescript
		var valid: any;
		valid=null;
        var diff:any;

		diff = new Date(control.value).valueOf() - new Date('2002-01-01').valueOf();

		if (diff>0){
			valid=true;
		}
        return valid ? null : { validEndDate: true };

	}

	static cannotContainUppercase(control: FormControl){
		if (control.value.indexOf(' ')>=0 )
			return { cannotContainUppercase: true}

		return null;

	}


}

